import { BillInfo } from '@did/das-app-components'
import { useDasBalanceContext } from '@did/das-app-context'
import { useRewardRecordsList } from '@did/das-app-hooks'
import { smartOpen } from '@did/tools'
import { LANGUAGE } from '@did/types/uikit'
import React from 'react'

export const RewardRecords = () => {
  const { tt, services, connectedAccount, toast, router, outSiteUrl, lang } =
    useDasBalanceContext()

  const { billList, loading } = useRewardRecordsList({ size: 5 })

  return (
    <BillInfo
      loading={loading}
      billList={billList}
      title={tt('Reward records')}
      footer={
        <span
          className="text-sm cursor-pointer text-[#22C493] hover:opacity-70"
          onClick={() => {
            router.push('/referral/reward-records')
          }}
        >
          {tt('View all')}
        </span>
      }
      description={tt('邀请奖励说明', {
        balance: (
          <span
            className="text-[#3E66B3] cursor-pointer hover:opacity-70"
            onClick={() => {
              smartOpen(outSiteUrl?.dasBalanceUrl!)
            }}
          >
            {tt('dotbit Balance')}
          </span>
        ),
        faq: (
          <span
            className="text-[#3E66B3] cursor-pointer hover:opacity-70"
            onClick={() => {
              smartOpen(
                lang?.value === LANGUAGE.zhCN
                  ? 'https://talk.did.id/t/bit/630'
                  : 'https://talk.did.id/t/bits-reward-system/631'
              )
            }}
          >
            {tt('Why are some rewards delayed?')}
          </span>
        )
      })}
    />
  )
}
