import { useDasBalanceContext } from '@did/das-app-context'
import {
  useHandleRegister,
  useLocalstorage,
  useRegisterInfo,
  useSaveCodeItem
} from '@did/das-app-hooks'
import { RegisterPageType } from '@did/das-app-types/module'
import { BottomSheet } from '@did/uikit'
import { useMemo } from 'react'
import { DasBalanceInsufficientDialog } from './das-balance-insufficient-dialog'
import { FiatPayDialog } from '@did/stripe'
import { PaymentConfirm } from './payment-confirm'
import { RegisterInfo } from './register-info'
import { SignatureErrorDialog } from './signature-error-dialog'
import { UpdatedRegistrationPeriodDialog } from './updated-registration-period-dialog'
import { SaveCodeItem } from './save-code-item'

export const RegisterPage: RegisterPageType = ({
  bitName,
  couponCode,
  inviter: propsInviter,
  channel: propsChannel
}) => {
  const { tt } = useDasBalanceContext()
  const { getLocalData, setInviter } = useLocalstorage()
  const {
    accountInfo,
    annualFee,
    storageDeposit,
    registrationPeriod,
    onRegisterPeriodChange,
    totalCost
  } = useRegisterInfo({ bitName, couponCode })

  const inviter = useMemo(() => {
    if (propsInviter) {
      return propsInviter
    }
    return getLocalData()?.me?.inviter
  }, [propsInviter])

  const channel = useMemo(() => {
    if (propsInviter) {
      return propsInviter
    }
    return getLocalData()?.me?.channel
  }, [propsChannel])

  const {
    saveCodeProps,
    checkCouponCode,
    setBuilderCodeCheckResult,
    setBuilderCodeErrorMessages,
    checkInviter
  } = useSaveCodeItem({
    accountInfo,
    totalCost,
    annualFee,
    couponCode,
    inviter
  })

  const { originalPrice, paidAmount } = useMemo(() => {
    return {
      originalPrice: totalCost,
      paidAmount: totalCost.sub(saveCodeProps?.saveFee || 0)
    }
  }, [totalCost, saveCodeProps?.saveFee])

  const {
    handleRegister,
    handleConfirm,
    registerWithCouponCode,
    goRegisterStatusPage,
    paymentTokens,
    selectedToken,
    setSelectedToken,
    clientSecret, // stripe client secret
    // payment amount
    stripeServiceFee,
    stripePaidAmount,
    paidTokenAmount,
    // loading state
    onRegisterLoading,
    confirmRegisterLoading,
    // payment modal show
    confirmRegisterShowing,
    setConfirmRegisterShowing,
    fiatPayDialogShowing,
    setFiatPayDialogShowing,
    // error messages dialog
    updatedRegistrationPeriodDialogShowing,
    setUpdatedRegistrationPeriodDialogShowing,
    dasBalanceInsufficientDialogShowing,
    signatureErrorDialogShowing,
    setDasBalanceInsufficientDialogShowing,
    setSignatureErrorDialogShowing,
    orderId
  } = useHandleRegister({
    couponCode: saveCodeProps?.builderCode || '',
    originalPrice,
    paidAmount,
    bitName,
    registrationPeriod,
    inviter: saveCodeProps.inviter || '',
    channel: channel || '',
    checkCouponCode,
    checkInviter: async (inviterStr) => {
      const checkResult = await checkInviter(inviterStr)
      if (checkResult) {
        // save to localstorage
        setInviter(inviterStr)
      }
      return checkResult
    },
    setBuilderCodeCheckResult,
    setBuilderCodeErrorMessages
  })

  const returnUrl = useMemo(() => {
    try {
      return `${
        new URL(window?.location?.href).origin
      }/bit/account/create/status/${bitName}?paid=1&action=`
    } catch (e: any) {
      return ''
    }
  }, [])

  return (
    <div className="w-full md:w-[680px] mx-auto pt-5 md:px-0 md:pb-8 flex flex-col items-center flex-[1_0_0] justify-end md:justify-start">
      <RegisterInfo
        handleRegister={handleRegister}
        bitName={bitName}
        annualFee={annualFee as any as number}
        storageDeposit={storageDeposit}
        registrationPeriod={registrationPeriod}
        onRegisterPeriodChange={onRegisterPeriodChange}
        totalCost={originalPrice as any as number}
        paidAmount={paidAmount as any as number}
        registerLoading={onRegisterLoading}
        couponCode={couponCode}
        additionInfo={<SaveCodeItem {...saveCodeProps} />}
      />
      <BottomSheet
        title={<div className="px-3 sm:px-0">{tt('Select payment')}</div>}
        open={confirmRegisterShowing}
        onClose={() => {
          setConfirmRegisterShowing(false)
        }}
      >
        <PaymentConfirm
          onTokenChange={setSelectedToken}
          selectedToken={selectedToken}
          stripePaidAmount={stripePaidAmount}
          stripeServiceFee={stripeServiceFee}
          paidTokenAmount={paidTokenAmount}
          fiatTermsLink="https://topdid.com/terms.html"
          tokenList={paymentTokens}
          handleConfirm={handleConfirm}
          confirmRegisterLoading={confirmRegisterLoading}
        />
      </BottomSheet>
      <UpdatedRegistrationPeriodDialog
        showing={updatedRegistrationPeriodDialogShowing}
        onClose={() => {
          setUpdatedRegistrationPeriodDialogShowing(false)
        }}
        paidAmount={paidAmount as any as string}
        onContinue={registerWithCouponCode}
      />
      <FiatPayDialog
        showing={fiatPayDialogShowing}
        title={tt('Pay to Join')}
        onClose={() => {
          setFiatPayDialogShowing(false)
        }}
        returnUrl={returnUrl}
        clientSecret={clientSecret!}
        orderId={orderId}
        successCallback={goRegisterStatusPage}
      />
      <DasBalanceInsufficientDialog
        selectToken={selectedToken}
        showing={dasBalanceInsufficientDialogShowing}
        onClose={() => {
          setDasBalanceInsufficientDialogShowing(false)
        }}
        registrationFees={paidTokenAmount}
      />
      <SignatureErrorDialog
        showing={signatureErrorDialogShowing}
        onClose={() => {
          setSignatureErrorDialogShowing(false)
        }}
      />
    </div>
  )
}

export { DutchAuctionPage } from './dutch-auction-page'
