import { Dropdown, Iconfont } from '@did/uikit'
import {
  AccountStatusFilterType,
  IAccountStatusFilterOption
} from '@did/das-app-types/components'
import { useCallback, useState, FormEvent, useMemo } from 'react'
import { cn } from '@did/tools'

export const AccountStatusFilter: AccountStatusFilterType = ({
  value,
  className,
  options,
  onInput
}) => {
  const [optionsShowing, setOptionsShowing] = useState(false)
  const selectOption = useMemo<IAccountStatusFilterOption>(
    () => options?.find((v) => v.value === value) || ({} as any),
    [options, value]
  )
  const onSelect = useCallback(
    (value: string) => {
      setOptionsShowing(false)
      onInput(parseInt(value) as any)
    },
    [onInput]
  )

  const onFilterInput = useCallback((event: FormEvent<HTMLInputElement>) => {
    onInput((event.target as HTMLInputElement).value as any)
  }, [])

  return (
    <Dropdown
      align="start"
      options={options}
      onMenuClick={onSelect}
      itemClassName="p-[13px] text-left cursor-pointer whitespace-nowrap text-normal font-medium text-tab-active hover:rounded-lg hover:bg-normal-color"
    >
      <div
        className={cn(
          'relative flex items-center justify-between cursor-pointer',
          className
        )}
      >
        <input
          value={selectOption.label}
          className="inline-block w-full h-[41px] pr-8 pl-3 rounded-xl border-0 text-neutral-700 font-medium bg-gray-100 outline-0 text-sm cursor-pointer"
          type="text"
          readOnly
          onInput={onFilterInput}
        />
        <Iconfont
          className="absolute z-[0] top-[14.5px] right-[10px]"
          name="arrow-down"
          color="#868C98"
          size={12}
        />
      </div>
    </Dropdown>
  )
}
