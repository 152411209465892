import { DasButton, Dialog, IconImage } from '@did/uikit'
import { SwitchWalletTipsType } from '@did/das-app-types/components'
import { useDasBalanceContext } from '@did/das-app-context'
import React, { useMemo } from 'react'
import { cn, findParsingRecordChain } from '@did/tools'

export const SwitchWalletTips: SwitchWalletTipsType = ({
  showing,
  title,
  message,
  address,
  coinType,
  onSwitchWallet,
  onClose
}) => {
  const { tt } = useDasBalanceContext()

  const findLogoImage = (coinType: string) => {
    if (!coinType) return ''
    return `/bit/images/chain/chain-${
      findParsingRecordChain(coinType).value
    }.png`
  }

  const addressImage = useMemo(() => {
    if (coinType) {
      return findLogoImage(coinType)
    }
    return ''
  }, [coinType])

  return (
    <Dialog
      showing={showing}
      title={title || tt('Tips')}
      closeButton
      onClose={onClose}
    >
      <div className="text-neutral-700 text-sm font-medium leading-tight">
        {message}
      </div>
      {address && coinType && (
        <div
          className={cn(
            'flex items-center justify-between mt-3 py-[9px] px-[10px] bg-[#EDF0F2] rounded-[26px] w-full'
          )}
        >
          <span className={cn('inline-flex items-center')}>
            {addressImage && <IconImage url={addressImage} size={32} rounded />}
            <span className={cn('ml-2')}>
              <div
                className={cn(
                  'font-mono text-neutral-900 text-base font-bold leading-tight break-all'
                )}
              >
                {address}
              </div>
            </span>
          </span>
        </div>
      )}
      <div className="py-8">
        <DasButton black block onClick={onSwitchWallet}>
          {tt('Switch wallet')}
        </DasButton>
      </div>
    </Dialog>
  )
}
