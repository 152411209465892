const WalletErrorMap = {
  // MetaMask
  metaMaskUserDeniedMessageSignature: 4001,
  metaMaskWalletRequestPermissions: -32002,
  metaMaskUserRejectedAccountAccess: -32603,
  metaMaskWalletInvalidSignature: -32000,
  metaMaskReplacementTransactionUnderpriced:
    'replacement transaction underpriced',
  metaMaskTransactionHasBeenAborted: 'the transaction has been aborted',
  metaMaskUserRejectedTheRequest: 'User rejected the request',
  // WalletConnect
  walletConnectUserRejectedTheTransaction: 'User rejected the transaction',
  walletConnectInsufficientFundsForTransfer: 'insufficient funds for transfer',
  walletConnectConnectionRequestReset:
    'Connection request reset. Please try again.',
  walletConnectUserRejectedTheRequest: 'User rejected the request',
  // TronLink
  tronLinkConfirmationDeclinedByUser: 'Confirmation declined by user',
  tronLinkInsufficientBalance:
    'class org.tron.core.exception.ContractValidateException : Validate TransferContract error, no OwnerAccount.',
  tronLinkTypeErrorAddUpdateDataNotFunction: 'transactionBuilder.addUpdateData',
  tronLinkAuthorizationRequestsAreBeingProcessed: 4000,
  // imToken
  imTokenUserCanceled: 'user_canceled',
  // coinbase wallet
  coinbaseWalletUsingMultipleWallet:
    'Request method eth_chainId is not supported',
  // torus
  torusUserCancelledLogin: 'User cancelled login',
  torusUserClosedPopup: 'user closed popup',
  // passkey
  passkeyUserReject: 'user reject',
  popupsBlocked: 1001,
  // connect did sdk
  connectDidSdkAbort: 1000,
  // joyid
  joyidUserRejected: 'User Rejected',
  joyidPopupClosed: 'Popup closed'
}

const errorMap = {
  success: 0,
  requestTimedOut: 408,
  serverError: 500,
  networkError: -1,
  customError: -2,
  unknownError: 9999,
  // api error
  apiErrorParamsInvalid: 10000,
  apiErrorMethodNotExist: 10001,
  apiErrorDbError: 10002,
  apiErrorCacheError: 10003,
  apiErrorCodeNotExitsTrx: 11001,
  apiErrorCodeTokenInvalid: 10004,
  apiErrorCodeTokenExpired: 10005,
  apiErrorCodeLoginRateLimit: 10006,
  apiErrorCodeAccountNotExist: 11000,
  apiErrorCodeAccountNotOnSale: 11004,
  apiErrorCodeNotSupportFullAddress: 11005,
  apiErrorCodeInsufficientBalance: 11007,
  apiErrorCodeCannotBuyOwnedAccount: 11009,
  apiErrorCodeRejectedOutPoint: 11011,
  apiErrorCodeSyncBlockNumber: 11012,
  apiErrorCodeNotEnoughChange: 11014,
  apiErrorCodeSystemUpgrade: 30019,

  // wallet error,
  ...WalletErrorMap,

  // from das app
  rpcApiErrNotOpenForRegistration: 30001,
  rpcApiErrAccountNotExist: 30003,
  rpcApiErrOrderStatusExpired: 30008,
  rpcApiErrAccountCharsErr: 30015,
  rpcApiErrAccountFrequencyLimit: 11013,
  rpcApiErrAccountIsExpired: 30010,
  rpcApiErrNotHaveOwnerPermission: 30011,
  rpcApiErrTransferAccountSameAddress: 30023,
  rpcApiErrWithdrawFromDasLockInsufficientBalance: 30025,
  rpcApiErrWithdrawFromDasLockUnsupportedLongAddress: 30030,
  rpcApiErrLockAccountNotExist: 30032,
  rpcApiErrSignatureError: 30034,
  rpcApiErrExpiredAtCanNotLock: 31002,
  apiErrorTxExpired: 11008,
  apiErrorCodeResolveFailed: 11011,
  apiErrorCodeAmountInvalid: 11010,
  apiErrorCodeReverseAlreadyExist: 12001,
  apiErrorCodeCouponNotFound: 30038,
  apiErrorCodeCouponUsed: 30039,
  apiErrorCodeCouponEventDidNotStart: 30040,
  apiErrorCodeNoAccountAccess: 30042,
  apiErrorOrderNotExist: 30006,
  apiErrorAccountLengthErr: 30014,
  apiErrorReservedAccount: 30017,
  apiErrorInviterAccountNotExist: 30018,
  apiErrorAccountRegistering: 30004,
  apiErrorReverseAlreadyExist: 12001,
  apiErrorReverseNotExist: 12002,
  apiErrorRecordInvalid: 30020,
  apiErrorRecordsTotalLengthExceeded: 30021,
  apiErrorChannelAccountNotExist: 30026,
  apiErrorOrderPaid: 30027,
  apiErrorUnAvailableAccount: 30029,
  apiErrorAccountStatusOnSaleOrAuction: 30031,
  apiErrorAfterGracePeriod: 30037,
  apiErrorAuctionAccountNotFound: 30404,
  apiErrorAuctionAccountBided: 30405,
  apiErrorAuctionOrderNotFound: 30406,
  apiErrorRecommendAccountIsEmpty: 30043,
  apiErrorCodeInvalidTargetAddress: 30407,
  apiErrorCodeTooManyRecords: 30408,
  apiErrorAnyLockAddressInvalid: 30410,

  // sub_account
  apiErrorEnableSubAccountIsOn: 40000,
  apiErrorNotExistEditKey: 40001,
  apiErrorNotExistConfirmAction: 40002,
  apiErrorSignError: 40003,
  apiErrorNotExistSignType: 40004,
  apiErrorNotSubAccount: 40005,
  apiErrorEnableSubAccountIsOff: 40006,
  apiErrorCreateListCheckFail: 40007,
  apiErrorRecordDoing: 40010,
  apiErrorNoSupportPaymentToken: 40020,
  apiErrorRuleDataErr: 40022,
  apiErrorParentAccountNotExist: 40023,
  apiErrorSubAccountMinting: 40024,
  apiErrorSubAccountMinted: 40025,
  apiErrorBeyondMaxYears: 40026,
  apiErrorHitBlacklist: 40027,
  apiErrorNoTSetRules: 40028,
  apiErrorTokenIdNotSupported: 40029,
  apiErrorSubAccountNoEnable: 40031,
  apiErrorAutoDistributionClosed: 40032,
  apiErrorPriceRulePriceNotBeLessThanMin: 40034,
  apiErrorPriceMostReserveTwoDecimal: 40035,
  apiErrorConfigSubAccountPending: 40036,
  apiErrorAccountRepeat: 40037,
  apiErrorInListMostBeLessThan1000: 40038,
  apiErrorPreservedRulesMostBeOne: 40039,
  apiErrorRuleSizeExceedsLimit: 40040,
  apiErrorRuleFormatErr: 40041,
  apiErrorExceededMaxLength: 40042,
  apiErrorInvalidCharset: 40043,
  apiErrorAccountExpiringSoon: 40047,
  apiErrorUnauthorized: 40058,
  apiErrorCouponOpenTimeNotArrived: 40059,
  apiErrorCouponExpired: 40060,
  apiErrorCouponErrAccount: 40061,
  apiErrorCodeOrderClosed: 40062,

  // multi_device
  apiErrorHasNoAccessToCreate: 60000,
  apiErrorCreateConfigCellFail: 60001,
  apiErrorHasNoAccessToRemove: 60002
}

export const ignoreErrorCode = [
  ...Object.values(WalletErrorMap),
  // common api error
  errorMap.apiErrorCodeInsufficientBalance,
  errorMap.apiErrorCodeRejectedOutPoint,
  errorMap.rpcApiErrAccountFrequencyLimit,
  errorMap.apiErrorCodeNotEnoughChange,
  errorMap.rpcApiErrNotOpenForRegistration,
  errorMap.rpcApiErrAccountNotExist,
  errorMap.apiErrorAccountRegistering,
  errorMap.apiErrorAccountLengthErr,
  errorMap.rpcApiErrAccountIsExpired,
  errorMap.rpcApiErrNotHaveOwnerPermission,
  errorMap.rpcApiErrAccountCharsErr,
  errorMap.apiErrorReservedAccount,
  errorMap.apiErrorInviterAccountNotExist,
  errorMap.apiErrorCodeSystemUpgrade,
  errorMap.apiErrorRecordInvalid,
  errorMap.apiErrorRecordsTotalLengthExceeded,
  errorMap.rpcApiErrTransferAccountSameAddress,
  errorMap.apiErrorChannelAccountNotExist,
  errorMap.apiErrorUnAvailableAccount,
  errorMap.apiErrorAccountStatusOnSaleOrAuction,
  errorMap.apiErrorAfterGracePeriod,
  errorMap.apiErrorCodeCouponNotFound,
  errorMap.apiErrorCodeCouponUsed,
  errorMap.apiErrorCodeCouponEventDidNotStart,
  errorMap.apiErrorAuctionAccountNotFound,
  errorMap.apiErrorAuctionAccountBided,
  errorMap.apiErrorAuctionOrderNotFound,
  errorMap.apiErrorRecommendAccountIsEmpty,
  errorMap.apiErrorRecordDoing,
  errorMap.apiErrorSubAccountMinting,
  errorMap.apiErrorSubAccountMinted,
  errorMap.apiErrorHitBlacklist,
  errorMap.apiErrorNoTSetRules,
  errorMap.apiErrorSubAccountNoEnable,
  errorMap.apiErrorAutoDistributionClosed,
  errorMap.apiErrorPriceRulePriceNotBeLessThanMin,
  errorMap.apiErrorPriceMostReserveTwoDecimal,
  errorMap.apiErrorConfigSubAccountPending,
  errorMap.apiErrorAccountRepeat,
  errorMap.apiErrorInListMostBeLessThan1000,
  errorMap.apiErrorPreservedRulesMostBeOne,
  errorMap.apiErrorRuleSizeExceedsLimit,
  errorMap.apiErrorExceededMaxLength,
  errorMap.apiErrorInvalidCharset,
  errorMap.apiErrorAccountExpiringSoon,
  errorMap.apiErrorUnauthorized,
  errorMap.apiErrorCouponOpenTimeNotArrived,
  errorMap.apiErrorCouponExpired,
  errorMap.apiErrorCouponErrAccount,
  errorMap.apiErrorCodeNoAccountAccess,
  errorMap.apiErrorCodeInvalidTargetAddress,
  errorMap.apiErrorCodeTooManyRecords,
  errorMap.apiErrorAnyLockAddressInvalid
]

export default errorMap
