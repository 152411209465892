import React, { useContext } from 'react'
import { IContext } from '@did/types/das-context'
import { IConfig } from '@did/types'

export const DasBalanceContext = React.createContext<IContext>({} as IContext)

export const DasBalanceContextProvider = DasBalanceContext.Provider

export const useDasBalanceContext = () => useContext(DasBalanceContext)

// server common config
export const DasAppConfigContext = React.createContext<IConfig>({} as IConfig)

export const DasAppConfigContextProvider = DasAppConfigContext.Provider

export const useDasAppConfigContext = () => useContext(DasAppConfigContext)
