import React, { useMemo } from 'react'
import { LineChart } from '@did/das-chart'
import { premium } from '@did/tools/calc-dutch-price'
import { Decimal, formatDateTime, thousandSplit } from '@did/tools'
import { FIAT_DECIMAL_PLACES } from '@did/constants'
import { useDasBalanceContext } from '@did/das-app-context'
import { DutchAuctionType } from '@did/das-app-types/module'

const LOOP_GUARD = 365

export const DutchAuction: DutchAuctionType = ({
  expiredTime,
  currentPremium,
  className,
  totalDays,
  gracePeriod,
  bitName
}) => {
  const { tt } = useDasBalanceContext()
  const data: [] = useMemo(() => {
    const data: any = []
    if (!expiredTime || !totalDays || !gracePeriod) return data
    const expiredTimeInt = new Decimal(Math.floor(expiredTime))
    const startTime = expiredTime

    for (let i = 0; i <= totalDays && i < LOOP_GUARD; i++) {
      let time = new Decimal(startTime).add(i * 3600 * 24)

      data.push({
        x: `${formatDateTime(Number(time) * 1000, 'YYYY-MM-DD HH:mm')}`,
        y: Number(
          `${premium({
            expires: expiredTimeInt,
            nowTime: time,
            totalDays: new Decimal(totalDays),
            gracePeriod: new Decimal(gracePeriod)
          })}`
        )
      })
    }

    data.push({
      x: `${formatDateTime(Date.now(), 'YYYY-MM-DD HH:mm')}`,
      y: thousandSplit(currentPremium || 0, FIAT_DECIMAL_PLACES).replace(
        /,/g,
        ''
      ),
      color: '#2471FE'
    })

    if (Number(data?.[data?.length - 1].y) > 0) {
      data.push({
        x: `${formatDateTime(
          Number(new Decimal(startTime).add((totalDays + 1) * 3600 * 24)) *
            1000,
          'YYYY-MM-DD HH:mm'
        )}`,
        y: `0`
      })
    }

    return data
  }, [expiredTime, totalDays, gracePeriod, currentPremium])

  return (
    <div className={className}>
      <div className="w-full border-gray-400 border-dashed my-5 border-b-[0.7px]"></div>
      <div className="text-sm font-normal text-zinc-500 mb-4">
        {tt(
          `{account} is currently in auction with a temporary premium. The premium will decrease to $0 on {end}.`,
          {
            end: formatDateTime(
              (expiredTime +
                Number(gracePeriod) +
                Number(totalDays + 1) * 3600 * 24) *
                1000,
              'YYYY-MM-DD'
            ),
            account: bitName
          }
        )}
      </div>
      <div className=" border border-gray-200 rounded-lg w-full relative">
        <span className="absolute top-0 left-0 pl-1 pt-1 text-xs text-gray-400 font-medium">
          $100,000,000
        </span>
        <LineChart
          formatter={(date, price) => {
            const offset = new Date().getTimezoneOffset()
            const offsetHours = offset / 60
            return `Premium: $${thousandSplit(
              price || 0,
              FIAT_DECIMAL_PLACES
            )}<br />Time: ${date?.replace(/-/g, '.')}(UTC${
              offsetHours > 0 ? '-' : '+'
            }${Math.abs(offsetHours)})`
          }}
          style={{
            height: 113
          }}
          data={data}
        />
        <span className="absolute bottom-0 left-0 pl-1 pb-1 text-xs text-gray-400 font-medium">
          $0
        </span>
      </div>
      <div className="flex items-center justify-between mt-3 text-sm text-slate-900 font-normal">
        <span>{tt('Current premium')}</span>
        <div className="text-xl font-medium font-['Barlow-Medium']">
          ${thousandSplit(currentPremium || 0, FIAT_DECIMAL_PLACES)}
        </div>
      </div>
    </div>
  )
}
