import { DasButton, Dialog, Iconfont, IconImage } from '@did/uikit'
import { PermissionTipsType } from '@did/das-app-types/components'
import { useDasBalanceContext } from '@did/das-app-context'
import React, { useMemo } from 'react'
import { cn, copyText, findParsingRecordChain } from '@did/tools'

export const PermissionTips: PermissionTipsType = ({
  showing,
  title,
  message,
  managerTip,
  address,
  coinType,
  managerAddress,
  managerCoinType,
  ownerAddress,
  ownerCoinType,
  copyAction = true,
  onClose
}) => {
  const { tt, toast } = useDasBalanceContext()

  const handleCopy = () => {
    copyText(window.location.href).then(() => {
      toast('👌 ' + tt('Copied'))
    })
  }

  const findLogoImage = (coinType: string) => {
    if (!coinType) return ''
    return `/bit/images/chain/chain-${
      findParsingRecordChain(coinType).value
    }.png`
  }

  const ownerImage = useMemo(() => {
    if (ownerCoinType) {
      return findLogoImage(ownerCoinType)
    }
    return ''
  }, [ownerCoinType])

  const managerImage = useMemo(() => {
    if (managerCoinType) {
      return findLogoImage(managerCoinType)
    }
    return ''
  }, [managerCoinType])

  const addressImage = useMemo(() => {
    if (coinType) {
      return findLogoImage(coinType)
    }
    return ''
  }, [coinType])

  return (
    <Dialog
      showing={showing}
      title={title || tt('Tips')}
      closeButton
      onClose={onClose}
      action={
        <div className="p-8">
          <DasButton black block onClick={onClose}>
            {tt('OK')}
          </DasButton>
        </div>
      }
    >
      <div className="text-neutral-700 text-sm font-medium leading-tight">
        {message
          ? message
          : tt(
              'Please manually disconnect from your current wallet, and use your .bit {permissions} address to open and connect this page, displayed below.',
              { permissions: managerTip ? 'manager' : 'owner' }
            )}
      </div>
      {address && coinType ? (
        <div
          className={cn(
            'flex items-center justify-between mt-3 py-[9px] px-[10px] bg-[#EDF0F2] rounded-[26px] w-full'
          )}
        >
          <span className={cn('inline-flex items-center')}>
            {addressImage && <IconImage url={addressImage} size={32} rounded />}
            <span className={cn('ml-2')}>
              <div
                className={cn(
                  'font-mono text-neutral-900 text-base font-bold leading-tight break-all'
                )}
              >
                {address}
              </div>
            </span>
          </span>
        </div>
      ) : managerTip ? (
        <div
          className={cn(
            'flex items-center justify-between mt-3 py-[9px] px-[10px] bg-[#EDF0F2] rounded-[26px] w-full'
          )}
        >
          <span className={cn('inline-flex items-center')}>
            {managerImage && <IconImage url={managerImage} size={32} rounded />}
            <span className={cn('ml-2')}>
              <div
                className={cn(
                  'font-mono text-neutral-900 text-base font-bold leading-tight break-all'
                )}
              >
                {managerAddress}
              </div>
            </span>
          </span>
        </div>
      ) : (
        <div
          className={cn(
            'flex items-center justify-between mt-3 py-[9px] px-[10px] bg-[#EDF0F2] rounded-[26px] w-full'
          )}
        >
          <span className={cn('inline-flex items-center')}>
            {ownerImage && <IconImage url={ownerImage} size={32} rounded />}
            <span className={cn('ml-2')}>
              <div
                className={cn(
                  'font-mono text-neutral-900 text-base font-bold leading-tight break-all'
                )}
              >
                {ownerAddress}
              </div>
            </span>
          </span>
        </div>
      )}
      {copyAction && (
        <div className="flex justify-center mt-6">
          <span
            className="inline-flex items-center gap-x-1 text-neutral-700 text-sm leading-tight cursor-pointer hover:opacity-70"
            onClick={handleCopy}
          >
            <Iconfont name="copy" color="#5F6570" size="16" />
            {tt('Copy URL')}
          </span>
        </div>
      )}
    </Dialog>
  )
}
