import { Iconfont } from '@did/uikit'
import { StatusTipType } from '@did/das-app-types/components'

export const StatusTip: StatusTipType = ({
  icon,
  iconSize,
  tip,
  tipFontSize
}) => {
  return (
    <div className="text-center">
      <Iconfont name={icon} size={iconSize} />
      <p className="mt-4 font-bold" style={{ fontSize: `${tipFontSize}px` }}>
        {tip}
      </p>
    </div>
  )
}
