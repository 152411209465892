import { ParsingRecordDwebKey, ParsingRecordProfileKey } from '@did/types'

export const SETTINGS = {
  manageData: 'manageData',
  changeOwner: 'changeOwner',
  changeManage: 'changeManage',
  renew: 'renew',
  mintSubAccounts: 'mintSubAccounts',
  superDid: 'superDid',
  daodid: 'DAODID',
  subdid: 'SubDID'
}

export const DWEB_KEY_OPTIONS = [
  {
    text: 'IPFS',
    value: ParsingRecordDwebKey.ipfs
  },
  {
    text: 'IPNS',
    value: ParsingRecordDwebKey.ipns
  },
  {
    text: 'Arweave',
    value: ParsingRecordDwebKey.arweave
  },
  {
    text: 'Resilio',
    value: ParsingRecordDwebKey.resilio
  },
  {
    text: 'Skynet',
    value: ParsingRecordDwebKey.skynet
  }
]

export const PROFILE_KEY_OPTIONS = [
  {
    text: 'Nostr',
    value: ParsingRecordProfileKey.nostr
  },
  {
    text: 'Twitter',
    value: ParsingRecordProfileKey.twitter
  },
  {
    text: 'Facebook',
    value: ParsingRecordProfileKey.facebook
  },
  {
    text: 'Instagram',
    value: ParsingRecordProfileKey.instagram
  },
  {
    text: 'Reddit',
    value: ParsingRecordProfileKey.reddit
  },
  {
    text: 'LinkedIn',
    value: ParsingRecordProfileKey.linkedin
  },
  {
    text: 'GitHub',
    value: ParsingRecordProfileKey.github
  },
  {
    text: 'Telegram',
    value: ParsingRecordProfileKey.telegram
  },
  {
    text: 'Discord',
    value: ParsingRecordProfileKey.discord
  },
  {
    text: 'Youtube',
    value: ParsingRecordProfileKey.youtube
  },
  {
    text: 'bilibili',
    value: ParsingRecordProfileKey.bilibili
  },
  {
    text: 'Medium',
    value: ParsingRecordProfileKey.medium
  },
  {
    text: 'TikTok',
    value: ParsingRecordProfileKey.tiktok
  },
  {
    text: 'Weibo',
    value: ParsingRecordProfileKey.weibo
  },
  {
    text: 'Jike',
    value: ParsingRecordProfileKey.jike
  },
  {
    text: 'NextID',
    value: ParsingRecordProfileKey.nextid
  },
  {
    text: 'Dribbble',
    value: ParsingRecordProfileKey.dribbble
  },
  {
    text: 'Behance',
    value: ParsingRecordProfileKey.behance
  },
  {
    text: 'Mirror',
    value: ParsingRecordProfileKey.mirror
  },
  {
    text: 'Avatar',
    value: ParsingRecordProfileKey.avatar
  },
  {
    text: 'Description',
    value: ParsingRecordProfileKey.description
  },
  {
    text: 'Website',
    value: ParsingRecordProfileKey.website
  }
]

export const DATA_TABS = {
  data: 'data',
  permissions: 'permissions'
}

export const PARSING_RECORD_MAX_LIMIT = 100

export enum DOTBIT_UPGRADE_STATUS {
  upgradable = 0,
  paymentConfirmation = 1,
  upgrading = 2,
  loading = 3
}

export enum DOB_RECYCLE_STATUS {
  recyclable = 0,
  recycling = 1,
  loading = 2
}
