import React, { useMemo } from 'react'
import { IBaseProps } from '@did/types'
import { cn } from '@did/tools'

export const GradientBg: React.FC<
  IBaseProps & { type?: 'blue' | 'green' | 'orange' | 'red' }
> = ({ type, className }) => {
  const background = useMemo(() => {
    if (type === 'green') {
      return 'radial-gradient(50% 50% at 50% 50%,#d4f2e6 0,rgba(212,242,230,0) 100%)'
    }
    if (type === 'orange') {
      return 'radial-gradient(50% 50% at 50% 50%,#f2e2d4 0,rgba(242,226,212,0) 100%)'
    }
    if (type === 'red') {
      return 'radial-gradient(50% 50% at 50% 50%,#f2d4d4 0,rgba(242,212,212,0) 100%)'
    }
    return 'radial-gradient(50% 50% at 50% 50%, #D4DBF2 0%, rgba(212, 219, 242, 0) 100%)'
  }, [type])

  return (
    <div
      className={cn(
        'fixed h-80 -top-48 w-[770px] sm:-top-40 sm:h-96 sm:max-w-5xl sm:w-full left-1/2 -translate-x-1/2 -z-10',
        className
      )}
      style={{
        background
      }}
    ></div>
  )
}
