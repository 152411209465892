import {
  useContext,
  useState,
  createContext,
  ReactNode,
  useEffect,
  useRef
} from 'react'
import { ICCCStateContext } from '@did/types/das-context'

export const CCC_STATE_KEY = 'CccStateV1'

// CCC state context
export const CccContext = createContext<ICCCStateContext>(
  {} as ICCCStateContext
)

export const useCccContext = () => useContext(CccContext)

export const CccContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState({})
  const [isDobsMode, setIsDobsMode] = useState(false)
  const [ccc, setCcc] = useState<any>(undefined)
  const [address, setAddress] = useState('')
  const [chainId, setChainId] = useState<number | string>('')
  const [ckbAddress, setCkbAddress] = useState('')
  const [coinType, setCoinType] = useState('')
  const [isTestNet, setIsTestNet] = useState(false)
  const isInited = useRef<boolean>(false)

  // init ccc
  useEffect(() => {
    if (isInited.current) return
    isInited.current = true
    ;(async () => {
      const { ccc: _ccc } = await import('@ckb-ccc/connector-react')
      if (!_ccc) {
        const { ccc: _ccc } = await import('@ckb-ccc/connector-react')
        if (!_ccc) return
        setCcc(_ccc)
        return
      }
      setCcc(_ccc)
    })()
  }, [])

  // load state from local storage
  useEffect(() => {
    const storedState = window.localStorage.getItem(CCC_STATE_KEY)
    if (!storedState) {
      return
    }

    const _state = JSON.parse(storedState)
    if (!_state) {
      return
    }

    setState(_state)

    if (typeof _state.isDobsMode !== 'undefined') {
      setIsDobsMode(_state.isDobsMode)
    }
    if (typeof _state.address !== 'undefined') {
      setAddress(_state.address)
    }
    if (typeof _state.chainId !== 'undefined') {
      setChainId(_state.chainId)
    }
    if (typeof _state.ckbAddress !== 'undefined') {
      setCkbAddress(_state.ckbAddress)
    }
    if (typeof _state.coinType !== 'undefined') {
      setCoinType(_state.coinType)
    }
    if (typeof _state.isTestNet !== 'undefined') {
      setIsTestNet(_state.isTestNet)
    }
  }, [])

  // save state to local storage
  useEffect(() => {
    if (JSON.stringify(state) !== '{}') {
      window.localStorage.setItem(CCC_STATE_KEY, JSON.stringify(state))
    }
  }, [state])

  return (
    <CccContext.Provider
      value={{
        ccc,
        isDobsMode,
        setIsDobsMode: (val: boolean) => {
          setIsDobsMode(val)
          setState((preVal) => {
            return { ...preVal, isDobsMode: val }
          })
        },
        address,
        setAddress: (val: string) => {
          setAddress(val)
          setState((preVal) => {
            return { ...preVal, address: val }
          })
        },
        chainId,
        setChainId: (val: number | string) => {
          setChainId(val)
          setState((preVal) => {
            return { ...preVal, chainId: val }
          })
        },
        ckbAddress,
        setCkbAddress: (val: string) => {
          setCkbAddress(val)
          setState((preVal) => {
            return { ...preVal, ckbAddress: val }
          })
        },
        coinType,
        setCoinType: (val: string) => {
          setCoinType(val)
          setState((preVal) => {
            return { ...preVal, coinType: val }
          })
        },
        isTestNet,
        setIsTestNet: (val: boolean) => {
          setIsTestNet(val)
          setState((preVal) => {
            return { ...preVal, isTestNet: val }
          })
        }
      }}
    >
      {ccc?.Provider && <ccc.Provider>{children}</ccc.Provider>}
    </CccContext.Provider>
  )
}
