import { useDasBalanceContext } from '@did/das-app-context'
import { useDutchAuction } from '@did/das-app-hooks'
import { DutchAuctionPageType } from '@did/das-app-types/module'
import { Decimal } from '@did/tools'
import { BidStatus } from '@did/types'
import { BottomSheet, Dialog } from '@did/uikit'
import React, { useMemo } from 'react'
import { DasBalanceInsufficientDialog } from './das-balance-insufficient-dialog'
import { DutchAuction } from './dutch-auction'
import { PaymentConfirm } from './payment-confirm'
import { DashedLine, RegisterInfo } from './register-info'

export const DutchAuctionPage: DutchAuctionPageType = ({ bitName }) => {
  const { tt, router, connectedAccount } = useDasBalanceContext()

  const {
    premiumPrice,
    handleRegister,
    onRegisterLoading,
    setConfirmRegisterShowing,
    confirmRegisterShowing,
    handleConfirm,
    confirmRegisterLoading,
    auctionInfo,
    totalDays,
    gracePeriod,
    loading,
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing,
    dPtoken,
    setConfirmRegisterLoading
  } = useDutchAuction({ account: bitName })

  const totalCost = useMemo(() => {
    return new Decimal(premiumPrice?.account_price || 0).add(
      new Decimal(premiumPrice?.premium_price || 0)
        .add(new Decimal(premiumPrice?.base_amount || 0))
        .toFixed(2, Decimal.ROUND_UP)
    )
  }, [premiumPrice, dPtoken?.decimals])

  const onBack = () => {
    if (window.history.length === 2) {
      router.push('/create')
      return
    }
    router.back?.()
  }

  return (
    <div className="w-full md:w-[680px] mx-auto pt-5 md:px-0 md:pb-8 flex flex-col items-center flex-[1_0_0] justify-end md:justify-start">
      <RegisterInfo
        handleRegister={handleRegister}
        bitName={bitName}
        annualFee={premiumPrice?.account_price as any as number}
        storageDeposit={premiumPrice?.base_amount as any as number}
        totalCost={totalCost as any as number}
        registrationPeriodInfo={
          <div>
            1 <span className="font-normal">year</span>
          </div>
        }
        paidAmount={totalCost as any as number}
        registerLoading={onRegisterLoading}
        slots={{
          dashedLine: ({ index }) => {
            if (index === 0) return <></>
            return <DashedLine />
          }
        }}
        additionInfo={
          loading ? (
            <div className="animate-pulse">
              <div className=" h-8 my-2 bg-gray-100 rounded w-full"></div>
              <div className=" h-28 my-2 mb-4 bg-gray-100 rounded w-full"></div>
            </div>
          ) : (
            <DutchAuction
              bitName={bitName}
              totalDays={totalDays!}
              gracePeriod={gracePeriod!}
              currentPremium={premiumPrice?.premium_price}
              expiredTime={auctionInfo?.start_auction_time!}
            />
          )
        }
      />
      <BottomSheet
        title={<div className=" px-3 sm:px-0">{tt('Select payment')}</div>}
        open={confirmRegisterShowing}
        onClose={() => {
          setConfirmRegisterShowing(false)
        }}
      >
        <PaymentConfirm
          selectedToken={dPtoken}
          paidTokenAmount={`${totalCost}`}
          fiatTermsLink="https://topdid.com/terms.html"
          tokenList={[dPtoken!]}
          handleConfirm={handleConfirm}
          confirmRegisterLoading={confirmRegisterLoading}
        />
      </BottomSheet>
      <Dialog
        closeButton
        enableCloseAction
        showing={
          Boolean(connectedAccount?.address) &&
          auctionInfo?.bid_status === BidStatus.BidStatusByOthers
        }
        title={tt('Oops')}
        actionButtonText={tt('Back to home')}
        onAction={onBack}
        onClose={onBack}
      >
        <span>
          {tt(
            'This account has already been registered through bidding by another bidder.'
          )}
        </span>
      </Dialog>

      <DasBalanceInsufficientDialog
        selectToken={dPtoken}
        showing={dasBalanceInsufficientDialogShowing}
        onClose={() => {
          setDasBalanceInsufficientDialogShowing(false)
        }}
        registrationFees={`${totalCost}`}
      />
    </div>
  )
}
