import { IAccountInputProps, IBaseListPagerProps } from '@did/das-balance-types'
import {
  AccountInput as BaseAccountInput,
  BaseListPager as BaseBaseListPager
} from './src'
import { Iconfont } from '@did/uikit'

export {
  DasButton as Button,
  ConnectWallet,
  Dialog,
  LangSelect,
  LangSwitcher,
  TopNav,
  TopNavMobile
} from '@did/uikit'
export {
  BaseListItem,
  Link,
  NotFound,
  TextInput,
  Wireframe,
  openDialog,
  TransStatusDialog,
  GradientBg
} from './src'

export const AccountInput = (props: Omit<IAccountInputProps, 'slots'>) => {
  return <BaseAccountInput {...props} slots={{ icon: Iconfont }} />
}

export const BaseListPager = (props: Omit<IBaseListPagerProps, 'slots'>) => {
  return <BaseBaseListPager {...props} slots={{ icon: Iconfont }} />
}
