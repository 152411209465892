import { useDasBalanceContext } from '@did/das-app-context'
import { ReservePageType } from '@did/das-app-types/module'
import { DasButton, Iconfont } from '@did/uikit'
import { DidCard } from '@did/uikit'

export const ReservePage: ReservePageType = ({ bitName }) => {
  const { tt } = useDasBalanceContext()
  return (
    <div className="flex flex-col items-center w-full md:w-[680px] mx-auto pb-[52px] px-4 pt-4 md:p-0">
      <DidCard width="130" account={bitName || ''} />
      <div className="flex flex-col items-center p-8 bg-white rounded-[32px] mt-8">
        <div className="mx-auto mt-8 bg-emerald-400/10 pl-2 pr-4 py-0.5 rounded-full">
          <span className="inline-flex items-center text-emerald-400 text-2xl">
            <Iconfont
              className="mr-2"
              name="reserve"
              color="#22C493"
              size="24"
            />
            {tt('Reserved account')}
          </span>
        </div>
        <div className="text-center font-normal text-gray-600 text-xl mt-10 mb-[52px] px-0 md:px-8">
          {tt(
            'This account is a reserved account and can only be claimed for free by the corresponding organization/individual.'
          )}
        </div>
        <DasButton
          black
          block
          onClick={() => {
            window.open('https://dotbit.medium.com/29cdccea1db1')
          }}
        >
          <span className="px-4">{tt('Claim reserved account')}</span>
        </DasButton>
      </div>
    </div>
  )
}
