import { useDasBalanceContext } from '@did/das-app-context'
import { RegisterFailedType } from '@did/das-app-types/module'
import { DasButton, DidCard } from '@did/uikit'

export const RegisterFailed: RegisterFailedType = ({
  bitName,
  goHome,
  isAuction
}) => {
  const { tt } = useDasBalanceContext()

  return (
    <>
      <DidCard width="130" account={bitName || ''} />
      <div className="py-4 text-neutral-700 text-2xl font-bold break-all text-center">
        {tt('Oops! Registration failed')}
      </div>
      <div className="mb-6 text-base text-zinc-500 text-center leading-[18px] font-normal">
        {tt(
          isAuction
            ? 'Your bid has failed, possibly due to the account being registered by another bidder or the auction time having ended. Rest assured, your registration fee has not been charged.'
            : 'Registration of {accountName} failed! If you have already paid the relevant fees, you will be refunded to your payment address within 24 hour (except for transaction fees).',
          { accountName: bitName }
        )}
      </div>
      <DasButton large black block onClick={goHome}>
        {tt('Back to Home')}
      </DasButton>
    </>
  )
}
