import React, { useCallback, useEffect, useState } from 'react'
import RecommendStarPng from '@did/das-assets/images/account/recommend-star.png'
import LoadingSvg from '@did/das-assets/images/icon/loading.svg'
import { AccountRecommendListType } from '@did/das-app-types/module'
import { Iconfont } from '@did/uikit'
import { useDasBalanceContext } from '@did/das-app-context'
import { handleError } from '@did/das-app-utils'
import { cn } from '@did/tools'
import errno from '@did/constants/errno'

export const AccountRecommendList: AccountRecommendListType = (props) => {
  const { account } = props
  const { tt, alert, services, router } = useDasBalanceContext()
  const [list, setList] = useState<string[]>([])
  const [totalList, setTotalList] = useState<string[]>([])
  const [page, setPage] = useState(0)
  const size = 5
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = useCallback(async () => {
    if (account.replace(/\.bit$/, '').split('.').length > 1) {
      return
    }
    setIsLoading(true)
    try {
      const result = await services.account.recommendAccountList({
        account
      })
      setTotalList(result.acc_list || [])
      setList(result.acc_list.slice(0, size))
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      if (e.code !== errno.apiErrorRecommendAccountIsEmpty) {
        handleError(e, tt, alert)
      }
    }
  }, [services, account, size, tt, alert])

  const onRegister = useCallback((value: string) => {
    router?.push(`/account/create/${value}`, router.query)
  }, [])

  const changePage = useCallback(() => {
    let nextPage = page + 1
    let nextIndex = nextPage * size
    if (nextIndex >= totalList.length) {
      nextPage = 0
      nextIndex = 0
    }
    const endIndex = (nextPage + 1) * size
    setList(totalList.slice(nextIndex, endIndex))
    setPage(nextPage)
  }, [totalList, page, size])

  useEffect(() => {
    fetchData()
  }, [])

  return (
    list.length !== 0 && (
      <div className="w-full bg-white border-slate-300/40 border rounded-[32px] overflow-hidden shadow-panel">
        <div className="flex items-center justify-between px-6 pt-6 pb-3">
          <div className="flex items-center text-tip-font-color text-sm font-medium">
            <img src={RecommendStarPng.src} width={22} height={22} alt="" />
            <span className="ml-[10px] text-neutral-700 text-base font-medium">
              {tt('Suggestions')}
            </span>
          </div>
          {totalList.length > size && (
            <div
              className={cn(
                'flex items-center text-sm font-medium hover:opacity-70 cursor-pointer text-tip-font-color'
              )}
              onClick={changePage}
            >
              <span className="text-neutral-700 text-base font-medium">
                {tt('Change')}
              </span>
              <Iconfont
                color="#B0B8BF"
                className="ml-1"
                name="refresh"
                size={12}
              />
            </div>
          )}
        </div>
        {isLoading && (
          <div className="px-4 min-h-[100px] flex items-center justify-center">
            <LoadingSvg width={26} height={26} className="animate-spin" />
          </div>
        )}
        {!isLoading && (
          <div className="pl-3 pr-4">
            {list.map((v, i) => (
              <div
                onClick={() => onRegister(v)}
                key={i}
                className="px-2 hover:bg-gray-100 rounded-lg cursor-pointer group"
              >
                <div className="flex items-center justify-between py-4 border-b border-stone-300/20 group-last:border-b-transparent group-hover:border-b-transparent">
                  <div className="flex items-center">
                    <Iconfont
                      name="check-strong"
                      className="shrink-0"
                      color="#22C493"
                      size={26}
                    />
                    <span className="text-neutral-900 text-xl font-semibold ml-3">
                      {v}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <span className="text-zinc-500 text-base font-normal">
                      {tt('Create')}
                    </span>
                    <Iconfont name="arrow-right" color="#52627933" size={16} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  )
}
