import { BillInfo } from '@did/das-app-components'
import { useDasBalanceContext } from '@did/das-app-context'
import { useRewardRecordsList } from '@did/das-app-hooks'
import React from 'react'

const PAGE_SIZE = 50

export const RewardRecordsPage: React.FC = () => {
  const { tt, services, connectedAccount, toast, router } =
    useDasBalanceContext()
  const { billList, loading, page, total, handlePageChange } =
    useRewardRecordsList({
      size: PAGE_SIZE
    })

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 pt-5 md:px-0 flex flex-col items-center">
      <BillInfo
        className="w-full min-h-[175px]"
        loading={loading && page === 1}
        billList={billList}
        title={tt('All reward records')}
        footer={
          total / PAGE_SIZE > page ? (
            <>
              {loading && (
                <span className=" text-sm text-[#B0B8BF]">Loading</span>
              )}
              {!loading && (
                <a
                  className=" text-sm cursor-pointer text-[#22C493]"
                  onClick={() => {
                    handlePageChange(page + 1)
                  }}
                >
                  {tt('Load more')}
                </a>
              )}
            </>
          ) : (
            <a className="text-sm text-[#B0B8BF]">{tt('No more')}</a>
          )
        }
      />
    </div>
  )
}
