import errno from '@did/constants/errno'
import { useDasBalanceContext } from '@did/das-app-context'
import { ACCOUNT_STATUS, ISearchAccountRes } from '@did/types'
import Decimal from 'decimal.js'
import { useEffect, useMemo, useState } from 'react'
import { ErrorInfo } from '@did/monitoring'

export const useRegisterInfo = (param: {
  bitName: string
  couponCode?: string
}) => {
  const { bitName, couponCode } = param
  const { services, connectedAccount, router, alert, tt } =
    useDasBalanceContext()
  const [accountInfo, setAccountInfo] = useState<ISearchAccountRes>()
  const [registrationPeriod, setRegistrationPeriod] = useState(3)

  const checkAccountStatus = async () => {
    let result = true
    if (!connectedAccount) return false
    try {
      const res = await services.account.searchAccount({
        account: bitName,
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        }
      })
      if (res) {
        setAccountInfo(res)
      }

      if (res && res.is_self) {
        if (
          [
            ACCOUNT_STATUS.registeringLockedAccount,
            ACCOUNT_STATUS.registering,
            ACCOUNT_STATUS.registeringIncludeProposal,
            ACCOUNT_STATUS.registeringConfirmProposal
          ].includes(res.status)
        ) {
          router.push(`/account/create/status/${bitName}`)
          result = false
        } else if (
          [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
            res.status
          )
        ) {
          alert({
            title: tt('Tips'),
            message: tt(
              'You already have {accountName}, no need to register again',
              { accountName: bitName }
            )
          })
          result = false
        }
      } else if (
        [
          ACCOUNT_STATUS.registering,
          ACCOUNT_STATUS.registeringIncludeProposal,
          ACCOUNT_STATUS.registeringConfirmProposal
        ].includes(res.status)
      ) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: bitName }
          )
        })
        result = false
      } else if (
        [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
          res.status
        )
      ) {
        alert({
          title: tt('Tips'),
          message: tt(
            '{accountName} has been registered by someone else and can no longer be registered',
            { accountName: bitName }
          )
        })
        result = false
      } else if (res.status === ACCOUNT_STATUS.reservedAccount) {
        router.push(`/create/account/${bitName}`)
        result = false
      } else if (res.status === ACCOUNT_STATUS.notOpenRegister) {
        alert({
          title: tt('Tips'),
          message: tt('This account name is not yet open for registration')
        })
        result = false
      } else if (res.status === ACCOUNT_STATUS.unavailableAccount) {
        alert({
          title: tt('Tips'),
          message: tt('Unavailable Account')
        })
        result = false
      }
    } catch (e: any) {
      ErrorInfo.error(e)
      if (e.code === errno.rpcApiErrNotOpenForRegistration) {
        alert({
          title: tt('Error'),
          message: tt('{accountName} is not open for registration', {
            accountName: bitName
          })
        })
      } else {
        alert({
          title: tt('Error'),
          message: `${e.code}: ${e.message}`
        })
      }
      result = false
    }
    return result
  }

  useEffect(() => {
    if (!bitName || connectedAccount?.chain === null) return
    const init = async () => {
      await checkAccountStatus()
    }
    init()
  }, [bitName, connectedAccount?.chain])

  const annualFee = useMemo(() => {
    return new Decimal(registrationPeriod || 0).times(
      accountInfo?.account_price || 0
    )
  }, [accountInfo?.account_price, registrationPeriod])

  const storageDeposit = useMemo(() => {
    return Number(accountInfo?.base_amount) || 0
  }, [accountInfo?.base_amount])

  const totalCost = useMemo(() => {
    return annualFee?.add(storageDeposit)
  }, [annualFee, storageDeposit])

  useEffect(() => {
    if (couponCode) {
      setRegistrationPeriod(1)
    }
  }, [couponCode])

  return {
    accountInfo,
    registrationPeriod,
    onRegisterPeriodChange: setRegistrationPeriod,
    annualFee,
    storageDeposit,
    totalCost
  }
}
