import { cn } from '@did/tools'
import { Iconfont } from '@did/uikit'
import { InfoButtonProps } from '@did/das-app-types/components'

export const InfoButton = ({
  loading = false,
  className,
  icon,
  ...props
}: InfoButtonProps) => {
  return (
    <button
      {...props}
      className={cn(
        'box-border flex w-full cursor-pointer flex-col items-center rounded-2xl bg-neutral-100 px-2 py-3 text-sm font-medium leading-[normal] text-tip-font-color shadow-info-button outline-0 hover:shadow-none hover:opacity-70 active:bg-normal-color active:shadow-none',
        className
      )}
      disabled={props.disabled === true || loading}
    >
      {loading ? (
        <span className="mr-1.5 inline-flex">
          <Iconfont
            className="animate-spin"
            color="#5F6570"
            size={20}
            name="loading"
          />
        </span>
      ) : (
        icon
      )}
      <div className="mt-1">{props.children}</div>
    </button>
  )
}
