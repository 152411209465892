import { TOKEN_DECIMAL_PLACES } from '@did/constants'
import { DASBalanceTokenId, DASDidPointTokenId } from '@did/constants/chain'
import {
  useDasAppConfigContext,
  useDasBalanceContext
} from '@did/das-app-context'
import { DasBalanceInsufficientDialogType } from '@did/das-app-types/module'
import { Decimal, smartOpen, thousandSplit } from '@did/tools'
import { DasButton, Dialog } from '@did/uikit'
import React, { useMemo } from 'react'

const BALANCE_TIPS_ARR = [
  {
    tokenId: DASBalanceTokenId,
    msg: 'Before payment, please ensure that your DAS balance is greater than {recommendedDepositAmount} CKB. After payment, the remaining amount must not be less than {minChangeCapacity} CKB. Otherwise, the transaction can not be sent.',
    urlKey: 'ckb',
    btnText: 'Manage Balance',
    feedIncress: 200,
    precision: TOKEN_DECIMAL_PLACES
  },
  {
    tokenId: DASDidPointTokenId,
    msg: 'Before payment, please ensure that your DIDPoint balance is greater than {recommendedDepositAmount}.',
    urlKey: 'credits',
    feedIncress: 0,
    btnText: 'Manage Credits',
    precision: 2
  }
]

export const DasBalanceInsufficientDialog: DasBalanceInsufficientDialogType = ({
  registrationFees,
  showing,
  onClose,
  selectToken
}) => {
  const { tt, outSiteUrl, services } = useDasBalanceContext()

  const config = useDasAppConfigContext()

  const tipsInfo = useMemo(() => {
    const info = BALANCE_TIPS_ARR.find(
      (b) => b.tokenId === selectToken?.token_id
    )

    if (info) {
      return info
    }

    return BALANCE_TIPS_ARR.find((b) => b.tokenId === DASBalanceTokenId)
  }, [selectToken?.token_id])

  const recommendedDepositAmount = useMemo(() => {
    return thousandSplit(
      new Decimal(registrationFees).add(Number(tipsInfo?.feedIncress)),
      tipsInfo?.precision || TOKEN_DECIMAL_PLACES
    )
  }, [registrationFees, tipsInfo?.feedIncress, tipsInfo?.precision])

  const onManageBalance = () => {
    const link = `${outSiteUrl?.dasBalanceUrl}/${tipsInfo?.urlKey}`
    smartOpen(link!)
    onClose?.()
  }

  return (
    <Dialog
      showing={showing}
      title={tt('Insufficient balance')}
      closeButton
      onClose={onClose}
    >
      <div>
        <div className="text-black">
          {tt(tipsInfo?.msg || '', {
            recommendedDepositAmount: recommendedDepositAmount,
            minChangeCapacity: config.min_change_capacity
          })}
        </div>
        <DasButton className="my-8" block black onClick={onManageBalance}>
          {tt(tipsInfo?.btnText || '')}
        </DasButton>
      </div>
    </Dialog>
  )
}
