export { ignoreErrorCode } from './errno'

export const CYCLE_CALL_FUNCTION_TIME = 5000

export const TOAST_DURATION_TIME = 1000

export const DEBOUNCE_WAIT_TIME = 600

// number of decimal places to keep for token amount
export const TOKEN_DECIMAL_PLACES = 8

// number of decimal places to retain the amount
export const FIAT_DECIMAL_PLACES = 2

export const ACCOUNT_SUFFIX = '.bit'

export const SUB_ACCOUNT_REG_EXP = /.{1,42}[.|#].{1,42}.bit$/

export const TIME_FORMAT = {
  fullDateTime: 'YYYY-MM-DD HH:mm:ss',
  fullDate: 'YYYY-MM-DD',
  MMDDHHMM: 'MM-DD HH:mm'
}

export const DEFAULT_PAGE_SIZE = 20

export enum ACTION_TYPE {
  withdraw, // withdraw
  mergeRewards, // merge rewards
  list, // list account
  editPrice, // edit price
  delist, // delist account
  buyAccount, // buy account
  sellAccount, // sell account
  transferToTransit, // activate balance
  setReverseRecord, // set reverse record
  editReverseRecord, // edit reverse record
  delReverseRecord, // delet reverse record
  dasBalanceRegisterOrRenew, // das balance register or renew
  editRecords, // modify parsing records
  transferAccount, // modify owner
  editManager, // modify manager
  renewal, // DAS renewal
  makeOffer, // make offer
  editOfferAdd, // edit quote
  cancelOffer, // cancel offer
  acceptOffer, // accept offer
  offerAccepted, // offer accepted
  editOfferSub, // edit to reduce the offer
  orderRefund, // order refund
  enableSubAccount, // enable sub account
  createSubAccount, // create sub account
  accountRecycled = 26, // account recycled app.did.id refund
  accountRecycledRefund = 27, // account recycled did.top refund
  accountRecycledCrossRefund = 28, // account recycled cross refund
  subAccountProfit = 29, // SuperDID subAccount profit
  subAccountRenew = 30, // SubDID Renew
  accountAuctionRefund = 31, // account auction refund
  refundOfDeposit = 100 // refund of deposit
}

export enum Market {
  gasstation = 'gasstation',
  binance = 'binance'
}

export enum DIDPOINT_ACTIONS {
  mint = 'mint',
  burn = 'burn',
  transfer = 'transfer',
  transfer_deposit = 'transfer_deposit',
  transfer_refund = 'transfer_refund',
  transfer_tldid = 'transfer_tldid',
  transfer_sldid = 'transfer_sldid',
  transfer_auction = 'transfer_auction',
  transfer_coupon = 'transfer_coupon'
}

export const ALERT_KEY = {
  API_ERROR: 'API_ERROR',
  SYSTEM_UPGRADE: 'SYSTEM_UPGRADE'
}

export const TwitterUserUrlRegExp = /^https:\/\/twitter\.com\/[a-zA-Z0-9_]+/

export const SUB_ACCOUNT_ACTIONS = {
  enableSubAccount: 'enable_sub_account',
  owner: 'owner',
  manager: 'manager',
  records: 'records',
  update_sub_account: 'update_sub_account',
  collect_sub_account_profit: 'collect_sub_account_profit',
  sub_action_create: 'create',
  sub_action_edit: 'edit'
}
