import en from "./en.json";
import zh from "./zh-CN.json";
import { LANGUAGE } from "@did/types/uikit";

export const localeData = [
  {
    key: LANGUAGE.en,
    data: en,
  },
  {
    key: LANGUAGE.zhCN,
    data: zh,
  },
];
