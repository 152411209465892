import { RecordDetailsType } from '@did/das-app-types/module'
import styles from './styles.module.scss'
import { cn } from '@did/tools'
import { useDasBalanceContext } from '@did/das-app-context'
import { DasButton, Dialog } from '@did/uikit'
import React from 'react'

export const RecordDetails: RecordDetailsType = ({
  showing,
  recordDetails,
  onClose
}) => {
  const { tt } = useDasBalanceContext()

  return (
    <Dialog
      showing={showing}
      title={tt('Details')}
      closeButton
      enableCloseAction
      onClose={onClose}
    >
      <div className={cn(styles['record-details_item'])}>
        <div className={cn(styles['record-details_item_label'])}>
          {tt('Custom Key')}
        </div>
        <div className={cn(styles['record-details_value'])}>
          {recordDetails.key}
        </div>
      </div>
      <div className={cn(styles['record-details_item'])}>
        <div className={cn(styles['record-details_item_label'])}>
          {tt('Custom Value')}
        </div>
        {/^https?:\/\/.+/.test(recordDetails.value) ? (
          <a
            className={cn(
              styles['record-details_item_link'],
              styles['record-details_value']
            )}
            href={recordDetails.value}
            target="_blank"
          >
            {recordDetails.value}
          </a>
        ) : (
          <div className={cn(styles['record-details_value'])}>
            {recordDetails.value}
          </div>
        )}
      </div>
      {recordDetails.label && (
        <div
          className={cn(
            styles['record-details_item'],
            styles['record-details_margin-bottom-0']
          )}
        >
          <div className={cn(styles['record-details_item_label'])}>
            {tt('Label')}
          </div>
          <div className={cn(styles['record-details_value'])}>
            {recordDetails.label}
          </div>
        </div>
      )}
    </Dialog>
  )
}
