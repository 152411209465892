import { ActionLabelType } from '@did/das-app-types/module'
import styles from './styles.module.scss'
import { cn } from '@did/tools'
import { useDasBalanceContext } from '@did/das-app-context'
import { DATA_ACTIONS } from '@did/types'

export const ActionLabel: ActionLabelType = ({ action }) => {
  const { tt } = useDasBalanceContext()

  return (
    <span
      className={cn(
        styles['action-label'],
        action === DATA_ACTIONS.change && styles['action-label_change'],
        action === DATA_ACTIONS.add && styles['action-label_add'],
        action === DATA_ACTIONS.delete && styles['action-label_delete']
      )}
    >
      {action === DATA_ACTIONS.delete
        ? tt('Delete')
        : action === DATA_ACTIONS.add
          ? tt('Add')
          : action === DATA_ACTIONS.change
            ? tt('Modify')
            : tt('No change')}
    </span>
  )
}
