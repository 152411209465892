import { useDasBalanceContext } from "@did/das-app-context";
import { SignatureErrorDialogType } from "@did/das-app-types/module";
import { Dialog } from "@did/uikit";
import React from "react";

export const SignatureErrorDialog: SignatureErrorDialogType = ({
  showing,
  onClose,
}) => {
  const { tt } = useDasBalanceContext();

  return (
    <Dialog
      showing={showing}
      title={tt("Failed")}
      closeButton
      enableCloseAction
      onClose={onClose}
    >
      <div className="signature-error-dialog">
        {tt(
          "Please try another payment method or wallet App. There may be compatibility issues. For more support, please join our community:"
        )}
        <a className=" text-sky-700 hover:opacity-60 " href="http://discord.gg/did" target="_blank">
          https://discord.gg/did
        </a>
      </div>
    </Dialog>
  );
};
