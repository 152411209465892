import { useDasBalanceContext } from '@did/das-app-context'
import { BillInfoType } from '@did/das-app-types/components'
import { cn } from '@did/tools'
import { BaseCard, Iconfont } from '@did/uikit'

export const BillInfo: BillInfoType = ({
  title,
  billList,
  description,
  footer,
  loading,
  className
}) => {
  const { tt, router } = useDasBalanceContext()
  return (
    <BaseCard className={cn('w-full p-4', className)}>
      <div className="text-base font-bold flex justify-center text-[#31333E]">
        {title}
      </div>
      {description && (
        <div className="text-sm p-1 text-[#5f6570] bg-[#5262791a] rounded-lg text-center leading-4 my-3 ">
          {description}
        </div>
      )}
      <div
        className="h-[1px] my-4 sm:my-5"
        style={{
          background:
            'repeating-linear-gradient(to right, #979797, #979797 5px, transparent 5px, transparent 10px)'
        }}
      ></div>
      {loading && (
        <div className="flex justify-center min-h-[100px] items-center ">
          <Iconfont
            className="animate-spin"
            name="loading"
            size={16}
            color="#5F6570"
          />
        </div>
      )}
      {!loading && !billList?.length && (
        <div className="flex justify-center min-h-[100px] items-center text-sm text-[#5F6570] ">
          {tt('No record, go ahead and invite~')}
        </div>
      )}
      {!loading && Boolean(billList?.length) && (
        <>
          <div>
            <div className="flex justify-between items-center font-normal text-sm text-[#5F6570] mb-1">
              <div>{tt('Bonus (CKB)')}</div>
              <div>{tt('Invitees’s .bit')}</div>
            </div>
            {billList?.map((record, index) => (
              <div key={index} className=" flex justify-between py-2">
                <div>
                  <div className="text-sm font-medium">{record.reward}</div>
                  <div className="font-normal text-xs">
                    {record.invitation_time}
                  </div>
                </div>
                <div
                  className="w-40 text-[#3E66B3] break-all text-end text-sm cursor-pointer"
                  onClick={() => {
                    router.push(`/data/${record.invitee}`)
                  }}
                >
                  {record.invitee}
                </div>
              </div>
            ))}
          </div>

          <div
            className="h-[1px] my-4"
            style={{
              background:
                'repeating-linear-gradient(to right, #979797, #979797 5px, transparent 5px, transparent 10px)'
            }}
          ></div>
          <div className=" flex justify-center"> {footer}</div>
        </>
      )}
    </BaseCard>
  )
}
