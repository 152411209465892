import { ActionButtonsType } from '@did/das-app-types/module'
import { Iconfont } from '@did/uikit'
import styles from './styles.module.scss'
import { cn } from '@did/tools'

export const ActionButtons: ActionButtonsType = ({
  className,
  onEdit,
  onDelete
}) => {
  return (
    <span
      className={cn(styles['action-buttons'], className)}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
      }}
    >
      <button
        className={styles['action-buttons_button']}
        onClick={(event) => {
          onEdit()
        }}
      >
        <Iconfont name="edit" size="15" color="#31333E" />
      </button>
      <span className={styles['action-buttons_split-line']} />
      <button
        className={cn(styles['action-buttons_button'], styles['_right'])}
        onClick={(event) => {
          onDelete()
        }}
      >
        <Iconfont name="remove" size="15" color="#EB5757" />
      </button>
    </span>
  )
}
