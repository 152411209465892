import { CYCLE_CALL_FUNCTION_TIME } from '@did/constants'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { ACCOUNT_STATUS_TIPS_TYPE } from '@did/das-app-types/module'
import { isMobile } from '@did/tools'
import { ACCOUNT_STATUS, ISearchAccountRes } from '@did/types'
import { useState, useEffect, useRef } from 'react'
import { ErrorInfo } from '@did/monitoring'

export const useRegisterStatus = ({
  bitName,
  paid: propsPaid,
  polling = true,
  noAlert = false
}: {
  bitName: string
  paid: boolean
  polling?: boolean
  noAlert?: boolean
}) => {
  const { services, connectedAccount, alert, tt, router } =
    useDasBalanceContext()
  const { setIsDobsMode } = useCccContext()

  const [accountInfo, setAccountInfo] = useState<ISearchAccountRes>()
  const [tipStatus, setTipStatus] = useState<ACCOUNT_STATUS_TIPS_TYPE>(
    ACCOUNT_STATUS_TIPS_TYPE.registering
  )
  const [paid, setPaid] = useState(propsPaid)
  const isChecking = useRef(false)
  const timerId = useRef<any>()

  useEffect(() => {
    setPaid(propsPaid)
  }, [propsPaid])

  const clearCheckAccountStatus = () => {
    clearInterval(timerId.current)
  }

  const getAccountInfo = async () => {
    if (!bitName || isChecking.current || !connectedAccount) return
    isChecking.current = true
    try {
      const res = await services.account.searchAccount({
        account: bitName,
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        }
      })
      setAccountInfo(res)
      if (noAlert) return
      if (res && res.is_self) {
        if (res.status === ACCOUNT_STATUS.registerable) {
          setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registerFailed)
          clearCheckAccountStatus()
        } else if (res.status === ACCOUNT_STATUS.registeringPaymentConfirm) {
          if (paid || (res.register_tx_map && res.register_tx_map['1'])) {
            setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registering)
          } else {
            setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registeringPaymentConfirm)
          }
        } else if (
          [
            ACCOUNT_STATUS.registeringLockedAccount,
            ACCOUNT_STATUS.registering,
            ACCOUNT_STATUS.registeringIncludeProposal,
            ACCOUNT_STATUS.registeringConfirmProposal
          ].includes(res.status)
        ) {
          setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registering)
        } else if (
          [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
            res.status
          )
        ) {
          setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registered)
          clearCheckAccountStatus()
        }
      } else if (
        [
          ACCOUNT_STATUS.registerable,
          ACCOUNT_STATUS.registeringPaymentConfirm
        ].includes(res.status)
      ) {
        setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registerFailed)
        clearCheckAccountStatus()
      } else if (
        [
          ACCOUNT_STATUS.registeringLockedAccount,
          ACCOUNT_STATUS.registering,
          ACCOUNT_STATUS.registeringIncludeProposal,
          ACCOUNT_STATUS.registeringConfirmProposal
        ].includes(res.status)
      ) {
        alert({
          title: tt('Error'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: bitName }
          )
        })
        setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registerFailed)
        clearCheckAccountStatus()
      } else if (
        [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
          res.status
        )
      ) {
        alert({
          title: tt('Error'),
          message: tt(
            '{accountName} has been registered by someone else and can no longer be registered',
            { accountName: bitName }
          )
        })
        setTipStatus(ACCOUNT_STATUS_TIPS_TYPE.registerFailed)
        clearCheckAccountStatus()
      }
    } catch (err: any) {
      ErrorInfo.error(err)
    } finally {
      isChecking.current = false
    }
  }

  useEffect(() => {
    if (!bitName || !connectedAccount?.address) return
    getAccountInfo()
    if (!polling) return

    timerId.current = setInterval(() => {
      getAccountInfo()
    }, CYCLE_CALL_FUNCTION_TIME * 2)

    return () => {
      clearInterval(timerId.current)
    }
  }, [bitName, connectedAccount?.address, paid, polling])

  const onPaid = () => {
    setPaid(true)
    if (!timerId.current) {
      timerId.current = setInterval(() => {
        getAccountInfo()
      }, CYCLE_CALL_FUNCTION_TIME * 2)
    }
  }

  const onRegisterAgain = () => {
    router.push(`/account/create/${bitName}`, router.query)
  }

  const goHome = () => {
    router.push('/create')
  }

  const goMe = () => {
    setIsDobsMode(false)
    router.push('/my')
  }

  const onRegisterAnother = () => {
    if (isMobile()) {
      router.push('/create')
    } else {
      window.open('/bit/create')
    }
  }

  return {
    accountInfo,
    tipStatus,
    onPaid,
    onRegisterAgain,
    goHome,
    goMe,
    onRegisterAnother
  }
}
