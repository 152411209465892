import { AccountInfoActionType } from "@did/das-app-types/components";
import { Button, Iconfont } from "@did/uikit";
import React from "react";

export const AccountInfoAction: AccountInfoActionType = ({
  handleDelete,
  handleEdit,
  deleteLoading,
}) => {
  return (
    <>
      <div className="flex items-center">
        <Button type="text" size="small" onClick={handleDelete}>
          <Iconfont name="delete" size={16} className="mr-1" /> 删除
        </Button>
        <div className=" mx-2 w-px h-5 bg-gray-100 "></div>
        <Button type="text" size="small" onClick={handleEdit}>
          <Iconfont name="edit-line" size={16} className="mr-1" />
          编辑
        </Button>
      </div>
    </>
  );
};
