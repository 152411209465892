import { TextInputProps, TextInputType } from '@did/das-balance-types'
import React from 'react'
import styles from './styles.module.scss'
import { cn } from '@did/tools'

export const TextInput: TextInputType = React.forwardRef<
  HTMLInputElement,
  TextInputProps
>(({ type = 'text', suffix = '', errorMessages = [], ...props }, ref) => {
  const inputClass = `${styles.textInput__input} ${
    errorMessages[0] ? styles.textInput__input_error : ''
  } ${suffix ? styles.textInput__input__suffix : ''}`

  return (
    <div className={styles.textInput}>
      <div className={styles.textInput__container}>
        <input
          ref={ref}
          className={cn(inputClass, 'placeholder:text-gray-400')}
          type={type}
          {...props}
        />
        <span className={styles.textInput__suffix}>{suffix}</span>
      </div>
      {errorMessages[0] && (
        <div className={styles.textInput__errorMessages}>
          {errorMessages[0]}
        </div>
      )}
    </div>
  )
})
