import { useCallback } from 'react'
import { TabType } from '@did/das-app-types/components'
import { cn } from '@did/tools'

export const Tab: TabType = ({ items, value, onChange }) => {
  const onButtonChange = useCallback(
    (value: string) => {
      onChange?.(value)
    },
    [onChange]
  )

  return (
    <div className="w-full bg-gray-100 rounded-xl p-1 flex gap-1">
      {items.map((item) => (
        <button
          key={item.value}
          className={cn(
            'flex-1 h-8 border-none rounded-lg text-base font-semibold outline-0 cursor-pointer',
            value === item.value
              ? 'bg-white text-neutral-900 shadow-tab-button'
              : 'text-zinc-500'
          )}
          onClick={() => onButtonChange(item.value)}
        >
          {item.text}
          {item.total && ` (${item.total}) `}
        </button>
      ))}
    </div>
  )
}
