import Axios, { AxiosInstance } from 'axios'
import { AxiosError, AxiosResponse } from 'axios'

export class BasicService {
  axios: AxiosInstance
  isProdData: boolean

  constructor(param: {
    isProdData: boolean
    baseURL: string
    withCredentials?: boolean
    onFulfilled?: (data: AxiosResponse) => any
    onRejected?: (data: AxiosError) => any
    headers?: any
  }) {
    const {
      isProdData,
      baseURL,
      onFulfilled,
      onRejected,
      withCredentials = true,
      headers = {}
    } = param
    this.axios = Axios.create({
      baseURL,
      withCredentials,
      headers
    })
    this.isProdData = isProdData
    this.axios.interceptors.response.use(onFulfilled, onRejected)
  }
}
