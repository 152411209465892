import { validateParams } from '@did/tools'
import { BasicService } from './basic-service'

import {
  IDasReverse,
  IReverseInfoParams,
  IReverseInfoRes,
  ISetDasReverseParams,
  ISearchAccountsParams,
  ISearchAccountsRes,
  ICheckAccountStatusParams,
  ICheckAccountStatusRes,
  IUpdateReverseParams,
  IUpdateReverseRes,
  ISendSignatureParams,
  ISendSignatureRes,
  ISendTxRes,
  ICheckReverseTxStatusParams,
  ICheckReverseTxStatusRes,
  SignTxListParams,
  SignTxListRes,
  IKeyInfo,
  IReverseBatchParams,
  IReverseBatchRes
} from '@did/types'

export default class DasReverse extends BasicService implements IDasReverse {
  reverseInfo({
    coinType,
    address
  }: IReverseInfoParams): Promise<IReverseInfoRes> {
    return this.axios.post('/reverse/info', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }

  /**
   * set das reverse
   * @param key_info
   * @param evm_chain_id
   * @param account
   */
  setDasReverse({
    key_info,
    evm_chain_id,
    account
  }: ISetDasReverseParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, account }, 'setDasReverse')
    return this.axios.post('/reverse/declare', {
      type: 'blockchain',
      key_info,
      evm_chain_id,
      account
    })
  }

  /**
   * delete reverse record
   * @param key_info
   * @param evm_chain_id
   */
  deleteDasReverse({
    key_info,
    evm_chain_id
  }: {
    key_info: IKeyInfo
    evm_chain_id: number
  }): Promise<SignTxListParams> {
    validateParams({ ...key_info }, 'deleteDasReverse')
    return this.axios.post('/reverse/retract', {
      type: 'blockchain',
      key_info,
      evm_chain_id
    })
  }

  searchAccounts({
    keyword,
    coinType,
    address
  }: ISearchAccountsParams): Promise<ISearchAccountsRes> {
    return this.axios.post('/account/search', {
      type: address ? 'blockchain' : '',
      account: keyword,
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }

  checkAccountStatus({
    coinType,
    address,
    account
  }: ICheckAccountStatusParams): Promise<ICheckAccountStatusRes> {
    validateParams({ coinType, address, account }, 'checkAccountStatus')
    return this.axios.post('/account/check', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      },
      account
    })
  }

  updateReverse({
    coinType,
    address,
    account,
    action = 'update'
  }: IUpdateReverseParams): Promise<IUpdateReverseRes> {
    validateParams({ coinType, address, account }, 'updateReverse')
    return this.axios.post('/reverse/update', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      },
      action,
      account
    })
  }

  sendSignature({
    sign_key,
    signature,
    sign_address
  }: ISendSignatureParams): Promise<ISendSignatureRes> {
    validateParams({ sign_key, signature }, 'sendSignature')
    return this.axios.post('/reverse/send', {
      sign_key,
      signature,
      sign_address
    })
  }

  sendTx({
    sign_key,
    sign_list,
    sign_address
  }: SignTxListRes): Promise<ISendTxRes> {
    validateParams({ sign_key, sign_list }, 'sendTx')
    return this.axios.post('/transaction/send', {
      sign_key,
      sign_list,
      sign_address
    })
  }

  // Since the new reverse transaction is sent by backend, we cannot get tx hash directly. The only way that we could check the latest tx status is to call this API.
  checkReverseTxStatus({
    coinType,
    address
  }: ICheckReverseTxStatusParams): Promise<ICheckReverseTxStatusRes> {
    validateParams({ coinType, address }, 'checkReverseTxStatus')
    return this.axios.post('/reverse/status', {
      type: 'blockchain',
      key_info: {
        coin_type: coinType,
        key: address
      }
    })
  }

  reverseBatch({
    account,
    key_info_list
  }: IReverseBatchParams): Promise<IReverseBatchRes> {
    validateParams({ account, key_info_list }, 'reverseBatch')
    return this.axios.post('/reverse/batch', {
      type: 'blockchain',
      account,
      key_info_list
    })
  }
}
