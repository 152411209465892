import { useDasBalanceContext } from '@did/das-app-context'
import { cn } from '@did/tools'
import { IBackButtonProps } from '@did/types'
import { IconButton, Iconfont } from '@did/uikit'
import React from 'react'

export const BackButton: React.FC<IBackButtonProps> = ({
  className,
  onClick
}) => {
  const { tt } = useDasBalanceContext()

  return (
    <IconButton
      className={cn(
        'h-[38px] w-[38px] md:w-auto rounded-full text-neutral-700 text-base font-semibold',
        className
      )}
      onClick={() => {
        onClick?.()
      }}
      Icon={(props) => <Iconfont name="back" {...props} />}
    >
      <span className="hidden md:inline">{tt('Back')}</span>
    </IconButton>
  )
}
