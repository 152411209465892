import { Iconfont } from '@did/uikit'
import {
  FormEvent,
  useCallback,
  useRef,
  useState,
  KeyboardEventHandler,
  KeyboardEvent,
  MouseEvent
} from 'react'
import { ACCOUNT_SUFFIX, DEBOUNCE_WAIT_TIME } from '@did/constants'
import { digitalEmojiHandle } from '@did/tools/bit-account-string-handle'
import { AccountSearchType } from '@did/das-app-types/components'
import { cn, debounce } from '@did/tools'
import { useDasBalanceContext } from '@did/das-app-context'

export const AccountSearch: AccountSearchType = ({ className, onSearch }) => {
  const { tt } = useDasBalanceContext()

  const [active, setActive] = useState(false)
  const [query, setQuery] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  const onClickOutside = useCallback(() => {
    setActive(false)
    window.document.body.removeEventListener('click', clickOutsideListener)
  }, [])

  const clickOutsideListener = useCallback((event: any) => {
    if (event.target !== inputRef.current) {
      onClickOutside()
    }
  }, [])

  const onClickInput = useCallback(() => {
    if (!active) {
      setActive(true)
    }
    window.setTimeout(() => {
      inputRef.current?.focus()
      window.document.body.addEventListener('click', clickOutsideListener)
    })
  }, [inputRef.current, active])

  const onDebounceSearch = useCallback(
    debounce((accountStr: any) => {
      let account = accountStr
      account = account.replace(/\s+/g, '')
      account = account.toLowerCase()
      if (!/\.bit$/.test(account) || account.includes('#')) {
        account = account.replace(/\.bit$/, '')
        account = account + ACCOUNT_SUFFIX
        account = account.replace(/\.bit$/, '')
      }
      onSearch(account)
    }, DEBOUNCE_WAIT_TIME),
    [onSearch]
  )

  const onAccountSearch = useCallback(
    (account: string) => {
      onDebounceSearch(account)
    },
    [query, onDebounceSearch]
  )

  const onInput = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const inputStr = digitalEmojiHandle(
        (event.target as HTMLInputElement).value
      )
      setQuery(inputStr)
      onAccountSearch(inputStr)
    },
    [onAccountSearch]
  )

  const onKeydown: KeyboardEventHandler = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const keyCode = event.keyCode || event.which
      if (keyCode === 13) {
        onAccountSearch(query)
      }
    },
    [onAccountSearch, query]
  )

  const onCancel = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setQuery('')
      onSearch('')
      setActive(false)
    },
    [onSearch]
  )

  return (
    <div className={cn('inline-block', className)}>
      <div className="relative flex py-[3px] pr-[6px] pl-3 items-center bg-gray-100 rounded-xl h-[40px] box-border">
        <Iconfont className="mr-1" name="search" size={12} color="#868C98" />
        <input
          onClick={onClickInput}
          ref={inputRef}
          value={query}
          placeholder={tt('Search')}
          className="flex-1 w-[48px] ring-0 outline-0 p-0 border-none text-neutral-700 font-medium bg-transparent text-sm placeholder:text-neutral-400 focus:ring-0"
          type="text"
          onKeyDown={onKeydown}
          onInput={onInput}
        />
        <div
          className={cn(
            'ml-[10px] py-[4px] px-[7px] text-[12px] bg-[#EFF2F5] cursor-pointer rounded-lg text-assist-font-color font-medium',
            !active && 'hidden'
          )}
          onClick={onCancel}
        >
          {tt('Cancel')}
        </div>
      </div>
    </div>
  )
}
