import { useDasBalanceContext } from '@did/das-app-context'
import { RegisteredInfoType } from '@did/das-app-types/module'
import { confetti } from '@did/tools'
import { DidCard, Iconfont } from '@did/uikit'
import { useEffect } from 'react'

export const RegisteredInfo: RegisteredInfoType = ({
  bitName,
  goMe,
  goHome
}) => {
  const { tt } = useDasBalanceContext()

  const fire = (particleRatio: any, opts: any) => {
    confetti({
      ...opts,
      origin: { y: 0.33 },
      particleCount: Math.floor(300 * particleRatio)
    })
  }

  useEffect(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55
    })
    fire(0.2, {
      spread: 60
    })
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })
    fire(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }, [])
  return (
    <>
      <DidCard width="130" account={bitName || ''} />
      <div className="py-4 text-neutral-700 text-2xl font-bold break-all text-center">
        {tt('Congrats!')}
        <br />
        {tt(`Now you own {accountName}`, {
          accountName: bitName
        })}
      </div>
      <div
        className="w-full mt-8 p-5 bg-white rounded-3xl flex justify-between items-center hover:opacity-70 cursor-pointer"
        onClick={goMe}
      >
        <span className="text-neutral-900 text-base font-bold">
          <Iconfont className="mr-3" name="person" size={32} />
          {tt('Check my .bit account')}
        </span>
        <Iconfont name="arrow-right" color="#121314" size="24" />
      </div>
    </>
  )
}
