import { cn, ErrorInfo } from '@did/tools'
import { DidAvatar, Iconfont } from '@did/uikit'
import { useDasBalanceContext } from '@did/das-app-context'
import { useCallback, useEffect, useState } from 'react'
import { IAccountInfoRes } from '@did/types/das-services'
import { RegisteringAccountType } from '@did/das-app-types/components'

export const RegisteringAccountList: RegisteringAccountType = ({
  fetchDataComplete
}) => {
  const { tt, connectedAccount, services, router } = useDasBalanceContext()

  const [registeringAccounts, setRegisteringAccounts] = useState<
    IAccountInfoRes[]
  >([])

  const getRegisteringAccounts = useCallback(async () => {
    if (!connectedAccount?.chain?.coinType || !connectedAccount?.address) {
      fetchDataComplete()
      return
    }
    try {
      const res = await services.account.registeringAccounts({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        }
      })
      fetchDataComplete()
      setRegisteringAccounts(res?.registering_accounts || [])
    } catch (err: any) {
      ErrorInfo.error(err)
      throw err
    }
  }, [connectedAccount, services])

  const [isFirstRequest, setIsFirstRequest] = useState(true)

  useEffect(() => {
    if (connectedAccount?.address && isFirstRequest) {
      getRegisteringAccounts()
      setIsFirstRequest(false)
    } else {
      fetchDataComplete()
    }
  }, [connectedAccount?.address, getRegisteringAccounts])

  const seeDetails = useCallback(
    (account: IAccountInfoRes) => {
      router.push(`/account/create/status/${account.account}`, {
        from: 'pending'
      })
    },
    [router]
  )

  return (
    <div className={cn('w-full')}>
      <div
        className={cn(
          'p-2 mx-auto mb-3 flex items-center h-10 bg-status-warn-bg-color rounded-[8px] text-xs font-medium text-[#ff6b6b] box-content'
        )}
      >
        <Iconfont className="mr-2" name="tips" size="24" color="#E05656" />
        {tt(
          'Failed registration or unpaid orders will be automatically closed after 24 hours.'
        )}
      </div>
      <ul>
        {registeringAccounts?.map((account) => (
          <li
            key={account.account}
            className="flex justify-between items-center py-0 px-5 mb-2 h-[90px] bg-white rounded-[12px] border-[1px] border-status-border-color cursor-pointer hover:bg-[#F7F9FA]"
            onClick={() => seeDetails(account)}
          >
            <div className="flex justify-between items-center text-lg font-semibold text-tab-active break-words hyphens-auto leading-[21px]">
              <DidAvatar
                account={account?.account || ''}
                fontSize="20px"
                lineClassName="w-[23px] h-[10px] mb-[-2px]"
                textClassName="mb-[-2px]"
                className="mr-3"
                size={44}
              />
              <div className="flex flex-col text-lg font-semibold text-tab-active break-all hyphens-auto">
                <span className="mr-2">{account.account}</span>
                <div>
                  <span className="inline-flex items-center mt-4px py-0 px-1 rounded text-xs text-success-font-color bg-list-status-bg-color">
                    <Iconfont
                      className="animate-rotate360DegAnimation"
                      name="loading"
                      size="16"
                      color="#2EB67D"
                    />
                    {tt('Registering')}
                  </span>
                </div>
              </div>
            </div>
            <Iconfont name="arrow-right" color="#121314" size="18" />
          </li>
        ))}
      </ul>
    </div>
  )
}
