import {
  IAccountInfoRes,
  IBaseProps,
  IBasePropsWithSlots,
  IMyRewardsResInviteList
} from '@did/types'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { BannerTipVariantType } from './module'

export interface IAccountAutocompleteProps extends IBaseProps {
  address?: string
  onChange?: (bitName: string) => void
  value?: string
  placeholder?: string
  bitName?: string
  onlyOwned?: boolean
  onBillListChange?: (list: string[]) => void
}

export type AccountAutocompleteType = React.FC<IAccountAutocompleteProps>

export interface IAccountInfoActionProps extends IBaseProps {
  handleDelete?: () => void
  handleEdit?: () => void
  deleteLoading?: boolean
}

export type AccountInfoActionType = React.FC<IAccountInfoActionProps>

export interface IBannerTipProps extends IBaseProps {
  title: string
  icon?: React.ReactNode
  number?: number
  variant?: BannerTipVariantType
  to: string | { path: string; query?: string } | Function
}

export type BannerTipType = React.FC<IBannerTipProps>

export interface ITab extends IBaseProps {
  items: Array<{
    text: string
    value: string
    total?: string
  }>
  value: string
  onChange: (value: string) => void
}

export type TabType = React.FC<ITab>

export interface IDotbitListProps extends IBaseProps {}

export type DotbitListType = React.FC<IDotbitListProps>

export interface IAccountStatusFilterOption {
  label: string
  value: any
}

export enum EnumAccountStatusFilters {
  all = 0,
  secondLevelDids = 2,
  onSale = 3,
  expireSoon = 4,
  toBeRecycled = 5,
  regularDids = 6,
  upgradedDids = 7
}
export interface IAccountStatusFilter extends IBaseProps {
  value?: string | number
  options: IAccountStatusFilterOption[]
  onInput: (value: EnumAccountStatusFilters) => void
}

export type AccountStatusFilterType = React.FC<IAccountStatusFilter>

export interface IAccountSearch extends IBaseProps {
  onSearch: (value: string) => void
}

export type AccountSearchType = React.FC<IAccountSearch>

export interface IStatusTip extends IBaseProps {
  icon: string
  iconSize: string
  tip: string
  tipFontSize: string
}

export type StatusTipType = React.FC<IStatusTip>

export interface IAccountStatus extends IBaseProps {
  accountInfo: IAccountInfoRes
  highlighted: boolean
  onClick: () => void
}

export type AccountStatusType = React.FC<IAccountStatus>

export interface IPermissionTipsProps extends IBaseProps {
  showing: boolean
  managerTip?: boolean
  title?: string
  message?: React.ReactNode
  address?: string
  coinType?: string
  managerAddress?: string
  managerCoinType?: string
  ownerAddress?: string
  ownerCoinType?: string
  copyAction?: boolean
  onClose: () => void
}

export type PermissionTipsType = React.FC<IPermissionTipsProps>

export interface RegisteringAccountProps extends IBaseProps {
  fetchDataComplete: () => void
}

export type RegisteringAccountType = React.FC<RegisteringAccountProps>

export type AuctionAccountListType = React.FC<IBaseProps>

export interface IBillInfoProps extends IBaseProps {
  billList: IMyRewardsResInviteList[]
  title?: string
  loading?: boolean
  description?: string | React.ReactNode
  footer?: string | React.ReactNode
}

export type BillInfoType = React.FC<IBillInfoProps>

export interface IRenewalSubmittedProps extends IBaseProps {
  showing: boolean
  txHash: string
  txHashLink: string
  onClose: (value: boolean) => void
}

export type RenewalSubmittedType = React.FC<IRenewalSubmittedProps>

export interface IWarningAlertProps extends IBaseProps {}

export type WarningAlertType = React.FC<IWarningAlertProps>

export interface IOption {
  text: string
  value: any
  icon?: string
  logo?: string
}

export interface IDataSelectProps extends IBaseProps {
  defaultValue: string | number
  options: IOption[]
  search?: boolean
  errorMessages?: string[]
  onChange?: (value: string | number) => void
  placeholder?: string
  disabled?: boolean
}

export interface IInputCounterTypeProps extends IBasePropsWithSlots {
  value: number
  onChange?: (value: number) => void
  disabled?: boolean
  onClick?: () => void
}

export type InputCounterType = React.FC<IInputCounterTypeProps>

export interface InfoButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  icon?: ReactNode
}

export interface ISwitchWalletTipsProps extends IBaseProps {
  showing: boolean
  title?: string
  message?: React.ReactNode
  address?: string
  coinType?: string
  onSwitchWallet: () => void
  onClose: () => void
}

export type SwitchWalletTipsType = React.FC<ISwitchWalletTipsProps>

export interface IDoBListProps extends IBaseProps {}

export type DoBListType = React.FC<IDoBListProps>
