import { CKB, DASBalanceTokenId, USD } from '@did/constants/chain'
import { useDasBalanceContext } from '@did/das-app-context'
import { IToken } from '@did/types'
import { useEffect, useState } from 'react'

export const useTokenList = () => {
  const { services } = useDasBalanceContext()
  const [tokens, setTokens] = useState<IToken[]>([])

  const getTokens = async () => {
    const res = await services.common.tokens()
    if (!(res && res.token_list && res.token_list.length > 0)) {
      return
    }
    let portalWalletCkb = res.token_list.find((option: any) => {
      return option.token_id === CKB.tokenId
    })

    let stripe: any = res.token_list.find((option: any) => {
      return option.token_id === USD.tokenId
    })

    // @ts-ignore
    stripe = {
      ...stripe
    }

    const otherToken = res.token_list.filter((option: any) => {
      return option.token_id !== CKB.tokenId && option.token_id !== USD.tokenId
    })

    setTokens([
      ...otherToken,
      {
        ...portalWalletCkb,
        name: '.bit Balance',
        token_id: DASBalanceTokenId
      },
      stripe
    ])
  }

  useEffect(() => {
    getTokens()
  }, [])

  return tokens
}
