import { collapseString, ErrorInfo } from '@did/tools'
import { StatusTip } from './status-tip'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DasButton, DidAvatar, Iconfont, Tooltip } from '@did/uikit'
import { AccountSearch } from './account-search'
import { DEFAULT_PAGE_SIZE } from '@did/constants'
import { IUpgradableListRes } from '@did/types'
import { DOTBIT_UPGRADE_STATUS } from '@did/das-app-constants'
import { UpgradeDialog } from './upgrade-dialog'
import { CKB } from '@did/constants/chain'

export const UpgradeableListPage = () => {
  const { tt, services, connectedAccount, walletSdk, isProd } =
    useDasBalanceContext()
  const { ccc, ckbAddress } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const [fetchDataLoading, setFetchDataLoading] = useState(true)
  const [upgradeableList, setUpgradeableList] = useState<IUpgradableListRes[]>(
    []
  )
  const [noMoreShowing, setNoMoreShowing] = useState(false)
  const [loadMoreShowing, setLoadMoreShowing] = useState(false)
  const [loadingShowing, setLoadingShowing] = useState(false)
  const [page, setPage] = useState(0)
  const [searchWord, setSearchWord] = useState('')
  const [accountInfo, setAccountInfo] = useState<IUpgradableListRes>(
    {} as IUpgradableListRes
  )
  const [upgradeDialogShowing, setUpgradeDialogShowing] = useState(false)

  const noRecords = useMemo(() => {
    return (
      !fetchDataLoading && upgradeableList.length === 0 && searchWord === ''
    )
  }, [fetchDataLoading, upgradeableList, searchWord])

  const getUpgradeableList = useCallback(
    async (page: number, searchStr: string) => {
      if (!connectedAccount?.address || !connectedAccount?.chain?.coinType) {
        setUpgradeableList([])
        setLoadMoreShowing(false)
        return
      }
      if (upgradeableList.length === 0) {
        setFetchDataLoading(true)
      }
      setNoMoreShowing(false)
      if (page > 0) {
        setLoadMoreShowing(false)
        setLoadingShowing(true)
      }

      try {
        setPage(page + 1)
        const res = await services.account.upgradableList({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          page: page + 1,
          keyword: searchStr
        })

        setLoadingShowing(false)
        if (!res || !res.list) {
          setFetchDataLoading(false)
          return
        }

        if (page + 1 === 1) {
          setUpgradeableList(res.list)
        } else {
          setUpgradeableList(upgradeableList.concat(...res.list))
        }

        const length = res.list.length
        if (length < DEFAULT_PAGE_SIZE) {
          setLoadMoreShowing(false)
          if (length >= 0) {
            setNoMoreShowing(true)
          }
        } else {
          setLoadMoreShowing(true)
        }
      } catch (err: any) {
        ErrorInfo.error(err)
      } finally {
        setFetchDataLoading(false)
        setLoadingShowing(false)
      }
    },
    [
      upgradeableList,
      connectedAccount?.address,
      connectedAccount?.chain?.coinType
    ]
  )

  const updateUpgradeableList = (record: IUpgradableListRes) => {
    const list = upgradeableList.map((item) => {
      if (item.account === record.account) {
        return { ...item, ...record }
      }
      return item
    })
    setUpgradeableList(list)
  }

  const onSearch = useCallback(
    (value: string) => {
      setPage(0)
      setSearchWord(value)
      setFetchDataLoading(true)
      getUpgradeableList(0, value)
    },
    [getUpgradeableList]
  )

  const onConnect = useCallback(() => {
    walletSdk?.connectWallet?.()
  }, [walletSdk?.connectWallet])

  const onUpgrade = (info: IUpgradableListRes) => {
    if (!ckbAddress) {
      cccConnector?.open()
      return
    }
    if (!connectedAccount?.chain?.coinType || !connectedAccount?.address) {
      walletSdk?.connectWallet?.()
      return
    }
    setAccountInfo(info)
    setUpgradeDialogShowing(true)
  }

  useEffect(() => {
    if (connectedAccount?.chain?.coinType && connectedAccount?.address) {
      setPage(0)
      getUpgradeableList(0, searchWord)
    } else {
      setUpgradeableList([])
      setLoadingShowing(false)
      setLoadMoreShowing(false)
      setNoMoreShowing(false)
      setFetchDataLoading(false)
    }
  }, [connectedAccount?.address, connectedAccount?.chain?.coinType])

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 md:px-0 pt-5 md:pt-16 flex flex-col gap-y-4">
      {!noRecords && <AccountSearch className="w-full" onSearch={onSearch} />}
      {fetchDataLoading && (
        <div className="mt-[80px]">
          <StatusTip
            className="font-medium my-4 mx-0 text-[#31333E]"
            icon="pending"
            iconSize="72"
            tip={tt('Loading')}
            tipFontSize="14"
          />
        </div>
      )}
      {noRecords &&
        (connectedAccount?.address ? (
          <div className="w-full pt-10 pb-14 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
            <Iconfont name="no-records" size={156} />
            <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
              {tt(`You don't have a upgradeable .bit.`)}
            </div>
          </div>
        ) : (
          <div className="w-full pt-10 pb-20 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
            <Iconfont name="no-dobs-records" size={156} />
            <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
              {tt('Connect wallet to see your upgradeable .bit.')}
            </div>
            <DasButton
              className="h-[38px] mx-auto font-semibold text-sm leading-[16px]"
              black
              onClick={onConnect}
              middle
            >
              {tt('Connect wallet')}
            </DasButton>
          </div>
        ))}
      <ul className="w-full">
        {!fetchDataLoading &&
          upgradeableList.map((info, index) => (
            <li
              key={index}
              className="flex justify-between items-center py-1 px-5 mb-2 min-h-[84px] bg-white rounded-[12px] border border-status-border-color hover:bg-[#F7F9FA]"
            >
              <div className="flex justify-between items-center text-lg font-semibold text-tab-active break-words hyphens-auto leading-[21px]">
                <DidAvatar
                  account={info?.account || ''}
                  fontSize="20px"
                  lineClassName="w-[23px] h-[10px] mb-[-2px]"
                  textClassName="mb-[-2px]"
                  className="mr-3"
                  size={44}
                />
                <div className="flex flex-col text-lg font-semibold text-tab-active break-all hyphens-auto">
                  <span className="mr-2">{info.account}</span>
                  <div>
                    {info.upgrade_status ===
                    DOTBIT_UPGRADE_STATUS.paymentConfirmation ? (
                      <Tooltip
                        message={
                          <div className="max-w-full">
                            {tt(
                              'If payment is not made, you can pay again after 15 minutes.'
                            )}
                          </div>
                        }
                      >
                        <span className="inline-flex items-center mt-1 px-1 py-0.5 rounded text-xs text-success-font-color bg-list-status-bg-color leading-none cursor-default">
                          <Iconfont
                            className="animate-spin mr-1"
                            name="loading"
                            size="16"
                            color="#2EB67D"
                          />
                          {tt('Payment is being confirmed, about 5 minutes.')}
                        </span>
                      </Tooltip>
                    ) : info.upgrade_status ===
                      DOTBIT_UPGRADE_STATUS.upgrading ? (
                      <span className="inline-flex flex-col md:flex-row">
                        <span className="inline-flex items-center mt-1 px-1 py-0.5 rounded text-xs text-success-font-color bg-list-status-bg-color leading-none">
                          <Iconfont
                            className="animate-spin mr-1"
                            name="loading"
                            size="16"
                            color="#2EB67D"
                          />
                          {tt('Upgrading, about 5 minutes.')}
                        </span>
                        {info.upgrade_hash && (
                          <a
                            className="inline-flex items-center mt-1 px-1 py-0.5 rounded text-xs text-gray-400 font-normal bg-gray-100 leading-none w-fit md:ml-1"
                            href={`${CKB.getExplorerTrx(isProd)}${
                              info.upgrade_hash
                            }`}
                            target="_blank"
                          >
                            {collapseString(info.upgrade_hash, 5, 5)}
                            <Iconfont
                              name="arrow-right"
                              color="#B0B8BF"
                              size="10"
                              className="ml-1"
                              style={{ verticalAlign: '0px' }}
                            />
                          </a>
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {![
                DOTBIT_UPGRADE_STATUS.paymentConfirmation,
                DOTBIT_UPGRADE_STATUS.upgrading
              ].includes(info.upgrade_status) && (
                <DasButton
                  loading={
                    info.upgrade_status === DOTBIT_UPGRADE_STATUS.loading
                  }
                  black
                  small
                  isLoadingGradient={false}
                  onClick={() => {
                    onUpgrade(info)
                  }}
                >
                  <span className="font-normal text-sm">{tt('Upgrade')}</span>
                </DasButton>
              )}
            </li>
          ))}
        {loadMoreShowing && !fetchDataLoading && (
          <li
            className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm cursor-pointer text-link-font-color hover:text-link-hover-font-color"
            onClick={() => getUpgradeableList(page, searchWord)}
          >
            {tt('Load more')}
          </li>
        )}
        {loadingShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {tt('Loading')}
          </li>
        )}
        {noMoreShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {searchWord && upgradeableList?.length === 0
              ? tt('No matched .bit')
              : noRecords
                ? ''
                : tt('No more')}
          </li>
        )}
      </ul>
      <UpgradeDialog
        showing={upgradeDialogShowing}
        accountInfo={accountInfo}
        updateUpgradeableList={updateUpgradeableList}
        onClose={() => {
          setUpgradeDialogShowing(false)
        }}
      />
    </div>
  )
}
