import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import MoreSvg from '@did/das-assets/images/icon/icon-more.svg'
import { smartOpen } from '@did/tools'
import { IBaseProps } from '@did/types'
import {
  ConnectWallet,
  Dropdown,
  IconButton,
  IconItem,
  Iconfont,
  LangSelect
} from '@did/uikit'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { ErrorInfo } from '@did/monitoring'
import { LanguageOptions } from '@did/constants/language'
import { useContext } from './context'
import { CKB } from '@did/constants/chain'

export const LayoutRight: React.FC<IBaseProps> = () => {
  const {
    tt,
    connectedAccount,
    walletSdk,
    lang,
    setLang,
    services,
    outSiteUrl,
    router
  } = useDasBalanceContext()
  const { setRefreshReverseInfo } = useContext()
  const { isDobsMode, ccc, ckbAddress } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const [bitName, setBitName] = useState('')

  const walletAddressName = useMemo(() => {
    return isDobsMode
      ? ckbAddress
      : connectedAccount?.deviceData?.name || connectedAccount?.address!
  }, [connectedAccount, ckbAddress, isDobsMode])

  const chainIconName = useMemo(() => {
    return isDobsMode ? 'nervos' : connectedAccount?.chain?.icon
  }, [connectedAccount?.chain?.icon, isDobsMode])

  const getReverseInfo = async ({
    address,
    coinType
  }: Record<string, string>) => {
    if (!(address && coinType)) {
      setBitName('')
      return
    }
    try {
      const res = await services.dasReverse.reverseInfo({
        coinType: coinType,
        address: address
      })
      if (res?.is_valid) {
        setBitName(res?.account)
      } else {
        setBitName('')
      }
    } catch (e: any) {
      ErrorInfo.error(e)
      setBitName('')
    }
  }

  const onConnectWallet = useCallback(async () => {
    if (isDobsMode) {
      cccConnector?.open()
    } else {
      if (!!connectedAccount?.address) {
        walletSdk?.loggedInfo?.()
      } else {
        walletSdk?.connectWallet?.()
      }
    }
  }, [
    isDobsMode,
    cccConnector,
    walletSdk,
    connectedAccount?.address,
    ckbAddress
  ])

  useEffect(() => {
    if (isDobsMode && !ckbAddress) {
      setBitName('')
      return
    } else if (
      !isDobsMode &&
      (!connectedAccount?.address || !connectedAccount?.chain?.coinType)
    ) {
      setBitName('')
      return
    }

    const refreshReverseInfo = () => () => {
      return getReverseInfo({
        address: isDobsMode ? ckbAddress : connectedAccount?.address!,
        coinType: isDobsMode ? CKB.coinType : connectedAccount?.chain?.coinType!
      })
    }
    setRefreshReverseInfo(refreshReverseInfo as any)
  }, [connectedAccount, ckbAddress, isDobsMode])

  useEffect(() => {
    if (isDobsMode && !ckbAddress) {
      setBitName('')
      return
    } else if (
      !isDobsMode &&
      (!connectedAccount?.address || !connectedAccount?.chain?.coinType)
    ) {
      setBitName('')
      return
    }

    getReverseInfo({
      address: isDobsMode ? ckbAddress : connectedAccount?.address!,
      coinType: isDobsMode ? CKB.coinType : connectedAccount?.chain?.coinType!
    })
  }, [connectedAccount, ckbAddress, isDobsMode])

  return (
    <div className="flex gap-x-2">
      <ConnectWallet
        bitName={bitName}
        className="rounded-full h-[38px]"
        chainIconName={chainIconName!}
        address={walletAddressName}
        empty={tt('Connect')}
        onClick={onConnectWallet}
      />
      <Dropdown
        options={
          [
            {
              label: (
                <IconItem
                  icon={
                    <Iconfont
                      name="nervos"
                      size={18}
                      className="border border-gray-200 rounded-full"
                    />
                  }
                  outsite
                  text={tt('CKB & Credits Balance')}
                  onClick={() => {
                    smartOpen(`${outSiteUrl?.dasBalanceUrl}`)
                  }}
                />
              ),
              value: 'Balance'
            },
            {
              label: (
                <IconItem
                  icon={
                    <Iconfont
                      name="tab-explorer"
                      size={18}
                      className="text-primary"
                    />
                  }
                  text={tt('Create')}
                  onClick={() => {
                    router.push('/create')
                  }}
                />
              ),
              value: 'Create'
            },
            {
              label: (
                <IconItem
                  icon={
                    <Iconfont
                      name="tab-me"
                      size={18}
                      className="text-primary"
                    />
                  }
                  text={tt('My DIDs')}
                  onClick={() => {
                    router.push('/my')
                  }}
                />
              ),
              value: 'My DIDs'
            },
            {
              label: (
                <IconItem
                  icon={
                    <Iconfont
                      name="alias-fill"
                      size={18}
                      className="text-primary"
                    />
                  }
                  text={tt('.bit Alias')}
                  onClick={() => {
                    router.push('/alias')
                  }}
                />
              ),
              value: '.bit Alias'
            },
            {
              label: (
                <IconItem
                  icon={<Iconfont name="referral" size={18} />}
                  text={tt('Referral')}
                  onClick={() => {
                    router.push('/referral')
                  }}
                />
              ),
              value: 'referral'
            },
            {
              label: (
                <IconItem
                  icon={<Iconfont name="logo" size={18} />}
                  outsite
                  text={tt('What is .bit?')}
                  onClick={() => {
                    smartOpen('https://d.id/id-protocol/bit')
                  }}
                />
              ),
              value: 'What is .bit'
            },
            {
              label: <div></div>,
              value: 'separator',
              type: 'separator'
            },
            {
              label: (
                <div
                  className="w-full h-full flex items-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <LangSelect
                    langList={LanguageOptions}
                    computedLanguage={lang}
                    switchLanguage={(o) => {
                      setLang?.(o)
                    }}
                    className={`h-full z-50`}
                  />
                </div>
              ),
              value: 'switch'
            }
          ].filter(Boolean) as any
        }
      >
        <IconButton className="w-[38px] h-[38px] rounded-full">
          <MoreSvg width={14} />
        </IconButton>
      </Dropdown>
    </div>
  )
}
