import { BaseListPagerType } from "@did/das-balance-types";
import React, { useCallback } from "react";
import styles from "./base-list-pager.module.scss";

export const BaseListPager: BaseListPagerType = ({
  pages,
  current,
  noItems = false,
  onChange,
  slots,
  className,
}) => {
  const onClickPrev = useCallback(() => {
    if (current > 1) {
      onChange(current - 1);
    }
  }, [current, onChange]);

  const onClickNext = useCallback(() => {
    if (current < pages) {
      onChange(current + 1);
    }
  }, [current, pages, onChange]);

  const onClickPage = useCallback(
    (page: number) => {
      if (page !== current) {
        onChange(page);
      }
    },
    [current, onChange]
  );

  return (
    <div className={`${styles["base-list-pager"]} ${className}`}>
      <span
        className={`${styles["pager_item"]} ${
          current === 1 ? styles["_disabled"] : ""
        }`}
        onClick={onClickPrev}
      >
        <slots.icon
          className={`${styles["rotate-180"]}`}
          name="arrow-right"
          size="14"
        />
      </span>
      {!noItems &&
        Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
          <span
            key={page}
            className={`${styles["pager_item"]} ${
              current === page ? styles["_active"] : ""
            }`}
            onClick={() => onClickPage(page)}
          >
            {page}
          </span>
        ))}
      <span
        className={`${styles["pager_item"]} ${
          current >= pages ? styles["_disabled"] : ""
        }`}
        onClick={onClickNext}
      >
        <slots.icon name="arrow-right" size="14" />
      </span>
    </div>
  );
};
