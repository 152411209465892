import { CKB } from '@did/constants/chain'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { UseRewardRecordsListType } from '@did/das-app-types/hooks'
import { formatDateTime, shrinkUnit, thousandSplit } from '@did/tools'
import { IMyRewardsResInviteList } from '@did/types'
import { useState, useEffect } from 'react'
import { ErrorInfo } from '@did/monitoring'

export const useRewardRecordsList: UseRewardRecordsListType = ({ size }) => {
  const { services, connectedAccount } = useDasBalanceContext()
  const { isDobsMode, ckbAddress } = useCccContext()
  const [billList, setBillList] = useState<IMyRewardsResInviteList[]>([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const getData = async (currPage: number, pageSize: number) => {
    if (
      !isDobsMode &&
      (!connectedAccount?.chain?.coinType || !connectedAccount?.address)
    ) {
      return
    }

    if (isDobsMode && ckbAddress) {
      return
    }

    setLoading(true)
    try {
      const res = await services.account.myRewards({
        key_info: {
          coin_type: isDobsMode
            ? CKB.coinType
            : connectedAccount?.chain?.coinType!,
          key: isDobsMode ? ckbAddress : connectedAccount?.address!
        },
        size: pageSize,
        page: currPage
      })
      const list =
        res.list &&
        res.list.map((item: IMyRewardsResInviteList) => {
          return {
            ...item,
            reward: thousandSplit(shrinkUnit(item.reward, CKB.decimals)),
            invitation_time: formatDateTime(item.invitation_time)
          }
        })
      setTotal(res.count)
      setBillList((l = []) => {
        const newList = list?.filter((item) => {
          return !l.some((i) => i.invitation_time === item.invitation_time)
        })

        return [...(l || []), ...newList]
      })
    } catch (err: any) {
      ErrorInfo.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setBillList([])
    getData(1, size)
  }, [connectedAccount, isDobsMode, ckbAddress, size])

  const handlePageChange = (p: number) => {
    setPage(p)
    getData(p, size)
  }

  return {
    billList,
    page,
    handlePageChange,
    loading,
    total
  }
}
