import { PermissionTips } from '@did/das-app-components'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { DataPageType } from '@did/das-app-types/module'
import React, { useEffect, useMemo, useState } from 'react'
import { DasButton, DidCard, Iconfont, Tooltip } from '@did/uikit'
import {
  ACCOUNT_STATUS,
  IAccountInfoRes,
  IAccountParsingRecord,
  ParsingRecordType
} from '@did/types'
import { handleError } from '@did/das-app-utils'
import { Renewal } from './renewal/renewal'
import { ManagePermission } from './manage-permission/manage-permission'
import {
  cn,
  findParsingRecordChain,
  formatDateTime,
  removeDotbitSuffix,
  smartOpen
} from '@did/tools'
import { SUB_ACCOUNT_REG_EXP, TIME_FORMAT } from '@did/constants'
import { SETTINGS } from '@did/das-app-constants'
import dayjs from 'dayjs'
// @ts-ignore
import { atcb_action } from 'add-to-calendar-button'
import { ParsingRecords } from './parsing-records/parsing-records'
import { useIsManager, useIsOwner } from '@did/das-app-hooks'
import Head from 'next/head'

export const DatePage: DataPageType = ({ bitName }) => {
  const { tt, router, services, alert, walletSdk, outSiteUrl } =
    useDasBalanceContext()
  const { isDobsMode, setIsDobsMode, ccc, ckbAddress } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const [permissionTipsShowing, setPermissionTipsShowing] = useState(false)
  const [managerPermissionTips, setManagerPermissionTips] = useState(false)
  const [managePermissionShowing, setManagePermissionShowing] = useState(false)
  const [renewalShowing, setRenewalShowing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [enableEditRecordMode, setEnableEditRecordMode] = useState(false)
  const [accountInfo, setAccountInfo] = useState<IAccountInfoRes>(
    {} as IAccountInfoRes
  )
  const [accountRecords, setAccountRecords] = useState<IAccountParsingRecord[]>(
    []
  )
  const isManager = useIsManager(accountInfo)
  const isOwner = useIsOwner(accountInfo)

  const isSubAccount = useMemo(() => {
    return SUB_ACCOUNT_REG_EXP.test(bitName)
  }, [bitName])

  const canManage = useMemo(() => {
    return ![ACCOUNT_STATUS.onePriceSell].includes(accountInfo?.status)
  }, [accountInfo?.status])

  const onExitEditMode = (notClear?: boolean) => {
    setEnableEditRecordMode(false)
    if (!notClear) {
      getAccountRecords(bitName)
      getAccountInfo(bitName)
    }
  }

  const onRemind = () => {
    const account = accountInfo.account
    const expiredAt = dayjs(accountInfo.expired_at)
    const startDate = formatDateTime(
      expiredAt.subtract(3, 'day'),
      TIME_FORMAT.fullDate
    )
    const config = {
      name: `Your ${account} will expire in 3 days. Please renew it in time.`,
      description: `Account: ${account}\nExpires: ${formatDateTime(
        expiredAt
      )}\nRenew now: [url]${
        window.location.origin
      }/bit/data/${account}?action=renew[/url]`,
      startDate: startDate,
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'Outlook.com',
        'MicrosoftTeams',
        'Yahoo'
      ],
      trigger: 'click',
      iCalFileName: 'dotbit-reminder-event'
    }
    // @ts-ignore
    atcb_action(config)
  }

  const onManagePermission = (editManage?: boolean) => {
    if (!isOwner) {
      setManagerPermissionTips(false)
      setPermissionTipsShowing(true)
    } else if (!canManage) {
      alert({
        title: tt('Tips'),
        message: tt(
          'If a .bit account is on sale, it is in a special status and cannot be operated other than renewed and delisted.'
        )
      })
    } else {
      if (enableEditRecordMode) {
        onExitEditMode()
      }

      if (editManage) {
        setManagerPermissionTips(true)
      } else {
        setManagerPermissionTips(false)
      }
      setManagePermissionShowing(true)
    }
  }

  const onManageRecord = () => {
    if (!isManager) {
      setManagerPermissionTips(!isDobsMode)
      setPermissionTipsShowing(true)
    } else if (!canManage) {
      alert({
        title: tt('Tips'),
        message: tt(
          'If a .bit account is on sale, it is in a special status and cannot be operated other than renewed and delisted.'
        )
      })
    } else {
      if (enableEditRecordMode) {
        return
      }
      setManagePermissionShowing(false)
      setEnableEditRecordMode(true)
    }
  }

  const onSettings = async (value: string) => {
    try {
      if (value === SETTINGS.subdid) {
        smartOpen(
          `${outSiteUrl?.topdidPageUrl}/mint/dashboard?account=${bitName}`
        )
        return
      }

      if (value === SETTINGS.renew && isSubAccount) {
        smartOpen(
          `${outSiteUrl?.topdidPageUrl}/renew/${removeDotbitSuffix(bitName)}`
        )
        return
      }

      if (isDobsMode && !ckbAddress) {
        cccConnector?.open()
        return
      } else if (!isDobsMode) {
        const isInit = await walletSdk?.initWallet()
        if (!isInit) {
          return
        }
      }

      if (value === SETTINGS.manageData) {
        onManageRecord()
      } else if (value === SETTINGS.changeOwner) {
        onManagePermission()
      } else if (value === SETTINGS.changeManage) {
        onManagePermission(true)
      } else if (value === SETTINGS.renew) {
        const isInit = await walletSdk?.initWallet()
        if (!isInit) {
          return
        }
        if (enableEditRecordMode) {
          onExitEditMode()
        }
        router?.replace({
          query: {
            ...router.query,
            action: ''
          }
        })
        setRenewalShowing(true)
      }
    } catch (err: any) {
      handleError(err, tt, alert)
    }
  }

  const getAccountInfo = async (account: string) => {
    if (!account) {
      return
    }
    try {
      const res = await services.account.accountInfo(account)
      setAccountInfo(res)
      setIsDobsMode(res.status === ACCOUNT_STATUS.upgradedAccount)
    } catch (err: any) {
      handleError(err, tt, alert)
    }
  }

  const getAccountRecords = async (account: string) => {
    if (!account) {
      return
    }
    try {
      const res = await services.account.accountParsingRecords(account)
      if (res?.records) {
        const records = res.records.map((record) => {
          if (record.type === ParsingRecordType.address) {
            const ret = findParsingRecordChain(record.key)
            return {
              ...record,
              key: ret.coinType
            }
          } else {
            return record
          }
        })
        setAccountRecords(records)
      }
    } catch (err: any) {
      handleError(err, tt, alert)
    }
  }

  useEffect(() => {
    if (bitName) {
      const getData = async () => {
        await Promise.all([getAccountInfo(bitName), getAccountRecords(bitName)])
        setLoading(false)
      }
      getData()
    }
  }, [bitName])

  useEffect(() => {
    const action = router?.query?.action
    if (
      action === 'renew' &&
      [
        ACCOUNT_STATUS.registered,
        ACCOUNT_STATUS.upgradedAccount,
        ACCOUNT_STATUS.onePriceSell,
        ACCOUNT_STATUS.inTransferApproving
      ].includes(accountInfo.status)
    ) {
      onSettings(SETTINGS.renew)
    }
  }, [router?.query?.action, accountInfo.status, walletSdk])

  return (
    <div
      className={cn(
        'w-full md:w-[680px] mx-auto px-4 pt-5 md:px-0 flex flex-col items-center',
        enableEditRecordMode ? 'pb-[130px]' : 'pb-[52px]'
      )}
    >
      <Head>
        <title>{accountInfo.account}</title>
      </Head>
      <DidCard width="130" account={bitName || ''} />
      <div className="inline-flex gap-x-2 md:gap-x-5 my-5">
        {isDobsMode && (
          <Tooltip
            message={
              <div className="w-[420px] max-w-full">
                {tt(
                  'This .bit has been upgraded to DOBs Compatible .bit. Greater interoperability with other apps in the CKB ecosystem.'
                )}
              </div>
            }
          >
            <DasButton
              className="min-w-[156px] !cursor-default"
              status="normal"
            >
              <Iconfont className="mr-2" name="question-line" size={19} />
              {tt('About DOBs')}
            </DasButton>
          </Tooltip>
        )}
      </div>
      <div className="w-full p-6 bg-white rounded-[32px] border border-slate-300/40 justify-between items-center flex mb-5">
        <span className="inline-flex max-md:flex-col max-md:gap-y-5 gap-x-10">
          <span className="flex-col justify-center items-start gap-2 inline-flex">
            <span className="text-neutral-400 text-sm font-medium">
              {tt('Registered')}
            </span>
            <span className="text-zinc-700 text-sm font-medium">
              {formatDateTime(
                accountInfo.registered_at,
                TIME_FORMAT.fullDateTime
              )}
            </span>
          </span>
          <span className="flex-col justify-center items-start gap-2 inline-flex">
            <span className="text-neutral-400 text-sm font-medium">
              {tt('Expires')}
            </span>
            <span className="text-zinc-700 text-sm font-medium">
              {formatDateTime(accountInfo.expired_at)}
            </span>
          </span>
        </span>
        <span className="w-px h-[52px] bg-gray-100 max-md:hidden" />
        <span className="justify-center items-start gap-2 inline-flex max-md:flex-col max-md:items-center max-md:gap-y-5">
          {isDobsMode ? (
            isOwner && (
              <DasButton
                status="normal"
                onClick={() => {
                  onSettings(SETTINGS.renew)
                }}
              >
                {tt('Extend')}
              </DasButton>
            )
          ) : (
            <DasButton
              status="normal"
              onClick={() => {
                onSettings(SETTINGS.renew)
              }}
            >
              {tt('Extend')}
            </DasButton>
          )}
          <DasButton
            className="w-[52px] !p-0 max-md:w-full"
            status="normal"
            onClick={onRemind}
          >
            <Iconfont name="remind" size={17} />
          </DasButton>
        </span>
      </div>
      <ParsingRecords
        editMode={enableEditRecordMode}
        accountInfo={accountInfo}
        accountRecords={accountRecords}
        loading={loading}
        exitEditMode={onExitEditMode}
        enableEditMode={onManageRecord}
        onSettings={onSettings}
      />
      <Renewal
        showing={renewalShowing}
        accountInfo={accountInfo}
        onClose={() => {
          setRenewalShowing(false)
        }}
      />
      <ManagePermission
        showing={managePermissionShowing}
        accountInfo={accountInfo}
        editManager={managerPermissionTips}
        exitEditMode={onExitEditMode}
        onClose={() => {
          setManagePermissionShowing(false)
        }}
      />
      <PermissionTips
        showing={permissionTipsShowing}
        managerTip={managerPermissionTips}
        managerAddress={accountInfo?.manager}
        managerCoinType={accountInfo?.manager_coin_type}
        ownerAddress={accountInfo?.owner}
        ownerCoinType={accountInfo?.owner_coin_type}
        onClose={() => {
          setPermissionTipsShowing(false)
        }}
      />
    </div>
  )
}
