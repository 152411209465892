import { useDasBalanceContext } from '@did/das-app-context'
import { DasButton, Dialog } from '@did/uikit'
import { ConfirmToRemoveDialogType } from '@did/das-app-types/module'

export const ConfirmToRemoveDialog: ConfirmToRemoveDialogType = ({
  showing,
  aliasName,
  isOldAlias,
  deleteOldAlias,
  deleteAlias,
  onClose
}) => {
  const { tt } = useDasBalanceContext()

  return (
    <Dialog
      showing={showing}
      closeButton
      onClose={onClose}
      title={tt('Remove verification')}
    >
      {isOldAlias
        ? tt(
            'After removing the .bit Alias, the frozen 201 CKB will be returned to your balance automatically.'
          )
        : tt(
            `Once removed, it won't be named to {aliasName} anymore. But don't worry, you can always rename it.`,
            { aliasName: aliasName }
          )}
      <DasButton
        className="my-8"
        block
        black
        onClick={() => {
          if (isOldAlias) {
            deleteOldAlias()
          } else {
            deleteAlias()
          }
        }}
      >
        {tt('Remove')}
      </DasButton>
    </Dialog>
  )
}
