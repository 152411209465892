import { CYCLE_CALL_FUNCTION_TIME } from '@did/das-balance-constants'
import errno from '@did/constants/errno'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { TRX_STATUS } from '@did/das-balance-types/services'
import { collapseString } from '@did/tools'
import { IBaseProps, ICallback, ORDER_ACTION_TYPE } from '@did/types'
import React, { useEffect, useRef } from 'react'
import { Button, Dialog } from '../index'
import { CKB } from '@did/constants/chain'
import { Iconfont } from '@did/uikit'

interface ITransStatusProps extends IBaseProps, ICallback<any, any> {
  txHash: string
  txHashLink: string
  showing?: boolean
  actions?: ORDER_ACTION_TYPE[]
  account?: string
  action?: string
  subAccountAction?: string
  onClose?: () => void
}

export const TransStatusDialog: React.FC<ITransStatusProps> = ({
  txHash,
  txHashLink,
  showing,
  onClose,
  account,
  action,
  subAccountAction,
  actions
}) => {
  const { tt, services, connectedAccount } = useDasBalanceContext()
  const { ckbAddress, isDobsMode } = useCccContext()
  const [withdrawTxStatus, setWithdrawTxStatus] = React.useState<
    'pending' | 'success' | 'error'
  >('pending')
  const isInit = useRef<'init' | 'pending' | 'done'>('init')

  const checkStatus = async () => {
    if (isInit.current === 'done') return
    try {
      let res: any
      if (action && subAccountAction && account) {
        res = await services.subAccount.transactionStatus({
          account: account,
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          action: action,
          sub_action: subAccountAction
        })
      } else {
        res = await services.account.checkTxStatus({
          key_info: {
            coin_type: isDobsMode
              ? CKB.coinType
              : connectedAccount?.chain?.coinType!,
            key: isDobsMode ? ckbAddress : connectedAccount?.address!
          },
          actions: actions as any
        })
      }
      if (
        res &&
        (res.status === TRX_STATUS.pending ||
          res.status === TRX_STATUS.unpackaged)
      ) {
        setWithdrawTxStatus('pending')
        isInit.current = 'pending'
      } else {
        setWithdrawTxStatus('success')
        isInit.current = 'done'
      }
    } catch (err: any) {
      if (
        err.code === errno.apiErrorCodeNotExitsTrx &&
        isInit.current !== 'done'
      ) {
        setWithdrawTxStatus('success')
        isInit.current = 'done'
      } else {
        setWithdrawTxStatus('error')
      }
    }
  }

  useEffect(() => {
    if (!connectedAccount?.address || !showing) return
    const timerId = setInterval(() => {
      checkStatus()
    }, CYCLE_CALL_FUNCTION_TIME * 2)
    checkStatus()
    return () => {
      clearInterval(timerId)
    }
  }, [connectedAccount, showing])

  const handleClick = () => {
    onClose?.()
    isInit.current = 'init'
    // setWithdrawTxStatus('pending')
  }

  return (
    <Dialog
      closeButton
      showing={showing}
      title={tt('Submitted')}
      onClose={handleClick}
    >
      <div className=" text-sm font-medium">
        {tt('Approximately {number} minutes.', { number: 3 })}
      </div>
      {txHashLink && txHash && (
        <div className="mt-3 text-gray-400 text-xs">
          <a
            className="inline-flex items-center"
            href={txHashLink}
            target="_blank"
          >
            {collapseString(txHash, 5, 5)}
            <Iconfont
              name="arrow-right"
              color="#B0B8BF"
              size="10"
              className="ml-1"
              style={{ verticalAlign: '0px' }}
            />
          </a>
        </div>
      )}
      <div className="mt-8 pb-9">
        <Button
          primary
          loading={
            withdrawTxStatus === 'pending' || withdrawTxStatus === 'error'
          }
          done={withdrawTxStatus === 'success'}
          block
          className="bg-slate-400"
          onClick={handleClick}
        >
          {tt(withdrawTxStatus === 'success' ? 'Done' : 'Processing')}
        </Button>
      </div>
    </Dialog>
  )
}
