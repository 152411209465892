import errno from '@did/constants/errno'
import { ErrorInfo } from '@did/tools'

function isUserReject(err: any) {
  const metaMaskReject =
    ([
      errno.metaMaskUserRejectedAccountAccess,
      errno.metaMaskUserDeniedMessageSignature
    ].includes(err.code) &&
      !(
        err.message.includes(errno.metaMaskReplacementTransactionUnderpriced) ||
        err.message.includes(errno.metaMaskTransactionHasBeenAborted)
      )) ||
    err.message.includes(errno.metaMaskUserRejectedTheRequest)

  const tronReject = err === errno.tronLinkConfirmationDeclinedByUser

  const torusReject = [
    errno.torusUserCancelledLogin,
    errno.torusUserClosedPopup
  ].includes(err.message)

  const walletConnectReject = [
    errno.walletConnectUserRejectedTheTransaction,
    errno.walletConnectConnectionRequestReset
  ].includes(err.message)

  const connectDidSdkReject = err.code === errno.connectDidSdkAbort

  const joyidReject = [
    errno.joyidUserRejected,
    errno.joyidPopupClosed
  ].includes(err.message)

  return (
    metaMaskReject ||
    tronReject ||
    walletConnectReject ||
    torusReject ||
    connectDidSdkReject ||
    joyidReject
  )
}

export const handleError = (
  err: Error & { code: number },
  tt: any,
  alert: any,
  alertKey?: string
) => {
  if (isUserReject(err)) {
    return
  }
  // wallet
  if (err.code === errno.rpcApiErrAccountFrequencyLimit) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'The operation is too frequent. Please try again after {timeInterval} minutes',
        { timeInterval: 3 }
      )
    })
  } else if (
    err.code === errno.metaMaskWalletRequestPermissions ||
    err.code === errno.tronLinkAuthorizationRequestsAreBeingProcessed
  ) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'Other requests for the wallet are not processed, please try again after processing'
      )
    })
  } else if (err.message === errno.coinbaseWalletUsingMultipleWallet) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'Please check if you are using multiple wallet plugins. Please disable multiple wallet plugins, keep only one wallet plugin and try again.'
      )
    })
  } else if (
    <any>err === errno.tronLinkInsufficientBalance ||
    (err.message &&
      err.message.includes(errno.walletConnectInsufficientFundsForTransfer))
  ) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt('Insufficient balance')
    })
  } else if (
    err.message &&
    err.message.includes(errno.tronLinkTypeErrorAddUpdateDataNotFunction)
  ) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'The current wallet environment does not support payments using TRX, please upgrade your wallet version or register with another wallet.'
      )
    })
  } else if (err.code === errno.rpcApiErrTransferAccountSameAddress) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'No need to transfer. You can view all .bit accounts under the current address.'
      )
    })
  } else if (err.code === errno.apiErrorCodeInvalidTargetAddress) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt('Unsupported addresses.')
    })
  } else if (err.code === errno.apiErrorAnyLockAddressInvalid) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt('Please enter a CCC compatible address.')
    })
  } else if (err.code === errno.apiErrorCodeTooManyRecords) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt('The number of records exceeds the limit.')
    })
  }

  // reverse
  else if (err.code === errno.apiErrorReverseAlreadyExist) {
    alert({
      key: alertKey,
      title: tt('Error'),
      message: tt('The dotbit Alias has already been set.')
    })
  } else if (err.code === errno.apiErrorCodeResolveFailed) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'Frequent operations. There are still transactions being processed in your wallet address, please try again after 30s.'
      )
    })
  } else if (err.code === errno.rpcApiErrAccountIsExpired) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(
        'The account has expired. If you need to continue using it, please renew it.'
      )
    })
  } else if (err.code === errno.apiErrorCodeReverseAlreadyExist) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt('You have already set this account.')
    })
  } else if (err.code === errno.rpcApiErrAccountNotExist) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt(`The account doesn't exist.`)
    })
  } else if (err.code === errno.apiErrorAuctionAccountNotFound) {
    alert({
      key: alertKey,
      title: tt('Tips'),
      message: tt('This account has not been in dutch auction.')
    })
  } else {
    console.error(err)
    ErrorInfo.error(err)
    alert({
      key: alertKey,
      title: tt('Error'),
      message: err.code ? `${err.code}: ${err.message}` : `${err}`
    })
  }
}
