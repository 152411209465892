import { ExitEditModeTipsType } from '@did/das-app-types/module'
import styles from './styles.module.scss'
import { cn } from '@did/tools'
import { useDasBalanceContext } from '@did/das-app-context'
import { DasButton, Dialog } from '@did/uikit'
import React from 'react'

export const ExitEditModeTips: ExitEditModeTipsType = ({
  showing,
  onExit,
  onSave,
  onClose
}) => {
  const { tt } = useDasBalanceContext()

  return (
    <Dialog
      showing={showing}
      title={tt('Exit edit mode')}
      closeButton
      onClose={onClose}
    >
      <div>{tt('Would you like to save all changes?')}</div>
      <div className={cn(styles['exit-edit-mode-tips__buttons'], 'py-8')}>
        <DasButton onClick={onExit}>{tt('Exit')}</DasButton>
        <DasButton black onClick={onSave}>
          {tt('Save')}
        </DasButton>
      </div>
    </Dialog>
  )
}
