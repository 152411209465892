import { DEFAULT_PAGE_SIZE } from '@did/constants'
import { useDasBalanceContext } from '@did/das-app-context'
import {
  DotbitListType,
  EnumAccountStatusFilters,
  IAccountStatusFilterOption
} from '@did/das-app-types/components'
import { IAccountInfoRes } from '@did/types'
import { DasButton, Iconfont } from '@did/uikit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AccountSearch } from './account-search'
import { AccountStatus } from './account-status'
import { AccountStatusFilter } from './account-status-filter'
import { StatusTip } from './status-tip'
import { ErrorInfo } from '@did/monitoring'

export const DotbitList: DotbitListType = () => {
  const { tt, router, connectedAccount, services, isProd } =
    useDasBalanceContext()
  const [filter, setFilter] = useState<EnumAccountStatusFilters>(
    EnumAccountStatusFilters.all
  )
  const [fetchDataLoading, setFetchDataLoading] = useState(true)
  const [myAccounts, setMyAccounts] = useState<IAccountInfoRes[]>([])
  const [noMoreShowing, setNoMoreShowing] = useState(false)
  const [loadMoreShowing, setLoadMoreShowing] = useState(false)
  const [loadingShowing, setLoadingShowing] = useState(false)
  const [page, setPage] = useState(0)
  const [searchWord, setSearchWord] = useState('')

  const noRecords = useMemo(() => {
    return (
      !fetchDataLoading &&
      myAccounts.length === 0 &&
      searchWord === '' &&
      filter === EnumAccountStatusFilters.all
    )
  }, [fetchDataLoading, myAccounts, searchWord, filter])

  const filterOptions = useMemo((): IAccountStatusFilterOption[] => {
    return [
      {
        label: tt('All'),
        value: EnumAccountStatusFilters.all
      },
      {
        label: tt('Upgraded DIDs'),
        value: EnumAccountStatusFilters.upgradedDids
      },
      {
        label: tt('Second-Level DIDs'),
        value: EnumAccountStatusFilters.secondLevelDids
      },
      {
        label: tt('Regular DIDs'),
        value: EnumAccountStatusFilters.regularDids
      },
      {
        label: tt('On sale on DIDTop'),
        value: EnumAccountStatusFilters.onSale
      },
      {
        label: tt('Expire soon'),
        value: EnumAccountStatusFilters.expireSoon
      },
      {
        label: tt('To be recycled'),
        value: EnumAccountStatusFilters.toBeRecycled
      }
    ]
  }, [tt])

  const getMyAccounts = useCallback(
    async (
      page: number,
      searchStr: string,
      filter: EnumAccountStatusFilters
    ) => {
      if (!(connectedAccount?.address && connectedAccount?.chain?.coinType)) {
        setMyAccounts([])
        return
      }
      if (myAccounts.length === 0) {
        setFetchDataLoading(true)
      }
      setNoMoreShowing(false)
      if (page > 0) {
        setLoadMoreShowing(false)
        setLoadingShowing(true)
      }

      try {
        setPage(page + 1)
        let res = await services.account.myAccounts({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          category: filter,
          page: page + 1,
          keyword: searchStr
        })

        setLoadingShowing(false)
        if (!res || !res.list) {
          setFetchDataLoading(false)
          return
        }

        if (page + 1 === 1) {
          setMyAccounts(res.list)
        } else {
          setMyAccounts(myAccounts.concat(...res.list))
        }

        const length = res.list.length
        if (length < DEFAULT_PAGE_SIZE) {
          setLoadMoreShowing(false)
          if (length >= 0) {
            setNoMoreShowing(true)
          }
        } else {
          setLoadMoreShowing(true)
        }
      } catch (err: any) {
        ErrorInfo.error(err)
      } finally {
        setFetchDataLoading(false)
        setLoadingShowing(false)
      }
    },
    [connectedAccount, myAccounts]
  )

  const onSearch = useCallback(
    (value: string) => {
      setPage(0)
      setSearchWord(value)
      setFetchDataLoading(true)
      getMyAccounts(0, value, filter)
    },
    [getMyAccounts, filter]
  )

  const onFilter = useCallback(
    (value: any) => {
      setFilter(value as EnumAccountStatusFilters)
      setPage(0)
      getMyAccounts(0, searchWord, value)
    },
    [getMyAccounts, searchWord]
  )

  const goRegister = useCallback(() => {
    router.push('/create')
  }, [])

  const onClickAccount = useCallback((account: IAccountInfoRes) => {
    router.push(`/data/${account.account}`)
  }, [])

  useEffect(() => {
    if (connectedAccount?.chain?.coinType && connectedAccount?.address) {
      setPage(0)
      getMyAccounts(0, searchWord, filter)
    } else {
      setMyAccounts([])
      setLoadingShowing(false)
      setLoadMoreShowing(false)
      setNoMoreShowing(false)
      setFetchDataLoading(false)
    }
  }, [connectedAccount?.address, connectedAccount?.chain?.coinType])

  return (
    <>
      {!noRecords && (
        <div className="w-full flex justify-between items-center gap-x-2">
          <AccountSearch className="w-[200px]" onSearch={onSearch} />
          <AccountStatusFilter
            value={filter}
            options={filterOptions}
            onInput={onFilter}
          />
        </div>
      )}
      {fetchDataLoading && (
        <div className="mt-[80px]">
          <StatusTip
            className="font-medium my-4 mx-0 text-[#31333E]"
            icon="pending"
            iconSize="72"
            tip={tt('Loading')}
            tipFontSize="14"
          />
        </div>
      )}
      {noRecords && (
        <div className="w-full pt-10 pb-20 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
          <Iconfont name="no-records" size={156} />
          <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
            {tt(`You don't have a .bit account yet.`)}
          </div>
          <DasButton
            className="h-[38px] mx-auto font-semibold text-sm leading-[16px]"
            black
            onClick={goRegister}
            middle
          >
            {tt('Create one')}
          </DasButton>
        </div>
      )}
      {!fetchDataLoading && myAccounts.length > 0 && !isProd && (
        <div className="w-full flex py-[10px] px-4 bg-[#FCECEC] rounded-[9px] leading-[20px] text-error-font-color text-sm">
          <span className="mr-1">💡</span>
          {tt(
            'The following is the test network account, you need to re-register after the official launch.'
          )}
        </div>
      )}
      <ul className="w-full">
        {!fetchDataLoading &&
          myAccounts.map((account, index) => (
            <AccountStatus
              key={index}
              accountInfo={account}
              highlighted
              onClick={() => onClickAccount(account)}
            />
          ))}
        {loadMoreShowing && !fetchDataLoading && (
          <li
            className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm cursor-pointer text-link-font-color hover:text-link-hover-font-color"
            onClick={() => getMyAccounts(page, searchWord, filter)}
          >
            {tt('Load more')}
          </li>
        )}
        {loadingShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {tt('Loading')}
          </li>
        )}
        {noMoreShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {searchWord && myAccounts?.length === 0
              ? tt('No matched .bit')
              : noRecords
                ? ''
                : tt('No more')}
          </li>
        )}
      </ul>
    </>
  )
}
