import React, { useState, useEffect, FormEvent } from 'react'
import { Iconfont, IconImage } from '@did/uikit'
import * as Popover from '@radix-ui/react-popover'
import { cn } from '@did/tools'
import { IDataSelectProps, IOption } from '@did/das-app-types/components'
import styles from './styles.module.scss'

export const DataSelect = React.forwardRef<HTMLInputElement, IDataSelectProps>(
  (
    {
      defaultValue,
      options,
      search,
      errorMessages,
      onChange,
      className,
      ...props
    },
    ref
  ) => {
    const [keyword, setKeyword] = useState<string | number>('')
    const [open, setOpen] = useState(false)
    const [showOptios, setShowOptios] = useState<IOption[]>([])

    const onInput = (event?: FormEvent<HTMLInputElement>) => {
      // if (!search) {
      //   setShowOptios(options)
      //   return
      // }
      let _value = (event?.target as HTMLInputElement)?.value || ''
      setKeyword(_value)
      let _list: IOption[] = []
      if (_value) {
        options.forEach((option: IOption) => {
          const _text = option.text.toLowerCase()
          _value = _value.toLowerCase()
          if (_text.includes(_value)) {
            _list.push(option)
          }
        })
      } else {
        _list = options
      }
      setShowOptios(_list)
    }

    useEffect(() => {
      if (defaultValue) {
        const _option = options.find((option: IOption) => {
          return defaultValue === option.value
        })
        if (_option) {
          onChange?.(_option.value)
          setKeyword(_option.text)
        }
      } else if (options[0]) {
        setKeyword(options[0]?.text)
        onChange?.(options[0].value)
      }
      setShowOptios(options)
    }, [defaultValue, options])

    return (
      <div>
        <Popover.Root open={open} onOpenChange={setOpen}>
          <Popover.Trigger className="w-full relative">
            <input
              {...props}
              value={keyword}
              readOnly={!search}
              className={cn(
                'h-14 text-neutral-900 text-base rounded-xl w-full border border-neutral-100 bg-neutral-100 px-4 cursor-default focus:border-emerald-400 hover:border-emerald-400 focus:bg-white hover:bg-white disabled:cursor-no-drop focus-visible:outline-0 placeholder:text-gray-400',
                className
              )}
              ref={ref}
              onInput={(e) => onInput(e)}
            />
            <Iconfont
              className="absolute right-4 top-5"
              name="arrow-down"
              color="#121314"
              size="14"
            />
          </Popover.Trigger>
          {errorMessages?.[0] && (
            <div className={styles['select__input__error-messages']}>
              {errorMessages?.[0]}
            </div>
          )}
          <Popover.Portal>
            <Popover.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="bg-white w-[var(--radix-popover-trigger-width)] shadow rounded-xl border border-gray-200 p-3 my-2  max-h-44 overflow-y-auto z-50"
            >
              <ul>
                {showOptios?.map((option, index) => (
                  <li
                    key={index}
                    className="p-3 flex items-center font-semibold hover:bg-hover-item hover:text-white rounded-lg cursor-pointer "
                    onClick={() => {
                      onChange?.(option.value)
                      setKeyword(option.text)
                      setOpen(false)
                    }}
                  >
                    {option.logo && (
                      <IconImage
                        className="mr-2"
                        url={option.logo}
                        alt={option.text}
                        size={20}
                      />
                    )}
                    {option.icon && (
                      <Iconfont
                        v-else-if="option.icon"
                        className="mr-2"
                        name={option.icon}
                        size={20}
                      />
                    )}
                    {option.text}
                  </li>
                ))}
              </ul>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </div>
    )
  }
)
