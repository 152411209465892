import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { cn, ErrorInfo } from '@did/tools'
import { AUCTION_PARAM_TYPE, IBaseProps } from '@did/types'
import React, { useCallback, useEffect, useState } from 'react'
import { DotbitList } from './dotbit-list'
import { BannerTip } from './banner-tip'
import { AboutFaq, Iconfont } from '@did/uikit'
import { DoBList } from './dob-list'
import { CKB } from '@did/constants/chain'

export const MePage: React.FC<IBaseProps> = () => {
  const { tt, connectedAccount, services, walletSdk } = useDasBalanceContext()
  const { isDobsMode, setIsDobsMode, ccc, ckbAddress } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const [registeringAccountTotal, setRegisteringAccountTotal] = useState(0)
  const [auctionAccountTotal, setAuctionAccountTotal] = useState(0)
  const [upgradeableTotal, setUpgradeableTotal] = useState(0)
  const [recyclableTotal, setRecyclableTotal] = useState(0)

  const getRegisteringAccounts = useCallback(async () => {
    if (!connectedAccount?.address || !connectedAccount?.chain?.coinType) {
      setRegisteringAccountTotal(0)
      return
    }
    try {
      const res = await services.account.registeringAccounts({
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        }
      })
      setRegisteringAccountTotal(res?.registering_accounts?.length || 0)
    } catch (err: any) {
      ErrorInfo.error(err)
      throw err
    }
  }, [connectedAccount?.chain?.coinType, connectedAccount?.address, services])

  const getAuctionPendingOrder = useCallback(async () => {
    if (!connectedAccount?.address || !connectedAccount?.chain?.coinType) {
      return
    }
    try {
      const res = await services.account.auctionPendingOrder({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        type: AUCTION_PARAM_TYPE.BLOCKCHAIN
      })
      setAuctionAccountTotal(res.length || 0)
    } catch (err: any) {
      ErrorInfo.error(err)
    }
  }, [connectedAccount?.chain?.coinType, connectedAccount?.address, services])

  const getUpgradeableList = useCallback(async () => {
    if (!connectedAccount?.address || !connectedAccount?.chain?.coinType) {
      setUpgradeableTotal(0)
      return
    }
    try {
      const res = await services.account.upgradableList({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        }
      })
      setUpgradeableTotal(res?.total || 0)
    } catch (err: any) {
      ErrorInfo.error(err)
    }
  }, [connectedAccount?.address, connectedAccount?.chain?.coinType])

  const getRecyclableList = useCallback(async () => {
    if (!ckbAddress) {
      setRecyclableTotal(0)
      return
    }
    try {
      const res = await services.account.recyclableList({
        key_info: {
          coin_type: CKB.coinType,
          key: ckbAddress
        }
      })
      setRecyclableTotal(res?.total || 0)
    } catch (err: any) {
      ErrorInfo.error(err)
    }
  }, [ckbAddress])

  useEffect(() => {
    getRegisteringAccounts()
  }, [getRegisteringAccounts])

  useEffect(() => {
    getAuctionPendingOrder()
  }, [getAuctionPendingOrder])

  useEffect(() => {
    getUpgradeableList()
  }, [getUpgradeableList])

  useEffect(() => {
    getRecyclableList()
  }, [getRecyclableList])

  // useEffect(() => {
  //   if (isDobsMode) {
  //     !address && cccConnector && cccConnector?.open()
  //   } else if (walletSdk && !connectedAccount?.address) {
  //     walletSdk.connectWallet()
  //   }
  // }, [walletSdk, connectedAccount?.address, isDobsMode, cccConnector, address])

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 md:px-0 pt-5 md:pt-16 flex flex-col items-center gap-y-4">
      {(registeringAccountTotal > 0 || auctionAccountTotal > 0) &&
        !isDobsMode && (
          <BannerTip
            title={tt('Pending')}
            icon={
              <Iconfont
                className="mr-2"
                name="processing"
                color="#E9862D"
                size="18"
              />
            }
            number={registeringAccountTotal + auctionAccountTotal}
            to="/my/pending"
          />
        )}
      {isDobsMode && (
        <>
          <AboutFaq
            title={tt('Legacy vs DOBs Compatible .bit')}
            message={
              <div>
                <h3 className="text-neutral-700 text-sm font-semibold">
                  {tt('Legacy .bit:')}
                </h3>
                <div className="text-neutral-700 text-sm font-normal">
                  {tt('Most common .bit with lower storage fee.')}
                </div>
                <h3 className="text-neutral-700 text-sm font-semibold mt-2">
                  {tt('DOBs Compatible .bit:')}
                </h3>
                <div className="text-neutral-700 text-sm font-normal">
                  {tt(
                    'Greater interoperability with other apps in the CKB ecosystem, with higher storage fee.'
                  )}
                </div>
              </div>
            }
            localStorageKey="dobs_about-dotbit-dobs_hide"
            faqMessage={tt('Learn more')}
            faqUrl="https://docs.spore.pro/dob0/Introduction"
          />
          {upgradeableTotal > 0 && (
            <BannerTip
              title={tt('Upgradable .bit')}
              variant="rgb"
              icon={<Iconfont className="mr-2" name="dobs" size="16" />}
              number={upgradeableTotal}
              to="/my/upgradeable-list"
            />
          )}
          {recyclableTotal > 0 && (
            <BannerTip
              title={tt('Pending storage fee refund')}
              variant="rgb"
              number={recyclableTotal}
              to="/my/recyclable-list"
            />
          )}
        </>
      )}
      <div
        className={cn(
          'w-full',
          isDobsMode ? 'flex flex-col gap-y-4' : 'hidden'
        )}
      >
        <DoBList />
      </div>
      <div
        className={cn(
          'w-full',
          isDobsMode ? 'hidden' : 'flex flex-col gap-y-4'
        )}
      >
        <DotbitList />
      </div>
    </div>
  )
}
