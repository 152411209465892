import { ACCOUNT_SUFFIX, DEFAULT_PAGE_SIZE } from '@did/constants'
import {
  IAccountInfoRes,
  IAccountInfoParams,
  IAccountParsingRecords,
  IAccountService,
  IAvailableBalanceWithdrawParams,
  IBalanceInfoParams,
  IBalanceInfoRes,
  IChangeOrderParams,
  IChangeOrderRes,
  ICheckTxStatusParams,
  ICheckTxStatusRes,
  ICouponCheckRes,
  IOrderDetailParams,
  IOrderDetailRes,
  ISearchAccountParams,
  ISearchAccountRes,
  ISubmitRegisterOrderParams,
  ISubmitRegisterOrderRes,
  ITransitBalanceTransferParams,
  ITrxHistoryParams,
  ITrxHistoryRes,
  SignTxListParams,
  SignTxListRes,
  IDasBalancePayParams,
  IReturnRegisteredPaymentTrxIdParams,
  IRegisteringAccountParams,
  IMyRewardsParams,
  IMyRewardsRes,
  IAuctionInfoParams,
  IAuctionInfoRes,
  IAuctionPriceParam,
  IAuctionPriceRes,
  IAuctionOrderStatusParam,
  IAuctionOrderStatusRes,
  IAuctionPendingOrderParam,
  IAuctionPendingOrderRes,
  IAuctionBidParam,
  IAuctionBidRes,
  IRecommendAccountParams,
  IRecommendAccountRes,
  IEditRecordsParams,
  ISubmitRenewOrderParams,
  ISubmitRenewOrderRes,
  IEditOwnerParams,
  IEditManagerParams,
  IMyDobsParams,
  IMyDobsRes,
  IUpgradableListRes,
  IUpgradableListParams,
  IUpgradePriceInfoParams,
  IUpgradePriceInfoRes,
  IEditOwnerRes,
  IRecyclableListParams,
  IRecyclableListRes,
  IRecycleDobParams
} from '@did/types'
import { BasicService } from './basic-service'
import { validateParams } from '@did/tools'
import { splitAccount } from '@did/tools/bit-account-string-handle'

export default class Account extends BasicService implements IAccountService {
  /**
   * my transaction records
   * @param key_info
   * @param page
   * @param size
   */
  myTrxHistory({
    key_info,
    page,
    size = DEFAULT_PAGE_SIZE
  }: ITrxHistoryParams): Promise<ITrxHistoryRes> {
    validateParams({ ...key_info }, 'myTrxHistory')
    return this.axios.post('/transaction/list', {
      type: 'blockchain',
      key_info,
      page,
      size
    })
  }

  /**
   * Get account parsing records
   * @param accountName
   */
  accountParsingRecords(accountName: string): Promise<IAccountParsingRecords> {
    return this.axios.post('/account/records', {
      account: accountName
    })
  }

  /**
   * available balance withdraw
   * @param key_info
   * @param evm_chain_id
   * @param receiver_address
   * @param withdraw_all
   * @param amount
   */
  availableBalanceWithdraw({
    key_info,
    evm_chain_id,
    receiver_address,
    withdraw_all,
    amount
  }: IAvailableBalanceWithdrawParams): Promise<SignTxListParams> {
    return this.axios.post('/balance/withdraw', {
      type: 'blockchain',
      key_info,
      evm_chain_id,
      receiver_address,
      withdraw_all,
      amount
    })
  }

  /**
   * transit balance has been transferred to available balance
   * @param key_info
   * @param transfer_address
   */
  transitBalanceTransfer({
    key_info,
    transfer_address
  }: ITransitBalanceTransferParams): Promise<SignTxListParams> {
    return this.axios.post('/balance/transfer', {
      type: 'blockchain',
      key_info,
      transfer_address
    })
  }

  /**
   * balance info
   * @param key_info
   * @param transfer_address
   */
  balanceInfo({
    key_info,
    transfer_address
  }: IBalanceInfoParams): Promise<IBalanceInfoRes> {
    return this.axios.post('/balance/info', {
      type: 'blockchain',
      key_info,
      transfer_address
    })
  }

  checkTxStatus({
    key_info,
    actions
  }: ICheckTxStatusParams): Promise<ICheckTxStatusRes> {
    validateParams({ ...key_info }, 'checkTxStatus')
    return this.axios.post('/transaction/status', {
      type: 'blockchain',
      key_info,
      actions
    })
  }

  accountInfo(accountName: string): Promise<IAccountInfoRes> {
    validateParams({ accountName }, 'accountInfo')
    return this.axios.post('/account/detail', {
      account: accountName
    })
  }

  searchAccount({
    key_info,
    account
  }: ISearchAccountParams): Promise<ISearchAccountRes> {
    validateParams({ account }, 'searchAccount')
    account = account.replace(/\.bit$/, '')
    return this.axios.post('/account/search', {
      type: 'blockchain',
      key_info,
      account: account + ACCOUNT_SUFFIX,
      account_char_str: splitAccount(account, true)
    })
  }

  /**
   * get my accounts
   * @param key_info
   * @param keyword
   * @param category
   * @param page
   * @param size
   */
  myAccounts({
    key_info,
    keyword,
    category,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: IAccountInfoParams): Promise<{ list: IAccountInfoRes[]; total: number }> {
    validateParams({ ...key_info }, 'myAccounts')
    return this.axios.post('/account/mine', {
      type: 'blockchain',
      key_info,
      keyword,
      category,
      page,
      size
    })
  }

  /**
   * get my DOBs
   * @param key_info
   * @param keyword
   * @param page
   * @param size
   */
  myDobs({
    key_info,
    keyword,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: IMyDobsParams): Promise<{ list: IMyDobsRes[]; total: number }> {
    validateParams({ ...key_info }, 'myDobs')
    return this.axios.post('/did/cell/list', {
      type: 'blockchain',
      key_info,
      keyword,
      page,
      size
    })
  }

  /**
   * get upgradeable .bit
   * @param key_info
   * @param keyword
   * @param page
   * @param size
   */
  upgradableList({
    key_info,
    keyword,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: IUpgradableListParams): Promise<{
    list: IUpgradableListRes[]
    total: number
  }> {
    validateParams({ ...key_info }, 'upgradableList')
    return this.axios.post('/did/cell/upgradable/list', {
      type: 'blockchain',
      key_info,
      keyword,
      page,
      size
    })
  }

  /**
   * get the cost of the upgrade.
   * @param key_info
   * @param account
   */
  upgradePriceInfo({
    key_info,
    account
  }: IUpgradePriceInfoParams): Promise<IUpgradePriceInfoRes> {
    validateParams({ ...key_info }, 'upgradePriceInfo')
    return this.axios.post('/did/cell/upgrade/price', {
      type: 'blockchain',
      key_info,
      account
    })
  }

  /**
   * check coupon
   * @param coupon
   */
  couponCheck(coupon: string): Promise<ICouponCheckRes> {
    validateParams({ coupon }, 'couponCheck')
    return this.axios.post('/account/coupon/check', {
      coupon
    })
  }

  /**
   * submit register order
   * @param key_info
   * @param account
   * @param pay_token_id
   * @param pay_address
   * @param register_years
   * @param coin_type
   * @param inviter_account
   * @param channel_account
   * @param gift_card
   */
  submitRegisterOrder({
    key_info,
    account,
    pay_token_id,
    pay_address,
    register_years,
    coin_type,
    inviter_account = '',
    channel_account = '',
    gift_card = ''
  }: ISubmitRegisterOrderParams): Promise<ISubmitRegisterOrderRes> {
    validateParams({ ...key_info, account, coin_type }, 'submitRegisterOrder')
    account = account.replace(/\.bit$/, '')
    return this.axios.post('/account/order/register', {
      type: 'blockchain',
      key_info,
      account: account + ACCOUNT_SUFFIX,
      pay_token_id,
      pay_address,
      register_years,
      coin_type,
      inviter_account,
      channel_account,
      account_char_str: splitAccount(account, true),
      gift_card
    })
  }

  /**
   * order detail
   * @param key_info
   * @param account
   * @param action
   */
  orderDetail({
    key_info,
    account,
    action
  }: IOrderDetailParams): Promise<IOrderDetailRes> {
    validateParams({ ...key_info, account }, 'orderDetail')
    return this.axios.post('/account/order/detail', {
      type: 'blockchain',
      key_info,
      account,
      action
    })
  }

  /**
   * change order
   * @param key_info
   * @param account
   * @param pay_token_id
   * @param pay_address
   * @param register_years
   * @param coin_type
   * @param inviter_account
   * @param channel_account
   */
  changeOrder({
    key_info,
    account,
    pay_token_id,
    pay_address,
    register_years,
    coin_type,
    inviter_account = '',
    channel_account = ''
  }: IChangeOrderParams): Promise<IChangeOrderRes> {
    validateParams({ ...key_info, account, coin_type }, 'changeOrder')
    return this.axios.post('/account/order/change', {
      type: 'blockchain',
      key_info,
      account,
      pay_token_id,
      pay_address,
      register_years,
      coin_type,
      inviter_account,
      channel_account
    })
  }

  /**
   * DAS Balance payment
   * @param key_info
   * @param evm_chain_id
   * @param order_id
   */
  dasBalancePay({
    key_info,
    evm_chain_id,
    order_id
  }: IDasBalancePayParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, order_id }, 'dasBalancePay')
    return this.axios.post('/balance/pay', {
      type: 'blockchain',
      key_info,
      evm_chain_id,
      order_id
    })
  }

  /**
   * send transaction
   * @param sign_key
   * @param sign_list
   * @param sign_address
   * @param ckb_tx
   */
  sendTrx({
    sign_key,
    sign_list,
    sign_address,
    ckb_tx
  }: SignTxListRes): Promise<{ hash: string }> {
    return this.axios.post('/transaction/send', {
      sign_key,
      sign_list,
      sign_address,
      ckb_tx
    })
  }

  /**
   * return registered payment transaction ID
   * @param key_info
   * @param account
   * @param order_id
   * @param pay_hash
   */
  returnRegisteredPaymentTrxId({
    key_info,
    account,
    order_id,
    pay_hash
  }: IReturnRegisteredPaymentTrxIdParams): Promise<any> {
    validateParams(
      { ...key_info, account, order_id, pay_hash },
      'returnRegisteredPaymentTrxId'
    )
    return this.axios.post('/account/order/pay/hash', {
      type: 'blockchain',
      key_info,
      account,
      order_id,
      pay_hash
    })
  }

  /**
   * get registering accounts
   * @param key_info
   */
  registeringAccounts({ key_info }: IRegisteringAccountParams): Promise<{
    registering_accounts: IAccountInfoRes[]
  }> {
    validateParams({ ...key_info }, 'registeringAccounts')
    return this.axios.post('/account/registering/list', {
      type: 'blockchain',
      key_info
    })
  }

  /**
   * get my rewards
   * @param key_info
   * @param page
   * @param size
   */
  myRewards({
    key_info,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: IMyRewardsParams): Promise<IMyRewardsRes> {
    validateParams({ ...key_info }, 'myRewards')
    return this.axios.post('/rewards/mine', {
      type: 'blockchain',
      key_info,
      page,
      size
    })
  }

  auctionInfo(params: IAuctionInfoParams): Promise<IAuctionInfoRes> {
    validateParams(params, 'auctionInfo')
    return this.axios.post('/account/auction/info', params)
  }

  auctionPrice(params: IAuctionPriceParam): Promise<IAuctionPriceRes> {
    validateParams(params, 'auctionPrice')
    return this.axios.post('/account/auction/price', params)
  }

  auctionOrderStatus(
    params: IAuctionOrderStatusParam
  ): Promise<IAuctionOrderStatusRes> {
    validateParams(params, 'auctionOrderStatus')
    return this.axios.post('/account/auction/order-status', params)
  }

  auctionPendingOrder(
    params: IAuctionPendingOrderParam
  ): Promise<IAuctionPendingOrderRes> {
    validateParams(params, 'auctionPendingOrder')
    return this.axios.post('/account/auction/pending-order', params)
  }

  auctionBid(params: IAuctionBidParam): Promise<IAuctionBidRes> {
    validateParams(params, 'auctionBid')
    return this.axios.post('/account/auction/bid', params)
  }

  recommendAccountList(
    params: IRecommendAccountParams
  ): Promise<IRecommendAccountRes> {
    validateParams(params, 'recommendAccountList')
    return this.axios.post('/account/recommend', params)
  }

  /**
   * edit parsing records
   * @param key_info
   * @param account
   * @param records
   */
  editRecords({
    key_info,
    account,
    raw_param: { records }
  }: IEditRecordsParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, account }, 'editRecords')
    return this.axios.post('/account/edit/records', {
      type: 'blockchain',
      key_info,
      account,
      raw_param: {
        records
      }
    })
  }

  /**
   * submit renew order
   * @param key_info
   * @param account
   * @param pay_token_id
   * @param renew_years
   */
  submitRenewOrder({
    key_info,
    account,
    pay_token_id,
    renew_years
  }: ISubmitRenewOrderParams): Promise<ISubmitRenewOrderRes> {
    validateParams({ ...key_info, account }, 'submitRenewOrder')
    return this.axios.post('/account/order/renew', {
      type: 'blockchain',
      key_info,
      account,
      pay_token_id,
      renew_years
    })
  }

  /**
   * edit account owner
   * @param key_info
   * @param evm_chain_id
   * @param account
   * @param receiver_coin_type
   * @param receiver_address
   * @param pay_token_id
   */
  editOwner({
    key_info,
    account,
    raw_param: { receiver_coin_type, receiver_address },
    pay_token_id,
    is_upgrade
  }: IEditOwnerParams): Promise<IEditOwnerRes> {
    validateParams({ ...key_info, account }, 'editOwner')
    return this.axios.post('/account/edit/owner', {
      type: 'blockchain',
      key_info,
      account,
      raw_param: {
        receiver_coin_type,
        receiver_address
      },
      pay_token_id,
      is_upgrade
    })
  }

  /**
   * edit account manager
   * @param key_info
   * @param evm_chain_id
   * @param account
   * @param manager_coin_type
   * @param manager_address
   */
  editManager({
    key_info,
    evm_chain_id,
    account,
    raw_param: { manager_coin_type, manager_address }
  }: IEditManagerParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, account }, 'editManager')
    return this.axios.post('/account/edit/manager', {
      type: 'blockchain',
      key_info,
      evm_chain_id,
      account,
      raw_param: {
        manager_coin_type,
        manager_address
      }
    })
  }

  /**
   * get recyclable .bit DOB
   * @param key_info
   * @param keyword
   * @param page
   * @param size
   */
  recyclableList({
    key_info,
    keyword,
    page = 1,
    size = DEFAULT_PAGE_SIZE
  }: IRecyclableListParams): Promise<{
    list: IRecyclableListRes[]
    total: number
  }> {
    validateParams({ ...key_info }, 'recyclableList')
    return this.axios.post('/did/cell/recyclable/list', {
      type: 'blockchain',
      key_info,
      keyword,
      page,
      size
    })
  }

  /**
   * recycle .bit DOB
   * @param key_info
   * @param account
   */
  recycleDob({
    key_info,
    account
  }: IRecycleDobParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, account }, 'recycleDob')
    return this.axios.post('/did/cell/recycle', {
      type: 'blockchain',
      key_info,
      account
    })
  }
}
