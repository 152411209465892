import { ACCOUNT_SUFFIX } from '@did/constants'
import errno from '@did/constants/errno'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { ExplorerPageType } from '@did/das-app-types/module'
import { digitalEmojiHandle } from '@did/tools/bit-account-string-handle'
import { ACCOUNT_STATUS, IAccountInfoRes, ISearchAccountRes } from '@did/types'
import { DidCard, SearchInput } from '@did/uikit'
import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AccountStatus } from './account-status'
import { ErrorTips, NotOpenRegisterError } from './error-tips'
import { ErrorInfo } from '@did/monitoring'
import { SwitchNotice } from './switch-notice'

export const ExplorerPage: ExplorerPageType = ({ bitName }) => {
  const { tt, alert, services, connectedAccount, router } =
    useDasBalanceContext()
  const { isDobsMode } = useCccContext()
  const [searchVal, setSearchVal] = useState<string>('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [showIncorrectAccountFormat, setShowIncorrectAccountFormat] =
    useState(false)
  const [searchResult, setSearchResult] = useState<
    ISearchAccountRes | undefined
  >()
  const [accountNameError, setAccountNameError] = useState(true)
  const [showSwitchTips, setShowSwitchTips] = useState(false)
  const [accountInfo, setAccountInfo] = useState<IAccountInfoRes>()

  const checkAccountExists = async (account: string) => {
    try {
      const res = await services.account.accountInfo(account)
      return res
    } catch (err: any) {
      if (err.code === errno.rpcApiErrAccountNotExist) {
        return null
      } else {
        ErrorInfo.error(err)
      }
    }
  }

  const searchAccount = async (account: string) => {
    if (searchLoading) {
      return
    }
    setSearchResult({
      account
    } as any)
    setAccountInfo(undefined)
    if (account === ACCOUNT_SUFFIX) {
      return
    }
    setSearchLoading(true)
    const existsAccount = await checkAccountExists(account)

    if (existsAccount) {
      setAccountInfo({
        ...existsAccount,
        account: account
      } as any)
      setSearchLoading(false)
      return
    }

    account = account.replace(/\.bit$/, '')

    if (!account) {
      setSearchLoading(false)
      return
    }

    try {
      const res = await services.account.searchAccount({
        account,
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        }
      })

      setSearchResult(res)
    } catch (err: any) {
      if (err.code === errno.rpcApiErrAccountCharsErr) {
        setShowIncorrectAccountFormat(true)
      } else if (err.code === errno.apiErrorAccountLengthErr) {
        setSearchResult({
          account: account + ACCOUNT_SUFFIX,
          status: ACCOUNT_STATUS.error
        } as any)
      } else {
        alert({
          title: tt('Error'),
          message: `${err.code}: ${err.message}`
        })
      }
    } finally {
      setSearchLoading(false)
    }
  }

  const handleSearch = useCallback(
    debounce(async function (value: string) {
      setShowIncorrectAccountFormat(false)
      value = digitalEmojiHandle(value)
        .replace(/\s+/g, '')
        .toLowerCase()
        .replace(/\.bit$/, '')
      value = value + ACCOUNT_SUFFIX
      await searchAccount(value)
    }, 1000),
    []
  )

  const handleInputChange = async (val: any) => {
    const value = digitalEmojiHandle(val).replace(/\s+/g, '').toLowerCase()
    setSearchVal(value)
    setSearchResult(undefined)
    setAccountNameError(true)
    handleSearch(value)
  }

  const onCreate = async () => {
    delete router.query?.searchWord
    delete router.query?.i
    delete router.query?.inviter
    delete router.query?.channel
    router?.push(`/account/create/${accountData?.account}`, router.query)
  }

  const accountData = useMemo(() => {
    if (accountInfo && accountInfo?.account === searchResult?.account) {
      return accountInfo
    }
    if (!searchResult) return undefined
    let status = searchResult?.status
    if (
      !searchResult?.is_self &&
      [ACCOUNT_STATUS.registeringPaymentConfirm].includes(
        searchResult?.status as any
      )
    ) {
      status = ACCOUNT_STATUS.registerable
    } else if (
      !searchResult?.is_self &&
      [
        ACCOUNT_STATUS.registeringLockedAccount,
        ACCOUNT_STATUS.registering,
        ACCOUNT_STATUS.registeringIncludeProposal,
        ACCOUNT_STATUS.registeringConfirmProposal
      ].includes(searchResult?.status as any)
    ) {
      status = ACCOUNT_STATUS.othersRegistering
    }
    return {
      ...searchResult,
      status
    }
  }, [accountInfo, searchResult])

  const showAccountStatus = useMemo(() => {
    return (
      !accountNameError &&
      !searchLoading &&
      accountData &&
      accountData?.account !== ACCOUNT_SUFFIX
    )
  }, [accountNameError, searchLoading, accountData])

  const notRegistering = useMemo(() => {
    if (!accountData) return false
    return (
      accountData.status === ACCOUNT_STATUS.registeringPaymentConfirm &&
      // @ts-ignore
      accountData?.register_tx_map?.['1']
    )
  }, [accountData])

  const canRegister = useMemo(() => {
    return (
      ACCOUNT_STATUS.registerable === accountData?.status ||
      (ACCOUNT_STATUS.registeringPaymentConfirm === accountData?.status &&
        notRegistering &&
        // @ts-ignore
        !accountData?.is_self)
    )
  }, [accountData])

  useEffect(() => {
    if (bitName) {
      setSearchVal(bitName)
      handleSearch(bitName)
    }
  }, [bitName])

  useEffect(() => {
    if (isDobsMode) {
      setShowSwitchTips(true)
    }
  }, [isDobsMode])

  return (
    <div className="flex flex-col items-center w-full md:w-[680px] mx-auto pb-[52px] px-6 pt-[24px] md:pt-[54px] md:px-0">
      <DidCard width="130" account={searchVal || 'your.bit'} />
      <div className="text-zinc-800 text-base md:text-2xl text-center md:w-full font-bold md:font-semibold mb-6 mt-8">
        {tt('Create Your Barrier-free Human DID')}
      </div>
      <SearchInput
        value={searchVal}
        placeholder={tt('Type a name')}
        buttonText={tt('Create')}
        showButton={canRegister}
        searchLoading={searchLoading}
        onChange={handleInputChange}
        onSearch={handleSearch}
        onCreate={onCreate}
      />
      <ErrorTips
        showIncorrect={showIncorrectAccountFormat}
        accountName={accountData?.account}
        accountStatus={accountData?.status}
        onCheckPass={(val) => {
          setAccountNameError(val)
        }}
      />
      {accountData?.status === ACCOUNT_STATUS.notOpenRegister && (
        <NotOpenRegisterError
          registrableDate={(accountData as any)?.open_timestamp}
        />
      )}
      {showAccountStatus && <AccountStatus accountInfo={accountData} />}
      <SwitchNotice
        showing={showSwitchTips}
        onClose={() => {
          setShowSwitchTips(false)
        }}
      />
    </div>
  )
}
