import { RegisteringInfoType } from '@did/das-app-types/module'
import { DidCard, Iconfont } from '@did/uikit'
import { useDasBalanceContext } from '@did/das-app-context'
import { ACCOUNT_STATUS } from '@did/types'
import { useMemo } from 'react'
import { cn, collapseString } from '@did/tools'
import { CKB, TOKEN_ID_TO_CHAIN } from '@did/constants/chain'

export const RegisteringInfo: RegisteringInfoType = ({
  accountInfo,
  onRegisterAnother,
  isAuction,
  goMe
}) => {
  const { tt, isProd } = useDasBalanceContext()

  const REGISTION_LIST = useMemo(() => {
    if (isAuction) {
      return [
        {
          startStatus: ACCOUNT_STATUS.onDutchAuction,
          endStatus: ACCOUNT_STATUS.onDutchAuction,
          title: tt('Bid successful')
        },
        {
          startStatus: ACCOUNT_STATUS.onDutchAuction,
          endStatus: ACCOUNT_STATUS.auctionRecycling,
          title: tt('Waiting for confirmation')
        }
      ]
    }

    return [
      {
        startStatus: ACCOUNT_STATUS.registeringPaymentConfirm,
        endStatus: ACCOUNT_STATUS.registeringLockedAccount,
        title: tt('Confirm payment')
      },
      {
        startStatus: ACCOUNT_STATUS.registeringLockedAccount,
        endStatus: ACCOUNT_STATUS.registering,
        title: tt('Apply for registration in private')
      },
      {
        startStatus: ACCOUNT_STATUS.registering,
        endStatus: ACCOUNT_STATUS.registeringIncludeProposal,
        title: tt('Reveal the account name on the chain')
      },
      {
        startStatus: ACCOUNT_STATUS.registeringIncludeProposal,
        endStatus: ACCOUNT_STATUS.registeringConfirmProposal,
        title: tt('Submit a proposal')
      },
      {
        startStatus: ACCOUNT_STATUS.registeringConfirmProposal,
        endStatus: ACCOUNT_STATUS.registered,
        title: tt('Proposal approved')
      }
    ]
  }, [isAuction])

  return (
    <>
      <DidCard width="130" account={accountInfo?.account || ''} />
      <div className="py-4 text-neutral-700 text-2xl font-bold break-all text-center">
        {tt('Creating {account}', { account: accountInfo?.account })}
      </div>
      <div className="mb-6 text-base text-zinc-500 text-center leading-[18px] font-normal">
        {tt('注册过程说明', {
          mePage: (
            <span
              className="text-blue-800 hover:opacity-70 cursor-pointer"
              onClick={goMe}
            >
              {tt('My DIDs')}
            </span>
          )
        })}
      </div>
      <div className="w-full bg-white rounded-[32px] p-6 overflow-hidden">
        <ul>
          {REGISTION_LIST.map((i, n) => (
            <li
              key={n}
              className={cn(
                'mb-6 last:mb-0 text-base font-medium',
                accountInfo?.status >= i.startStatus
                  ? 'text-emerald-500'
                  : 'text-neutral-900'
              )}
            >
              <div className="flex items-center">
                <div
                  className={cn(
                    'inline-flex w-8 items-center',
                    accountInfo?.status >= i.endStatus ? '' : 'pl-0.5'
                  )}
                >
                  {accountInfo?.status >= i.startStatus && (
                    <>
                      {accountInfo?.status >= i.endStatus ? (
                        <Iconfont name="check-strong" color="#1EAE82" />
                      ) : (
                        <Iconfont
                          className="animate-spin"
                          name="loading"
                          color="#1EAE82"
                          size="20"
                        />
                      )}
                    </>
                  )}
                </div>

                <div className="flex-1">
                  {n + 1}. {i.title}
                  {accountInfo?.register_tx_map?.[n + 1] && (
                    <a
                      href={`${
                        n === 0
                          ? TOKEN_ID_TO_CHAIN[
                              accountInfo.register_tx_map['1'].token_id
                            ]?.getExplorerTrx(isProd)
                          : CKB.getExplorerTrx(isProd)
                      }${accountInfo.register_tx_map[n + 1].hash}`}
                      target="_blank"
                      className={cn(
                        'block font-normal text-sm text-zinc-500 hover:hover:opacity-70',
                        {
                          hidden:
                            n === 0 &&
                            !Boolean(
                              TOKEN_ID_TO_CHAIN[
                                accountInfo.register_tx_map['1'].token_id
                              ]
                            )
                        }
                      )}
                    >
                      <span>
                        {collapseString(
                          accountInfo.register_tx_map[n + 1].hash,
                          8,
                          8
                        )}
                      </span>
                      <Iconfont name="arrow-right" color="#31333E" size="12" />
                    </a>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
