import { useDasBalanceContext } from '@did/das-app-context'
import {
  AccountInputType,
  AccountInputValueParam,
  IAccountInputProps
} from '@did/das-balance-types'
import {
  collapseString,
  findParsingRecordChain,
  ErrorInfo,
  toLowerCase
} from '@did/tools'
import { DidAvatar } from '@did/uikit'
import debounce from 'lodash.debounce'
import React, { useCallback, useState } from 'react'
import styles from './styles.module.scss'
import { ParsingRecordType, IAccountParsingRecord } from '@did/types'
import { useCompositionInput } from 'foxact/use-composition-input'

export const AccountInput: AccountInputType = React.forwardRef<
  HTMLInputElement,
  IAccountInputProps
>(
  (
    { slots, chain, onChange, errorMessages = [], className, ...otherProps },
    ref
  ) => {
    const [value, setValue] = useState<AccountInputValueParam>()
    const [addressErrors, setAddressErrors] = useState<string[]>([])
    const [isDasAccount, setIsDasAccount] = useState(false)
    const [currentChainParsingRecords, setCurrentChainParsingRecords] =
      useState<any[]>([])
    const [toAddress, setToAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [showParsingRecords, setShowParsingRecords] = useState(false)
    const [top, setTop] = useState(false)

    const { services, tt } = useDasBalanceContext()

    const onFocus = () => {
      // Handle focus
      if (currentChainParsingRecords.length > 0) {
        setShowParsingRecords(true)
      }
    }

    const switchParsingRecords = () => {
      // Handle switch parsing records
    }

    const selectAddress = (record: any) => {
      setToAddress(record.value)
      setShowParsingRecords(false)
      const newValue = {
        value: value?.value || '',
        address: record.value
      }
      onChange?.(newValue)
      setValue(newValue)
    }

    const getAccountParsingRecords = debounce(async function (account: string) {
      try {
        setLoading(true)
        const res = await services.account.accountParsingRecords(account)

        if (res.records && res.records.length > 0) {
          const records = res.records.filter(
            (record: IAccountParsingRecord) => {
              const type = record.type
              const key = findParsingRecordChain(record.key).coinType
              return (
                type === ParsingRecordType.address && key === chain.coinType
              )
            }
          )
          setCurrentChainParsingRecords(records)
          if (records.length > 0) {
            setShowParsingRecords(true)
            setAddressErrors([])
          } else {
            setAddressErrors([
              tt('No {symbol} related parsing records', {
                symbol: chain.symbol
              }) as string
            ])
          }
        } else {
          setAddressErrors([
            tt('No {symbol} related parsing records', {
              symbol: chain.symbol
            }) as string
          ])
        }
      } catch (err: any) {
        ErrorInfo.error(err)
      } finally {
        setLoading(false)
      }
    }, 600)

    const handleChange = (value: string) => {
      setAddressErrors([])
      const newValue = {
        value: value,
        address: value
      }
      onChange?.(newValue)
      setValue(newValue)
      setShowParsingRecords(false)

      const isDas = /\.bit$/.test(toLowerCase(newValue?.value))
      setIsDasAccount(isDas)
      if (isDas) {
        getAccountParsingRecords(toLowerCase(newValue?.value))
      } else {
        setShowParsingRecords(false)
        setCurrentChainParsingRecords([])
      }
    }

    return (
      <div className={`${styles.accountInput} ${className}`}>
        <input
          ref={ref}
          className={`${styles.accountInputInput} ${
            addressErrors[0] || errorMessages[0]
              ? styles.accountInputInputError
              : ''
          } ${isDasAccount ? styles.accountInputInputIsAccount : ''} ${
            currentChainParsingRecords.length > 0 && toAddress
              ? styles.accountInputInputAccount
              : ''
          }`}
          type="text"
          onFocus={onFocus}
          {...useCompositionInput(
            useCallback(
              (value: string) => {
                handleChange?.(value)
              },
              [handleChange]
            )
          )}
          {...(otherProps as any)}
        />
        {loading ? (
          <span className={styles.accountInputAccount}>
            <span className={styles.accountInputParsingRecordsLoadingIcon}>
              <slots.icon name="loading" color="#D5D5D5" />
            </span>
          </span>
        ) : currentChainParsingRecords.length > 0 ? (
          <span
            className={styles.accountInputAccount}
            onClick={switchParsingRecords}
          >
            <span
              className={
                toAddress
                  ? styles.accountInputAccountContainerToAddress
                  : styles.accountInputAccountContainer
              }
            >
              {toAddress && (
                <span className={styles.accountInputAccountToAddress}>
                  {collapseString(toAddress, 3, 3)}
                </span>
              )}
              <DidAvatar
                account={value?.value || ''}
                fontSize="10px"
                lineClassName="mb-[-2px]"
                lineStyle={{ height: '3px' }}
                textClassName="mb-0"
                size={22}
              />
            </span>
          </span>
        ) : null}
        {showParsingRecords && (
          <div
            className={`${styles.accountInputParsingRecords} ${
              top ? styles.accountInputParsingRecordsTop : ''
            }`}
          >
            <div className={styles.accountInputParsingRecordsLabel}>
              {currentChainParsingRecords.length > 1
                ? tt('Multiple results exist, please select')
                : tt('Results')}
            </div>
            <ul className={styles.accountInputParsingRecordsList}>
              {currentChainParsingRecords.map((record, index) => (
                <li
                  key={`address${index}`}
                  className={styles.accountInputParsingRecordsItem}
                  onClick={() => selectAddress(record)}
                >
                  <span
                    className={`${
                      styles.accountInputParsingRecordsMarginRight8
                    } ${
                      toAddress ? styles.accountInputParsingRecordsWidth24 : ''
                    }`}
                  >
                    {toAddress.toUpperCase() === record.value.toUpperCase() && (
                      <slots.icon name="check" color="#22C493" />
                    )}
                  </span>
                  <div>
                    <div className={styles.accountInputParsingRecordsAddress}>
                      {record.value}
                    </div>
                    {record.label && (
                      <span className={styles.accountInputParsingRecordsTag}>
                        {record.label}
                      </span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {addressErrors[0] && (
          <div className={styles.accountInputErrorMessages}>
            {addressErrors[0]}
          </div>
        )}
        {errorMessages[0] && !addressErrors[0] && (
          <div className={styles.accountInputErrorMessages}>
            {errorMessages[0]}
          </div>
        )}
      </div>
    )
  }
)
