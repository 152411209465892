import { ILocalstorageData } from '@did/das-app-types/hooks'
import { ErrorInfo } from '@did/monitoring'
import { useMemo } from 'react'

export const useLocalstorage = () => {
  const storageKey = useMemo(() => {
    try {
      const host = globalThis.location.href
      const url = new URL(host)
      return `${url.host}v3`
    } catch (e: any) {
      return 'didv3'
    }
  }, [])

  const getLocalData = () => {
    try {
      return JSON.parse(
        localStorage.getItem(storageKey) || '{}'
      ) as ILocalstorageData
    } catch (e: any) {
      return {} as ILocalstorageData
    }
  }

  const setLocalData = (val: ILocalstorageData) => {
    localStorage.setItem(storageKey, JSON.stringify(val))
  }

  const setInviter = (inviter: string) => {
    try {
      const data = getLocalData()
      data.me = data.me || {}
      data.me.inviter = inviter
      setLocalData(data)
    } catch (e: any) {
      ErrorInfo.error(e)
    }
  }

  const setChannel = (channel: string) => {
    try {
      const data = getLocalData()
      data.me = data.me || {}
      data.me.channel = channel
      setLocalData(data)
    } catch (e: any) {
      ErrorInfo.error(e)
    }
  }

  const setLange = (lang: string) => {
    try {
      const data = getLocalData()
      data.common = data.common || {}
      data.common.language = lang
      setLocalData(data)
    } catch (e: any) {
      ErrorInfo.error(e)
    }
  }

  return {
    getLocalData,
    setLocalData,
    setInviter,
    setChannel,
    setLange
  }
}
