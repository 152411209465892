export const CYCLE_CALL_FUNCTION_TIME = 5000

// number of decimal places to retain the amount
export const FIAT_DECIMAL_PLACES = 2

export enum RECORD_STATUS {
  processing = 1,
  succeed = 2,
  failed = 3
}

export const SUB_TABS = {
  ckb: 'ckb',
  credits: 'credits'
}
