import { DEFAULT_PAGE_SIZE } from '@did/constants'
import {
  IDidPointService,
  IDidPointBalanceParams,
  IDidPointBalanceRes,
  IDidPointDepositParams,
  IDidPointDepositRes,
  IReturnPaymentHashParams,
  SignTxListRes,
  ITxSendRes,
  IDidPointRecordsParams,
  IDidPointRecordsRes,
  IRecordDetailParams,
  IRecordDetailRes,
  IWithdrawableListParams,
  IWithdrawableListRes,
  SignTxListParams,
  IDidPointWithdrawParams,
  IDidPointConfigInfoRes,
  ITokenListRes,
  IDasBalancePayParams,
  ITransferParams,
  IReportStripeErrorParams
} from '@did/types'
import { BasicService } from './basic-service'
import { validateParams } from '@did/tools'

export default class DidPoint extends BasicService implements IDidPointService {
  /**
   * Get the DIDPoint balance.
   * @param key_info
   */
  didPointBalance({
    key_info
  }: IDidPointBalanceParams): Promise<IDidPointBalanceRes> {
    validateParams({ ...key_info }, 'didPointBalance')
    return this.axios.post('/dp/balance', {
      type: 'blockchain',
      key_info
    })
  }

  /**
   * Place an order to recharge DIDPoint.
   * @param key_info
   * @param amount_dp
   * @param token_id
   */
  didPointDeposit({
    key_info,
    amount_dp,
    token_id
  }: IDidPointDepositParams): Promise<IDidPointDepositRes> {
    validateParams({ ...key_info, amount_dp, token_id }, 'didPointDeposit')
    return this.axios.post('/dp/deposit', {
      type: 'blockchain',
      key_info,
      amount_dp,
      token_id
    })
  }

  /**
   * Returns the recharge order payment hash.
   * @param key_info
   * @param order_id
   * @param payment_hash
   */
  returnPaymentHash({
    key_info,
    order_id,
    payment_hash
  }: IReturnPaymentHashParams): Promise<{}> {
    validateParams({ ...key_info, order_id, payment_hash }, 'returnPaymentHash')
    return this.axios.post('/payment/hash', {
      type: 'blockchain',
      key_info,
      order_id,
      payment_hash
    })
  }

  /**
   * transaction send
   * @param action
   * @param sign_key
   * @param sign_list
   * @param sign_address
   */
  txSend({
    action,
    sign_key,
    sign_list,
    sign_address
  }: SignTxListRes): Promise<ITxSendRes> {
    validateParams({ sign_key, sign_list }, 'txSend')
    return this.axios.post('/tx/send', {
      action,
      sign_key,
      sign_list,
      sign_address
    })
  }

  /**
   * Get the DIDPoint record.
   * @param key_info
   * @param record_type
   * @param page
   * @param size
   */
  didPointRecords({
    key_info,
    record_type,
    page,
    size = DEFAULT_PAGE_SIZE
  }: IDidPointRecordsParams): Promise<IDidPointRecordsRes> {
    validateParams({ ...key_info }, 'didPointRecords')
    return this.axios.post('/dp/records', {
      type: 'blockchain',
      key_info,
      record_type,
      page,
      size
    })
  }

  /**
   * Get the DIDPoint record detail.
   * @param key_info
   * @param record_id
   * @param order_id
   */
  recordDetail({
    key_info,
    record_id,
    order_id
  }: IRecordDetailParams): Promise<IRecordDetailRes> {
    validateParams({ ...key_info }, 'recordDetail')
    return this.axios.post('/record/detail', {
      type: 'blockchain',
      key_info,
      record_id,
      order_id
    })
  }

  /**
   * Get the DIDPoint withdrawable list.
   * @param key_info
   * @param page
   * @param size
   */
  withdrawableList({
    key_info,
    page,
    size = DEFAULT_PAGE_SIZE
  }: IWithdrawableListParams): Promise<IWithdrawableListRes> {
    validateParams({ ...key_info }, 'withdrawableList')
    return this.axios.post('/withdrawable/list', {
      type: 'blockchain',
      key_info,
      page,
      size
    })
  }

  /**
   * DIDPoint Withdrawal.
   * @param key_info
   * @param order_id
   */
  didPointWithdraw({
    key_info,
    order_id
  }: IDidPointWithdrawParams): Promise<SignTxListParams> {
    validateParams({ ...key_info }, 'withdraw')
    return this.axios.post('/dp/withdraw', {
      type: 'blockchain',
      key_info,
      order_id
    })
  }

  /**
   * DIDPoint config info
   */
  didPointConfigInfo(): Promise<IDidPointConfigInfoRes> {
    return this.axios.post('/config/info', {})
  }

  /**
   * get token list
   */
  tokenList(): Promise<ITokenListRes> {
    return this.axios.post('/token/list', {})
  }

  /**
   * DAS Balance payment
   * @param key_info
   * @param evm_chain_id
   * @param order_id
   */
  dasBalancePay({
    key_info,
    evm_chain_id,
    order_id
  }: IDasBalancePayParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, order_id }, 'dasBalancePay')
    return this.axios.post('/balance/pay', {
      type: 'blockchain',
      key_info,
      evm_chain_id,
      order_id
    })
  }

  /**
   * DIDPoint transfer
   * @param key_info
   * @param amount_dp
   * @param order_id
   * @param action_dp
   */
  transfer({
    key_info,
    amount_dp,
    order_id,
    action_dp
  }: ITransferParams): Promise<SignTxListParams> {
    validateParams({ ...key_info, order_id }, 'transfer')
    return this.axios.post('/dp/transfer', {
      type: 'blockchain',
      key_info,
      amount_dp,
      order_id,
      action_dp
    })
  }

  /**
   * Report a Stripe error
   * @param order_id
   * @param code
   * @param decline_code
   * @param doc_url
   * @param message
   * @param type
   */
  reportStripeError({
    order_id,
    code,
    decline_code,
    doc_url,
    message,
    type
  }: IReportStripeErrorParams): Promise<void> {
    validateParams({ order_id }, 'reportStripeError')
    return this.axios.post('/stripe/err', {
      order_id,
      code,
      decline_code,
      doc_url,
      message,
      type
    })
  }
}
