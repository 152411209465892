import { DEFAULT_PAGE_SIZE } from '@did/constants'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { DoBListType } from '@did/das-app-types/components'
import { IAccountInfoRes } from '@did/types'
import { DasButton, Iconfont } from '@did/uikit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AccountSearch } from './account-search'
import { AccountStatus } from './account-status'
import { StatusTip } from './status-tip'
import { ErrorInfo } from '@did/monitoring'
import { CKB } from '@did/constants/chain'

export const DoBList: DoBListType = () => {
  const { tt, router, services, isProd } = useDasBalanceContext()
  const { ckbAddress, ccc } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const [fetchDataLoading, setFetchDataLoading] = useState(true)
  const [myAccounts, setMyAccounts] = useState<IAccountInfoRes[]>([])
  const [noMoreShowing, setNoMoreShowing] = useState(false)
  const [loadMoreShowing, setLoadMoreShowing] = useState(false)
  const [loadingShowing, setLoadingShowing] = useState(false)
  const [page, setPage] = useState(0)
  const [searchWord, setSearchWord] = useState('')

  const noRecords = useMemo(() => {
    return !fetchDataLoading && myAccounts.length === 0 && searchWord === ''
  }, [fetchDataLoading, myAccounts, searchWord])

  const getMyAccounts = useCallback(
    async (page: number, searchStr: string) => {
      if (!ckbAddress) {
        setMyAccounts([])
        return
      }
      if (myAccounts.length === 0) {
        setFetchDataLoading(true)
      }
      setNoMoreShowing(false)
      if (page > 0) {
        setLoadMoreShowing(false)
        setLoadingShowing(true)
      }

      try {
        setPage(page + 1)
        let res = await services.account.myDobs({
          key_info: {
            coin_type: CKB.coinType,
            key: ckbAddress
          },
          page: page + 1,
          keyword: searchStr
        })

        setLoadingShowing(false)
        if (!res || !res.list) {
          setFetchDataLoading(false)
          return
        }

        if (page + 1 === 1) {
          setMyAccounts(res.list)
        } else {
          setMyAccounts(myAccounts.concat(...res.list))
        }

        const length = res.list.length
        if (length < DEFAULT_PAGE_SIZE) {
          setLoadMoreShowing(false)
          if (length >= 0) {
            setNoMoreShowing(true)
          }
        } else {
          setLoadMoreShowing(true)
        }
      } catch (err: any) {
        ErrorInfo.error(err)
      } finally {
        setFetchDataLoading(false)
        setLoadingShowing(false)
      }
    },
    [myAccounts, ckbAddress]
  )

  const onSearch = useCallback(
    (value: string) => {
      setPage(0)
      setSearchWord(value)
      setFetchDataLoading(true)
      getMyAccounts(0, value)
    },
    [getMyAccounts]
  )

  const goUpgrade = useCallback(() => {
    router.push('/my/upgradeable-list')
  }, [])

  const onConnect = useCallback(() => {
    cccConnector?.open()
  }, [cccConnector?.open])

  const onClickAccount = useCallback((account: IAccountInfoRes) => {
    router.push(`/data/${account.account}`)
  }, [])

  useEffect(() => {
    if (ckbAddress) {
      setPage(0)
      getMyAccounts(0, searchWord)
    } else {
      setMyAccounts([])
      setLoadingShowing(false)
      setLoadMoreShowing(false)
      setNoMoreShowing(false)
      setFetchDataLoading(false)
    }
  }, [ckbAddress])

  return (
    <>
      {!noRecords && (
        <div className="w-full flex justify-between items-center gap-x-2">
          <AccountSearch className="w-full" onSearch={onSearch} />
        </div>
      )}
      {fetchDataLoading && (
        <div className="mt-[80px]">
          <StatusTip
            className="font-medium my-4 mx-0 text-[#31333E]"
            icon="pending"
            iconSize="72"
            tip={tt('Loading')}
            tipFontSize="14"
          />
        </div>
      )}
      {noRecords &&
        (ckbAddress ? (
          <div className="w-full pt-10 pb-20 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
            <Iconfont name="no-dobs-records" size={156} />
            <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
              {tt(`You haven't upgraded any .bit yet.`)}
            </div>
            <DasButton
              className="h-[38px] mx-auto font-semibold text-sm leading-[16px]"
              black
              onClick={goUpgrade}
              middle
            >
              {tt('Upgrade now')}
            </DasButton>
          </div>
        ) : (
          <div className="w-full pt-10 pb-20 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
            <Iconfont name="no-dobs-records" size={156} />
            <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
              {tt('Connect wallet to see your DOBs Compatible .bit.')}
            </div>
            <DasButton
              className="h-[38px] mx-auto font-semibold text-sm leading-[16px]"
              black
              onClick={onConnect}
              middle
            >
              {tt('Connect wallet')}
            </DasButton>
          </div>
        ))}
      {!fetchDataLoading && myAccounts.length > 0 && !isProd && (
        <div className="w-full flex py-[10px] px-4 bg-[#FCECEC] rounded-[9px] leading-[20px] text-error-font-color text-sm">
          <span className="mr-1">💡</span>
          {tt(
            'The following is the test network account, you need to re-register after the official launch.'
          )}
        </div>
      )}
      <ul className="w-full">
        {!fetchDataLoading &&
          myAccounts.map((account, index) => (
            <AccountStatus
              key={index}
              accountInfo={account}
              highlighted
              onClick={() => onClickAccount(account)}
            />
          ))}
        {loadMoreShowing && !fetchDataLoading && (
          <li
            className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm cursor-pointer text-link-font-color hover:text-link-hover-font-color"
            onClick={() => getMyAccounts(page, searchWord)}
          >
            {tt('Load more')}
          </li>
        )}
        {loadingShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {tt('Loading')}
          </li>
        )}
        {noMoreShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {searchWord && myAccounts?.length === 0
              ? tt('No matched .bit')
              : noRecords
                ? ''
                : tt('No more')}
          </li>
        )}
      </ul>
    </>
  )
}
