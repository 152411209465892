import { ConfirmChangesItemType } from '@did/das-app-types/module'
import { IconImage } from '@did/uikit'
import styles from './styles.module.scss'
import { cn } from '@did/tools'
import { DATA_ACTIONS } from '@did/types'
import { ActionLabel } from '../action-label/action-label'

export const ConfirmChangesItem: ConfirmChangesItemType = ({
  logo,
  label,
  value,
  action
}) => {
  return (
    <div className={cn(styles['confirm-changes-item'])}>
      <span className={cn(styles['confirm-changes__item__header'])}>
        <IconImage
          className={cn(styles['confirm-changes_item_info_logo'])}
          url={logo}
          alt={label}
          size={32}
          rounded
        />
        <span>
          <div className={cn(styles['confirm-changes__item__label'])}>
            {label}
          </div>
          <div
            className={cn(
              styles['confirm-changes__item__value'],
              action === DATA_ACTIONS.delete &&
                styles['confirm-changes__item_delete']
            )}
          >
            {value}
          </div>
        </span>
      </span>
      <ActionLabel action={action} />
    </div>
  )
}
