import { useDasBalanceContext } from '@did/das-app-context'
import { AccountStatusType } from '@did/das-app-types/module'
import { ACCOUNT_STATUS } from '@did/types'
import { Iconfont } from '@did/uikit'
import React, { useMemo } from 'react'
import { AccountRecommendList } from './account-recommend-list'
import { formatDateTime, cn, ErrorInfo } from '@did/tools'
import { LANGUAGE } from '@did/types/uikit'

export const AccountStatus: AccountStatusType = ({ accountInfo }) => {
  const { tt, outSiteUrl, router, lang } = useDasBalanceContext()

  const notRegistering = useMemo(() => {
    if (!accountInfo) return false
    return (
      accountInfo.status === ACCOUNT_STATUS.registeringPaymentConfirm &&
      accountInfo?.register_tx_map?.['1']
    )
  }, [accountInfo])

  const accountStatusInfo = useMemo(() => {
    if (!accountInfo) return null

    const ACCOUNT_STATUS_LIST: {
      [key: string]: {
        statusText: string | React.ReactNode
        href: string
        actionText: string
        statusDescription?: string | React.ReactNode
        isShowViewButton?: boolean
        isShowRecomList?: boolean
        iconName?: string
        textColor?: string
      }
    } = {
      [ACCOUNT_STATUS.error]: {
        statusText: '',
        href: '',
        actionText: ''
      },
      [ACCOUNT_STATUS.notOpenRegister]: {
        statusText: '',
        href: '',
        actionText: '',
        isShowRecomList: true
      },
      [ACCOUNT_STATUS.registerable]: {
        statusText: '',
        href: '/account/create/',
        actionText: tt('Register'),
        textColor: 'text-emerald-400'
      },
      [ACCOUNT_STATUS.registeringPaymentConfirm]: {
        statusText:
          notRegistering && !accountInfo.is_self
            ? tt('Available')
            : tt('Registering'),
        href:
          notRegistering && !accountInfo.is_self
            ? '/account/create/'
            : '/account/create/status/',
        actionText:
          notRegistering && !accountInfo.is_self ? tt('Register') : tt('View'),
        isShowViewButton: accountInfo.is_self,
        isShowRecomList: !notRegistering && !accountInfo.is_self
      },
      [ACCOUNT_STATUS.registeringLockedAccount]: {
        statusText: tt('Registering'),
        href: '/account/create/status/',
        actionText: accountInfo.is_self ? tt('View') : '',
        isShowViewButton: accountInfo.is_self,
        isShowRecomList: !accountInfo.is_self
      },
      [ACCOUNT_STATUS.registering]: {
        statusText: tt('Registering'),
        href: '/account/create/status/',
        actionText: accountInfo.is_self ? tt('View') : '',
        isShowViewButton: accountInfo.is_self,
        isShowRecomList: !accountInfo.is_self
      },
      [ACCOUNT_STATUS.registeringIncludeProposal]: {
        statusText: tt('Registering'),
        href: '/account/create/status/',
        actionText: accountInfo.is_self ? tt('View') : '',
        isShowViewButton: accountInfo.is_self,
        isShowRecomList: !accountInfo.is_self
      },
      [ACCOUNT_STATUS.registeringConfirmProposal]: {
        statusText: tt('Registering'),
        href: '/account/create/status/',
        actionText: accountInfo.is_self ? tt('View') : '',
        isShowViewButton: accountInfo.is_self,
        isShowRecomList: !accountInfo.is_self
      },
      [ACCOUNT_STATUS.registered]: {
        statusText: tt('Already registered'),
        href: '/data/',
        actionText: tt('View'),
        isShowViewButton: true,
        isShowRecomList: true,
        textColor: 'text-amber-500'
      },
      [ACCOUNT_STATUS.reservedAccount]: {
        statusText: tt('Reserved account'),
        href: '/create/account/',
        actionText: tt('View'),
        isShowViewButton: true,
        iconName: 'reserve',
        textColor: 'text-emerald-400'
      },
      [ACCOUNT_STATUS.onePriceSell]: {
        statusText: tt('On sale'),
        href: '',
        actionText: tt('View'),
        isShowViewButton: true,
        isShowRecomList: true,
        textColor: 'text-amber-500'
      },
      [ACCOUNT_STATUS.auctionSell]: {
        statusText: tt('Bid for sale'),
        href: '',
        actionText: tt('View')
      },
      [ACCOUNT_STATUS.candidateAccount]: {
        statusText: tt('Candidate account'),
        href: '',
        actionText: '',
        isShowViewButton: true
      },
      [ACCOUNT_STATUS.othersRegistering]: {
        statusText: tt('Others are registering'),
        href: '',
        actionText: '',
        isShowViewButton: true,
        isShowRecomList: true
      },
      [ACCOUNT_STATUS.unavailableAccount]: {
        statusText: tt('Unavailable Account'),
        href: '',
        actionText: '',
        isShowViewButton: true,
        isShowRecomList: true
      },
      [ACCOUNT_STATUS.subAccountNotCreated]: {
        statusText: tt(
          `{account} is a Second-level DID and can only be distributed by {subAccount}.bit . Please try another one.`,
          {
            account: accountInfo.account,
            subAccount: accountInfo.account?.replace('.bit', '')?.split('.')[1]
          }
        ),
        href: '',
        actionText: '',
        isShowViewButton: true
      },
      [ACCOUNT_STATUS.onDutchAuction]: {
        statusText: tt('In Dutch auction'),
        href: accountInfo?.is_self
          ? '/account/create/status/'
          : '/account/create/auction/',
        actionText: accountInfo?.is_self ? tt('View') : tt('Bid now'),
        isShowViewButton: true,
        iconName: 'auction',
        textColor: 'text-emerald-400'
      },
      [ACCOUNT_STATUS.auctionRecycling]: {
        statusText: tt('Expired'),
        href: '',
        actionText: '',
        isShowViewButton: true,
        isShowRecomList: true,
        statusDescription: tt('Dutch auction is expected to begin on {end}', {
          end: accountInfo?.re_register_time
            ? formatDateTime(
                Number(accountInfo?.re_register_time) * 1000,
                lang?.value === LANGUAGE.zhCN
                  ? 'YYYY 年 M 月 DD 日'
                  : 'YYYY-MM-DD'
              )
            : ''
        }),
        iconName: 'expired',
        textColor: 'text-amber-500'
      },
      [ACCOUNT_STATUS.inTransferApproving]: {
        statusText: tt('In transfer approving'),
        href: '',
        actionText: '',
        isShowViewButton: true,
        isShowRecomList: true,
        iconName: 'switch',
        textColor: 'text-emerald-400'
      },
      [ACCOUNT_STATUS.upgradedAccount]: {
        statusText: tt('Already registered'),
        href: '/data/',
        actionText: tt('View'),
        isShowViewButton: true,
        isShowRecomList: true,
        textColor: 'text-amber-500'
      }
    }

    const result = ACCOUNT_STATUS_LIST[accountInfo.status]
    if (accountInfo.account.replace(/\.bit$/, '').split('.').length > 1) {
      try {
        result.isShowRecomList = false
      } catch (err: any) {
        console.log('======accountInfo.status======')
        console.log(accountInfo.status)
        console.log('======accountInfo.status======')
        ErrorInfo.error(err)
      }
    }

    return ACCOUNT_STATUS_LIST[accountInfo.status]
  }, [accountInfo, notRegistering, tt])

  const goPage = () => {
    if (!accountInfo) return
    if (
      [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
        accountInfo.status
      ) &&
      !accountInfo.is_self
    ) {
      window.location.href = `https://d.id/bit/data/${accountInfo.account}`
    } else if (accountInfo.status === ACCOUNT_STATUS.onePriceSell) {
      window.location.href = `${outSiteUrl?.didtopPageUrl}/account/${accountInfo.account}`
    } else if (accountStatusInfo?.href) {
      router?.push(
        `${accountStatusInfo?.href}${accountInfo.account}`,
        router.query
      )
    }
  }

  return (
    <div className="w-full mt-3 md:mt-7 mb-8">
      {accountStatusInfo?.isShowViewButton && (
        <div
          className={cn(
            'w-full border-slate-300/40 border rounded-full mb-4 px-6 py-5 bg-white flex items-center justify-between shadow-panel',
            accountStatusInfo?.textColor || 'text-zinc-700',
            (accountStatusInfo?.href || accountStatusInfo?.actionText) &&
              'cursor-pointer hover:bg-gray-100'
          )}
          onClick={goPage}
        >
          <div
            className={cn('text-base font-semibold inline-flex items-center')}
          >
            {accountStatusInfo?.iconName && (
              <Iconfont
                className="mr-2"
                name={accountStatusInfo?.iconName}
                size={16}
              />
            )}
            {accountStatusInfo?.statusText}
          </div>
          {accountStatusInfo?.actionText ? (
            <div className="text-zinc-500 text-base font-medium flex items-center">
              {accountStatusInfo.actionText}
              <Iconfont name="arrow-right" color="#52627933" size={16} />
            </div>
          ) : accountStatusInfo?.statusDescription ? (
            <span className="text-zinc-500 text-base font-medium">
              {accountStatusInfo.statusDescription}
            </span>
          ) : null}
        </div>
      )}
      {accountStatusInfo?.isShowRecomList && (
        <AccountRecommendList account={accountInfo?.account || ''} />
      )}
    </div>
  )
}
