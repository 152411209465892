import { Iconfont } from '@did/uikit'
import { WarningAlertType } from '@did/das-app-types/components'
import styles from './styles.module.scss'
import { cn } from '@did/tools'

export const WarningAlert: WarningAlertType = ({ children, className }) => {
  return (
    <div className={cn(styles['warning-alert'], className)}>
      <Iconfont name="tips" size="16" color="#E05656" />
      <span>{children}</span>
    </div>
  )
}
