import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { useState } from 'react'
import { DasButton, Dialog } from '@did/uikit'
import { handleError } from '@did/das-app-utils'
import { RecycleDialogType } from '@did/das-app-types/module'
import { DOB_RECYCLE_STATUS } from '@did/das-app-constants'
import { CKB } from '@did/constants/chain'

export const RecycleDialog: RecycleDialogType = ({
  showing,
  accountInfo,
  updateRecyclableList,
  onClose
}) => {
  const { tt, services } = useDasBalanceContext()
  const { ckbAddress, ccc } = useCccContext()
  const signer = ccc?.useSigner()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const onRefund = async () => {
    setConfirmLoading(true)

    try {
      const res = await services.account.recycleDob({
        key_info: {
          coin_type: CKB.coinType,
          key: ckbAddress
        },
        account: accountInfo.account
      })

      if (!res) {
        setConfirmLoading(false)
        return
      }

      if (res.ckb_tx) {
        if (!signer?.signTransaction) {
          setConfirmLoading(false)
          return
        }
        // @ts-ignore
        const { cccA } = await import('@ckb-ccc/connector-react/advanced')
        const ckbTx = cccA.JsonRpcTransformers.transactionTo(
          JSON.parse(res.ckb_tx)
        )
        let ckbSignature = await signer?.signTransaction(ckbTx)
        ckbSignature = cccA.JsonRpcTransformers.transactionFrom(ckbSignature)
        const { hash } = await services.account.sendTrx({
          ...res,
          ckb_tx: JSON.stringify(ckbSignature)
        })
        updateRecyclableList({
          ...accountInfo,
          recycle_status: DOB_RECYCLE_STATUS.recycling,
          recycle_hash: hash
        })
        onClose()
      }
    } catch (err: any) {
      handleError(err, tt, alert)
    } finally {
      setConfirmLoading(false)
    }
  }

  return (
    <Dialog
      showing={showing}
      title={tt('Refunding fee')}
      closeButton
      onClose={() => {
        onClose()
      }}
    >
      <div className="text-neutral-700 text-sm font-medium leading-tight break-words">
        {tt(
          'Storage fee deposited for this .bit will be refunded back to your .bit owner address. You can use supported wallet to manage the refunds.'
        )}
      </div>
      <div className="py-8">
        <DasButton
          black
          block
          onClick={onRefund}
          isLoadingGradient={false}
          loading={confirmLoading}
        >
          {tt('Refund')}
        </DasButton>
      </div>
    </Dialog>
  )
}
