import { ConfirmChangesType } from '@did/das-app-types/module'
import { DasButton, Dialog } from '@did/uikit'
import styles from './styles.module.scss'
import {
  cn,
  collapseString,
  findDweb,
  findParsingRecordChain,
  findProfile
} from '@did/tools'
import { useDasBalanceContext } from '@did/das-app-context'
import React, { useMemo } from 'react'
import { IAccountParsingRecord, ParsingRecordProfileKey } from '@did/types'
import { ConfirmChangesItem } from '../confirm-changes-item/confirm-changes-item'

export const ConfirmChanges: ConfirmChangesType = ({
  showing,
  addressParsingRecords,
  profileParsingRecords,
  dwebParsingRecords,
  customParsingRecords,
  onConfirm,
  onClose
}) => {
  const { tt } = useDasBalanceContext()

  const addressList = useMemo(() => {
    return addressParsingRecords.filter((record) => {
      return record.action !== ''
    })
  }, [addressParsingRecords])

  const profileList = useMemo(() => {
    return profileParsingRecords.filter((record) => {
      return record.action !== ''
    })
  }, [profileParsingRecords])

  const dwebList = useMemo(() => {
    return dwebParsingRecords.filter((record) => {
      return record.action !== ''
    })
  }, [dwebParsingRecords])

  const customList = useMemo(() => {
    return customParsingRecords.filter((record) => {
      return record.action !== ''
    })
  }, [customParsingRecords])

  const profileLogo = (record: IAccountParsingRecord) => {
    if ([ParsingRecordProfileKey.avatar].includes(record.key as any)) {
      if (record.value.match(/[https|http]?:\/\//)) {
        return record.value
      }
    }
    return `/bit/images/social/social-${record.key}.png`
  }

  return (
    <Dialog
      showing={showing}
      title={tt('Confirm information')}
      closeButton
      onClose={onClose}
    >
      <div className={cn(styles['confirm-changes__container'])}>
        {addressList.length > 0 && (
          <div className={cn(styles['confirm-changes__group'])}>
            {tt('Addresses ({amount})', { amount: addressList.length })}
          </div>
        )}
        {addressList.map((record, index) => {
          return (
            <ConfirmChangesItem
              key={`address${index}`}
              logo={`/bit/images/chain/chain-${
                findParsingRecordChain(record.key).value
              }.png`}
              label={findParsingRecordChain(record.key).text}
              value={collapseString(record.value, 6, 6)}
              action={record.action}
            />
          )
        })}
        {profileList.length > 0 && (
          <div
            className={cn(
              styles['confirm-changes__group'],
              styles['confirm-changes__margin-top-14']
            )}
          >
            {tt('Profile ({amount})', { amount: profileList.length })}
          </div>
        )}
        {profileList.map((record, index) => {
          return (
            <ConfirmChangesItem
              key={`profile${index}`}
              logo={profileLogo(record)}
              label={findProfile(record.key).text}
              value={record.value}
              action={record.action}
            />
          )
        })}
        {dwebList.length > 0 && (
          <div
            className={cn(
              styles['confirm-changes__group'],
              styles['confirm-changes__margin-top-14']
            )}
          >
            {tt('DWeb ({amount})', { amount: dwebList.length })}
          </div>
        )}
        {dwebList.map((record, index) => {
          return (
            <ConfirmChangesItem
              key={`dweb${index}`}
              logo={`/bit/images/dweb/dweb-${findDweb(record.key).value}.png`}
              label={findDweb(record.key).text}
              value={record.value}
              action={record.action}
            />
          )
        })}
        {customList.length > 0 && (
          <div
            className={cn(
              styles['confirm-changes__group'],
              styles['confirm-changes__margin-top-14']
            )}
          >
            {tt('Custom ({amount})', { amount: customList.length })}
          </div>
        )}
        {customList.map((record, index) => {
          return (
            <ConfirmChangesItem
              key={`custom${index}`}
              logo={
                record.key === 'bitcc_theme'
                  ? '/images/social/social-theme.png'
                  : ''
              }
              label={record.key}
              value={record.value}
              action={record.action}
            />
          )
        })}
      </div>
      <div className="py-8">
        <DasButton
          isLoadingGradient={false}
          type="submit"
          black
          block
          onClick={onConfirm}
        >
          {tt('Confirm')}
        </DasButton>
      </div>
    </Dialog>
  )
}
