import { CYCLE_CALL_FUNCTION_TIME } from '@did/constants'
import { useDasBalanceContext } from '@did/das-app-context'
import { UseTransStatusDialogType } from '@did/das-app-types/hooks'
import { REVERSE_TX_STATUS } from '@did/types'
import { useEffect, useState } from 'react'
import { ErrorInfo } from '@did/monitoring'

export const useTransStatusDialog: UseTransStatusDialogType = ({
  account,
  coinType,
  address,
  showing,
  onClose = () => {},
  handleTxStatus,
  successCallback,
  actions
}) => {
  const { tt, services } = useDasBalanceContext()

  const [txStatus, setTxStatus] = useState<REVERSE_TX_STATUS>(
    REVERSE_TX_STATUS.pending
  )

  const checkStatus = async () => {
    if (!coinType || !address) return
    try {
      const res = await services.dasReverse.checkReverseTxStatus({
        coinType: coinType,
        address: address
      })
      // If the latest tx doesn't fit the target tx, txStatus will always be notStarted
      // The mounted check should not be repeated, otherwise it will conflict with user's check
      const isTargetTx = res.account === account && actions.includes(res.action)
      if (!isTargetTx) {
        return
      }

      handleTxStatus?.(res.status)
      setTxStatus(res.status)
    } catch (err: any) {
      ErrorInfo.error(err)
    }
  }

  useEffect(() => {
    if (!coinType || !address) return
    let timerId: number = 0
    if (showing) {
      setTxStatus(REVERSE_TX_STATUS.pending)
      // @ts-ignore
      timerId = setInterval(() => {
        checkStatus()
      }, CYCLE_CALL_FUNCTION_TIME * 2)
      checkStatus()
    } else {
      clearInterval(timerId)
    }

    return () => {
      clearInterval(timerId)
      setTxStatus(REVERSE_TX_STATUS.pending)
    }
  }, [showing, account, coinType, address])

  const handleBtnClick = () => {
    successCallback?.({})
  }

  const handleClose = () => {
    if (txStatus === REVERSE_TX_STATUS.success) {
      successCallback?.({})
      return
    }
    onClose?.()
  }

  return {
    handleBtnClick,
    onClose: handleClose,
    showing: Boolean(showing),
    loading: txStatus === REVERSE_TX_STATUS.pending,
    done: txStatus === REVERSE_TX_STATUS.success,
    btnText: tt(txStatus === REVERSE_TX_STATUS.success ? 'Done' : 'Processing')
  }
}
