import { cn } from '@did/tools'
import { StatusTip } from './status-tip'
import { useDasBalanceContext } from '@did/das-app-context'
import { useState } from 'react'
import { RegisteringAccountList } from './registering-account-list'
import { AuctionAccountList } from './auction-account-list'

export const PendingPage = () => {
  const { tt } = useDasBalanceContext()

  const [fetchDataLoading, setFetchDataLoading] = useState(false)

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 pt-4 md:p-0">
      {fetchDataLoading ? (
        <div className="mt-[100px]">
          <StatusTip
            className="font-semibold"
            icon="pending"
            iconSize="72"
            tip={tt('Loading')}
            tipFontSize="14"
          />
        </div>
      ) : null}
      <div className={cn(fetchDataLoading && 'hidden', 'w-full')}>
        <RegisteringAccountList
          fetchDataComplete={() => {
            setFetchDataLoading(false)
          }}
        />
        <AuctionAccountList />
        <div className="py-4 px-0 text-center text-[13px] font-semibold text-assist-font-color">
          {tt('No more')}
        </div>
      </div>
    </div>
  )
}
