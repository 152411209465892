import { ParsingRecordType } from '@did/types'
import { CoinType } from '@did/constants/chain'

export enum TRX_STATUS {
  failed = -1,
  pending,
  success,
  unpackaged,
  unknown
}

export interface IMainChain {
  name: string
  symbol: string
  coinType: CoinType
  decimals: number
  icon: string
  tokenId: string
  getExplorerTrx: (isProd: boolean) => string
}

export interface ITrxHistoryListInfo {
  account: string
  action: number
  block_number: number
  hash: string
  timestamp: number
  capacity: string
}

export interface IAccountParsingRecord {
  type: ParsingRecordType | string
  key: string
  label: string
  value: string
  ttl: string
}
