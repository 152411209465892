import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { AccountAutocompleteType } from '@did/das-app-types/components'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import debounce from 'lodash.debounce'
import { ACCOUNT_SUFFIX, DEBOUNCE_WAIT_TIME } from '@did/constants'
import { DasButton, DidAvatar, Iconfont } from '@did/uikit'
import { ErrorInfo } from '@did/monitoring'
import { useCompositionInput } from 'foxact/use-composition-input'
import * as Popover from '@radix-ui/react-popover'
import { cn } from '@did/tools'
import { digitalEmojiHandle } from '@did/tools/bit-account-string-handle'
import { CKB } from '@did/constants/chain'

export const AccountAutocomplete: AccountAutocompleteType = ({
  className,
  address,
  placeholder,
  onChange,
  bitName: propsBitName,
  onlyOwned
}) => {
  const { services, connectedAccount, tt, router } = useDasBalanceContext()
  const { isDobsMode, ckbAddress } = useCccContext()
  const [keyword, setKeyword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [ownerBitNames, setOwnerBitNames] = useState<string[]>([])

  useEffect(() => {
    setKeyword(propsBitName || '')
  }, [propsBitName])

  const searchAllAccounts = debounce(
    async function (searchKey: string) {
      if (
        !searchKey &&
        !isDobsMode &&
        (!connectedAccount?.chain?.coinType || !connectedAccount?.address)
      ) {
        return
      }

      if (!searchKey && isDobsMode && !ckbAddress) {
        return
      }

      setLoading(true)
      try {
        const res = await services.dasReverse.searchAccounts({
          keyword: searchKey,
          coinType: isDobsMode
            ? CKB.coinType
            : connectedAccount?.chain?.coinType!,
          address: isDobsMode ? ckbAddress : connectedAccount?.address!
        })
        if (res) {
          setOwnerBitNames(res.list?.map((l) => l.account))
        }
      } catch (error: any) {
        ErrorInfo.error(error)
      } finally {
        setLoading(false)
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: true }
  )

  const searchOwnedAccounts = debounce(
    async function (searchKey: string, keepLastResult = false) {
      setLoading(true)
      try {
        let res: any

        if (isDobsMode && ckbAddress) {
          res = await services.account.myDobs({
            key_info: {
              coin_type: CKB.coinType,
              key: ckbAddress
            },
            keyword: searchKey
          })
        } else if (
          isDobsMode === false &&
          connectedAccount?.chain?.coinType &&
          connectedAccount?.address
        ) {
          res = await services.account.myAccounts({
            key_info: {
              coin_type: connectedAccount?.chain?.coinType!,
              key: connectedAccount?.address!
            },
            keyword: searchKey
          })
        }

        if (res) {
          setOwnerBitNames((options) => {
            const newOptions = res.list?.map((l: any) => l.account)
            return keepLastResult ? [...options, ...newOptions] : newOptions
          })
        }
      } catch (error: any) {
        ErrorInfo.error(error)
      } finally {
        setLoading(false)
      }
    },
    DEBOUNCE_WAIT_TIME,
    { leading: true }
  )

  const searchAccount = useMemo(() => {
    if (onlyOwned) return searchOwnedAccounts
    return searchAllAccounts
  }, [onlyOwned, connectedAccount, ckbAddress, isDobsMode])

  const handleName = (name: string) => {
    if (!name) {
      return ''
    }
    let _name = digitalEmojiHandle(name)
      .replace(/\s+/g, '')
      .toLowerCase()
      .replace(/\.bit$/, '')
    _name = _name + ACCOUNT_SUFFIX
    return _name?.replace(/\.bit$/, '').split('.').length > 1
      ? _name?.replace(/\.bit$/, '')
      : _name || ''
  }

  return (
    <div>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger className="w-full relative outline-0">
          <input
            placeholder={placeholder}
            onFocus={() => searchAccount(keyword)}
            {...useCompositionInput(
              useCallback(
                (value: string) => {
                  const _val = digitalEmojiHandle(value || '')
                    .replace(/\s+/g, '')
                    .toLowerCase()
                  setKeyword(_val)
                  searchAccount(_val)
                  setOpen(true)
                  if (!_val) {
                    onChange?.('')
                  }
                },
                [onChange]
              )
            )}
            className={cn(
              'h-14 rounded-xl w-full border border-gray-200 bg-gray-50 pl-6 pr-12 outline-0 hover:border-primary hover:bg-white focus:border-primary focus:ring-green-500 focus:bg-white',
              className
            )}
          />
          <Iconfont
            name="search"
            color="#B0B8BF"
            size="16"
            className="absolute right-6 top-5"
          />
        </Popover.Trigger>
        {(keyword || ownerBitNames?.length > 0) && (
          <Popover.Portal>
            <Popover.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="bg-white w-[var(--radix-popover-trigger-width)] shadow rounded-xl border border-gray-200 p-3 my-2 max-h-[268px] overflow-y-auto"
            >
              <ul>
                {ownerBitNames?.map((a) => (
                  <li
                    key={a}
                    className="p-3 flex items-center font-semibold hover:bg-hover-item hover:text-white rounded-lg cursor-pointer break-all"
                    onClick={() => {
                      setKeyword(a)
                      onChange?.(a)
                      setOpen(false)
                    }}
                  >
                    <DidAvatar
                      account={a}
                      fontSize="14px"
                      lineClassName="w-[18px] h-[8px] mb-[-2px]"
                      textClassName="mb-[-2px]"
                      className="mr-2"
                      size={32}
                    />
                    {a}
                  </li>
                ))}
              </ul>
              {loading && <div className=" p-3 ">{tt('Searching...')}</div>}
              {!loading && ownerBitNames?.length === 0 && (
                <div className="p-3 text-gray-600 flex items-center">
                  {`${handleName(keyword)} ${tt('is not exist')}`}
                  <DasButton
                    className="ml-2"
                    black
                    small
                    onClick={() => {
                      router?.push('/create', {
                        searchWord: keyword
                      })
                    }}
                  >
                    <span className="leading-none">{tt('Create now')}</span>
                  </DasButton>
                </div>
              )}
            </Popover.Content>
          </Popover.Portal>
        )}
      </Popover.Root>
    </div>
  )
}
