import Axios from 'axios'
import { BasicService } from './basic-service'

import { ICommon, IConfig, IToken, IUpgradeNoticeRes } from '@did/types'
import { ErrorInfo } from '@did/tools'

export default class Common extends BasicService implements ICommon {
  /**
   * get token list
   */
  tokens(): Promise<{ token_list: IToken[] }> {
    return this.axios.post('/token/list')
  }

  /**
   * get config info
   */
  config(): Promise<IConfig> {
    return this.axios.post('/config/info')
  }

  async upgradeNotice(): Promise<IUpgradeNoticeRes> {
    try {
      const timestamp = Date.now()
      const fileName = this.isProdData
        ? 'upgrade-notice'
        : 'upgrade-notice-test'
      const { data } = await Axios.get(
        `https://dotbit-upgrade-notice-hk.oss-cn-hongkong.aliyuncs.com/upgrade-notice/${fileName}.json?t=${timestamp}`
      )
      if (data && data.end_date) {
        const endTimestamp = new Date(
          `${data.end_date}T24:00:00.000+0800`
        ).getTime()
        return timestamp < endTimestamp ? data : {}
      }
      return {}
    } catch (err: any) {
      ErrorInfo.error(err)
      return {}
    }
  }
}
