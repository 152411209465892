import { IServices, IConfig } from '@did/types'
import { useEffect, useState, useCallback } from 'react'

export const useConfigVal = (params: { services: IServices }) => {
  const { services } = params
  const [config, setConfig] = useState<IConfig>({} as any)

  const getConfig = useCallback(async () => {
    try {
      const result = await services.common?.config()
      setConfig(result)
    } catch (e: any) {
      console.log(e)
    }
  }, [services])

  useEffect(() => {
    getConfig()
  }, [])

  return {
    ...config,
    min_change_capacity: config.min_change_capacity
      ? config.min_change_capacity / Math.pow(10, 8)
      : 120
  }
}
