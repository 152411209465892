import {
  useDasAppConfigContext,
  useDasBalanceContext
} from '@did/das-app-context'
import { InvitationLinkPageType } from '@did/das-app-types/module'
import img from '@did/das-assets/images/me/invitation-link-bg-en.png'
import { QRCode } from '@did/uikit'
import React, { useEffect, useMemo, useState } from 'react'

function svgToDataURL(svg: any) {
  var xml = new XMLSerializer().serializeToString(svg)
  var svg64 = btoa(unescape(encodeURIComponent(xml)))
  var b64Start = 'data:image/svg+xml;base64,'
  var image64 = b64Start + svg64
  return image64
}
export const InvitationLinkPage: InvitationLinkPageType = ({ bitName }) => {
  const { tt, services, router } = useDasBalanceContext()

  const config = useDasAppConfigContext()

  const [inviteLinkImgBase64, setInviteLinkImgBase64] = useState<string>('')

  const { inviterDiscount, inviterProfitRate } = useMemo(() => {
    if (!config) return { inviterDiscount: 0, inviterProfitRate: 0 }

    return {
      inviterDiscount: (Number(config?.inviter_discount) || 0.05) * 100,
      inviterProfitRate: (Number(config?.profit_rate_of_inviter) || 0.1) * 100
    }
  }, [config])

  useEffect(() => {
    if (!bitName) return

    const bgImg = new Image()
    bgImg.crossOrigin = 'anonymous'

    bgImg.onload = () => {
      const myCanvas = document.getElementById('myCanvas') as HTMLCanvasElement
      const ctx = myCanvas.getContext('2d') as CanvasRenderingContext2D
      ctx.drawImage(bgImg, 0, 0, 750, 845)
      const qrcodeSvg = document.querySelector(
        '#qrcode>div>svg'
      ) as HTMLCanvasElement
      const qrcodeBase64 = svgToDataURL(qrcodeSvg)
      const qrcodeImg = new Image()
      qrcodeImg.onload = () => {
        ctx.drawImage(qrcodeImg, 540, 580, 130, 130)
        const _account = bitName
        if (_account.length > 14) {
          ctx.font = 'bold 60px Arial'
          ctx.fillStyle = '#22C493'
          ctx.fillText(_account, 75, 465)
        } else if (_account.length > 10) {
          ctx.font = 'bold 80px Arial'
          ctx.fillStyle = '#22C493'
          ctx.fillText(_account, 75, 475)
        } else {
          ctx.font = 'bold 106px Arial'
          ctx.fillStyle = '#22C493'
          ctx.fillText(_account, 75, 475)
        }
        setInviteLinkImgBase64(myCanvas.toDataURL('image/jpeg', 1.0))
      }
      qrcodeImg.src = qrcodeBase64
    }
    bgImg.src = img.src
  }, [bitName])

  return (
    <div className="max-w-5xl mx-auto z-0 relative">
      <div className="mx-auto w-full py-12 px-3">
        <div className=" font-bold text-2xl text-center">
          <div>{tt('Refer Friends,')}</div>
          <div>{tt('Earn & Save Crypto')}</div>
        </div>
        <div className=" text-sm font-bold mb-9 mt-4  text-center">
          {tt(
            'Invite friends to register .bit, they will save {inviterDiscount}%, and you will earn {inviterProfitRate}% reward.',
            {
              inviterDiscount,
              inviterProfitRate
            }
          )}
        </div>
        <canvas id="myCanvas" width="750" height="845" className=" hidden" />
        {inviteLinkImgBase64 && (
          <img src={inviteLinkImgBase64} className=" w-[532px] mx-auto" />
        )}
      </div>
      <div id="qrcode" className=" hidden">
        <QRCode
          value={`${new URL(window?.location?.href).origin}/bit/r?i=${bitName}`}
        />
      </div>
    </div>
  )
}
