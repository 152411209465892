import { Iconfont } from '@did/uikit'
import { useDasBalanceContext } from '@did/das-app-context'
import { BannerTipType } from '@did/das-app-types/components'
import {
  BannerTipVariant,
  BannerTipVariantType
} from '@did/das-app-types/module'
import { cn } from '@did/tools'

const variantClasses: Record<BannerTipVariantType, string> = {
  [BannerTipVariant.processing]:
    'bg-[#FFEDD3] rounded-xl font-semibold text-[#E9862D]',
  [BannerTipVariant.rgb]:
    'bg-[#D6EFE7] rounded-xl font-semibold text-emerald-400'
}

export const BannerTip: BannerTipType = ({
  title,
  to,
  number,
  icon,
  variant = BannerTipVariant.processing
}) => {
  const { router } = useDasBalanceContext()

  const onClick = () => {
    if (typeof to === 'string') {
      const routePath = to.split('?')
      const queryStr = routePath[1]
      const queryObj: any = {}
      if (queryStr) {
        queryStr?.split('&')?.map((v) => {
          const keyValues = v.split('=')
          queryObj[keyValues[0]] = keyValues[1]
        })
        router.push(routePath[0], queryObj)
      } else {
        router.push(routePath[0])
      }
    } else if (typeof to === 'function') {
      to?.()
    } else {
      router.push(to.path, to.query)
    }
  }

  return (
    <div
      className={cn(
        'w-full shrink-0 px-4 flex justify-between items-center h-10 cursor-pointer text-[14px]',
        variantClasses[variant]
      )}
      onClick={onClick}
    >
      <div className="flex items-center">
        {icon}
        {title}
      </div>
      <div className="flex items-center">
        {typeof number !== 'undefined' && (
          <span className="font-semibold mr-[-8px] mt-[1px]">{number}</span>
        )}
        <Iconfont className="ml-[6px]" name="arrow-right" size="16" />
      </div>
    </div>
  )
}
