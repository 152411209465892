import React, { useState } from 'react'
import { InputCounterType } from '@did/das-app-types/components'
import { useDasBalanceContext } from '@did/das-app-context'
import { cn } from '@did/tools'

const MIN_VALUE = 1
const MAX_VALUE = 20

export const InputCounter: InputCounterType = ({
  disabled,
  value,
  onChange,
  onClick
}) => {
  const { tt } = useDasBalanceContext()
  const [isCustom, setIsCustom] = useState(false)
  const [customValue, setCustomValue] = useState<string | number>('')

  const list = [
    {
      value: 1,
      unit: tt('year')
    },
    {
      value: 3,
      unit: tt('years')
    },
    {
      value: 5,
      unit: tt('years')
    }
  ]

  return (
    <div className="inline-flex gap-x-2">
      {list.map((item) => {
        return (
          <span
            key={item.value}
            className={cn(
              'w-[68px] inline-flex text-xl font-bold justify-center leading-none bg-white rounded-xl shadow-[0_1px_3px_0_rgba(43,54,77,0.06)] hover:border-2 hover:border-zinc-800 hover:py-[6px]',
              value === item.value && !isCustom
                ? 'text-zinc-800 border-2 border-zinc-800 py-[6px]'
                : 'text-neutral-400 border border-neutral-300 py-[7px]',
              disabled ? 'cursor-no-drop' : 'cursor-pointer'
            )}
            onClick={() => {
              onClick?.()
              if (disabled) {
                return
              }
              setCustomValue('')
              setIsCustom(false)
              onChange?.(Number(item.value))
            }}
          >
            {item.value}
            <span className="inline-flex text-sm items-center font-medium ml-1">
              {item.unit}
            </span>
          </span>
        )
      })}
      <input
        className={cn(
          'h-10 w-[80px] text-xl inline-flex leading-none bg-white rounded-xl shadow-[0_1px_3px_0_rgba(43,54,77,0.06)] outline-0 text-center appearance-none placeholder:font-medium placeholder:text-neutral-400 focus:placeholder:text-neutral-400/50 hover:border-2 hover:border-zinc-800',
          isCustom
            ? 'text-zinc-800 border-2 border-zinc-800'
            : 'text-neutral-400 border border-neutral-300',
          disabled ? 'cursor-no-drop' : 'cursor-text'
        )}
        placeholder={tt('Custom')}
        value={customValue}
        type="number"
        inputMode="numeric"
        pattern="[0-9]*"
        min={MIN_VALUE}
        max={MAX_VALUE}
        onChange={(e) => {
          if (disabled) {
            return
          }
          setCustomValue(
            e.target.value ? parseInt(e.target.value) : e.target.value
          )
          onChange?.(
            e.target.value ? parseInt(e.target.value) : Number(e.target.value)
          )
        }}
        onBlur={() => {
          if (value > MAX_VALUE) {
            setCustomValue(MAX_VALUE)
            onChange?.(MAX_VALUE)
          } else if (value < MIN_VALUE) {
            setCustomValue(MIN_VALUE)
            onChange?.(MIN_VALUE)
          }
          if (value && !customValue) {
            setIsCustom(false)
          }
        }}
        onClick={() => {
          setIsCustom(true)
          onClick?.()
        }}
      />
    </div>
  )
}
