import { useRegisterStatus } from '@did/das-app-hooks'
import { useRegisterAuctionStatus } from '@did/das-app-hooks/src/use-register-auction-status'
import {
  ACCOUNT_STATUS_TIPS_TYPE,
  AuctionStatusPageType
} from '@did/das-app-types/module'
import { Iconfont } from '@did/uikit'
import { useMemo } from 'react'
import { RegisterFailed } from './register-failed'
import { RegisteredInfo } from './registered-info'
import { RegisteringInfo } from './registering-info'
import { ACCOUNT_STATUS } from '@did/types'
export const AuctionStatusPage: AuctionStatusPageType = ({ bitName, hash }) => {
  const { accountInfo, goHome, goMe, onRegisterAnother } = useRegisterStatus({
    bitName,
    paid: true,
    polling: false,
    noAlert: true
  })

  const { tipStatus } = useRegisterAuctionStatus({
    bitName,
    hash
  })

  const { statusContent, loading, bgType } = useMemo(() => {
    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registering && accountInfo) {
      return {
        statusContent: (
          <RegisteringInfo
            isAuction
            accountInfo={{
              ...accountInfo,
              status: ACCOUNT_STATUS.onDutchAuction,
              register_tx_map: [{}, {}, { hash: hash }] as any
            }}
            onRegisterAnother={onRegisterAnother}
            goMe={goMe}
          />
        ),
        loading: false,
        bgType: 'blue'
      }
    }

    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registered) {
      return {
        statusContent: (
          <RegisteredInfo bitName={bitName} goHome={goHome} goMe={goMe} />
        ),
        bgType: 'orange',
        loading: false
      }
    }

    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registerFailed) {
      return {
        statusContent: (
          <RegisterFailed isAuction bitName={bitName} goHome={goHome} />
        ),
        loading: false,
        bgType: 'red'
      }
    }
    return {
      statusContent: (
        <div className="w-full flex justify-center">
          <Iconfont className=" animate-spin" color="#B0B8BF" name="loading" />
        </div>
      ),
      loading: true
    }
  }, [bitName, accountInfo, tipStatus])

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 pt-5 md:px-0 flex flex-col items-center">
      {statusContent}
    </div>
  )
}
