import {
  IAccountInfoRes,
  IAccountParsingRecord,
  IBaseProps,
  IBasePropsWithSlots,
  ICallback,
  IRecyclableListRes,
  ISearchAccountRes,
  IToken,
  IUpgradableListRes,
  ParsingRecordType,
  REVERSE_TX_STATUS
} from '@did/types'

export interface ITransStatusProps extends IBaseProps, ICallback<any, any> {
  account: string
  coinType: string
  address: string
  showing?: boolean
  onClose?: () => void
  handleTxStatus?: (status: REVERSE_TX_STATUS | undefined) => void
  actions: Array<'update' | 'remove'>
}

export type TransStatusType = React.FC<ITransStatusProps>

export interface IErrorTipsProps extends IBaseProps {
  showIncorrect?: boolean
  accountName?: string
  onCheckPass?: (isError: boolean) => void
  errorCode?: number
  accountStatus: number | undefined
}

export type ErrorTipsType = React.FC<IErrorTipsProps>

export interface IAccountStatusProps extends IBaseProps {
  accountInfo?: Pick<
    IAccountInfoRes,
    'account' | 'status' | 're_register_time'
  > & {
    register_tx_map?: any
    is_self?: boolean
  }
  searchResult?: ISearchAccountRes
  couponCode?: string
}

export type AccountStatusType = React.FC<IAccountStatusProps>

export enum ACCOUNT_STATUS_TIPS_TYPE {
  registeringPaymentConfirm = 'registeringPaymentConfirm',
  registering = 'registering',
  registered = 'registered',
  registerFailed = 'registerFailed'
}

export interface IStatusPageProps extends IBaseProps {
  bitName: string
  paid?: boolean
  isAuction?: boolean
}
export type StatusPageType = React.FC<IStatusPageProps>

export interface IAuctionStatusPageProps extends IBaseProps {
  bitName: string
  hash: string
}
export type AuctionStatusPageType = React.FC<IAuctionStatusPageProps>

export interface IRegisteringPaymentConfirmProps extends IBaseProps {
  bitName: string
  onRegisterAgain?: () => void
  onPaid?: () => void
}
export type RegisteringPaymentConfirmType =
  React.FC<IRegisteringPaymentConfirmProps>

export interface IRegisteringInfoProps extends IBaseProps {
  accountInfo: ISearchAccountRes
  onRegisterAnother?: () => void
  goMe?: () => void
  isAuction?: boolean
}
export type RegisteringInfoType = React.FC<IRegisteringInfoProps>

export interface IRegisteredInfoProps extends IBaseProps {
  bitName: string
  goMe?: () => void
  goHome?: () => void
}
export type RegisteredInfoType = React.FC<IRegisteredInfoProps>

export interface IRegisterFailedProps extends IBaseProps {
  bitName: string
  goHome?: () => void
  isAuction?: boolean
}
export type RegisterFailedType = React.FC<IRegisterFailedProps>

export interface IRegisterPageProps extends IBaseProps {
  bitName: string
  couponCode?: string
  inviter?: string
  channel?: string
}
export type RegisterPageType = React.FC<IRegisterPageProps>

export interface IDataPageProps extends IBaseProps {
  bitName: string
}
export type DataPageType = React.FC<IDataPageProps>

export enum SAVE_CODE_TYPE {
  INVITER = 'inviter',
  BUILDER_CODE = 'builderCode'
}
export interface ISaveCodeItemProps extends IBaseProps {
  saveFee?: number
  saveFeeLoading?: boolean
  inviter?: string
  builderCode?: string
  inviterErrorMessages?: string[]
  builderCodeErrorMessages?: string[]
  onInviterChange?: (inviter: string) => void
  onBuilderCodeChange?: (builderCode: string) => void
  onInviterBlur?: (inviter: string) => Promise<void>
  onBuilderCodeBlur?: (builderCode: string) => Promise<void>
  onTypeChange?: (type: SAVE_CODE_TYPE) => void
  inviterDiscount?: number
}

export type SaveCodeItemType = React.FC<ISaveCodeItemProps>

export interface IUpdatedRegistrationPeriodDialogProps extends IBaseProps {
  showing: boolean
  onClose: () => void
  paidAmount: string
  onContinue: () => void
}

export type UpdatedRegistrationPeriodDialogType =
  React.FC<IUpdatedRegistrationPeriodDialogProps>

export interface IDasBalanceInsufficientDialogProps extends IBaseProps {
  registrationFees: string
  showing?: boolean
  onClose?: () => void
  selectToken?: IToken
}

export type DasBalanceInsufficientDialogType =
  React.FC<IDasBalanceInsufficientDialogProps>

export interface ISignatureErrorDialogProps extends IBaseProps {
  showing?: boolean
  onClose?: () => void
}

export type SignatureErrorDialogType = React.FC<ISignatureErrorDialogProps>

export interface ITokenInfo extends IToken {
  logoSvg?: React.ReactNode
}

export interface IPaymentConfirmProps extends IBaseProps {
  tokenList: ITokenInfo[]
  onTokenChange?: (token: ITokenInfo) => void
  selectedToken?: ITokenInfo
  fiatTermsLink?: string
  stripeServiceFee?: string
  stripePaidAmount?: string
  paidTokenAmount?: string
  showAll?: boolean
  handleConfirm?: () => Promise<any>
  confirmRegisterLoading?: boolean
}

export type PaymentConfirmType = React.FC<IPaymentConfirmProps>

export interface IRegisterInfoProps extends IBasePropsWithSlots {
  bitName: string
  annualFee: number
  totalCost: number
  storageDeposit: number
  registrationPeriod?: number
  couponCode?: string
  paidAmount: number
  handleRegister?: () => void
  registerLoading?: boolean
  onRegisterPeriodChange?: (registrationPeriod: number) => void
  additionInfo?: React.ReactNode
  registrationPeriodInfo?: React.ReactNode
  slots?: {
    dashedLine: React.FC<{ index: number }>
  }
}

export type RegisterInfoType = React.FC<IRegisterInfoProps>

export interface IInvitationLinkPageProps extends IBaseProps {
  bitName: string
}
export type InvitationLinkPageType = React.FC<IInvitationLinkPageProps>

export interface IExplorerPageProps extends IBaseProps {
  bitName?: string
}
export type ExplorerPageType = React.FC<IExplorerPageProps>

export interface IDutchAuctionProps extends IBaseProps {
  expiredTime: number // unix timestamp
  currentPremium?: number
  totalDays: number
  gracePeriod: number
  bitName: string
}

export type DutchAuctionType = React.FC<IDutchAuctionProps>

export interface IDutchAuctionPageProps extends IBaseProps {
  bitName: string
}

export type DutchAuctionPageType = React.FC<IDutchAuctionPageProps>

export interface IReservePageProps extends IBaseProps {
  bitName?: string
}
export type ReservePageType = React.FC<IReservePageProps>

export interface IAccountRecommendListProps extends IBaseProps {
  account: string
  setSearchValue?: (value: any) => void
  handleInputChange?: (value: any) => void
  handleSearch?: (value: any) => void
}

export type AccountRecommendListType = React.FC<IAccountRecommendListProps>

export interface IRenewalProps extends IBaseProps {
  showing: boolean
  accountInfo: IAccountInfoRes
  onClose: () => void
}

export type RenewalType = React.FC<IRenewalProps>

export interface IManagePermissionProps extends IBaseProps {
  showing: boolean
  accountInfo: IAccountInfoRes
  editManager?: boolean
  exitEditMode: (notClear?: boolean) => void
  onClose: () => void
}

export type ManagePermissionType = React.FC<IManagePermissionProps>

export interface IParsingRecordsProps extends IBaseProps {
  editMode: boolean
  accountInfo: IAccountInfoRes
  accountRecords: IAccountParsingRecord[]
  loading: Boolean
  exitEditMode: (notClear?: boolean) => void
  enableEditMode: () => void
  onSettings: (value: string) => void
}

export type ParsingRecordsType = React.FC<IParsingRecordsProps>

export interface IRecordListProps extends IBaseProps {
  title: React.ReactNode
  editMode: boolean
  recordType: ParsingRecordType
  records: IAccountParsingRecord[]
  loading: Boolean
  accountInfo: IAccountInfoRes
  notAdd?: boolean
  showAction?: boolean
  onEdit?: (record: IAccountParsingRecord) => void
  onDelete?: (record: IAccountParsingRecord) => void
  addNew?: (type?: string, key?: any) => void
}

export type RecordListType = React.FC<IRecordListProps>

export interface IActionButtonsProps extends IBaseProps {
  onEdit: () => void
  onDelete: () => void
}

export type ActionButtonsType = React.FC<IActionButtonsProps>

export interface IEditParsingRecordProps extends IBaseProps {
  showing: boolean
  addNew?: boolean
  record: IAccountParsingRecord
  onSave: (record: IAccountParsingRecord) => void
  onClose: () => void
}

export type EditParsingRecordType = React.FC<IEditParsingRecordProps>

export interface IConfirmChangesProps extends IBaseProps {
  showing: boolean
  addressParsingRecords: IAccountParsingRecord[]
  profileParsingRecords: IAccountParsingRecord[]
  dwebParsingRecords: IAccountParsingRecord[]
  customParsingRecords: IAccountParsingRecord[]
  onConfirm: () => void
  onClose: () => void
}

export type ConfirmChangesType = React.FC<IConfirmChangesProps>

export interface IConfirmChangesItemProps extends IBaseProps {
  logo: string
  label: string
  value: string
  action: string | undefined
}

export type ConfirmChangesItemType = React.FC<IConfirmChangesItemProps>

export interface IActionLabelProps extends IBaseProps {
  action: string | undefined
}

export type ActionLabelType = React.FC<IActionLabelProps>

export interface IExitEditModeTipsProps extends IBaseProps {
  showing: boolean
  onClose: () => void
  onExit: () => void
  onSave: () => void
}

export type ExitEditModeTipsType = React.FC<IExitEditModeTipsProps>

export interface IRecordDetailsProps extends IBaseProps {
  showing: boolean
  recordDetails: IAccountParsingRecord
  onClose: () => void
}

export type RecordDetailsType = React.FC<IRecordDetailsProps>

export interface IConfirmToRemoveDialogProps extends IBaseProps {
  showing: boolean
  aliasName: string
  isOldAlias: boolean
  deleteOldAlias: () => void
  deleteAlias: () => void
  onClose: () => void
}

export type ConfirmToRemoveDialogType = React.FC<IConfirmToRemoveDialogProps>

export enum BannerTipVariant {
  processing = 'processing',
  rgb = 'rgb'
}

export type BannerTipVariantType = BannerTipVariant | 'processing' | 'rgb'

export interface ISwitchNoticeProps extends IBaseProps {
  showing: boolean
  onClose: () => void
}

export type SwitchNoticeType = React.FC<ISwitchNoticeProps>

export interface IUpgradeDialogProps extends IBaseProps {
  showing: boolean
  accountInfo: IUpgradableListRes
  updateUpgradeableList: (accountInfo: IUpgradableListRes) => void
  onClose: () => void
}

export type UpgradeDialogType = React.FC<IUpgradeDialogProps>

export interface IDobRenewalDialogProps extends IBaseProps {
  showing: boolean
  renewalPeriod: number
  accountInfo: IAccountInfoRes
  setTxHash: (txHash: string) => void
  setTxHashLink: (txHashLink: string) => void
  setRenewalSubmittedShowing: (showing: boolean) => void
  onClose: () => void
}

export type DobRenewalDialogType = React.FC<IDobRenewalDialogProps>

export interface IRecycleDialogProps extends IBaseProps {
  showing: boolean
  accountInfo: IRecyclableListRes
  updateRecyclableList: (accountInfo: IRecyclableListRes) => void
  onClose: () => void
}

export type RecycleDialogType = React.FC<IRecycleDialogProps>
