import { CYCLE_CALL_FUNCTION_TIME } from '@did/constants'
import { useDasBalanceContext } from '@did/das-app-context'
import { ACCOUNT_STATUS_TIPS_TYPE } from '@did/das-app-types/module'
import {
  AUCTION_PARAM_TYPE,
  AUCTION_STATUS,
  IAuctionOrderStatusRes
} from '@did/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ErrorInfo } from '@did/monitoring'

export const useRegisterAuctionStatus = ({
  bitName,
  hash
}: {
  bitName: string
  hash: string
}) => {
  const { services, connectedAccount, alert, tt, router, outSiteUrl } =
    useDasBalanceContext()

  const [orderStatusInfo, setOrderStatusInfo] =
    useState<IAuctionOrderStatusRes>()

  const isChecking = useRef(false)
  const timerId = useRef<any>()

  const clearCheckAccountStatus = () => {
    clearInterval(timerId.current)
  }

  const getOrderInfo = async () => {
    if (!bitName || isChecking.current || !hash || !connectedAccount?.address)
      return
    isChecking.current = true
    try {
      const res = await services.account.auctionOrderStatus({
        type: AUCTION_PARAM_TYPE.BLOCKCHAIN,
        account: bitName,
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        hash
      })
      setOrderStatusInfo(res)
      if (res.status !== AUCTION_STATUS.PENDING) {
        clearCheckAccountStatus()
      }
    } catch (err: any) {
      ErrorInfo.error(err)
    } finally {
      isChecking.current = false
    }
  }

  useEffect(() => {
    if (!bitName || !connectedAccount?.address) return
    getOrderInfo()

    timerId.current = setInterval(() => {
      getOrderInfo()
    }, CYCLE_CALL_FUNCTION_TIME * 2)

    return () => {
      clearInterval(timerId.current)
    }
  }, [bitName, connectedAccount?.address])

  const tipStatus: ACCOUNT_STATUS_TIPS_TYPE = useMemo(() => {
    if (orderStatusInfo?.status === AUCTION_STATUS.PENDING) {
      return ACCOUNT_STATUS_TIPS_TYPE.registering
    }

    if (orderStatusInfo?.status === AUCTION_STATUS.SUCCESS) {
      return ACCOUNT_STATUS_TIPS_TYPE.registered
    }

    if (orderStatusInfo?.status === AUCTION_STATUS.REJECTED) {
      return ACCOUNT_STATUS_TIPS_TYPE.registerFailed
    }
    return ACCOUNT_STATUS_TIPS_TYPE.registering
  }, [orderStatusInfo?.status])

  return {
    orderStatusInfo,
    tipStatus
  }
}
