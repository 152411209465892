import {
  useDasBalanceContext,
  useDasAppConfigContext,
  useCccContext
} from '@did/das-app-context'
import { ParsingRecordsType } from '@did/das-app-types/module'
import {
  cn,
  addressValidate,
  findParsingRecordChain,
  stringMaxLengthValidate,
  positiveIntegersValidate,
  profileValueValidate,
  objectKeyValidate
} from '@did/tools'
import { DasButton } from '@did/uikit'
import { useEffect, useState, useMemo, useCallback } from 'react'
import styles from './styles.module.scss'
import {
  CKB,
  CoinTypeToChainMap,
  PARSING_RECORD_SUPPORT_CHAINS
} from '@did/constants/chain'
import errno from '@did/constants/errno'
import {
  DATA_ACTIONS,
  IAccountParsingRecord,
  ORDER_ACTION_TYPE,
  ParsingRecordType,
  SignTxListParams
} from '@did/types'
import { Tab } from '../../me/tab'
import {
  DATA_TABS,
  DWEB_KEY_OPTIONS,
  PARSING_RECORD_MAX_LIMIT,
  PROFILE_KEY_OPTIONS,
  SETTINGS
} from '@did/das-app-constants'
import { RecordList } from './record-list'
import { EditParsingRecord } from '../edit-parsing-record/edit-parsing-record'
import { ConfirmChanges } from '../confirm-changes/confirm-changes'
import { SUB_ACCOUNT_ACTIONS, SUB_ACCOUNT_REG_EXP } from '@did/constants'
import { TransStatusDialog } from '@did/das-balance-components'
import { ExitEditModeTips } from '../exit-edit-mode-tips/exit-edit-mode-tips'
import { useIsManager, useIsOwner } from '@did/das-app-hooks'
import { handleError } from '@did/das-app-utils'

let urlQueryParsed = false

export const ParsingRecords: ParsingRecordsType = ({
  editMode,
  accountInfo,
  accountRecords,
  loading,
  exitEditMode,
  enableEditMode,
  onSettings
}) => {
  const { tt, connectedAccount, services, walletSdk, alert, isProd, router } =
    useDasBalanceContext()
  const { ccc, ckbAddress, isDobsMode } = useCccContext()
  const signer = ccc?.useSigner()
  const cccConnector = ccc?.useCcc()
  const isManager = useIsManager(accountInfo)
  const isOwner = useIsOwner(accountInfo)
  const config = useDasAppConfigContext()
  const [txHash, setTxHash] = useState('')
  const [txHashLink, setTxHashLink] = useState('')
  const [actions, setActions] = useState<ORDER_ACTION_TYPE[]>([])
  const [action, setAction] = useState('')
  const [subAccountAction, setSubAccountAction] = useState('')
  const [editRecord, setEditRecord] = useState<IAccountParsingRecord>(
    {} as IAccountParsingRecord
  )
  const [submittedDialogShowing, setSubmittedDialogShowing] = useState(false)
  const [exitEditModeTipsShowing, setExitEditModeTipsShowing] = useState(false)
  const [confirmChangesShowing, setConfirmChangesShowing] = useState(false)
  const [saveChangesLoading, setSaveChangesLoading] = useState(false)
  const [editParsingRecordShowing, setEditParsingRecordShowing] =
    useState(false)
  const [addNew, setAddNew] = useState(false)
  const [profileParsingRecords, setProfileParsingRecords] = useState<
    IAccountParsingRecord[]
  >([])
  const [addressParsingRecords, setAddressParsingRecords] = useState<
    IAccountParsingRecord[]
  >([])
  const [dwebParsingRecords, setDwebParsingRecords] = useState<
    IAccountParsingRecord[]
  >([])
  const [customParsingRecords, setCustomParsingRecords] = useState<
    IAccountParsingRecord[]
  >([])
  const [currentTab, setCurrentTab] = useState<string>(
    (router.query?.tab as string) || DATA_TABS.data
  )

  const isSubAccount = useMemo(
    (): boolean => SUB_ACCOUNT_REG_EXP.test(accountInfo?.account),
    [accountInfo?.account]
  )

  const editRecordsThrottle = useMemo(() => {
    const value = config.edit_records_throttle
    if (value) {
      return parseInt(String(value / 60))
    }
    return 5
  }, [])

  const saveChanges = () => {
    setExitEditModeTipsShowing(false)
    setConfirmChangesShowing(true)
  }

  const onTabChange = useCallback(
    (value: string) => {
      setCurrentTab(value)
      router.replace?.({
        query: {
          ...router.query,
          tab: value,
          action: ''
        }
      })
    },
    [router]
  )

  const urlQueryParser = () => {
    const action = router.query?.action
    if (action === 'add-address') {
      addNewRecord(ParsingRecordType.address)
      return
    }

    if (!isManager) {
      return
    }

    try {
      const recordsString = router.query?.records
      if (recordsString) {
        const records = JSON.parse(recordsString as string)
        if (records && records.length > 0) {
          enableEditMode()
          const deleteList: any[] = []
          const addList: any[] = []
          records.forEach((item: any) => {
            const action = item.action
            const keyParts = item.key.split('.') // address.966
            const type = keyParts[0]
            const key = keyParts[1]
            item.type = type
            if (type === ParsingRecordType.address) {
              item.key = findParsingRecordChain(key).coinType
            } else {
              item.key = key
            }

            if (action === DATA_ACTIONS.delete) {
              deleteList.push(item)
            } else if (action === DATA_ACTIONS.add) {
              addList.push(item)
            } else if (action === DATA_ACTIONS.change) {
              deleteList.push(item)
              addList.push(item)
            }
          })

          deleteList.forEach((item: any) => {
            const type = item.type
            const key = item.key

            if (type === ParsingRecordType.address) {
              addressParsingRecords.forEach((record, index) => {
                if (record.key === key) {
                  record.action = DATA_ACTIONS.delete
                }
              })
            } else if (type === ParsingRecordType.profile) {
              profileParsingRecords.forEach((record, index) => {
                if (record.key === key) {
                  record.action = DATA_ACTIONS.delete
                }
              })
            } else if (type === ParsingRecordType.dweb) {
              dwebParsingRecords.forEach((record, index) => {
                if (record.key === key) {
                  record.action = DATA_ACTIONS.delete
                }
              })
            } else if (type === ParsingRecordType.customKey) {
              customParsingRecords.forEach((record, index) => {
                if (record.key === key) {
                  record.action = DATA_ACTIONS.delete
                }
              })
            }
          })

          addList.forEach(async (item: any) => {
            const type = item.type
            const key = item.key
            const value = item.value
            const label = item.label
            const ttl = item.ttl
            if (type === ParsingRecordType.address) {
              const chainValue = PARSING_RECORD_SUPPORT_CHAINS.filter(
                (chain) => {
                  return chain.coinType === key
                }
              )
              if (chainValue) {
                const valueVerified = addressValidate(
                  value,
                  CoinTypeToChainMap[key].symbol,
                  isProd
                )
                const labelVerified = stringMaxLengthValidate(label || '', 64)
                const ttlVerified = positiveIntegersValidate(ttl || 1)
                if (valueVerified && labelVerified && ttlVerified) {
                  setAddressParsingRecords([
                    ...addressParsingRecords,
                    {
                      ...item,
                      action: DATA_ACTIONS.add
                    }
                  ])
                }
              }
            } else if (type === ParsingRecordType.profile) {
              const optionValue = PROFILE_KEY_OPTIONS.filter((option) => {
                return option.value === key
              })
              if (optionValue) {
                const valueVerified =
                  stringMaxLengthValidate(value, 1024) &&
                  profileValueValidate(value, key)
                const labelVerified = stringMaxLengthValidate(label || '', 64)
                const ttlVerified = positiveIntegersValidate(ttl || 1)
                if (valueVerified && labelVerified && ttlVerified) {
                  setProfileParsingRecords([
                    ...profileParsingRecords,
                    {
                      ...item,
                      action: DATA_ACTIONS.add
                    }
                  ])
                }
              }
            } else if (type === ParsingRecordType.dweb) {
              const optionValue = DWEB_KEY_OPTIONS.filter((option) => {
                return option.value === key
              })
              if (optionValue) {
                const valueVerified = stringMaxLengthValidate(value, 1024)
                const labelVerified = stringMaxLengthValidate(label || '', 64)
                const ttlVerified = positiveIntegersValidate(ttl || 1)
                if (valueVerified && labelVerified && ttlVerified) {
                  setDwebParsingRecords([
                    ...dwebParsingRecords,
                    {
                      ...item,
                      action: DATA_ACTIONS.add
                    }
                  ])
                }
              }
            } else if (type === ParsingRecordType.customKey) {
              const keyVerified =
                stringMaxLengthValidate(key, 255) && objectKeyValidate(key)
              const valueVerified = stringMaxLengthValidate(value, 1024)
              const labelVerified = stringMaxLengthValidate(label || '', 1024)
              const ttlVerified = positiveIntegersValidate(ttl || 1)
              if (
                keyVerified &&
                valueVerified &&
                labelVerified &&
                ttlVerified
              ) {
                setCustomParsingRecords([
                  ...customParsingRecords,
                  {
                    ...item,
                    action: DATA_ACTIONS.add
                  }
                ])
              }
            }
          })
          saveChanges()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const addNewRecord = (type?: string, key?: any) => {
    if (saveChangesLoading) {
      return
    }

    if (!editMode) {
      onSettings(SETTINGS.manageData)
    }

    if (!isManager) {
      return
    }

    if (!type) {
      return
    }

    const _total =
      addressParsingRecords.length +
      profileParsingRecords.length +
      customParsingRecords.length +
      dwebParsingRecords.length
    if (_total >= PARSING_RECORD_MAX_LIMIT) {
      alert({
        title: tt('Tips'),
        message: tt(
          'You cannot add new parsing records, and the number of parsing records cannot exceed {num}',
          { num: PARSING_RECORD_MAX_LIMIT }
        )
      })
    } else {
      setEditRecord({
        type: type,
        key: key
      } as IAccountParsingRecord)
      setAddNew(true)
      setEditParsingRecordShowing(true)
    }
  }

  const deleteParsingRecord = (record: IAccountParsingRecord) => {
    if (saveChangesLoading) {
      return
    }
    if (record.action === DATA_ACTIONS.add) {
      if (record.type === ParsingRecordType.address) {
        const _addressParsingRecords = [...addressParsingRecords]
        _addressParsingRecords.forEach((item, index) => {
          if (item === record) {
            _addressParsingRecords.splice(index, 1)
          }
        })
        setAddressParsingRecords(_addressParsingRecords)
      } else if (record.type === ParsingRecordType.profile) {
        const _profileParsingRecords = [...profileParsingRecords]
        _profileParsingRecords.forEach((item, index) => {
          if (item === record) {
            _profileParsingRecords.splice(index, 1)
          }
        })
        setProfileParsingRecords(_profileParsingRecords)
      } else if (record.type === ParsingRecordType.dweb) {
        const _dwebParsingRecords = [...dwebParsingRecords]
        _dwebParsingRecords.forEach((item, index) => {
          if (item === record) {
            _dwebParsingRecords.splice(index, 1)
          }
        })
        setDwebParsingRecords(_dwebParsingRecords)
      } else if (record.type === ParsingRecordType.customKey) {
        const _customParsingRecords = [...customParsingRecords]
        _customParsingRecords.forEach((item, index) => {
          if (item === record) {
            _customParsingRecords.splice(index, 1)
          }
        })
        setCustomParsingRecords(_customParsingRecords)
      }
      return
    }

    if (record.type === ParsingRecordType.address) {
      const _addressParsingRecords = [...addressParsingRecords]
      _addressParsingRecords.forEach((item, index) => {
        if (item === record) {
          item.action = DATA_ACTIONS.delete
        }
      })
      setAddressParsingRecords(_addressParsingRecords)
    } else if (record.type === ParsingRecordType.profile) {
      const _profileParsingRecords = [...profileParsingRecords]
      _profileParsingRecords.forEach((item, index) => {
        if (item === record) {
          item.action = DATA_ACTIONS.delete
        }
      })
      setProfileParsingRecords(_profileParsingRecords)
    } else if (record.type === ParsingRecordType.dweb) {
      const _dwebParsingRecords = [...dwebParsingRecords]
      _dwebParsingRecords.forEach((item, index) => {
        if (item === record) {
          item.action = DATA_ACTIONS.delete
        }
      })
      setDwebParsingRecords(_dwebParsingRecords)
    } else if (record.type === ParsingRecordType.customKey) {
      const _customParsingRecords = [...customParsingRecords]
      _customParsingRecords.forEach((item, index) => {
        if (item === record) {
          item.action = DATA_ACTIONS.delete
        }
      })
      setCustomParsingRecords(_customParsingRecords)
    }
  }

  const saveNewParsingRecord = (record: IAccountParsingRecord) => {
    if (record.type === ParsingRecordType.address) {
      setAddressParsingRecords([...addressParsingRecords, record])
    } else if (record.type === ParsingRecordType.profile) {
      setProfileParsingRecords([...profileParsingRecords, record])
    } else if (record.type === ParsingRecordType.dweb) {
      setDwebParsingRecords([...dwebParsingRecords, record])
    } else if (record.type === ParsingRecordType.customKey) {
      setCustomParsingRecords([...customParsingRecords, record])
    }
  }

  const saveModifyParsingRecord = (record: IAccountParsingRecord) => {
    if (record.type === ParsingRecordType.address) {
      const _addressParsingRecords = [...addressParsingRecords]
      _addressParsingRecords.forEach((item, index) => {
        if (item === editRecord) {
          if (item.action === DATA_ACTIONS.add) {
            Object.assign(item, record, { action: DATA_ACTIONS.add })
          } else {
            Object.assign(item, record)
          }
        }
      })
      setAddressParsingRecords(_addressParsingRecords)
    } else if (record.type === ParsingRecordType.profile) {
      const _profileParsingRecords = [...profileParsingRecords]
      _profileParsingRecords.forEach((item, index) => {
        if (item === editRecord) {
          if (item.action === DATA_ACTIONS.add) {
            Object.assign(item, record, { action: DATA_ACTIONS.add })
          } else {
            Object.assign(item, record)
          }
        }
      })
      setProfileParsingRecords(_profileParsingRecords)
    } else if (record.type === ParsingRecordType.dweb) {
      const _dwebParsingRecords = [...dwebParsingRecords]
      _dwebParsingRecords.forEach((item, index) => {
        if (item === editRecord) {
          if (item.action === DATA_ACTIONS.add) {
            Object.assign(item, record, { action: DATA_ACTIONS.add })
          } else {
            Object.assign(item, record)
          }
        }
      })
      setDwebParsingRecords(_dwebParsingRecords)
    } else if (record.type === ParsingRecordType.customKey) {
      const _customParsingRecords = [...customParsingRecords]
      _customParsingRecords.forEach((item, index) => {
        if (item === editRecord) {
          if (item.action === DATA_ACTIONS.add) {
            Object.assign(item, record, { action: DATA_ACTIONS.add })
          } else {
            Object.assign(item, record)
          }
        }
      })
      setCustomParsingRecords(_customParsingRecords)
    }
  }

  const saveRecord = (record: IAccountParsingRecord) => {
    if (addNew) {
      saveNewParsingRecord(record)
    } else {
      saveModifyParsingRecord(record)
    }
  }

  const operationStatistics = useMemo(() => {
    const statistics = {
      new: 0,
      delete: 0,
      edit: 0
    }
    const list = [
      ...addressParsingRecords,
      ...profileParsingRecords,
      ...dwebParsingRecords,
      ...customParsingRecords
    ]
    list.forEach((record) => {
      if (record.action === DATA_ACTIONS.add) {
        statistics.new += 1
      } else if (record.action === DATA_ACTIONS.delete) {
        statistics.delete += 1
      } else if (record.action === DATA_ACTIONS.change) {
        statistics.edit += 1
      }
    })
    return statistics
  }, [
    addressParsingRecords,
    profileParsingRecords,
    dwebParsingRecords,
    customParsingRecords
  ])

  const parsingRecordsIsChanged = useMemo(() => {
    return (
      operationStatistics.new +
        operationStatistics.delete +
        operationStatistics.edit >
      0
    )
  }, [operationStatistics])

  const recordsGroups = () => {
    const _addressParsingRecords: IAccountParsingRecord[] = []
    const _profileParsingRecords: IAccountParsingRecord[] = []
    const _dwebParsingRecords: IAccountParsingRecord[] = []
    const _customParsingRecords: IAccountParsingRecord[] = []
    accountRecords.forEach((record: IAccountParsingRecord) => {
      record = {
        ...record,
        action: ''
      }
      if (record.type === ParsingRecordType.address) {
        _addressParsingRecords.push(record)
      } else if (record.type === ParsingRecordType.profile) {
        _profileParsingRecords.push(record)
      } else if (record.type === ParsingRecordType.dweb) {
        _dwebParsingRecords.push(record)
      } else if (record.type === ParsingRecordType.customKey) {
        _customParsingRecords.push(record)
      }
    })
    setAddressParsingRecords(_addressParsingRecords)
    setProfileParsingRecords(_profileParsingRecords)
    setDwebParsingRecords(_dwebParsingRecords)
    setCustomParsingRecords(_customParsingRecords)
  }

  const showExitEditModeTips = () => {
    if (parsingRecordsIsChanged) {
      setExitEditModeTipsShowing(true)
    } else {
      exitEditMode()
    }
  }

  const modifyParsingRecord = (record: IAccountParsingRecord) => {
    if (saveChangesLoading) {
      return
    }
    setEditRecord(record)
    setAddNew(false)
    setEditParsingRecordShowing(true)
  }

  const taskNotCompleted = () => {
    alert({
      title: tt('Failed'),
      message: (
        <>
          <div>
            {tt(
              'The current account cannot be edited. Please try again in 5 minutes. Possible reasons:'
            )}
          </div>
          <div
            className={cn(styles['parsing-records_task-not-completed_item'])}
          >
            <span>1.</span>
            <span>
              {tt(
                'Your operations are too frequent. The last transaction is still pending;'
              )}
            </span>
          </div>
          <div
            className={cn(styles['parsing-records_task-not-completed_item'])}
          >
            <span>2.</span>
            <span>
              {tt('Currently, the parent account is minting some new SubDIDs.')}
            </span>
          </div>
          <a
            className={cn(styles['parsing-records_task-not-completed_faq'])}
            target="_blank"
            href="https://talk.did.id/t/bit-subdids-are-live/435#what-are-subdids-1"
          >
            {tt('Learn more')}
          </a>
        </>
      )
    })
  }

  const onConfirmChanges = async () => {
    if (
      !isDobsMode &&
      (!connectedAccount?.chain?.coinType || !connectedAccount?.address)
    ) {
      return
    }

    if (isDobsMode && !ckbAddress) {
      return
    }

    setConfirmChangesShowing(false)
    setTxHash('')
    setTxHashLink('')
    setActions([])
    setAction('')
    setSubAccountAction('')
    setSaveChangesLoading(true)
    const _addressParsingRecords = addressParsingRecords.filter(
      (record: IAccountParsingRecord) => {
        return record.action !== DATA_ACTIONS.delete
      }
    )
    const _profileParsingRecords = profileParsingRecords.filter(
      (record: IAccountParsingRecord) => {
        return record.action !== DATA_ACTIONS.delete
      }
    )
    const _dwebParsingRecords = dwebParsingRecords.filter(
      (record: IAccountParsingRecord) => {
        return record.action !== DATA_ACTIONS.delete
      }
    )
    const _customParsingRecords = customParsingRecords.filter(
      (record: IAccountParsingRecord) => {
        return record.action !== DATA_ACTIONS.delete
      }
    )

    const { signTxList, onFailed } =
      !isDobsMode && (await walletSdk.initSignContext())

    try {
      let orderInfo: SignTxListParams
      if (isSubAccount) {
        orderInfo = await services.subAccount.editSubAccount({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          account: accountInfo.account,
          edit_key: SUB_ACCOUNT_ACTIONS.records,
          edit_value: {
            records: [
              ..._addressParsingRecords,
              ..._profileParsingRecords,
              ..._dwebParsingRecords,
              ..._customParsingRecords
            ]
          }
        })
        setAction(SUB_ACCOUNT_ACTIONS.update_sub_account)
        setSubAccountAction(SUB_ACCOUNT_ACTIONS.sub_action_edit)
      } else {
        orderInfo = await services.account.editRecords({
          key_info: {
            coin_type: isDobsMode
              ? CKB.coinType
              : connectedAccount?.chain?.coinType!,
            key: isDobsMode ? ckbAddress : connectedAccount?.address!
          },
          account: accountInfo.account,
          raw_param: {
            records: [
              ..._addressParsingRecords,
              ..._profileParsingRecords,
              ..._dwebParsingRecords,
              ..._customParsingRecords
            ]
          }
        })
        setActions([ORDER_ACTION_TYPE.EDIT_RECORDS])
      }

      if (!orderInfo) {
        setSaveChangesLoading(false)
        return
      }

      if (orderInfo.ckb_tx) {
        if (!signer?.signTransaction) {
          setSaveChangesLoading(false)
          return
        }
        // @ts-ignore
        const { cccA } = await import('@ckb-ccc/connector-react/advanced')
        const ckbTx = cccA.JsonRpcTransformers.transactionTo(
          JSON.parse(orderInfo.ckb_tx)
        )
        let ckbSignature = await signer?.signTransaction(ckbTx)
        ckbSignature = cccA.JsonRpcTransformers.transactionFrom(ckbSignature)
        const { hash } = await services.account.sendTrx({
          ...orderInfo,
          ckb_tx: JSON.stringify(ckbSignature)
        })
        setTxHash(hash)
        setTxHashLink(`${CKB.getExplorerTrx(isProd)}${hash}`)
      } else {
        const signatureList = await signTxList(orderInfo)
        if (isSubAccount) {
          await services.subAccount.sendTransaction(signatureList)
        } else {
          const { hash } = await services.account.sendTrx(signatureList)
          setTxHash(hash)
          setTxHashLink(`${CKB.getExplorerTrx(isProd)}${hash}`)
        }
      }

      setSubmittedDialogShowing(true)
      exitEditMode(true)
    } catch (err: any) {
      onFailed?.()

      if (err.code === errno.rpcApiErrAccountFrequencyLimit) {
        alert({
          title: tt('Tips'),
          message: tt(
            'The operation is too frequent. Please try again after {timeInterval} minutes',
            { timeInterval: editRecordsThrottle }
          )
        })
      } else if (err.code === errno.apiErrorRecordDoing) {
        taskNotCompleted()
      } else {
        handleError(err, tt, alert)
      }
    } finally {
      setSaveChangesLoading(false)
    }
  }

  useEffect(
    () => setCurrentTab((router.query?.tab as string) || DATA_TABS.data),
    [router.query?.tab]
  )

  useEffect(() => {
    if (
      (router?.query?.records || router?.query?.action) &&
      walletSdk &&
      cccConnector &&
      accountInfo?.status &&
      !urlQueryParsed
    ) {
      urlQueryParsed = true
      urlQueryParser()
    }
  }, [
    router?.query?.records,
    router?.query?.action,
    walletSdk,
    cccConnector,
    accountInfo?.status
  ])

  useEffect(() => {
    window.onbeforeunload = () => {
      if (parsingRecordsIsChanged) {
        return tt('Changes you made may not be saved.')
      }
    }
  }, [])

  useEffect(() => {
    if (accountRecords) {
      recordsGroups()
    }
  }, [accountRecords])

  return (
    <div className="w-full flex flex-col gap-5 p-6 bg-white rounded-[32px] border border-slate-300/40">
      <Tab
        value={currentTab}
        onChange={onTabChange}
        items={[
          {
            text: tt('Data'),
            value: DATA_TABS.data
          },
          {
            text: tt('Permissions'),
            value: DATA_TABS.permissions
          }
        ]}
      />
      {currentTab === DATA_TABS.data && (
        <>
          <RecordList
            title={tt('Addresses ({total})', {
              total: addressParsingRecords.length
            })}
            editMode={editMode}
            recordType={ParsingRecordType.address}
            records={addressParsingRecords}
            loading={loading}
            accountInfo={accountInfo}
            showAction={isManager}
            addNew={addNewRecord}
            onDelete={deleteParsingRecord}
            onEdit={modifyParsingRecord}
          />
          <RecordList
            title={tt('Profile ({total})', {
              total: profileParsingRecords.length
            })}
            editMode={editMode}
            recordType={ParsingRecordType.profile}
            records={profileParsingRecords}
            loading={loading}
            accountInfo={accountInfo}
            showAction={isManager}
            addNew={addNewRecord}
            onDelete={deleteParsingRecord}
            onEdit={modifyParsingRecord}
          />
          <RecordList
            title={tt('Custom ({total})', {
              total: customParsingRecords.length
            })}
            editMode={editMode}
            recordType={ParsingRecordType.customKey}
            records={customParsingRecords}
            loading={loading}
            accountInfo={accountInfo}
            showAction={isManager}
            addNew={addNewRecord}
            onDelete={deleteParsingRecord}
            onEdit={modifyParsingRecord}
          />
          <RecordList
            title={tt('DWeb ({total})', { total: dwebParsingRecords.length })}
            editMode={editMode}
            recordType={ParsingRecordType.dweb}
            records={dwebParsingRecords}
            loading={loading}
            accountInfo={accountInfo}
            showAction={isManager}
            addNew={addNewRecord}
            onDelete={deleteParsingRecord}
            onEdit={modifyParsingRecord}
          />
          <div
            className={cn(
              styles['parse-record_bottom-tips'],
              editMode
                ? styles['parse-record-bottom-tips-enter-active']
                : styles['parse-record-bottom-tips-leave-active'],
              editMode ? 'block' : 'hidden',
              'bg-slate-50/80 border-t border-slate-600/10 backdrop-blur-[20px]'
            )}
          >
            <div
              className={cn(
                styles['parse-record_bottom-tips_container'],
                'w-full md:w-[680px] px-0 py-4 md:px-4 md:py-9 flex-col md:flex-row'
              )}
            >
              <DasButton
                status="dark"
                className={cn(
                  '!hidden md:!inline-flex',
                  styles['parse-record_top-tips_exit']
                )}
                onClick={showExitEditModeTips}
              >
                {tt('Exit')}
              </DasButton>
              <div
                className={cn(styles['parse-record_bottom-tips_statistics'])}
              >
                <span className={cn(styles['parse-record_bottom-tips_item'])}>
                  {tt('New: ')}
                  <span
                    className={cn(
                      styles['parse-record_bottom-tips_item_value']
                    )}
                  >
                    {operationStatistics.new}
                  </span>
                </span>
                <span className={cn(styles['parse-record_bottom-tips_item'])}>
                  {tt('Delete: ')}
                  <span
                    className={cn(
                      styles['parse-record_bottom-tips_item_value']
                    )}
                  >
                    {operationStatistics.delete}
                  </span>
                </span>
                <span className={cn(styles['parse-record_bottom-tips_item'])}>
                  {tt('Edit: ')}
                  <span
                    className={cn(
                      styles['parse-record_bottom-tips_item_value']
                    )}
                  >
                    {operationStatistics.edit}
                  </span>
                </span>
              </div>
              <DasButton
                className={cn(
                  '!hidden md:!inline-flex',
                  styles['parse-record_bottom-tips_button']
                )}
                black
                isLoadingGradient={false}
                loading={saveChangesLoading}
                disabled={!parsingRecordsIsChanged}
                onClick={saveChanges}
              >
                {tt('Save')}
              </DasButton>
              <div className={cn('md:hidden flex flex-row gap-x-8 mt-3')}>
                <DasButton
                  className={cn(styles['parse-record_top-tips_exit'])}
                  status="dark"
                  onClick={showExitEditModeTips}
                >
                  {tt('Exit')}
                </DasButton>
                <DasButton
                  className={cn(styles['parse-record_bottom-tips_button'])}
                  black
                  isLoadingGradient={false}
                  loading={saveChangesLoading}
                  disabled={!parsingRecordsIsChanged}
                  onClick={saveChanges}
                >
                  {tt('Save')}
                </DasButton>
              </div>
            </div>
          </div>
        </>
      )}
      {currentTab === DATA_TABS.permissions && (
        <>
          <RecordList
            title={tt('Owner')}
            editMode={editMode}
            recordType={ParsingRecordType.owner}
            records={[
              {
                key: accountInfo.owner_coin_type,
                value: accountInfo.owner,
                label: isOwner ? tt('Me') : '',
                type: ParsingRecordType.owner,
                ttl: '0'
              }
            ]}
            loading={loading}
            accountInfo={accountInfo}
            notAdd={true}
            showAction={isOwner}
            addNew={() => {
              onSettings(SETTINGS.changeOwner)
            }}
          />
          {accountInfo.manager && (
            <RecordList
              title={tt('Manager')}
              editMode={editMode}
              recordType={ParsingRecordType.manager}
              records={[
                {
                  key: accountInfo.manager_coin_type,
                  value: accountInfo.manager,
                  label: isManager ? tt('Me') : '',
                  type: ParsingRecordType.manager,
                  ttl: '0'
                }
              ]}
              loading={loading}
              accountInfo={accountInfo}
              notAdd={true}
              showAction={isOwner}
              addNew={() => {
                onSettings(SETTINGS.changeManage)
              }}
            />
          )}
        </>
      )}
      <EditParsingRecord
        showing={editParsingRecordShowing}
        addNew={addNew}
        record={editRecord}
        onSave={saveRecord}
        onClose={() => setEditParsingRecordShowing(false)}
      />
      <ConfirmChanges
        showing={confirmChangesShowing}
        addressParsingRecords={addressParsingRecords}
        profileParsingRecords={profileParsingRecords}
        dwebParsingRecords={dwebParsingRecords}
        customParsingRecords={customParsingRecords}
        onConfirm={onConfirmChanges}
        onClose={() => setConfirmChangesShowing(false)}
      />
      <TransStatusDialog
        txHash={txHash}
        txHashLink={txHashLink}
        showing={submittedDialogShowing}
        actions={actions}
        account={accountInfo?.account}
        action={action}
        subAccountAction={subAccountAction}
        onClose={() => {
          setSubmittedDialogShowing(false)
          exitEditMode()
        }}
      />
      <ExitEditModeTips
        showing={exitEditModeTipsShowing}
        onExit={() => {
          setExitEditModeTipsShowing(false)
          exitEditMode()
        }}
        onSave={saveChanges}
        onClose={() => {
          setExitEditModeTipsShowing(false)
        }}
      />
    </div>
  )
}
