import { useDasBalanceContext } from '@did/das-app-context'
import { LinkType } from '@did/das-balance-types'

export const Link: LinkType = ({ children, to, className }) => {
  const { router } = useDasBalanceContext()

  const handleClick = () => {
    if (!to) return
    router.push(to)
  }

  return (
    <a className={` cursor-pointer  ${className}`} onClick={handleClick}>
      {children}
    </a>
  )
}
