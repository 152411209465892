import {
  useDasBalanceContext,
  useDasAppConfigContext,
  useCccContext
} from '@did/das-app-context'
import { ManagePermissionType } from '@did/das-app-types/module'
import { cn, addressValidate, sleep, smartOpen } from '@did/tools'
import { CheckBox, DasButton, Dialog, Iconfont } from '@did/uikit'
import React, { useEffect, useState, useMemo } from 'react'
import styles from './styles.module.scss'
import {
  BSC,
  CKB,
  CoinType,
  CoinTypeToChainMap,
  DOGE,
  ETH,
  EvmCoinTypes,
  Polygon,
  TRON
} from '@did/constants/chain'
import errno from '@did/constants/errno'
import { DataSelect, WarningAlert } from '@did/das-app-components'
import { AccountInput, TransStatusDialog } from '@did/das-balance-components'
import { useForm } from 'react-hook-form'
import { IEditOwnerRes, ORDER_ACTION_TYPE } from '@did/types'
import { SUB_ACCOUNT_ACTIONS, SUB_ACCOUNT_REG_EXP } from '@did/constants'
import { handleError } from '@did/das-app-utils'

interface IFormValues {
  coin_type: string
  address: string
}

export const ManagePermission: ManagePermissionType = ({
  showing,
  accountInfo,
  editManager,
  exitEditMode,
  onClose
}) => {
  const { tt, connectedAccount, services, walletSdk, alert, isProd } =
    useDasBalanceContext()
  const { ccc, ckbAddress, isDobsMode } = useCccContext()
  const signer = ccc?.useSigner()
  const config = useDasAppConfigContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
    reset,
    watch
  } = useForm<IFormValues>({
    mode: 'all',
    defaultValues: {
      coin_type: '',
      address: ''
    }
  })
  const [watchAddress, watchCoinType] = watch(['address', 'coin_type'])
  const [txHash, setTxHash] = useState('')
  const [actions, setActions] = useState<ORDER_ACTION_TYPE[]>([])
  const [action, setAction] = useState('')
  const [subAccountAction, setSubAccountAction] = useState('')
  const [rawParam, setRawParam] = useState({
    coin_type: '',
    address: ''
  })
  const [txHashLink, setTxHashLink] = useState('')
  const [modifyConfirmShowing, setModifyConfirmShowing] = useState(false)
  const [modifySecondConfirmation, setModifySecondConfirmation] =
    useState(false)
  const [modifyConfirmLoading, setModifyConfirmLoading] = useState(false)
  const [submittedDialogShowing, setSubmittedDialogShowing] = useState(false)
  const [oldOmigaCkbAddressTipShowing, setOldOmigaCkbAddressTipShowing] =
    useState(false)

  const chainOptions = useMemo(() => {
    return isDobsMode
      ? [
          {
            text: 'Nervos',
            value: CoinType.ckb,
            icon: 'nervos'
          }
        ]
      : [
          {
            text: ETH.name,
            value: CoinType.eth,
            icon: ETH.icon
          },
          {
            text: BSC.name,
            value: CoinType.bsc,
            icon: BSC.icon
          },
          {
            text: TRON.name,
            value: CoinType.trx,
            icon: TRON.icon
          },
          {
            text: Polygon.name,
            value: CoinType.pol,
            icon: Polygon.icon
          },
          {
            text: DOGE.name,
            value: CoinType.doge,
            icon: DOGE.icon
          },
          {
            text: CKB.name,
            value: CoinType.ckb,
            icon: CKB.icon
          }
        ]
  }, [isDobsMode])

  const transferThrottle = useMemo(() => {
    const value = config.transfer_throttle
    if (value) {
      return parseInt(String(value / 60))
    }
    return 5
  }, [])

  const isSubAccount = useMemo(() => {
    return SUB_ACCOUNT_REG_EXP.test(accountInfo?.account)
  }, [accountInfo?.account])

  const modifyNext = async (param: IFormValues) => {
    setRawParam(param)
    onClose()
    // await sleep(1000)
    setModifyConfirmShowing(true)
  }

  const modifyConfirm = async () => {
    if (
      !isDobsMode &&
      (!connectedAccount?.chain?.coinType || !connectedAccount?.address)
    ) {
      return
    }

    if (isDobsMode && !ckbAddress) {
      return
    }

    setTxHash('')
    setTxHashLink('')
    setActions([])
    setAction('')
    setSubAccountAction('')

    setModifyConfirmLoading(true)
    const { signTxList, onFailed } =
      !isDobsMode && (await walletSdk.initSignContext())
    const _newCoinType = EvmCoinTypes.includes(rawParam.coin_type as CoinType)
      ? CoinType.eth
      : rawParam.coin_type

    try {
      let orderInfo: IEditOwnerRes
      if (isSubAccount) {
        orderInfo = await services.subAccount.editSubAccount({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          account: accountInfo.account,
          edit_key: editManager
            ? SUB_ACCOUNT_ACTIONS.manager
            : SUB_ACCOUNT_ACTIONS.owner,
          edit_value: editManager
            ? {
                manager: {
                  type: 'blockchain',
                  key_info: {
                    coin_type: _newCoinType,
                    key: rawParam.address
                  }
                }
              }
            : {
                owner: {
                  type: 'blockchain',
                  key_info: {
                    coin_type: _newCoinType,
                    key: rawParam.address
                  }
                }
              }
        })
        setAction(SUB_ACCOUNT_ACTIONS.update_sub_account)
        setSubAccountAction(SUB_ACCOUNT_ACTIONS.sub_action_edit)
      } else {
        if (editManager) {
          orderInfo = await services.account.editManager({
            key_info: {
              coin_type: connectedAccount?.chain?.coinType!,
              key: connectedAccount?.address!
            },
            evm_chain_id: connectedAccount?.computedChainId!,
            account: accountInfo.account,
            raw_param: {
              manager_coin_type: _newCoinType,
              manager_address: rawParam.address
            }
          })
          setActions([ORDER_ACTION_TYPE.EDIT_MANAGER])
        } else {
          orderInfo = await services.account.editOwner({
            key_info: {
              coin_type: isDobsMode
                ? CKB.coinType
                : connectedAccount?.chain?.coinType!,
              key: isDobsMode ? ckbAddress : connectedAccount?.address!
            },
            account: accountInfo.account,
            raw_param: {
              receiver_coin_type: _newCoinType,
              receiver_address: rawParam.address
            }
          })
          setActions([ORDER_ACTION_TYPE.TRANSFER_ACCOUNT])
        }
      }

      if (!orderInfo) {
        setModifyConfirmLoading(false)
        return
      }

      if (orderInfo.ckb_tx) {
        if (!signer?.signTransaction) {
          setModifyConfirmLoading(false)
          return
        }
        // @ts-ignore
        const { cccA } = await import('@ckb-ccc/connector-react/advanced')
        const ckbTx = cccA.JsonRpcTransformers.transactionTo(
          JSON.parse(orderInfo.ckb_tx)
        )
        let ckbSignature = await signer?.signTransaction(ckbTx)
        ckbSignature = cccA.JsonRpcTransformers.transactionFrom(ckbSignature)
        const { hash } = await services.account.sendTrx({
          ...orderInfo,
          ckb_tx: JSON.stringify(ckbSignature)
        })
        setTxHash(hash)
        setTxHashLink(`${CKB.getExplorerTrx(isProd)}${hash}`)
      } else {
        const signatureList = await signTxList(orderInfo)
        if (isSubAccount) {
          await services.subAccount.sendTransaction(signatureList)
        } else {
          const { hash } = await services.account.sendTrx(signatureList)
          setTxHash(hash)
          setTxHashLink(`${CKB.getExplorerTrx(isProd)}${hash}`)
        }
      }

      setModifyConfirmShowing(false)
      setModifySecondConfirmation(false)
      setSubmittedDialogShowing(true)
      reset()
    } catch (err: any) {
      onFailed?.()

      if (err.code === errno.rpcApiErrAccountFrequencyLimit) {
        alert({
          title: tt('Tips'),
          message: tt(
            'The operation is too frequent. Please try again after {timeInterval} minutes',
            { timeInterval: transferThrottle }
          )
        })
      } else if (
        err.code === errno.apiErrorCodeInvalidTargetAddress &&
        _newCoinType === CKB.coinType
      ) {
        alert({
          title: tt('Tips'),
          message: isDobsMode
            ? tt('Please enter a CCC compatible address.')
            : tt(
                'Unsupported CKB addresses. Use the CKB address for the d.id Passkey login.'
              )
        })
      } else {
        handleError(err, tt, alert)
      }
    } finally {
      setModifyConfirmLoading(false)
    }
  }

  useEffect(() => {
    register('address', {
      validate: (val, formVal) => {
        if (
          addressValidate(
            val,
            CoinTypeToChainMap[formVal.coin_type].symbol,
            isProd
          )
        ) {
          return true
        }
        return tt('{chain} address format is wrong', {
          chain: CoinTypeToChainMap[formVal.coin_type].symbol
        })
      }
    })
  }, [])

  useEffect(() => {
    if (!isDobsMode || !signer?.client || !watchAddress || !watchCoinType) {
      setOldOmigaCkbAddressTipShowing(false)
      return
    }
    const validated = addressValidate(
      watchAddress,
      CoinTypeToChainMap[watchCoinType].symbol,
      isProd
    )
    if (validated) {
      ;(async () => {
        try {
          const script = await ccc.Address.fromString(
            watchAddress,
            signer?.client
          )
          if (script?.prefix === 'ckt') {
            setOldOmigaCkbAddressTipShowing(
              script?.script?.codeHash ===
                '0xf329effd1c475a2978453c8600e1eaf0bc2087ee093c3ee64cc96ec6847752cb' &&
                script?.script?.args?.startsWith('0x01')
            )
          } else {
            setOldOmigaCkbAddressTipShowing(
              script?.script?.codeHash ===
                '0x9b819793a64463aed77c615d6cb226eea5487ccfc0783043a587254cda2b6f26' &&
                script?.script?.args?.startsWith('0x01')
            )
          }
        } catch (err) {
          setOldOmigaCkbAddressTipShowing(false)
        }
      })()
    } else {
      setOldOmigaCkbAddressTipShowing(false)
    }
  }, [watchAddress, watchCoinType, isProd, isDobsMode, signer?.client])

  return (
    <>
      <Dialog
        showing={showing}
        title={editManager ? tt('Modify Manager') : tt('Changing Ownership')}
        closeButton
        onClose={onClose}
      >
        <form onSubmit={handleSubmit(modifyNext)}>
          {!editManager && (
            <WarningAlert className="mb-8">
              {tt(
                'You’ll lose all control and data of this .bit. Proceed with caution.'
              )}
            </WarningAlert>
          )}
          <label
            className={cn(styles['manage-permission-bottom-sheet__label'])}
          >
            {tt('From {chain} address', {
              chain:
                CoinTypeToChainMap[
                  editManager
                    ? accountInfo.manager_coin_type
                    : accountInfo.owner_coin_type
                ]?.symbol
            })}
          </label>
          {(accountInfo.owner_coin_type || accountInfo.manager_coin_type) && (
            <a
              className={cn(
                styles['manage-permission-bottom-sheet__link'],
                'font-mono'
              )}
              href={
                CoinTypeToChainMap[
                  editManager
                    ? accountInfo.manager_coin_type
                    : accountInfo.owner_coin_type
                ] &&
                `${CoinTypeToChainMap[
                  editManager
                    ? accountInfo.manager_coin_type
                    : accountInfo.owner_coin_type
                ].getExplorerAddress(isProd)}${accountInfo.owner}`
              }
              target="_blank"
            >
              {editManager ? accountInfo.manager : accountInfo.owner}
            </a>
          )}
          <label
            className={cn(
              styles['manage-permission-bottom-sheet__label'],
              styles['manage-permission-bottom-sheet__margin-top-32']
            )}
          >
            {tt('Change to')}
          </label>
          <DataSelect
            defaultValue={getValues('coin_type')}
            options={chainOptions}
            disabled={isDobsMode}
            errorMessages={[errors?.coin_type?.message || ''].filter(Boolean)}
            onChange={(val) => {
              setValue('coin_type', val as string)
              clearErrors()
            }}
          />
          <label
            className={cn(
              styles['manage-permission-bottom-sheet__label'],
              styles['manage-permission-bottom-sheet__margin-top-32']
            )}
          >
            {tt('Address')}
          </label>
          {isDobsMode && (
            <div className="text-neutral-400 text-xs font-normal mb-1">
              {tt(
                'Only support CKB addresses created in DOBs compatible mode.'
              )}
              <span
                onClick={() => {
                  smartOpen('https://docs.spore.pro/dob0/Introduction')
                }}
                className="text-neutral-400 font-medium underline cursor-pointer hover:opacity-70"
              >
                {' '}
                {tt('Learn more')}
              </span>
            </div>
          )}
          <AccountInput
            chain={CoinTypeToChainMap[getValues('coin_type')]}
            name="Address"
            placeholder={tt('.bit account or {symbol} address', {
              symbol: CoinTypeToChainMap[getValues('coin_type')]?.symbol
            })}
            errorMessages={[errors?.address?.message || ''].filter(Boolean)}
            onChange={(val) => {
              setValue('address', val.address)
              clearErrors()
            }}
          />
          {oldOmigaCkbAddressTipShowing && (
            <div className="text-xs text-amber-500 mt-1 font-semibold text-left">
              {tt(
                'This address cannot be recognized. You are transferring your .bit to other website. Please make sure the receiver can manage such address, or the asset will be lost.'
              )}
            </div>
          )}
          <div className="py-8">
            <DasButton type="submit" black block>
              {tt('Next')}
            </DasButton>
          </div>
        </form>
      </Dialog>
      <Dialog
        showing={modifyConfirmShowing}
        title={editManager ? tt('Modify Manager') : tt('Changing Ownership')}
        closeButton
        onClose={() => {
          setModifyConfirmShowing(false)
          setModifySecondConfirmation(false)
        }}
      >
        {editManager ? (
          <WarningAlert>
            {tt(
              'After the change, the management of this account will be transferred to someone else. You will still have Owner rights.'
            )}
          </WarningAlert>
        ) : (
          <WarningAlert>
            {tt(
              'After the modification, the ownership of {account1} is completely transferred to someone else and you will lose control of {account2} permanently.',
              { account1: accountInfo?.account, account2: accountInfo?.account }
            )}
          </WarningAlert>
        )}
        <div className={styles['manage-permission-bottom-sheet__confirm']}>
          <label
            className={cn(
              styles['manage-permission-bottom-sheet__label'],
              styles['manage-permission-bottom-sheet__margin-top-16']
            )}
          >
            {tt('From {chain} address', {
              chain:
                CoinTypeToChainMap[
                  editManager
                    ? accountInfo.manager_coin_type
                    : accountInfo.owner_coin_type
                ]?.symbol
            })}
          </label>
          {(accountInfo.owner_coin_type || accountInfo.manager_coin_type) && (
            <a
              className={cn(
                styles['manage-permission-bottom-sheet__link'],
                'font-mono'
              )}
              href={
                CoinTypeToChainMap[
                  editManager
                    ? accountInfo.manager_coin_type
                    : accountInfo.owner_coin_type
                ] &&
                `${CoinTypeToChainMap[
                  editManager
                    ? accountInfo.manager_coin_type
                    : accountInfo.owner_coin_type
                ].getExplorerAddress(isProd)}${accountInfo.owner}`
              }
              target="_blank"
            >
              {editManager ? accountInfo.manager : accountInfo.owner}
            </a>
          )}
          <label
            className={cn(
              styles['manage-permission-bottom-sheet__label'],
              'mt-5'
            )}
          >
            {tt('Change to {chain} address', {
              chain:
                getValues('coin_type') &&
                CoinTypeToChainMap[getValues('coin_type')]?.symbol
            })}
          </label>
          {getValues('coin_type') && (
            <a
              className={cn(
                styles['manage-permission-bottom-sheet__link'],
                'font-mono'
              )}
              href={
                CoinTypeToChainMap[getValues('coin_type')] &&
                `${CoinTypeToChainMap[
                  getValues('coin_type')
                ].getExplorerAddress(isProd)}${getValues('address')}`
              }
              target="_blank"
            >
              {getValues('address')}
            </a>
          )}
        </div>
        <div
          className={
            styles['manage-permission-bottom-sheet__second-confirmation']
          }
        >
          <CheckBox
            checked={modifySecondConfirmation}
            onChange={setModifySecondConfirmation}
            className="mt-4"
          >
            {tt('I am aware of the risks')}
          </CheckBox>
        </div>
        <div className="pb-8">
          <DasButton
            loading={modifyConfirmLoading}
            disabled={!modifySecondConfirmation}
            isLoadingGradient={false}
            type="submit"
            block
            black
            onClick={modifyConfirm}
          >
            {tt('Confirm')}
          </DasButton>
        </div>
      </Dialog>
      <TransStatusDialog
        txHash={txHash}
        txHashLink={txHashLink}
        showing={submittedDialogShowing}
        actions={actions}
        account={accountInfo?.account}
        action={action}
        subAccountAction={subAccountAction}
        onClose={() => {
          setSubmittedDialogShowing(false)
          exitEditMode()
        }}
      />
    </>
  )
}
