import { ACCOUNT_SUFFIX } from '@did/constants'
import errno from '@did/constants/errno'
import {
  useDasAppConfigContext,
  useDasBalanceContext
} from '@did/das-app-context'
import { ISaveCodeItemProps, SAVE_CODE_TYPE } from '@did/das-app-types/module'
import {
  ACCOUNT_STATUS,
  COUPON_STATUS,
  COUPON_TYPE,
  ISearchAccountRes
} from '@did/types'
import Decimal from 'decimal.js'
import { useEffect, useMemo, useState } from 'react'
import { ErrorInfo } from '@did/monitoring'
import { toLowerCase } from '@did/tools'

export const useSaveCodeItem = (param: {
  accountInfo?: ISearchAccountRes
  totalCost: Decimal
  annualFee: Decimal
  couponCode?: string
  inviter?: string
}) => {
  const {
    accountInfo,
    totalCost,
    annualFee,
    couponCode: propsCouponCode,
    inviter: propsInviter
  } = param
  const [saveFeeLoading, setSaveFeeLoading] = useState<boolean>(false)
  const [inviter, setInviter] = useState<string>('')
  const [builderCode, setBuilderCode] = useState<string>('')
  const [inviteCheckResult, setInviteCheckResult] = useState<boolean>(false)
  const [builderCodeCheckResult, setBuilderCodeCheckResult] =
    useState<boolean>(false)
  const [inviterErrorMessages, setInviterErrorMessages] = useState<string[]>()
  const [builderCodeErrorMessages, setBuilderCodeErrorMessages] =
    useState<string[]>()
  const [type, setType] = useState<SAVE_CODE_TYPE>(SAVE_CODE_TYPE.INVITER)
  const { services, tt, router } = useDasBalanceContext()
  const config = useDasAppConfigContext()

  useEffect(() => {
    if (!propsCouponCode) {
      return
    }
    setBuilderCode(propsCouponCode)
    onBuilderCodeBlur(propsCouponCode)
  }, [propsCouponCode])

  useEffect(() => {
    if (!propsInviter) {
      return
    }
    setInviter(propsInviter)
    onInviterBlur(propsInviter)
  }, [propsInviter])

  const formatInviterStr = (str: string) => {
    return (
      str
        ?.replace(/\s+/g, '')
        .toLowerCase()
        .replace(/\.bit$/, '') + ACCOUNT_SUFFIX
    )
  }

  const formatBuilderCodeStr = (str: string) => {
    if (!str || typeof str !== 'string') {
      return str
    }
    return str?.replace(/\s+/g, '').toUpperCase()
  }

  const checkInviter = async (inviterVal: string) => {
    setInviterErrorMessages([])
    if (!inviterVal) {
      return false
    }

    inviterVal = formatInviterStr(inviterVal)

    try {
      setSaveFeeLoading(true)
      const res = await services.account.accountInfo(inviterVal)

      if (
        ![
          ACCOUNT_STATUS.registered,
          ACCOUNT_STATUS.onePriceSell,
          ACCOUNT_STATUS.upgradedAccount
        ].includes(res.status)
      ) {
        setInviterErrorMessages((msg) => {
          return [tt('The account does not exist.'), ...(msg || [])]
        })
        return false
      }
      return true
    } catch (err: any) {
      if (err.code === errno.rpcApiErrAccountNotExist) {
        setInviterErrorMessages((msg) => {
          return [tt('The account does not exist.'), ...(msg || [])]
        })
      } else {
        ErrorInfo.error(err)
        setInviterErrorMessages((msg) => {
          return [
            err.code ? `${err.code}: ${err.message}` : err,
            ...(msg || [])
          ]
        })
      }
      return false
    } finally {
      setSaveFeeLoading(false)
    }
  }

  const checkCouponCode = async (couponCode: string) => {
    if (!couponCode) {
      setBuilderCodeErrorMessages([])
      return false
    }

    if (couponCode.length !== 8) {
      setBuilderCodeErrorMessages((msg) => {
        return [tt('Please enter a valid Builder Code.'), ...(msg || [])]
      })
      return false
    }

    try {
      setSaveFeeLoading(true)

      const res = await services.account.couponCheck(couponCode)
      if (res.status === COUPON_STATUS.nonExistent) {
        setBuilderCodeErrorMessages((msg) => {
          return [tt('Please enter a valid Builder Code.'), ...(msg || [])]
        })
        return false
      } else if (res.status === COUPON_STATUS.used) {
        setBuilderCodeErrorMessages((msg) => {
          return [
            tt('This Builder Code has been redeemed. Please try another one.'),
            ...(msg || [])
          ]
        })
        return false
      } else if (res.status === COUPON_STATUS.notStarted) {
        setBuilderCodeErrorMessages((msg) => {
          return [tt('Please enter a valid Builder Code.'), ...(msg || [])]
        })
        return false
      } else if (
        res.type === COUPON_TYPE.fourChars &&
        accountInfo?.account.length !== 8
      ) {
        setBuilderCodeErrorMessages((msg) => {
          return [
            tt(
              'Your Builder Code only supports the registration of a 4 character .bit account. Please choose a 4 character .bit account and try again.'
            ),
            ...(msg || [])
          ]
        })
        return false
      } else if (
        res.type === COUPON_TYPE.fiveCharsAndAbove &&
        Number(accountInfo?.account?.length) < 9
      ) {
        setBuilderCodeErrorMessages((msg) => {
          return [
            tt(
              'Your Builder Code only supports the registration of a 5+ character .bit account. Please choose a 5+ character .bit account and try again.'
            ),
            ...(msg || [])
          ]
        })
        return false
      } else {
        setBuilderCodeErrorMessages([])
        return true
      }
    } catch (e: any) {
      ErrorInfo.error(e)
      setBuilderCodeErrorMessages((msg: any) => {
        return [e.code ? `${e.code}: ${e.message}` : e, ...(msg || [])]
      })
      return false
    } finally {
      setSaveFeeLoading(false)
    }
  }

  const onInviterChange = (inviter: string) => {
    if (!inviter) {
      setInviterErrorMessages([])
    }
    setInviter(toLowerCase(inviter))
  }

  const onBuilderCodeChange = (builderCode: string) => {
    setBuilderCode(formatBuilderCodeStr(builderCode))
  }

  const onInviterBlur = async (inviter: string) => {
    setInviteCheckResult(false)
    const result = await checkInviter(toLowerCase(inviter))
    setInviteCheckResult(result)
  }

  const onBuilderCodeBlur = async (builderCode: string) => {
    builderCode = formatBuilderCodeStr(builderCode)
    setBuilderCodeCheckResult(false)
    const result = await checkCouponCode(builderCode)
    if (result) {
      setInviter('')
      setInviterErrorMessages([])
      if (builderCode !== router.query['gift-card']) {
        router.replace({
          query: {
            ...router?.query,
            'gift-card': builderCode
          }
        })
      }
    } else {
      router.replace({
        query: {
          ...router?.query,
          'gift-card': ''
        }
      })
    }
    setBuilderCodeCheckResult(result)
  }

  const onTypeChange = (type: SAVE_CODE_TYPE) => {
    setType(type)
  }

  const discountAmount = useMemo(() => {
    if (builderCode) {
      if (!builderCodeCheckResult) return 0
      return totalCost
    }
    if (!config) return 0
    if (!inviteCheckResult) return 0
    return annualFee?.times(config?.inviter_discount || 0)
  }, [
    builderCode,
    totalCost,
    config,
    annualFee,
    inviteCheckResult,
    builderCodeCheckResult
  ])

  return {
    saveCodeProps: {
      saveFee: discountAmount,
      inviter,
      builderCode,
      inviterErrorMessages,
      builderCodeErrorMessages,
      onInviterChange,
      onBuilderCodeChange,
      onInviterBlur,
      onBuilderCodeBlur,
      onTypeChange,
      saveFeeLoading,
      inviterDiscount: new Decimal(config?.inviter_discount || 0)
        .times(100)
        .toNumber()
    } as ISaveCodeItemProps,
    setBuilderCodeErrorMessages,
    setBuilderCodeCheckResult,
    checkCouponCode,
    checkInviter
  }
}
