import { cn, displayDid } from '@did/tools'
import { Iconfont, DidAvatar } from '@did/uikit'
import { ACCOUNT_STATUS } from '@did/types/das-services'
import {
  useCccContext,
  useDasAppConfigContext,
  useDasBalanceContext
} from '@did/das-app-context'
import { AccountStatusType } from '@did/das-app-types/components'
import { useMemo } from 'react'
import { Decimal } from '@did/tools'

export const AccountStatus: AccountStatusType = ({
  accountInfo,
  highlighted,
  onClick
}) => {
  const { tt, router } = useDasBalanceContext()
  const { isDobsMode } = useCccContext()
  const { account_expiration_grace_period } = useDasAppConfigContext()

  const oneDayMillisecond = 24 * 60 * 60 * 1000

  const gracePeriod = useMemo((): number => {
    if (account_expiration_grace_period) {
      return account_expiration_grace_period * 1000
    }
    return 0
  }, [account_expiration_grace_period])

  const countdownToExpiredDays = useMemo((): number => {
    const currentTimestamp = new Date().getTime()
    const { expired_at: expiredAt } = accountInfo
    let result = '0'
    if (
      currentTimestamp > expiredAt - oneDayMillisecond * 30 &&
      currentTimestamp < expiredAt
    ) {
      result = new Decimal(expiredAt)
        .sub(currentTimestamp)
        .div(oneDayMillisecond)
        .toFixed(0, Decimal.ROUND_UP)
    }
    return new Decimal(result).toNumber()
  }, [accountInfo])

  const countdownToRecoveryDays = useMemo((): number => {
    const currentTimestamp = new Date().getTime()
    const { expired_at: expiredAt } = accountInfo
    let result = '0'
    if (
      currentTimestamp > expiredAt &&
      currentTimestamp < expiredAt + gracePeriod
    ) {
      result = new Decimal(expiredAt)
        .add(gracePeriod)
        .sub(currentTimestamp)
        .div(oneDayMillisecond)
        .toFixed(0, Decimal.ROUND_UP)
    }
    return new Decimal(result).toNumber()
  }, [accountInfo])

  const onRenew = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    router.push(`/data/${accountInfo.account}`, {
      action: 'renew'
    })
  }

  return (
    <li
      onClick={onClick}
      className={cn(
        'flex justify-between items-center py-2 px-5 mb-2 min-h-[84px] box-border rounded-2xl border border-slate-300/40 cursor-pointer hover:opacity-70',
        highlighted ? 'bg-white' : '',
        isDobsMode ? 'bg-gradient-to-r from-sky-100 to-indigo-50' : 'bg-white'
      )}
    >
      <div className="flex items-center">
        <DidAvatar
          account={accountInfo?.account || ''}
          fontSize="20px"
          lineClassName="w-[23px] h-[10px] mb-[-2px]"
          textClassName="mb-[-2px]"
          className="mr-3"
          size={44}
        />
        <div className="text-neutral-700 text-lg font-bold break-all hyphens-auto">
          {displayDid(accountInfo.account, !!accountInfo.enable_sub_account)}
          <div className="flex grid-cols-[4px] items-center break-keep hyphens-auto">
            <div className="flex items-center flex-wrap">
              {!!accountInfo.enable_sub_account && (
                <div className="inline-flex mt-1 mr-1 py-0.5 px-[3px] rounded text-[12px] font-medium bg-normal-color items-center opacity-70 leading-none hyphens-none break-all">
                  {accountInfo.account}
                </div>
              )}
              {countdownToExpiredDays > 0 && (
                <div className="inline-flex mt-1 mr-1 py-0.5 px-[3px] rounded text-[12px] font-medium items-center text-error-font-color bg-status-warn-bg-color leading-none">
                  <Iconfont
                    className="mr-[6px]"
                    name="warning"
                    size="12"
                    color="#FF6B6B"
                  />
                  {tt('Expires in {days} days', {
                    days: countdownToExpiredDays
                  })}
                </div>
              )}
              {countdownToRecoveryDays > 0 && (
                <div className="inline-flex mr-1 mt-1 py-0.5 px-[3px] rounded text-[12px] font-medium items-center text-error-font-color bg-status-warn-bg-color leading-none">
                  <Iconfont
                    className="mr-[6px]"
                    name="warning"
                    size="12"
                    color="#FF6B6B"
                  />
                  {tt('账号回收提示', { days: countdownToRecoveryDays })}
                  <span
                    className="ml-1 text-link-font-color hover:text-link-hover-font-color"
                    onClick={(event) => {
                      onRenew(event)
                    }}
                  >
                    {tt('Renew now')}
                  </span>
                </div>
              )}
              {accountInfo.status === ACCOUNT_STATUS.onePriceSell && (
                <div className="inline-flex mt-1 py-0.5 px-[3px] rounded text-[12px] font-medium items-center text-second bg-status-info-bg-color leading-none">
                  {tt('On sale on DIDTop')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Iconfont
        className="min-w-[24px]"
        name="arrow-right"
        color="#5F6570"
        size={16}
      />
    </li>
  )
}
