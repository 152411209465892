import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

export interface ILineChartProps {
  data: { x: string; y: string; color?: string }[];
  formatter?: (x: string, y: string) => string;
  style?: React.CSSProperties;
}

export const LineChart: React.FC<ILineChartProps> = ({
  data,
  style,
  formatter,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if(!data) return ;
    const chartInstance = echarts.init(chartRef.current);
    const options = {
      animation: true,
      tooltip: {
        trigger: "axis",
        backgroundColor: "#31333E",
        borderRadius: 8,
        textStyle: {
          color: "white",
        },
        formatter: function (params: any) {
          const [x, y] = params?.[0].data;
          return formatter ? formatter(x, y) : `x: ${x}<br /> y: ${y}`;
        },
        axisPointer: {
          type: "line",
          lineStyle: {
            color: "#00DF9B",
            width: 3,
            type: "solid",
          },
        },
      },
      grid: {
        left: "10",
        right: "10",
        top: "0",
        bottom: "0",
      },
      xAxis: {
        // type: "category",
        type: "time",
        boundaryGap: false,
        // data: data.map((d) => d.x),
        show: false,
        axisLine: { show: false },
        splitLine: { show: true },
      },
      yAxis: {
        type: "value",
        min: -100000000 * 0.25,
        max: 100000000 * 1.25,
        show: false,
        axisLine: { show: false },
        splitLine: { show: true },
      },
      series: [
        {
          data: data
            .sort((a, b) => (new Date(a.x) > new Date(b.x) ? 1 : -1))
            .map((d) => [d.x, d.y]),
          type: "line",
          smooth: true,
          areaStyle: null,
          showSymbol: false,
          symbolSize: 8,
          symbol: "circle",
          zlevel: 2,
          lineStyle: {
            width: 4,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: "#00df9b80",
              },
              {
                offset: 0,
                color: "#00DF9B",
              },
            ]),
            cap: "round",
          },
          itemStyle: {
            color: "white",
            borderColor: "#00DF9B",
            borderWidth: 2,
            borderType: "solid",
          },
          emphasis: {
            showSymbol: true,
          },
          markLine: {
            label: {
              show: false,
            },
            animation: false,
            symbol: "none",
            data: data.filter(d=>!Boolean(d.color)).map((d) => ({ xAxis: d.x })),
            lineStyle: {
              type: "dashed",
              color: "#52627933",
              shadowBlur: 0,
            },
          },
        },
        {
          data: data.map((d) =>
            d.color ? [d.x, d.y] : [d.x, -Number.MAX_SAFE_INTEGER]
          ),
          type: "line",
          smooth: true,
          areaStyle: null,
          showSymbol: false,
          symbolSize: 8,
          symbol: "circle",
          zlevel: 5,
          itemStyle: {
            color: "white",
            borderColor: data.find((d) => d.color)?.color || "#00DF9B",
            borderWidth: 2,
            borderType: "solid",
          },
          lineStyle: {
            width: 4,
            opacity: 0, // 默认隐藏
          },
        },
        {
          data: data.map((d) =>
            d.color ? [d.x, d.y] : [d.x, -Number.MAX_SAFE_INTEGER]
          ),
          type: "line",
          smooth: true,
          areaStyle: null,
          showSymbol: true,
          symbolSize: 8,
          symbol: "circle",
          zlevel: 4,
          itemStyle: {
            color: "white",
            borderColor: "#00DF9B",
            borderWidth: 2,
            borderType: "solid",
          },
          lineStyle: {
            width: 4,
            opacity: 0, // 默认隐藏
          },
        },
      ],
    };
    chartInstance.setOption(options);
  }, [data]);

  return <div ref={chartRef} style={style} />;
};
