import { useDasBalanceContext } from '@did/das-app-context'
import { EditParsingRecordType } from '@did/das-app-types/module'
import {
  cn,
  addressValidate,
  capitalize,
  findDweb,
  stringMaxLengthValidate,
  profileValueValidate,
  dwebValueValidate,
  objectKeyValidate,
  findParsingRecordChain
} from '@did/tools'
import { DasButton, Dialog } from '@did/uikit'
import React, { useEffect, useState, useMemo } from 'react'
import styles from './styles.module.scss'
import {
  DWEB_KEY_OPTIONS,
  PARSING_RECORD_SUPPORT_CHAINS,
  PROFILE_KEY_OPTIONS
} from '@did/constants/chain'
import { DataSelect } from '@did/das-app-components'
import { TextInput } from '@did/das-balance-components'
import { useForm } from 'react-hook-form'
import {
  DATA_ACTIONS,
  IAccountParsingRecord,
  ParsingRecordDwebKey,
  ParsingRecordType
} from '@did/types'
// @ts-ignore
import isValidDomain from 'is-valid-domain'
import { IOption } from '@did/das-app-types/components'

export const EditParsingRecord: EditParsingRecordType = ({
  showing,
  addNew,
  record,
  onSave,
  onClose
}) => {
  const { tt, isProd } = useDasBalanceContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
    reset
  } = useForm<IAccountParsingRecord>({
    mode: 'all'
  })
  const [type, setType] = useState('')
  const [key, setKey] = useState('')

  const parsingRecordSupportChains = useMemo(() => {
    const _list: IOption[] = []
    PARSING_RECORD_SUPPORT_CHAINS.forEach((item) => {
      _list.push({
        text: item.text,
        value: item.coinType,
        logo: `/bit/images/chain/chain-${item.value}.png`
      })
    })
    return _list
  }, [])

  const profileKeyOptions = useMemo(() => {
    const _list: IOption[] = []
    PROFILE_KEY_OPTIONS.forEach((item) => {
      _list.push({
        text: item.text,
        value: item.value,
        logo: `/bit/images/social/social-${item.value}.png`
      })
    })
    return _list
  }, [])

  const dwebKeyOptions = useMemo(() => {
    const _list: IOption[] = []
    DWEB_KEY_OPTIONS.forEach((item) => {
      _list.push({
        text: item.text,
        value: item.value,
        logo: `/bit/images/dweb/dweb-${item.value}.png`
      })
    })
    return _list
  }, [])

  const modifyParsingRecordValueInputPlaceholder = useMemo(() => {
    const _key = getValues('key')
    const dWeb = findDweb(_key)
    if (_key === ParsingRecordDwebKey.ipfs) {
      return tt('Please enter {value} CID', { value: dWeb.text })
    } else if (_key === ParsingRecordDwebKey.ipns) {
      return tt('Please enter {value} CID or domain', { value: dWeb.text })
    } else {
      return tt('Please enter {value}', { value: dWeb.text })
    }
  }, [getValues('key')])

  const onChangeModifyParsingRecordValue = (val: string) => {
    const _key = getValues('key')
    const _value = val
    if (
      [ParsingRecordDwebKey.ipfs, ParsingRecordDwebKey.ipns].includes(
        _key as ParsingRecordDwebKey
      )
    ) {
      if (/^https?:\/\/.+/.test(_value)) {
        try {
          const url = new URL(_value)
          if (
            isValidDomain(url.host, {
              subdomain: true,
              wildcard: false,
              allowUnicode: true,
              topLevel: false
            })
          ) {
            setValue('value', url.host)
          }
        } catch (err) {
          console.error(err)
        }
      } else if (_value) {
        try {
          const url = new URL(`https://${_value}`)
          if (
            isValidDomain(url.host, {
              subdomain: true,
              wildcard: false,
              allowUnicode: true,
              topLevel: false
            })
          ) {
            setValue('value', url.host)
          }
        } catch (err) {
          console.error(err)
        }
      }
    }
  }

  const onConfirm = async (param: IAccountParsingRecord) => {
    if (addNew) {
      onSave({ ...param, action: DATA_ACTIONS.add })
    } else {
      onSave({ ...param, action: DATA_ACTIONS.change })
    }
    onClose()
    reset()
  }

  const labelRegister = register('label', {
    validate: (val, formVal) => {
      if (val && !stringMaxLengthValidate(val, 64)) {
        return tt('{fieldName} must not exceed {length} characters', {
          fieldName: tt('Label'),
          length: 64
        })
      }
      return true
    }
  })

  const keyRegister = register('key', {
    validate: (val, formVal) => {
      if (formVal.type === ParsingRecordType.customKey) {
        if (val && !stringMaxLengthValidate(val, 255)) {
          return tt('{fieldName} must not exceed {length} characters', {
            fieldName: tt('Custom Key'),
            length: 1024
          })
        }
        if (!objectKeyValidate(val)) {
          return tt(
            '{fieldName} can only contain lowercase letters, numbers and underscores',
            { fieldName: tt('Custom Key') }
          )
        }
        return true
      }
      return true
    }
  })

  const valueRegister = register('value', {
    validate: (val, formVal) => {
      if (formVal.type === ParsingRecordType.address && formVal.key) {
        const chain = findParsingRecordChain(formVal.key)
        let symbol = chain.text
        if (!addressValidate(val, symbol, isProd)) {
          return tt('{chain} address format is wrong', {
            chain: symbol
          })
        }
        return true
      } else if (formVal.type === ParsingRecordType.profile) {
        if (val && !stringMaxLengthValidate(val, 1024)) {
          return tt('{fieldName} must not exceed {length} characters', {
            fieldName: capitalize(formVal.key),
            length: 1024
          })
        }
        if (!profileValueValidate(val, formVal.key)) {
          return tt(
            '{fieldName} can only contain letters, numbers and underscores',
            { fieldName: capitalize(formVal.key) }
          )
        }
        return true
      } else if (formVal.type === ParsingRecordType.dweb) {
        const fieldName = findDweb(formVal.key).text
        if (val && !stringMaxLengthValidate(val, 1024)) {
          return tt('{fieldName} must not exceed {length} characters', {
            fieldName,
            length: 1024
          })
        }
        if (val && !dwebValueValidate(val, formVal.key)) {
          switch (formVal.key) {
            case ParsingRecordDwebKey.ipfs:
              return tt('Incorrect {fieldName} CID', { fieldName: fieldName })
            case ParsingRecordDwebKey.ipns:
              return tt('Incorrect {fieldName} CID or domain', {
                fieldName: fieldName
              })
            default:
              return tt('Invalid {fieldName}', { fieldName: fieldName })
          }
        }
        return true
      } else if (formVal.type === ParsingRecordType.customKey) {
        if (val && !stringMaxLengthValidate(val, 1024)) {
          return tt('{fieldName} must not exceed {length} characters', {
            fieldName: tt('Custom Value'),
            length: 1024
          })
        }
        return true
      }
    }
  })

  useEffect(() => {
    if (record) {
      if (record.type !== undefined) {
        setType(record.type)
        setValue('type', record.type)
      }
      if (record.key !== undefined) {
        setKey(record.key)
        setValue('key', record.key)
      }
      if (record.label !== undefined) {
        setValue('label', record.label)
      }
      if (record.value !== undefined) {
        setValue('value', record.value)
      }
    }
  }, [record])

  return (
    <Dialog
      showing={showing}
      title={addNew ? tt('Add Data') : tt('Edit Data')}
      closeButton
      onClose={() => {
        onClose()
        clearErrors()
      }}
    >
      <form
        className={cn(styles['modify-parsing-record'])}
        onSubmit={handleSubmit(onConfirm)}
      >
        <div className={cn(styles['modify-parsing-record'])}>
          {type === ParsingRecordType.address && (
            <>
              <label className={cn(styles['modify-parsing-record__label'])}>
                {tt('Public Chain')}
              </label>
              <DataSelect
                defaultValue={key}
                options={parsingRecordSupportChains}
                search
                placeholder={tt('Please select the public chain')}
                errorMessages={[errors?.key?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('key', val as string)
                  clearErrors()
                }}
              />
              <label
                className={cn(
                  styles['modify-parsing-record__label'],
                  styles['modify-parsing-record__margin-top-32']
                )}
              >
                {tt('Address')}
              </label>
              <TextInput
                {...valueRegister}
                placeholder={tt('Please enter the address')}
                errorMessages={[errors?.value?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('value', val?.target?.value?.trim())
                  clearErrors()
                }}
              />
            </>
          )}
          {type === ParsingRecordType.profile && (
            <>
              <label className={cn(styles['modify-parsing-record__label'])}>
                {tt('Key')}
              </label>
              <DataSelect
                defaultValue={key}
                options={profileKeyOptions}
                search
                placeholder={tt('Please select the key')}
                errorMessages={[errors?.key?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('key', val as string)
                  clearErrors()
                }}
              />
              <label
                className={cn(
                  styles['modify-parsing-record__label'],
                  styles['modify-parsing-record__margin-top-32']
                )}
              >
                {capitalize(getValues('key'))}
              </label>
              <TextInput
                {...valueRegister}
                placeholder={tt('Please enter {value}', {
                  value: capitalize(getValues('key'))
                })}
                errorMessages={[errors?.value?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('value', val?.target?.value)
                  clearErrors()
                }}
                onBlur={(val) => {
                  setValue('value', val?.target?.value?.trim())
                  clearErrors()
                }}
              />
            </>
          )}
          {type === ParsingRecordType.dweb && (
            <>
              <label className={cn(styles['modify-parsing-record__label'])}>
                {tt('Key')}
              </label>
              <DataSelect
                defaultValue={key}
                options={dwebKeyOptions}
                search
                placeholder={tt('Please select the key')}
                errorMessages={[errors?.key?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('key', val as string)
                  clearErrors()
                }}
              />
              <label
                className={cn(
                  styles['modify-parsing-record__label'],
                  styles['modify-parsing-record__margin-top-32']
                )}
              >
                {findDweb(getValues('key')).text}
              </label>
              <TextInput
                {...valueRegister}
                placeholder={modifyParsingRecordValueInputPlaceholder}
                errorMessages={[errors?.value?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  onChangeModifyParsingRecordValue(val?.target?.value)
                }}
              />
            </>
          )}
          {type === ParsingRecordType.customKey && (
            <>
              <label className={cn(styles['modify-parsing-record__label'])}>
                {tt('Custom Key')}
              </label>
              <TextInput
                {...keyRegister}
                placeholder={tt('Please enter custom key')}
                errorMessages={[errors?.key?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('key', val?.target?.value)
                }}
                onBlur={(val) => {
                  setValue('key', val?.target?.value?.trim())
                }}
              />
              <label
                className={cn(
                  styles['modify-parsing-record__label'],
                  styles['modify-parsing-record__margin-top-32']
                )}
              >
                {tt('Custom Value')}
              </label>
              <TextInput
                {...valueRegister}
                placeholder={tt('Please enter custom value')}
                errorMessages={[errors?.value?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('value', val?.target?.value)
                }}
                onBlur={(val) => {
                  setValue('value', val?.target?.value?.trim())
                }}
              />
            </>
          )}
          {type && (
            <>
              <label
                className={cn(
                  styles['modify-parsing-record__label'],
                  styles['modify-parsing-record__margin-top-32']
                )}
              >
                {tt('Label')}
                <span
                  className={cn(styles['modify-parsing-record__label__tip'])}
                >
                  ({tt('Optional')})
                </span>
              </label>
              <TextInput
                {...labelRegister}
                placeholder={tt('e.g. personal account')}
                errorMessages={[errors?.label?.message || ''].filter(Boolean)}
                onChange={(val) => {
                  setValue('label', val?.target?.value)
                }}
                onBlur={(val) => {
                  setValue('label', val?.target?.value?.trim())
                }}
              />
            </>
          )}
        </div>
        <div className="py-8">
          <DasButton type="submit" black block>
            {tt('Confirm')}
          </DasButton>
        </div>
      </form>
    </Dialog>
  )
}
