import { SwitchNoticeType } from '@did/das-app-types/module'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { DasButton, Dialog } from '@did/uikit'

export const SwitchNotice: SwitchNoticeType = ({ showing, onClose }) => {
  const { tt, toast } = useDasBalanceContext()
  const { setIsDobsMode } = useCccContext()

  const onSwitch = () => {
    onClose()
    setIsDobsMode(false)
    toast(tt('Switched'))
  }

  return (
    <Dialog showing={showing} title={tt('Notice')} onClose={onClose}>
      <div>
        <div className="text-neutral-700 text-sm font-medium leading-tight">
          {tt(
            'To proceed with creation, you need to switch to "Legacy .bit" mode.'
          )}
        </div>
        <div className="my-8">
          <DasButton block black onClick={onSwitch}>
            {tt('Switch to Legacy .bit')}
          </DasButton>
        </div>
      </div>
    </Dialog>
  )
}
