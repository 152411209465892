import { FIAT_DECIMAL_PLACES } from '@did/constants'
import { useDasBalanceContext } from '@did/das-app-context'
import { UpdatedRegistrationPeriodDialogType } from '@did/das-app-types/module'
import { thousandSplit } from '@did/tools'
import { DasButton, Dialog } from '@did/uikit'
import React from 'react'

export const UpdatedRegistrationPeriodDialog: UpdatedRegistrationPeriodDialogType =
  ({ showing, onClose, onContinue, paidAmount }) => {
    const { tt } = useDasBalanceContext()

    return (
      <Dialog
        showing={showing}
        title={tt('Updated')}
        closeButton
        onClose={onClose}
      >
        <div>
          {tt(
            'You are using a Builder Code to register, and the registration year changed to 1 year. The total cost is {amount}.',
            { amount: `$${thousandSplit(paidAmount, FIAT_DECIMAL_PLACES)}` }
          )}
          <DasButton className="my-8" block black onClick={onContinue}>
            {tt('Continue')}
          </DasButton>
        </div>
      </Dialog>
    )
  }
