import { NotFoundType } from '@did/das-balance-types'
import { cn } from '@did/tools'
import { Iconfont } from '@did/uikit'
import React from 'react'

export const NotFound: NotFoundType = ({ title, className, iconName }) => {
  return (
    <div className={cn('w-full flex items-center flex-col', className)}>
      {iconName && <Iconfont name={iconName} color="#B0B8BF" size="30" />}
      <h3 className="font-normal text-sm mt-2 text-third-font-color">
        {title}
      </h3>
    </div>
  )
}
