import {
  ACCOUNT_SUFFIX,
  CYCLE_CALL_FUNCTION_TIME,
  TOKEN_DECIMAL_PLACES
} from '@did/constants'
import {
  CoinType,
  DASBalanceTokenId,
  DASDidPointTokenId,
  USD
} from '@did/constants/chain'
import errno from '@did/constants/errno'
import {
  useDasBalanceContext,
  useDasAppConfigContext
} from '@did/das-app-context'
import { sleep } from '@did/tools'
import { DIDPOINT_ACTIONS } from '@did/constants'
import Decimal from 'decimal.js'
import { useMemo, useState } from 'react'
import { ACCOUNT_STATUS, IToken, ORDER_ACTION_TYPE } from '@did/types'
import { ErrorInfo } from '@did/monitoring'
import { useTokenList } from './use-token-list'

export const useHandleRegister = (param: {
  couponCode?: string
  originalPrice?: Decimal
  paidAmount?: Decimal
  bitName: string
  registrationPeriod: number
  inviter?: string
  channel?: string
  checkCouponCode?: (couponCode: string) => Promise<boolean>
  checkInviter?: (inviter: string) => Promise<boolean>
  setBuilderCodeCheckResult?: (result: any) => any
  setBuilderCodeErrorMessages?: (msg: any) => any
}) => {
  const {
    couponCode,
    paidAmount,
    bitName,
    registrationPeriod,
    inviter,
    channel,
    checkCouponCode,
    setBuilderCodeErrorMessages,
    setBuilderCodeCheckResult,
    checkInviter
  } = param
  const { walletSdk, services, connectedAccount, router, alert, tt } =
    useDasBalanceContext()
  const config = useDasAppConfigContext()
  const [confirmRegisterLoading, setConfirmRegisterLoading] = useState(false)
  const [confirmRegisterShowing, setConfirmRegisterShowing] = useState(false)
  const [
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing
  ] = useState(false)

  const [signatureErrorDialogShowing, setSignatureErrorDialogShowing] =
    useState(false)

  const [fiatPayDialogShowing, setFiatPayDialogShowing] = useState(false)

  const [
    updatedRegistrationPeriodDialogShowing,
    setUpdatedRegistrationPeriodDialogShowing
  ] = useState(false)

  const [onRegisterLoading, setOnRegisterLoading] = useState(false)
  const paymentTokens = useTokenList()
  const [selectedToken, setSelectedToken] = useState<IToken>()
  const [orderId, setOrderId] = useState('')
  const [clientSecret, setClientSecret] = useState<string>()

  const goRegisterStatusPage = () => {
    router.push(`/account/create/status/${bitName}`, {
      paid: '1'
    })
  }

  const formatInviterStr = (str: string) => {
    if (!str) return ''
    return (
      str
        ?.replace(/\s+/g, '')
        .toLowerCase()
        .replace(/\.bit$/, '') + ACCOUNT_SUFFIX
    )
  }

  const checkAccountStatus = async () => {
    if (!connectedAccount) return false
    let _result = true
    try {
      const res = await services.account.searchAccount({
        account: bitName,
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        }
      })

      // determine if the current user is in the registration process
      if (res && res.is_self) {
        if (
          [
            ACCOUNT_STATUS.registeringLockedAccount,
            ACCOUNT_STATUS.registering,
            ACCOUNT_STATUS.registeringIncludeProposal,
            ACCOUNT_STATUS.registeringConfirmProposal
          ].includes(res.status)
        ) {
          router.push(`/account/create/status/${bitName}`)
          _result = false
        } else if (
          [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
            res.status
          )
        ) {
          alert({
            title: tt('Tips'),
            message: tt(
              'You already have {accountName}, no need to register again',
              { accountName: bitName }
            )
          })
          _result = false
        }
      } else if (
        [
          ACCOUNT_STATUS.registering,
          ACCOUNT_STATUS.registeringIncludeProposal,
          ACCOUNT_STATUS.registeringConfirmProposal
        ].includes(res.status)
      ) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: bitName }
          )
        })
        _result = false
      } else if (
        [ACCOUNT_STATUS.registered, ACCOUNT_STATUS.upgradedAccount].includes(
          res.status
        )
      ) {
        alert({
          title: tt('Tips'),
          message: tt(
            '{accountName} has been registered by someone else and can no longer be registered',
            { accountName: bitName }
          )
        })
        _result = false
      } else if (res.status === ACCOUNT_STATUS.reservedAccount) {
        router.push(`/create/account/${bitName}`)
        _result = false
      } else if (res.status === ACCOUNT_STATUS.notOpenRegister) {
        alert({
          title: tt('Tips'),
          message: tt('This account name is not yet open for registration')
        })
        _result = false
      } else if (res.status === ACCOUNT_STATUS.unavailableAccount) {
        alert({
          title: tt('Tips'),
          message: tt('Unavailable Account')
        })
        _result = false
      }
    } catch (err: any) {
      ErrorInfo.error(err)
      if (err.code === errno.rpcApiErrNotOpenForRegistration) {
        alert({
          title: tt('Error'),
          message: tt('{accountName} is not open for registration', {
            accountName: bitName
          })
        })
      } else {
        alert({
          title: tt('Error'),
          message: `${err.code}: ${err.message}`
        })
      }
      _result = false
    }
    return _result
  }

  const getOrderInfo = async () => {
    if (!connectedAccount) return
    try {
      const res = await services.account.orderDetail({
        account: bitName,
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        },
        action: ORDER_ACTION_TYPE.APPLY_REGISTER
      })

      if (res) {
        setOrderId(res.order_id)
      }
      return res
    } catch (err: any) {
      if (err.code !== errno.apiErrorOrderNotExist) {
        ErrorInfo.error(err)
      }
    }
  }

  const changeOrder = async () => {
    if (!connectedAccount || !selectedToken) return
    try {
      await services.account.changeOrder({
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        },
        account: bitName,
        pay_token_id: selectedToken.token_id,
        pay_address: connectedAccount.address,
        register_years: registrationPeriod,
        coin_type: connectedAccount.chain.coinType,
        inviter_account: formatInviterStr(inviter || ''),
        channel_account: formatInviterStr(channel || '')
      })
    } catch (err: any) {
      ErrorInfo.error(err)
      throw err
    }
  }

  const amountVerify = (amount: number | string): string => {
    let number = amount
    // Compatible with TokenPocket
    if (selectedToken?.coin_type === CoinType.doge) {
      number = new Decimal(number || 0)
        .div(new Decimal(10).pow(TOKEN_DECIMAL_PLACES))
        .toFixed(TOKEN_DECIMAL_PLACES)
    }
    return number + ''
  }

  const onFiatPay = async () => {
    if (!connectedAccount) return
    setConfirmRegisterLoading(true)

    const checkAccountStatusRes = await checkAccountStatus()
    if (!checkAccountStatusRes) {
      setConfirmRegisterLoading(false)
      return
    }

    try {
      let order = await getOrderInfo()
      if (order?.order_id) {
        if (
          order.register_years !== registrationPeriod ||
          order.inviter_account !== formatInviterStr(inviter || '') ||
          order.channel_account !== formatInviterStr(channel || '') ||
          order.pay_token_id !== selectedToken?.token_id ||
          order.coin_type !== connectedAccount?.chain.coinType
        ) {
          await changeOrder()
          order = await getOrderInfo()
        }

        if (order?.client_secret) {
          setClientSecret(order.client_secret)
        }

        setConfirmRegisterShowing(false)
        setFiatPayDialogShowing(true)
      } else {
        const applyRegisterRes = await services.account.submitRegisterOrder({
          key_info: {
            coin_type: connectedAccount.chain?.coinType,
            key: connectedAccount.address
          },
          account: bitName,
          pay_token_id: selectedToken?.token_id,
          pay_address: connectedAccount.address,
          register_years: registrationPeriod,
          coin_type: connectedAccount.chain.coinType,
          inviter_account: formatInviterStr(inviter || ''),
          channel_account: formatInviterStr(channel || '')
        })
        if (applyRegisterRes.order_id) {
          setOrderId(applyRegisterRes.order_id)
        }
        if (applyRegisterRes.client_secret) {
          setClientSecret(applyRegisterRes.client_secret)
        }
        setConfirmRegisterShowing(false)
        setFiatPayDialogShowing(true)
      }
    } catch (err: any) {
      ErrorInfo.error(err)

      if (err.code === errno.apiErrorCodeResolveFailed) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Frequent operations. There are still transactions being processed in your wallet address, please try again after 30s.'
          )
        })
      } else if (err.code === errno.rpcApiErrSignatureError) {
        setSignatureErrorDialogShowing(true)
      } else if (err.code === errno.apiErrorAccountRegistering) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: bitName }
          )
        })
      } else {
        alert({
          title: tt('Error'),
          message: err.code ? `${err.code}: ${err.message}` : `${err}`
        })
      }
    } finally {
      setConfirmRegisterLoading(false)
    }
  }

  const registerWithCouponCode = async () => {
    const checkAccountStatusRes = await checkAccountStatus()
    if (!checkAccountStatusRes) {
      setOnRegisterLoading(false)
      return
    }

    try {
      const applyRegisterRes = await services.account.submitRegisterOrder({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        account: bitName,
        register_years: 1,
        coin_type: connectedAccount?.chain.coinType!,
        channel_account: channel!,
        gift_card: couponCode
      })

      if (!applyRegisterRes) {
        return
      }

      if (applyRegisterRes.order_id) {
        setOrderId(applyRegisterRes.order_id)
      }
      await sleep(CYCLE_CALL_FUNCTION_TIME)
      goRegisterStatusPage()
    } catch (err: any) {
      ErrorInfo.error(err)
      if (err.code === errno.apiErrorCodeResolveFailed) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Frequent operations. There are still transactions being processed in your wallet address, please try again after 30s.'
          )
        })
      } else if (err.code === errno.rpcApiErrAccountFrequencyLimit) {
        alert({
          title: tt('Tips'),
          message: tt(
            'The operation is too frequent. Please try again after {timeInterval} minutes',
            { timeInterval: 3 }
          )
        })
      } else if (err.code === errno.apiErrorCodeCouponNotFound) {
        setBuilderCodeErrorMessages?.((msg: string[]) => {
          return [tt('Please enter a valid Builder Code.'), ...(msg || [])]
        })
        setBuilderCodeCheckResult?.(false)
      } else if (err.code === errno.apiErrorCodeCouponEventDidNotStart) {
        setBuilderCodeErrorMessages?.((msg: string[]) => {
          return [tt('Please enter a valid Builder Code.'), ...(msg || [])]
        })
        setBuilderCodeCheckResult?.(false)
      } else if (err.code === errno.apiErrorCodeCouponUsed) {
        setBuilderCodeErrorMessages?.((msg: string[]) => {
          return [
            tt('This Builder Code has been redeemed. Please try another one.'),
            ...(msg || [])
          ]
        })
        setBuilderCodeCheckResult?.(false)
      } else if (err.code === errno.apiErrorAccountRegistering) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: bitName }
          )
        })
      } else {
        alert({
          title: tt('Error'),
          message: err.code ? `${err.code}: ${err.message}` : `${err}`
        })
      }
    } finally {
      setOnRegisterLoading(false)
    }
  }

  const checkCacheOldRegistrationPeriod = async (coupon: string) => {
    const couponCheckResult = await checkCouponCode?.(coupon)
    if (!couponCheckResult) {
      setOnRegisterLoading(false)
      return
    }

    if (registrationPeriod === 1) {
      registerWithCouponCode()
    } else {
      setUpdatedRegistrationPeriodDialogShowing(true)
    }
  }

  const handleConfirm = async () => {
    if (!selectedToken || !connectedAccount) return

    if (selectedToken.token_id === USD.tokenId) {
      onFiatPay()
      return
    }
    setConfirmRegisterLoading(true)
    const { signTxList, onClose } = await walletSdk?.initSignContext()

    const checkAccountStatusRes = await checkAccountStatus()
    if (!checkAccountStatusRes) {
      setConfirmRegisterLoading(false)
      onClose?.()
      return
    }

    try {
      let order = await getOrderInfo()
      if (order?.order_id) {
        if (
          order.register_years !== registrationPeriod ||
          order.inviter_account != formatInviterStr(inviter || '') ||
          order.channel_account != formatInviterStr(channel || '') ||
          order.pay_token_id !== selectedToken.token_id ||
          order.coin_type !== connectedAccount?.chain.coinType
        ) {
          await changeOrder()
          order = await getOrderInfo()
        }

        if (selectedToken.token_id === DASBalanceTokenId) {
          const res = await services.account.dasBalancePay({
            evm_chain_id: connectedAccount?.computedChainId,
            key_info: {
              coin_type: connectedAccount.chain?.coinType,
              key: connectedAccount.address
            },
            order_id: order?.order_id!
          })

          const signatureList = await signTxList(res)

          const { hash } = await services.account.sendTrx(signatureList)
          if (hash) {
            await services.account.returnRegisteredPaymentTrxId({
              key_info: {
                coin_type: connectedAccount.chain?.coinType,
                key: connectedAccount.address
              },
              account: bitName,
              order_id: order?.order_id!,
              pay_hash: hash
            })
            goRegisterStatusPage()
            setConfirmRegisterShowing(false)
          }
        } else if (order?.pay_token_id === DASDidPointTokenId) {
          const res = await services.didPoint.transfer({
            key_info: {
              coin_type: connectedAccount?.chain?.coinType!,
              key: connectedAccount?.address!
            },
            amount_dp: new Decimal(order?.pay_amount)
              .dividedBy(new Decimal(10).pow(selectedToken?.decimals || 6))
              .toString(),
            order_id: order?.order_id,
            action_dp: DIDPOINT_ACTIONS.transfer_tldid
          })

          await signTxList(res)

          const { hash } = await services.didPoint.txSend(res)
          if (hash) {
            goRegisterStatusPage()
            setConfirmRegisterShowing(false)
          }
        } else {
          const trxAmount = await amountVerify(order?.pay_amount!)
          const trxId = await walletSdk.sendTransaction({
            to: order?.receipt_address,
            value: trxAmount,
            data: order?.order_id
          })

          if (trxId && typeof trxId === 'string') {
            await services.account.returnRegisteredPaymentTrxId({
              key_info: {
                coin_type: connectedAccount.chain?.coinType,
                key: connectedAccount.address
              },
              account: bitName,
              order_id: order?.order_id!,
              pay_hash: trxId
            })
            goRegisterStatusPage()
            setConfirmRegisterShowing(false)
          }
        }
      } else {
        const applyRegisterRes = await services.account.submitRegisterOrder({
          key_info: {
            coin_type: connectedAccount.chain?.coinType,
            key: connectedAccount.address
          },
          account: bitName,
          pay_token_id: selectedToken.token_id,
          pay_address: connectedAccount.address,
          register_years: registrationPeriod,
          coin_type: connectedAccount.chain.coinType,
          inviter_account: formatInviterStr(inviter || ''),
          channel_account: formatInviterStr(channel || '')
        })

        if (applyRegisterRes.order_id) {
          setOrderId(applyRegisterRes.order_id)
        }

        if (selectedToken.token_id === DASBalanceTokenId) {
          const res = await services.account.dasBalancePay({
            evm_chain_id: connectedAccount.computedChainId,
            key_info: {
              coin_type: connectedAccount.chain?.coinType,
              key: connectedAccount.address
            },
            order_id: applyRegisterRes.order_id
          })

          const signatureList = await signTxList(res)

          const { hash } = await services.account.sendTrx(signatureList)
          if (hash) {
            await services.account.returnRegisteredPaymentTrxId({
              key_info: {
                coin_type: connectedAccount.chain?.coinType,
                key: connectedAccount.address
              },
              account: bitName,
              order_id: applyRegisterRes.order_id,
              pay_hash: hash
            })
            goRegisterStatusPage()
            setConfirmRegisterShowing(false)
          }
        } else if (selectedToken?.token_id === DASDidPointTokenId) {
          const res = await services.didPoint.transfer({
            key_info: {
              coin_type: connectedAccount?.chain?.coinType!,
              key: connectedAccount?.address!
            },
            amount_dp: new Decimal(applyRegisterRes?.amount)
              .dividedBy(new Decimal(10).pow(selectedToken?.decimals || 6))
              .toString(),
            order_id: applyRegisterRes?.order_id,
            action_dp: DIDPOINT_ACTIONS.transfer_tldid
          })

          await signTxList(res)

          const { hash } = await services.didPoint.txSend(res)
          if (hash) {
            goRegisterStatusPage()
            setConfirmRegisterShowing(false)
          }
        } else {
          const trxAmount = amountVerify(applyRegisterRes.amount)
          const trxId = await walletSdk.sendTransaction({
            to: applyRegisterRes.receipt_address,
            value: trxAmount,
            data: applyRegisterRes.order_id
          })
          if (trxId && typeof trxId === 'string') {
            await services.account.returnRegisteredPaymentTrxId({
              key_info: {
                coin_type: connectedAccount.chain?.coinType,
                key: connectedAccount.address
              },
              account: bitName,
              order_id: applyRegisterRes.order_id,
              pay_hash: trxId
            })

            goRegisterStatusPage()
            setConfirmRegisterShowing(false)
          }
        }
      }
    } catch (err: any) {
      onClose?.()

      if (
        ![
          errno.metaMaskUserRejectedAccountAccess,
          errno.metaMaskUserDeniedMessageSignature
        ].includes(err.code) &&
        err !== errno.tronLinkConfirmationDeclinedByUser &&
        err.message !== errno.walletConnectUserRejectedTheTransaction &&
        err.shortMessage !== errno.walletConnectUserRejectedTheRequest
      ) {
        if (
          err.code === errno.metaMaskWalletRequestPermissions ||
          err.code === errno.tronLinkAuthorizationRequestsAreBeingProcessed
        ) {
          alert({
            title: tt('Tips'),
            message: tt(
              'Other requests for the wallet are not processed, please try again after processing'
            )
          })
        } else if (err.message === errno.coinbaseWalletUsingMultipleWallet) {
          alert({
            title: tt('Tips'),
            message: tt(
              'Please check if you are using multiple wallet plugins. Please disable multiple wallet plugins, keep only one wallet plugin and try again.'
            )
          })
        } else if (
          err === errno.tronLinkInsufficientBalance ||
          (err.message &&
            err.message.includes(
              errno.walletConnectInsufficientFundsForTransfer
            ))
        ) {
          alert({
            title: tt('Tips'),
            message: tt('Insufficient balance')
          })
        } else if (
          err.message &&
          err.message.includes(errno.tronLinkTypeErrorAddUpdateDataNotFunction)
        ) {
          alert({
            title: tt('Tips'),
            message: tt(
              'The current wallet environment does not support payments using TRX, please upgrade your wallet version or register with another wallet.'
            )
          })
        } else if (err.code === errno.apiErrorCodeInsufficientBalance) {
          setDasBalanceInsufficientDialogShowing(true)
        } else if (err.code === errno.apiErrorCodeNotEnoughChange) {
          alert({
            title: tt('Tips'),
            message: tt(
              'DAS is a smart contract that runs on the Nervos. Due to the underlying logic of the contract, the remaining amount is too low (less than {minChangeCapacity} CKB) to send a transaction.',
              {
                minChangeCapacity: config.min_change_capacity
              }
            )
          })
        } else if (err.code === errno.apiErrorCodeResolveFailed) {
          alert({
            title: tt('Tips'),
            message: tt(
              'Frequent operations. There are still transactions being processed in your wallet address, please try again after 30s.'
            )
          })
        } else if (err.code === errno.rpcApiErrSignatureError) {
          setSignatureErrorDialogShowing(true)
        } else if (err.code === errno.apiErrorAccountRegistering) {
          alert({
            title: tt('Tips'),
            message: tt(
              'Someone else is registering {accountName}, it is currently unavailable, please try again later',
              { accountName: bitName }
            )
          })
        } else {
          ErrorInfo.error(err)
          alert({
            title: tt('Error'),
            message: err.code ? `${err.code}: ${err.message}` : `${err}`
          })
        }
      }
    } finally {
      setConfirmRegisterLoading(false)
    }
  }

  const handleRegister = async () => {
    try {
      if (onRegisterLoading) {
        return
      }
      setOnRegisterLoading(true)
      const isInit = await walletSdk?.initWallet()
      if (!isInit) {
        setOnRegisterLoading(false)
        return
      }

      if (couponCode) {
        await checkCacheOldRegistrationPeriod(couponCode)
        return
      }
      if (inviter) {
        const checkResult = await checkInviter?.(formatInviterStr(inviter))
        if (!checkResult) {
          setOnRegisterLoading(false)
          return
        }
      }

      setOnRegisterLoading(false)
      setConfirmRegisterShowing(true)
    } catch (e: any) {
      ErrorInfo.error(e)
    }
  }

  const stripeServiceFee = useMemo(() => {
    if (!selectedToken?.decimals) return ''
    return new Decimal(paidAmount || 0)
      .times(config?.premium_percentage || 0)
      .add(config?.premium_base || 0)
      .toFixed(selectedToken?.decimals, Decimal.ROUND_UP)
  }, [selectedToken, paidAmount, config])

  const stripePaidAmount = useMemo(() => {
    if (!selectedToken?.decimals) return ''

    return new Decimal(paidAmount || 0)
      .add(stripeServiceFee)
      .toFixed(selectedToken?.decimals, Decimal.ROUND_UP)
  }, [paidAmount, stripeServiceFee, selectedToken])

  const paidTokenAmount = useMemo(() => {
    let tokenPrecision = TOKEN_DECIMAL_PLACES

    if (selectedToken?.token_id === USD.tokenId) {
      tokenPrecision = selectedToken?.decimals
    }

    if (selectedToken?.token_id === DASDidPointTokenId) {
      tokenPrecision = 2
    }

    return new Decimal(paidAmount || 0)
      .div(selectedToken?.price || 1)
      .times(selectedToken?.premium || 1)
      .toFixed(tokenPrecision, Decimal.ROUND_UP)
  }, [paidAmount, selectedToken])

  return {
    handleRegister,
    onRegisterLoading,
    confirmRegisterShowing,
    setConfirmRegisterShowing,
    paymentTokens,
    selectedToken,
    setSelectedToken,
    stripeServiceFee,
    stripePaidAmount,
    paidTokenAmount,
    handleConfirm,
    confirmRegisterLoading,
    setConfirmRegisterLoading,
    updatedRegistrationPeriodDialogShowing,
    setUpdatedRegistrationPeriodDialogShowing,
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing,
    signatureErrorDialogShowing,
    setSignatureErrorDialogShowing,
    registerWithCouponCode,
    fiatPayDialogShowing,
    setFiatPayDialogShowing,
    clientSecret,
    goRegisterStatusPage,
    orderId
  }
}
