import { useDasBalanceContext } from '@did/das-app-context'
import { useTransStatusDialog } from '@did/das-app-hooks'
import { DasButton, Dialog } from '@did/uikit'
import React from 'react'

import { TransStatusType } from '@did/das-app-types/module'

export const TransStatusDialog: TransStatusType = (props) => {
  const { tt } = useDasBalanceContext()

  const { handleBtnClick, onClose, showing, loading, done, btnText } =
    useTransStatusDialog(props)
  return (
    <Dialog
      closeButton
      showing={showing}
      title={tt('Submitted')}
      onClose={onClose}
    >
      <div className=" text-sm  ">
        {tt('Approximately {number} minutes.', { number: 3 })}
      </div>

      <div className="mt-8 pb-9">
        <DasButton
          primary
          loading={loading}
          done={done}
          block
          className="bg-slate-400"
          onClick={handleBtnClick}
        >
          {btnText}
        </DasButton>
      </div>
    </Dialog>
  )
}
