import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { IAccountInfoRes } from '@did/types'
import { useMemo } from 'react'

export const useIsOwner = (accountInfo: IAccountInfoRes): boolean => {
  const { connectedAccount } = useDasBalanceContext()
  const { ckbAddress, isDobsMode } = useCccContext()

  return useMemo((): boolean => {
    return isDobsMode
      ? Boolean(
          ckbAddress &&
            accountInfo?.owner &&
            ckbAddress?.toLowerCase() === accountInfo?.owner?.toLowerCase()
        )
      : Boolean(
          connectedAccount?.address &&
            accountInfo?.owner &&
            connectedAccount?.address?.toUpperCase() ===
              accountInfo?.owner?.toUpperCase()
        )
  }, [connectedAccount?.address, accountInfo?.owner, isDobsMode, ckbAddress])
}
