import { useDasBalanceContext } from '@did/das-app-context'
import { RegisteringPaymentConfirmType } from '@did/das-app-types/module'
import { DasButton, DidCard } from '@did/uikit'

export const RegisteringPaymentConfirm: RegisteringPaymentConfirmType = ({
  bitName,
  onPaid,
  onRegisterAgain
}) => {
  const { tt } = useDasBalanceContext()
  return (
    <>
      <DidCard width="130" account={bitName || ''} />
      <div className="py-4 text-neutral-700 text-2xl font-bold break-all text-center">
        {tt('Payment Confirmation')}
      </div>
      <div className="mb-6 text-base text-zinc-500 text-center leading-[18px] font-normal">
        {tt(
          'The payment status of the order registered with {accountName} is not detected, please confirm whether you have paid?',
          { accountName: bitName }
        )}
      </div>
      <DasButton className="mb-6" large block black onClick={onPaid}>
        {tt('I have already paid')}
      </DasButton>
      <DasButton block onClick={onRegisterAgain} large>
        {tt('Not paid, continue to pay')}
      </DasButton>
    </>
  )
}
