import { useDasBalanceContext } from '@did/das-app-context'
import { RecordListType } from '@did/das-app-types/module'
import {
  DATA_ACTIONS,
  IAccountParsingRecord,
  ParsingRecordProfileKey,
  ParsingRecordType
} from '@did/types'
import { EllipsisText, Iconfont, IconImage } from '@did/uikit'
import styles from './styles.module.scss'
import {
  cn,
  copyText,
  findDweb,
  findParsingRecordChain,
  findProfile,
  isMobile
} from '@did/tools'
import { useState } from 'react'
import { CKB, CoinTypeToChainMap } from '@did/constants/chain'
import { ActionButtons } from '../action-buttons/action-buttons'
import { RecordDetails } from '../record-details/record-details'

export const RecordList: RecordListType = ({
  title,
  editMode,
  recordType,
  records,
  loading,
  accountInfo,
  onEdit,
  onDelete,
  addNew,
  notAdd,
  showAction
}) => {
  const {
    tt,
    connectedAccount,
    services,
    walletSdk,
    alert,
    isProd,
    router,
    toast
  } = useDasBalanceContext()
  const [parseRecordDetails, setParseRecordDetails] =
    useState<IAccountParsingRecord>({} as IAccountParsingRecord)
  const [parseRecordDetailsShowing, setParseRecordDetailsShowing] =
    useState(false)

  const onCopyAddress = (address: string) => {
    copyText(address).then(() => {
      toast('👌 ' + tt('Copied'))
    })
  }

  const onClickRecord = (record: IAccountParsingRecord) => {
    if (record.type === ParsingRecordType.profile) {
      if (/^(http:\/\/|https:\/\/)/.test(record.value)) {
        window.open(record.value)
      } else if (record.key === ParsingRecordProfileKey.twitter) {
        window.open(`https://twitter.com/${record.value.replace(/^@/, '')}`)
      } else if (record.key === ParsingRecordProfileKey.website) {
        window.open(
          record.value.indexOf('http') === 0
            ? record.value
            : `http://${record.value}`
        )
      } else {
        onCopyAddress(record.value)
      }
    } else if (
      record.type === ParsingRecordType.address ||
      record.type === ParsingRecordType.dweb ||
      record.type === ParsingRecordType.owner ||
      record.type === ParsingRecordType.manager
    ) {
      onCopyAddress(record.value)
    } else if (record.type === ParsingRecordType.customKey) {
      setParseRecordDetails(record)
      setParseRecordDetailsShowing(true)
    }
  }

  const profileLogo = (record: IAccountParsingRecord) => {
    if ([ParsingRecordProfileKey.avatar].includes(record.key as any)) {
      if (record.value.match(/[https|http]?:\/\//)) {
        return record.value
      }
    }
    return `/bit/images/social/social-${record.key}.png`
  }

  const findLogoImage = (record: IAccountParsingRecord) => {
    if (record.type === ParsingRecordType.profile) {
      return profileLogo(record)
    } else if (record.type === ParsingRecordType.address) {
      return `/bit/images/chain/chain-${
        findParsingRecordChain(record.key).value
      }.png`
    } else if (record.type === ParsingRecordType.customKey) {
      return record.key === 'bitcc_theme'
        ? '/bit/images/social/social-theme.png'
        : ''
    } else if (record.type === ParsingRecordType.dweb) {
      return `/bit/images/dweb/dweb-${findDweb(record.key).value}.png`
    }
  }

  const findIcon = (record: IAccountParsingRecord) => {
    if (record.type === ParsingRecordType.manager) {
      const _icon =
        accountInfo.manager_coin_type &&
        CoinTypeToChainMap[accountInfo.manager_coin_type] &&
        CoinTypeToChainMap[accountInfo.manager_coin_type].icon
      return _icon === CKB.icon ? 'nervos' : _icon
    } else if (record.type === ParsingRecordType.owner) {
      const _icon =
        accountInfo.owner_coin_type &&
        CoinTypeToChainMap[accountInfo.owner_coin_type] &&
        CoinTypeToChainMap[accountInfo.owner_coin_type].icon
      return _icon === CKB.icon ? 'nervos' : _icon
    }
  }

  const findKey = (record: IAccountParsingRecord) => {
    if (record.type === ParsingRecordType.profile) {
      return findProfile(record.key)
    } else if (record.type === ParsingRecordType.address) {
      return findParsingRecordChain(record.key)
    } else if (record.type === ParsingRecordType.customKey) {
      return {
        text: record.key,
        value: record.key
      }
    } else if (record.type === ParsingRecordType.dweb) {
      return findDweb(record.key)
    } else if (record.type === ParsingRecordType.owner) {
      let _key =
        record.key &&
        CoinTypeToChainMap[record.key] &&
        CoinTypeToChainMap[record.key].name
      _key = _key === CKB.name ? 'Nervos' : _key
      return {
        text: _key,
        value: _key
      }
    } else if (record.type === ParsingRecordType.manager) {
      let _key =
        record.key &&
        CoinTypeToChainMap[record.key] &&
        CoinTypeToChainMap[record.key].name
      _key = _key === CKB.name ? 'Nervos' : _key
      return {
        text: _key,
        value: _key
      }
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center py-0.5 px-4">
        <span className="text-neutral-400 text-sm font-medium">{title}</span>
        {showAction && (
          <span
            className="inline-flex cursor-pointer p-2"
            onClick={() => {
              addNew?.()
            }}
          >
            <Iconfont
              name="edit-line"
              size={14}
              color={
                [ParsingRecordType.owner, ParsingRecordType.manager].includes(
                  recordType
                )
                  ? '#E47272'
                  : '#868C98'
              }
            />
          </span>
        )}
      </div>
      <div className="flex flex-col gap-y-2">
        {editMode && !notAdd && (
          <div
            className={cn(
              'w-full h-[50px] px-2.5 py-4 bg-white border-[0.5px] border-dashed border-neutral-900 justify-center items-center gap-2 inline-flex rounded-full cursor-pointer hover:bg-neutral-100 text-gray-600 text-base'
            )}
            onClick={() => {
              addNew?.(recordType)
            }}
          >
            <Iconfont color="#121314" name="add" size="16" />
            {tt('Add new')}
          </div>
        )}
        {records.map((record, index) => {
          if (record.action !== DATA_ACTIONS.delete) {
            const logoImage = findLogoImage(record)
            const icon = findIcon(record)
            const key = findKey(record)
            return (
              <div
                key={`${recordType}${index}`}
                className={cn(styles['parsing-records_item'], 'rounded-full')}
                onClick={() => {
                  onClickRecord(record)
                }}
              >
                <span className={cn(styles['parsing-records_item_info'])}>
                  {logoImage ? (
                    <IconImage
                      url={logoImage}
                      alt={key?.text}
                      size={32}
                      rounded
                    />
                  ) : icon ? (
                    <Iconfont name={icon} size={32} rounded />
                  ) : (
                    <IconImage alt={key?.text} size={32} rounded />
                  )}
                  <span className="ml-2">
                    <div
                      className={cn(
                        styles['parsing-records_account-info_label']
                      )}
                    >
                      {key?.text}
                      {record.label && (
                        <span
                          className={cn(styles['parsing-records_item_tag'])}
                        >
                          {record.label}
                        </span>
                      )}
                    </div>
                    <div
                      className={cn(
                        styles['parsing-records_account-info_address'],
                        {
                          'font-mono': [
                            ParsingRecordType.address,
                            ParsingRecordType.dweb,
                            ParsingRecordType.owner,
                            ParsingRecordType.manager
                          ].includes(record.type as ParsingRecordType)
                        },
                        'md:!w-[560px] hidden md:block'
                      )}
                    >
                      {[
                        ParsingRecordType.address,
                        ParsingRecordType.dweb,
                        ParsingRecordType.owner,
                        ParsingRecordType.manager
                      ].includes(record.type as ParsingRecordType) ? (
                        <EllipsisText text={record.value} />
                      ) : (
                        record.value
                      )}
                    </div>
                    <div
                      className={cn(
                        styles['parsing-records_account-info_address'],
                        {
                          'font-mono': [
                            ParsingRecordType.address,
                            ParsingRecordType.dweb,
                            ParsingRecordType.owner,
                            ParsingRecordType.manager
                          ].includes(record.type as ParsingRecordType)
                        },
                        'block md:hidden'
                      )}
                      style={{
                        width: isMobile() ? 'calc(100vw - 150px)' : undefined
                      }}
                    >
                      {[
                        ParsingRecordType.address,
                        ParsingRecordType.dweb,
                        ParsingRecordType.owner,
                        ParsingRecordType.manager
                      ].includes(record.type as ParsingRecordType) ? (
                        <EllipsisText text={record.value} />
                      ) : (
                        record.value
                      )}
                    </div>
                  </span>
                </span>
                {editMode &&
                  ![
                    ParsingRecordType.owner,
                    ParsingRecordType.manager
                  ].includes(record.type as ParsingRecordType) && (
                    <ActionButtons
                      onEdit={() => {
                        onEdit?.(record)
                      }}
                      onDelete={() => {
                        onDelete?.(record)
                      }}
                    />
                  )}
              </div>
            )
          }
          return null
        })}
      </div>
      <RecordDetails
        showing={parseRecordDetailsShowing}
        recordDetails={parseRecordDetails}
        onClose={() => {
          setParseRecordDetailsShowing(false)
        }}
      />
    </div>
  )
}
