import { validateParams } from '@did/tools'
import { BasicService } from './basic-service'
import {
  IEditSubAccountParams,
  ISubAccountService,
  ITransactionStatusParams,
  ITransactionStatusRes,
  SignTxListParams,
  SignTxListRes
} from '@did/types'

export default class SubAccount
  extends BasicService
  implements ISubAccountService
{
  /**
   * edit sub account
   * @param type
   * @param key_info
   * @param account
   * @param edit_key
   * @param edit_value
   */
  editSubAccount({
    key_info,
    account,
    edit_key,
    edit_value
  }: IEditSubAccountParams): Promise<SignTxListParams> {
    validateParams(
      { ...key_info, account, edit_key, edit_value },
      'editSubAccount'
    )
    return this.axios.post('/sub/account/edit', {
      type: 'blockchain',
      key_info,
      account,
      edit_key,
      edit_value
    })
  }

  /**
   * send transaction
   * @param action
   * @param list
   * @param sign_key
   * @param sign_address
   */
  sendTransaction({
    action,
    sign_list,
    sign_key,
    sign_address
  }: SignTxListRes): Promise<{ hash: string; hash_list: string[] }> {
    validateParams({ action, sign_list, sign_key }, 'sendTransaction')
    return this.axios.post('/transaction/send', {
      action,
      sign_list,
      sign_key,
      sign_address
    })
  }

  /**
   * transaction status
   * @param type
   * @param key_info
   * @param action
   * @param sub_action
   * @param account
   */
  transactionStatus({
    key_info,
    action,
    sub_action,
    account
  }: ITransactionStatusParams): Promise<ITransactionStatusRes> {
    validateParams({ ...key_info, action, account }, 'transactionStatus')
    return this.axios.post('/transaction/status', {
      type: 'blockchain',
      key_info,
      action,
      sub_action,
      account
    })
  }
}
