import { AccountAutocomplete } from '@did/das-app-components'
import {
  useCccContext,
  useDasAppConfigContext,
  useDasBalanceContext
} from '@did/das-app-context'
import { DasButton, Iconfont } from '@did/uikit'
import { copyText, ErrorInfo } from '@did/tools'
import React, { useMemo, useState, useEffect } from 'react'
import { RewardRecords } from './reward-records'
import { CKB } from '@did/constants/chain'

const DEFAULT_PROFIT_RATE = 0.1
const DEFAULT_DISCOUNT = 0.05

export const ReferralPage: React.FC = () => {
  const { tt, services, connectedAccount, toast, router, walletSdk } =
    useDasBalanceContext()
  const { isDobsMode, ckbAddress } = useCccContext()
  const [account, setAccount] = useState<string>()
  const config = useDasAppConfigContext()
  const [accountList, setAccountList] = useState<string[]>()

  const getMyAccounts = async () => {
    try {
      let res: any

      if (isDobsMode && ckbAddress) {
        res = await services.account.myDobs({
          key_info: {
            coin_type: CKB.coinType,
            key: ckbAddress
          }
        })
      } else if (
        isDobsMode === false &&
        connectedAccount?.chain?.coinType &&
        connectedAccount?.address
      ) {
        res = await services.account.myAccounts({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          }
        })
      }

      if (res?.list?.[0]) {
        setAccountList(res?.list?.map((a: any) => a.account))
      }
    } catch (err: any) {
      ErrorInfo.error(err)
    }
  }

  useEffect(() => {
    getMyAccounts()
  }, [
    isDobsMode,
    ckbAddress,
    connectedAccount?.address,
    connectedAccount?.chain?.coinType
  ])

  const { inviterDiscount, inviterProfitRate } = useMemo(() => {
    if (!config) return { inviterDiscount: 0, inviterProfitRate: 0 }

    return {
      inviterDiscount:
        (Number(config?.inviter_discount) || DEFAULT_DISCOUNT) * 100,
      inviterProfitRate:
        (Number(config?.profit_rate_of_inviter) || DEFAULT_PROFIT_RATE) * 100
    }
  }, [config])

  const inviterAccount = useMemo(() => {
    if (!account && !accountList?.[0]) return ''
    return account || accountList?.[0] || ''
  }, [accountList?.[0], account])

  const invitationLink = useMemo(() => {
    if (!account && !accountList?.[0]) return ''
    const baseUrl = new URL(window.location.href)
    baseUrl.pathname = `/bit/r`
    baseUrl.search = `i=${inviterAccount}`
    return baseUrl.toString()
  }, [inviterAccount])

  const handleCopyLink = () => {
    copyText(invitationLink).then(() => {
      toast('👌 ' + tt('Copied'))
    })
  }

  const shareOnImage = () => {
    router.push(`/referral/invitation-link/${inviterAccount}`)
  }

  const shareOnTwitter = () => {
    const sharedMsg = `\uD83E\uDD29I have got a cool "${inviterAccount}"! @DIDbased\n\n\uD83D\uDE80Come get your .bit now!\n\nCreate & Get a ✨5% discount:\n${invitationLink}`
    const url = `https://twitter.com/compose/tweet?text=${encodeURIComponent(
      sharedMsg
    )}`
    window.open(url)
  }

  const shareOnTelegram = () => {
    const sharedMsg = `\uD83E\uDD29I have got a cool "${inviterAccount}"! @DIDbased\n\n\uD83D\uDE80Come get your .bit now!\n\nCreate & Get a ✨5% discount.`
    const targetUrl = `https://telegram.me/share/url?url=${encodeURIComponent(
      invitationLink
    )}&text=${encodeURIComponent(sharedMsg)}`
    window.open(targetUrl)
  }

  const shareOnFacebook = () => {
    const targetUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      invitationLink
    )}`
    window.open(targetUrl)
  }

  const handleStartClick = () => {
    walletSdk?.connectWallet()
  }

  const goHome = () => {
    router.push('/create')
  }

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 pt-5 md:px-0 flex flex-col items-center">
      <Iconfont name="referral" size="66" />
      <div className="flex flex-col items-center my-4 text-2xl font-bold leading-7 ">
        <div>{tt('Refer Friends,')}</div>
        <div>{tt('Earn & Save Crypto')}</div>
      </div>
      <div className="w-full text-sm font-bold text-center leading-4 mb-9 sm:px-11">
        {tt(
          'Invite friends to register .bit, they will save {inviterDiscount}%, and you will earn {inviterProfitRate}% reward.',
          {
            inviterDiscount,
            inviterProfitRate
          }
        )}
      </div>
      {connectedAccount?.address && !Boolean(accountList?.length) && (
        <div className="w-full">
          <div className=" text-sm font-normal text-center text-[#31333E] sm:mx-11 ">
            {tt(
              'You do not have a DAS account, register your DAS account to generate an invitation link and get the reward.'
            )}
          </div>
          <DasButton className="w-full mt-5 mb-9" black large onClick={goHome}>
            {tt('Create now')}
          </DasButton>
        </div>
      )}
      {!connectedAccount?.address && (
        <DasButton black large onClick={handleStartClick}>
          <span className=" px-4">{tt('Get Started')}</span>
        </DasButton>
      )}
      {connectedAccount?.address && (
        <>
          {Boolean(accountList?.length) && (
            <>
              <div className="w-full">
                <AccountAutocomplete
                  className="w-full bg-white "
                  placeholder={tt('Select DID to change referral link')}
                  onChange={setAccount}
                />
              </div>
              <div className=" flex justify-between items-center mt-4 border border-[#b6c4d966] rounded-xl h-10 w-full p-1 pl-3 ">
                <div className="  text-base overflow-hidden font-medium text-[#5F6570] whitespace-nowrap w-60 sm:w-80">
                  {invitationLink}
                </div>
                <div className=" w-20 shrink-0 flex justify-end">
                  <DasButton black small onClick={handleCopyLink}>
                    {tt('Copy')}
                  </DasButton>
                </div>
              </div>
              <div className=" pt-4 pb-1 mb-9 ">
                <Iconfont
                  className=" mx-3 cursor-pointer"
                  name="image"
                  size={32}
                  onClick={shareOnImage}
                />
                <Iconfont
                  className=" mx-3 cursor-pointer"
                  name="facebook"
                  size={32}
                  onClick={shareOnFacebook}
                />
                <Iconfont
                  className=" mx-3 cursor-pointer"
                  name="telegram"
                  size={32}
                  onClick={shareOnTelegram}
                />
                <Iconfont
                  className=" mx-3 cursor-pointer"
                  name="twitter"
                  size={32}
                  onClick={shareOnTwitter}
                />
              </div>
            </>
          )}
          <RewardRecords />
        </>
      )}
    </div>
  )
}

export { RewardRecordsPage } from './reward-records-page'
export { InvitationLinkPage } from './invitation-link-page'
