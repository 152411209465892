import { useDasBalanceContext } from '@did/das-app-context'
import { useRegisterStatus } from '@did/das-app-hooks'
import {
  ACCOUNT_STATUS_TIPS_TYPE,
  StatusPageType
} from '@did/das-app-types/module'
import { Iconfont } from '@did/uikit'
import { useMemo } from 'react'
import { RegisterFailed } from './register-failed'
import { RegisteredInfo } from './registered-info'
import { RegisteringInfo } from './registering-info'
import { RegisteringPaymentConfirm } from './registering-payment-confirm'

export const StatusPage: StatusPageType = ({ bitName, paid }) => {
  const { tt, router } = useDasBalanceContext()

  const {
    accountInfo,
    tipStatus,
    onPaid,
    onRegisterAgain,
    goHome,
    goMe,
    onRegisterAnother
  } = useRegisterStatus({
    bitName,
    paid: !!paid
  })

  const { statusContent, loading, bgType } = useMemo(() => {
    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registeringPaymentConfirm) {
      return {
        statusContent: (
          <RegisteringPaymentConfirm
            bitName={bitName}
            onPaid={onPaid}
            onRegisterAgain={onRegisterAgain}
          />
        ),
        loading: false,
        bgType: 'blue'
      }
    }

    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registering && accountInfo) {
      return {
        statusContent: (
          <RegisteringInfo
            accountInfo={accountInfo}
            onRegisterAnother={onRegisterAnother}
            goMe={goMe}
          />
        ),
        loading: false,
        bgType: 'blue'
      }
    }

    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registered) {
      return {
        statusContent: (
          <RegisteredInfo bitName={bitName} goHome={goHome} goMe={goMe} />
        ),
        bgType: 'green',
        loading: false
      }
    }

    if (tipStatus === ACCOUNT_STATUS_TIPS_TYPE.registerFailed) {
      return {
        statusContent: <RegisterFailed bitName={bitName} goHome={goHome} />,
        loading: false,
        bgType: 'red'
      }
    }
    return {
      statusContent: (
        <div className="w-full flex justify-center mt-10">
          <Iconfont className="animate-spin" size={36} name="loading" />
        </div>
      ),
      loading: true
    }
  }, [bitName, accountInfo, tipStatus])

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 pt-5 md:px-0 flex flex-col items-center">
      {statusContent}
    </div>
  )
}
