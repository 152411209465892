import { FIAT_DECIMAL_PLACES } from '@did/constants'
import { useDasBalanceContext } from '@did/das-app-context'
import { SAVE_CODE_TYPE, SaveCodeItemType } from '@did/das-app-types/module'
import { thousandSplit, cn } from '@did/tools'
import { IBaseProps } from '@did/types'
import { Iconfont, TextInput } from '@did/uikit'
import React, { useState, useEffect } from 'react'

interface ISelectTagProps extends IBaseProps {
  itenms: {
    value: string
    label: string
  }[]
  value: string
  onChange: (value: string) => void
}

const SelectTag: React.FC<ISelectTagProps> = ({ itenms, value, onChange }) => {
  return (
    <div className="flex items-center">
      {itenms.map((item, n) => (
        <div
          key={n}
          className={cn(
            ' px-4 font-normal cursor-pointer border rounded-3xl text-sm py-0.5',
            {
              ['text-pri-700 border-primary  bg-green-50']:
                value === item.value,
              ['text-gray-600 border-white bg-gray-100']: value !== item.value,
              ['ml-2']: n > 0
            }
          )}
          onClick={() => {
            onChange(item.value)
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}

export const SaveCodeItem: SaveCodeItemType = ({
  saveFee,
  inviter,
  builderCode,
  onBuilderCodeBlur,
  onBuilderCodeChange,
  onInviterBlur,
  onInviterChange,
  inviterErrorMessages,
  builderCodeErrorMessages,
  onTypeChange,
  inviterDiscount,
  saveFeeLoading
}) => {
  const { tt } = useDasBalanceContext()
  const [open, setOpen] = useState(false)
  const [selectValue, setSelectValue] = useState<SAVE_CODE_TYPE>(
    SAVE_CODE_TYPE.INVITER
  )

  useEffect(() => {
    if (!inviterErrorMessages?.filter(Boolean).length) {
      return
    }
    setOpen(true)
    setSelectValue(SAVE_CODE_TYPE.INVITER)
  }, [inviterErrorMessages?.length])

  useEffect(() => {
    if (!builderCodeErrorMessages?.filter(Boolean).length) {
      return
    }
    setOpen(true)
    setSelectValue(SAVE_CODE_TYPE.BUILDER_CODE)
  }, [builderCodeErrorMessages?.length])

  useEffect(() => {
    if (!!builderCode) {
      setSelectValue(SAVE_CODE_TYPE.BUILDER_CODE)
      onTypeChange?.(SAVE_CODE_TYPE.BUILDER_CODE)
    }
  }, [builderCode])

  return (
    <>
      <div className="flex justify-between items-center py-1.5">
        <div
          className="inline-flex items-center text-xl font-medium text-neutral-400 cursor-pointer hover:opacity-70"
          onClick={() => {
            setOpen(!open)
          }}
        >
          {tt('Code')}
          <Iconfont
            className={cn('ml-1 transition-all', {
              '-rotate-180': open
            })}
            name="arrow-down"
            color="#B0B8BF"
            size="16"
          />
        </div>
        <div>
          {!saveFeeLoading &&
            (saveFee ? (
              <span className="text-[#FFA800] text-xl font-bold font-['Barlow-Medium']">
                -${thousandSplit(saveFee || 0, FIAT_DECIMAL_PLACES)}
              </span>
            ) : (
              <span className="text-xl font-bold text-neutral-400">-</span>
            ))}
          {saveFeeLoading && (
            <Iconfont
              name="loading"
              size={16}
              className="mr-1 animate-spin "
              color="#B0B8BF"
            />
          )}
        </div>
      </div>
      {open && (
        <div className="mt-5">
          <SelectTag
            value={selectValue}
            onChange={(value) => {
              setSelectValue(value as any)
              onTypeChange?.(value as any)
            }}
            itenms={[
              {
                value: SAVE_CODE_TYPE.INVITER,
                label: tt('Inviter')
              },
              {
                value: SAVE_CODE_TYPE.BUILDER_CODE,
                label: tt('Builder code')
              }
            ]}
          />
          {selectValue === 'inviter' ? (
            <>
              <TextInput
                key={SAVE_CODE_TYPE.INVITER}
                errorMessages={inviterErrorMessages}
                suffix=".bit"
                className=" mt-2 text-sm font-medium"
                disabled={!!builderCode}
                onBlur={(e) => onInviterBlur?.(e.target.value)}
                value={inviter}
                onChange={onInviterChange}
                placeholder={tt(`Enter inviter's .bit account`)}
              />
              <div className="text-xs font-normal text-orange-400 mt-2">
                *
                {tt('Save {inviterDiscount}% on fees', {
                  inviterDiscount: inviterDiscount || ''
                })}
              </div>
            </>
          ) : (
            <TextInput
              key={SAVE_CODE_TYPE.BUILDER_CODE}
              errorMessages={builderCodeErrorMessages}
              className="mt-2  text-sm font-medium"
              onBlur={(e) => onBuilderCodeBlur?.(e.target.value)}
              value={builderCode}
              placeholder={tt('Enter a Builder Code')}
              onChange={onBuilderCodeChange}
            />
          )}
        </div>
      )}
    </>
  )
}
