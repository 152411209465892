import { ParsingRecordDwebKey, ParsingRecordProfileKey } from '@did/types'

// EVM-based Chains https://github.com/ethereum-lists/chains
export enum ChainId {
  eth = 1,
  ethHolesky = 17000,
  bsc = 56,
  bscTestnet = 97,
  polygon = 137,
  polygonAmoy = 80002,
  tron = '0x2b6653dc',
  tronNile = '0xcd8690dc',
  btc = 'livenet',
  btcTestnet = 'testnet'
}

// SLIP-0044 : Registered coin types for BIP-0044 https://github.com/satoshilabs/slips/blob/master/slip-0044.md
export enum CoinType {
  btc = '0',
  doge = '3',
  eth = '60',
  trx = '195',
  ckb = '309',
  bsc = '9006',
  pol = '966',
  nostr = '1237'
}

export interface IMainChain {
  name: string
  symbol: string
  coinType: CoinType
  decimals: number
  icon: string
  tokenId: string
  getExplorerTrx: (isProd: boolean) => string
  getExplorerAddress: (isProd: boolean) => string
}

export interface ICoinTypeInfo {
  text: string
  value: string
  coinType: string
}
export const DASBalanceTokenId = 'ckb_das'

export const CCCBalanceTokenId = 'ckb_ccc'

export const DASDidPointTokenId = 'did_point'

export const USD = {
  name: 'USD',
  symbol: 'USD',
  decimals: 2,
  coinType: CoinType.ckb,
  icon: 'usd',
  tokenId: 'stripe_usd',
  explorerTrx: '',
  testExplorerTrx: ''
}

export const CKB: IMainChain = {
  name: 'Device',
  symbol: 'CKB',
  coinType: CoinType.ckb,
  decimals: 8,
  icon: 'computer',
  tokenId: 'ckb_ckb',
  getExplorerTrx: (isProdData) =>
    isProdData
      ? 'https://explorer.nervos.org/transaction/'
      : 'https://pudge.explorer.nervos.org/transaction/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://explorer.nervos.org/address/'
      : 'https://explorer.nervos.org/aggron/address/'
}

export const ETH: IMainChain = {
  name: 'Ethereum',
  symbol: 'ETH',
  coinType: CoinType.eth,
  decimals: 18,
  icon: 'ethereum',
  tokenId: 'eth_eth',
  getExplorerTrx: (isProdData) =>
    isProdData
      ? 'https://etherscan.io/tx/'
      : 'https://holesky.etherscan.io/tx/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://etherscan.io/address/'
      : 'https://holesky.etherscan.io/address/'
}

export const TRON: IMainChain = {
  name: 'Tron',
  symbol: 'TRX',
  coinType: CoinType.trx,
  decimals: 6,
  icon: 'tron',
  tokenId: 'tron_trx',
  getExplorerTrx: (isProdData) =>
    isProdData
      ? 'https://tronscan.org/#/transaction/'
      : 'https://nile.tronscan.org/#/transaction/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://tronscan.org/#/address/'
      : 'https://nile.tronscan.org/#/address/'
}

export const BSC: IMainChain = {
  name: 'BSC',
  symbol: 'BNB',
  coinType: CoinType.bsc,
  decimals: 18,
  icon: 'binance-smart-chain',
  tokenId: 'bsc_bnb',
  getExplorerTrx: (isProdData) =>
    isProdData ? 'https://bscscan.com/tx/' : 'https://testnet.bscscan.com/tx/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/'
}

export const Polygon: IMainChain = {
  name: 'Polygon',
  symbol: 'POL',
  coinType: CoinType.pol,
  decimals: 18,
  icon: 'polygon',
  tokenId: 'polygon_pol',
  getExplorerTrx: (isProdData) =>
    isProdData
      ? 'https://polygonscan.com/tx/'
      : 'https://amoy.polygonscan.com/tx/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://polygonscan.com/address/'
      : 'https://amoy.polygonscan.com/address/'
}

export const BTC: IMainChain = {
  name: 'Bitcoin',
  symbol: 'BTC',
  coinType: CoinType.btc,
  decimals: 8,
  icon: 'bitcoin',
  tokenId: 'btc_btc',
  getExplorerTrx: (isProdData) =>
    isProdData
      ? 'https://blockchair.com/bitcoin/transaction/'
      : 'https://blockchair.com/bitcoin/testnet/transaction/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://blockchair.com/bitcoin/address/'
      : 'https://blockchair.com/bitcoin/testnet/address/'
}

export const DOGE: IMainChain = {
  name: 'Dogecoin',
  symbol: 'DOGE',
  coinType: CoinType.doge,
  decimals: 8,
  icon: 'dogecoin',
  tokenId: 'doge_doge',
  getExplorerTrx: (isProdData) =>
    isProdData
      ? 'https://blockchair.com/dogecoin/transaction/'
      : 'https://blockexplorer.one/dogecoin/testnet/tx/',
  getExplorerAddress: (isProdData) =>
    isProdData
      ? 'https://blockchair.com/dogecoin/address/'
      : 'https://blockexplorer.one/dogecoin/testnet/address/'
}

export const CoinTypeToChainMap: { [key: string]: IMainChain } = {
  [CoinType.eth]: ETH,
  [CoinType.bsc]: BSC,
  [CoinType.pol]: Polygon,
  [CoinType.trx]: TRON,
  [CoinType.doge]: DOGE,
  [CoinType.btc]: BTC,
  [CoinType.ckb]: CKB
}

export const EvmCoinTypes = [CoinType.eth, CoinType.bsc, CoinType.pol]

// reference https://github.com/dotbitHQ/cell-data-generator/blob/master/data/record_key_namespace.txt
export const PARSING_RECORD_SUPPORT_CHAINS: ICoinTypeInfo[] = [
  {
    text: BTC.symbol,
    value: BTC.symbol.toLowerCase(),
    coinType: BTC.coinType
  },
  {
    text: ETH.symbol,
    value: ETH.symbol.toLowerCase(),
    coinType: ETH.coinType
  },
  {
    text: BSC.symbol,
    value: BSC.name.toLowerCase(),
    coinType: BSC.coinType
  },
  {
    text: 'ADA',
    value: 'ada',
    coinType: '1815'
  },
  {
    text: 'XRP',
    value: 'xrp',
    coinType: '144'
  },
  {
    text: 'ATOM',
    value: 'atom',
    coinType: '118'
  },
  {
    text: 'AVAX',
    value: 'avalanche',
    coinType: '9000'
  },
  {
    text: 'BCH',
    value: 'bch',
    coinType: '145'
  },
  {
    text: 'BSV',
    value: 'bsv',
    coinType: '236'
  },
  {
    text: 'Celo',
    value: 'celo',
    coinType: '52752'
  },
  {
    text: 'CKB',
    value: 'ckb',
    coinType: '309'
  },
  {
    text: 'DASH',
    value: 'dash',
    coinType: '5'
  },
  {
    text: 'DOGE',
    value: 'doge',
    coinType: '3'
  },
  {
    text: 'DOT',
    value: 'dot',
    coinType: '354'
  },
  {
    text: 'EOS',
    value: 'eos',
    coinType: '194'
  },
  {
    text: 'ETC',
    value: 'etc',
    coinType: '61'
  },
  {
    text: 'FIL',
    value: 'fil',
    coinType: '461'
  },
  {
    text: 'FLOW',
    value: 'flow',
    coinType: '539'
  },
  {
    text: 'HT',
    value: 'heco',
    coinType: '1010'
  },
  {
    text: 'ICP',
    value: 'dfinity',
    coinType: '223'
  },
  {
    text: 'IOST',
    value: 'iost',
    coinType: '291'
  },
  {
    text: 'IOTA',
    value: 'iota',
    coinType: '4218'
  },
  {
    text: 'IOTX',
    value: 'iotx',
    coinType: '304'
  },
  {
    text: 'KSM',
    value: 'ksm',
    coinType: '434'
  },
  {
    text: 'LTC',
    value: 'ltc',
    coinType: '2'
  },
  {
    text: 'LUNA',
    value: 'terra',
    coinType: '330'
  },
  {
    text: Polygon.symbol,
    value: Polygon.name.toLowerCase(),
    coinType: CoinType.pol
  },
  {
    text: 'NEAR',
    value: 'near',
    coinType: '397'
  },
  {
    text: 'SC',
    value: 'sc',
    coinType: '1991'
  },
  {
    text: 'SOL',
    value: 'sol',
    coinType: '501'
  },
  {
    text: 'STX',
    value: 'stacks',
    coinType: '5757'
  },
  {
    text: TRON.symbol,
    value: TRON.symbol.toLowerCase(),
    coinType: CoinType.trx
  },
  {
    text: 'VET',
    value: 'vet',
    coinType: '818'
  },
  {
    text: 'XEM',
    value: 'xem',
    coinType: '43'
  },
  {
    text: 'XLM',
    value: 'xlm',
    coinType: '148'
  },
  {
    text: 'XMR',
    value: 'xmr',
    coinType: '128'
  },
  {
    text: 'XTZ',
    value: 'xtz',
    coinType: '1729'
  },
  {
    text: 'ZEC',
    value: 'zec',
    coinType: '133'
  },
  {
    text: 'ZIL',
    value: 'zil',
    coinType: '313'
  }
]

export const DWEB_KEY_OPTIONS = [
  {
    text: 'IPFS',
    value: ParsingRecordDwebKey.ipfs
  },
  {
    text: 'IPNS',
    value: ParsingRecordDwebKey.ipns
  },
  {
    text: 'Arweave',
    value: ParsingRecordDwebKey.arweave
  },
  {
    text: 'Resilio',
    value: ParsingRecordDwebKey.resilio
  },
  {
    text: 'Skynet',
    value: ParsingRecordDwebKey.skynet
  }
]

export const PROFILE_KEY_OPTIONS = [
  {
    text: 'Nostr',
    value: ParsingRecordProfileKey.nostr
  },
  {
    text: 'Twitter',
    value: ParsingRecordProfileKey.twitter
  },
  {
    text: 'Facebook',
    value: ParsingRecordProfileKey.facebook
  },
  {
    text: 'Instagram',
    value: ParsingRecordProfileKey.instagram
  },
  {
    text: 'Reddit',
    value: ParsingRecordProfileKey.reddit
  },
  {
    text: 'LinkedIn',
    value: ParsingRecordProfileKey.linkedin
  },
  {
    text: 'GitHub',
    value: ParsingRecordProfileKey.github
  },
  {
    text: 'Telegram',
    value: ParsingRecordProfileKey.telegram
  },
  {
    text: 'Discord',
    value: ParsingRecordProfileKey.discord
  },
  {
    text: 'Youtube',
    value: ParsingRecordProfileKey.youtube
  },
  {
    text: 'bilibili',
    value: ParsingRecordProfileKey.bilibili
  },
  {
    text: 'Medium',
    value: ParsingRecordProfileKey.medium
  },
  {
    text: 'TikTok',
    value: ParsingRecordProfileKey.tiktok
  },
  {
    text: 'Weibo',
    value: ParsingRecordProfileKey.weibo
  },
  {
    text: 'Jike',
    value: ParsingRecordProfileKey.jike
  },
  {
    text: 'NextID',
    value: ParsingRecordProfileKey.nextid
  },
  {
    text: 'Dribbble',
    value: ParsingRecordProfileKey.dribbble
  },
  {
    text: 'Behance',
    value: ParsingRecordProfileKey.behance
  },
  {
    text: 'Mirror',
    value: ParsingRecordProfileKey.mirror
  },
  {
    text: 'Avatar',
    value: ParsingRecordProfileKey.avatar
  },
  {
    text: 'Description',
    value: ParsingRecordProfileKey.description
  },
  {
    text: 'Website',
    value: ParsingRecordProfileKey.website
  }
]

export const getCoinTypeToChainIdMap = (isProdData: boolean) => {
  const CoinTypeToChainIdMap: Record<string, ChainId> = {
    [CoinType.eth]: isProdData ? ChainId.eth : ChainId.ethHolesky,
    [CoinType.bsc]: isProdData ? ChainId.bsc : ChainId.bscTestnet,
    [CoinType.pol]: isProdData ? ChainId.polygon : ChainId.polygonAmoy
    // [CoinType.trx]: isProdData ? ChainId.tron : ChainId.tronNile,
    // [CoinType.btc]: isProdData ? ChainId.btc : ChainId.btcTestnet
  }

  return CoinTypeToChainIdMap
}

export const ChainIdToCoinTypeMap: Record<string, CoinType> = {
  [ChainId.eth]: CoinType.eth,
  [ChainId.bsc]: CoinType.bsc,
  [ChainId.polygon]: CoinType.pol,
  [ChainId.tron]: CoinType.trx,
  [ChainId.btc]: CoinType.btc,
  [ChainId.ethHolesky]: CoinType.eth,
  [ChainId.bscTestnet]: CoinType.bsc,
  [ChainId.polygonAmoy]: CoinType.pol,
  [ChainId.tronNile]: CoinType.trx,
  [ChainId.btcTestnet]: CoinType.btc
}

export const TOKEN_ID_TO_CHAIN: { [key: string]: IMainChain } = {
  [ETH.tokenId]: ETH,
  [CKB.tokenId]: CKB,
  [TRON.tokenId]: TRON,
  [BTC.tokenId]: BTC,
  [BSC.tokenId]: BSC,
  [Polygon.tokenId]: Polygon,
  [DASBalanceTokenId]: CKB,
  [DOGE.tokenId]: DOGE
}
