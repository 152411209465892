import React from "react";
import styles from "./index.module.scss";
import { WireframeType } from "@did/das-balance-types";

export const Wireframe: WireframeType = ({
  shape = "round",
  height = 10,
  width = 10,
}) => {
  const shapeClass = styles[shape] || "";

  return (
    <div
      className={`${styles.wireframe} ${shapeClass}`}
      style={{ height: `${height}px`, width: `${width}px` }}
    />
  );
};

