import {
  useDasBalanceContext,
  useDasAppConfigContext
} from '@did/das-app-context'
import { ErrorTipsType } from '@did/das-app-types/module'
import { ACCOUNT_STATUS } from '@did/types'
import React, { useEffect, useState } from 'react'
import {
  CHAR_TYPE,
  accountChars,
  splitAccount
} from '@did/tools/bit-account-string-handle'
import { LANGUAGE } from '@did/types/uikit'
import { Iconfont } from '@did/uikit'

export const AccountNameErrorInfo = ({ errorText }: { errorText?: string }) => {
  const { tt } = useDasBalanceContext()

  return (
    <div className="text-base text-red-400 mt-3 md:mt-7 text-center font-semibold w-full">
      {errorText}
      <a
        className="text-zinc-500 ml-0.5 hover:opacity-70"
        href="https://community.d.id/c/knowledge-base-bit/charsets"
        target="_blank"
      >
        {tt('Rules Details')}
        <Iconfont
          className="ml-0.5"
          name="arrow-right-up"
          color="#5F6570"
          size="9"
        />
      </a>
    </div>
  )
}

export const ErrorTips: ErrorTipsType = ({
  accountName,
  onCheckPass,
  errorCode,
  showIncorrect,
  accountStatus
}) => {
  const { tt, lang } = useDasBalanceContext()
  const config = useDasAppConfigContext()

  const [showIllegalStringLength, setShowIllegalStringLength] = useState(false)

  const [showIncorrectAccountFormat, setShowIncorrectAccountFormat] =
    useState(false)

  const [incorrectAccountFormatText, setIncorrectAccountFormatText] =
    useState('')

  const incorrectAccountFormat = (account: string) => {
    const linker = lang?.value === LANGUAGE.zhCN ? '、' : ', '
    const list = accountChars(account)
    // Characters x, y, z are not included
    let notIncluded: string[] = []
    list.forEach((item) => {
      if (item.char_set_name === CHAR_TYPE.unknown) {
        notIncluded.push(item.char)
      }
    })
    if (notIncluded.length > 0) {
      notIncluded = [...new Set(notIncluded)]
      return tt('Characters {list} are not included.', {
        list: notIncluded.join(linker)
      })
    } else {
      // For safety, character x and y cannot be used in combination
      let charTypes: CHAR_TYPE[] = []
      list.forEach((item) => {
        charTypes.push(item.char_set_name)
      })
      const charTypesSet = new Set(charTypes)
      charTypesSet.delete(CHAR_TYPE.emoji)
      charTypesSet.delete(CHAR_TYPE.number)
      charTypesSet.delete(CHAR_TYPE.unknown)
      const language: { [key: string]: string } = {
        [CHAR_TYPE.english]: tt('English'),
        [CHAR_TYPE.simplifiedChinese]: tt('Simplified chinese'),
        [CHAR_TYPE.traditionalChinese]: tt('Traditional chinese'),
        [CHAR_TYPE.japanese]: tt('Japanese'),
        [CHAR_TYPE.korean]: tt('Korean'),
        [CHAR_TYPE.russian]: tt('Russian'),
        [CHAR_TYPE.turkish]: tt('Turkish'),
        [CHAR_TYPE.thai]: tt('Thai'),
        [CHAR_TYPE.vietnamese]: tt('Vietnamese')
      }
      charTypes = [...charTypesSet]
      const languageList = charTypes.map((item) => {
        return language[item]
      })
      if (languageList.length > 1) {
        return tt('For safety, {list} cannot be used in combination.', {
          list: languageList.join(linker)
        })
      }
      return ''
    }
  }

  const checkSearchWord = (value: string, isSubAccount?: boolean) => {
    const result = {
      isIllegal: false,
      isInCorrectFormat: false
    }

    const splitArr = splitAccount(value)
    const nonComplianceChar = splitArr.find(
      (item: { char_set_name: number; char: string }) => {
        return item.char_set_name === CHAR_TYPE.unknown
      }
    )
    const accountLength = splitArr.length
    if (
      !isSubAccount &&
      (accountLength < config?.min_account_len! ||
        accountLength > config?.max_account_len!) &&
      [ACCOUNT_STATUS.error, ACCOUNT_STATUS.notOpenRegister].includes(
        accountStatus!
      )
    ) {
      result.isIllegal = true
    }
    result.isInCorrectFormat = !!nonComplianceChar
    return result
  }

  useEffect(() => {
    if (!accountName) return
    const accountList = accountName.replace(/\.bit$/, '').split('.')
    let formatCheckResult = {
      isIllegal: false,
      isInCorrectFormat: false
    }

    if (accountList[0]) {
      setIncorrectAccountFormatText(incorrectAccountFormat(accountList[0]))
      formatCheckResult = checkSearchWord(
        accountList[0],
        accountList.length === 2
      )
    }

    if (accountList[1]) {
      setIncorrectAccountFormatText(incorrectAccountFormat(accountList[1]))
      formatCheckResult = checkSearchWord(accountList[1])
    }

    setShowIllegalStringLength(formatCheckResult.isIllegal)
    setShowIncorrectAccountFormat(formatCheckResult.isInCorrectFormat)
    if (formatCheckResult.isIllegal || formatCheckResult.isInCorrectFormat) {
      onCheckPass?.(true)
    } else {
      onCheckPass?.(false)
    }
  }, [accountName, accountStatus])

  return (
    <>
      {showIllegalStringLength ? (
        <div className="text-base text-red-400 mt-3 md:mt-7 text-center font-semibold w-full">
          {tt('Account name length must be {min} to {max} characters.', {
            min: config?.min_account_len || 4,
            max: config?.max_account_len || 42
          })}
          <a
            className="text-zinc-500 ml-0.5 hover:opacity-70"
            href="https://community.d.id/c/knowledge-base-bit/open-registration-rules"
            target="_blank"
          >
            {tt('Releasing plan')}
            <Iconfont
              className="ml-0.5"
              name="arrow-right-up"
              color="#5F6570"
              size="9"
            />
          </a>
        </div>
      ) : showIncorrectAccountFormat || showIncorrect ? (
        <AccountNameErrorInfo errorText={incorrectAccountFormatText} />
      ) : null}
    </>
  )
}

export const NotOpenRegisterError = ({
  registrableDate
}: {
  registrableDate: string
}) => {
  const { tt } = useDasBalanceContext()

  if (!registrableDate) {
    return (
      <div className="text-base text-red-400 mt-3 md:mt-7 text-center font-semibold w-full">
        {tt('This account has not been released. Try another one.')}
        <a
          className="text-zinc-500 ml-0.5 hover:opacity-70"
          href="https://community.d.id/c/knowledge-base-bit/open-registration-rules"
          target="_blank"
        >
          {tt('Releasing plan')}
          <Iconfont
            className="ml-0.5"
            name="arrow-right-up"
            color="#5F6570"
            size="9"
          />
        </a>
      </div>
    )
  }

  return (
    <div className="text-base text-red-400 mt-3 md:mt-7 text-center font-semibold w-full">
      {tt('To be released at on-chain time: {date}.', {
        date: registrableDate
      })}
      <span
        className="cursor-pointer text-sky-700 hover:opacity-70 ml-0.5"
        // @click="addToCalendar"
      >
        {tt('Add to calendar')}
      </span>
    </div>
  )
}
