import {
  useDasBalanceContext,
  useDasAppConfigContext,
  useCccContext
} from '@did/das-app-context'
import { RenewalType } from '@did/das-app-types/module'
import { Decimal, thousandSplit, formatDateTime, cn } from '@did/tools'
import { DasButton, Dialog } from '@did/uikit'
import { useState, useMemo } from 'react'
import styles from './styles.module.scss'
import { ChangeEvent } from 'react'
import {
  DIDPOINT_ACTIONS,
  FIAT_DECIMAL_PLACES,
  TIME_FORMAT,
  TOKEN_DECIMAL_PLACES
} from '@did/constants'
import dayjs from 'dayjs'
import { PaymentConfirm } from '../../account/register-page/payment-confirm'
import { IToken } from '@did/types'
import {
  CoinType,
  CoinTypeToChainMap,
  DASBalanceTokenId,
  DASDidPointTokenId,
  USD
} from '@did/constants/chain'
import errno from '@did/constants/errno'
import { DasBalanceInsufficientDialog } from '../../account/register-page/das-balance-insufficient-dialog'
import { FiatPayDialog } from '@did/stripe'
import { RenewalSubmitted } from '@did/das-app-components'
import { SignatureErrorDialog } from '../../account/register-page/signature-error-dialog'
import { useIsManager, useIsOwner, useTokenList } from '@did/das-app-hooks'
import { handleError } from '@did/das-app-utils'
import { DobRenewalDialog } from './dob-renewal-dialog'

export const Renewal: RenewalType = ({ showing, accountInfo, onClose }) => {
  const MIN_VALUE = 1
  const MAX_VALUE = 20
  const { tt, connectedAccount, services, walletSdk, alert, isProd } =
    useDasBalanceContext()
  const { isDobsMode } = useCccContext()
  const config = useDasAppConfigContext()
  const [renewalPeriod, setRenewalPeriod] = useState('1')
  const [secondStepShowing, setSecondStepShowing] = useState(false)
  const [selectedToken, setSelectedToken] = useState<IToken>()
  const paymentTokens = useTokenList()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [txHash, setTxHash] = useState('')
  const [txHashLink, setTxHashLink] = useState('')
  const [renewalSubmittedShowing, setRenewalSubmittedShowing] = useState(false)
  const [
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing
  ] = useState(false)
  const [signatureErrorDialogShowing, setSignatureErrorDialogShowing] =
    useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [fiatPayDialogShowing, setFiatPayDialogShowing] = useState(false)
  const [dobRenewalDialogShowing, setDobRenewalDialogShowing] = useState(false)
  const isManager = useIsManager(accountInfo)
  const isOwner = useIsOwner(accountInfo)

  const checkRenewalPeriod = (event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setRenewalPeriod(value ? parseInt(value) + '' : value)
  }

  const renewalAmount = useMemo(() => {
    const _fee = new Decimal(renewalPeriod || 0).times(
      accountInfo?.account_price || 0
    )
    return _fee.toFixed(FIAT_DECIMAL_PLACES)
  }, [renewalPeriod, accountInfo?.account_price])

  const renewalToDate = useMemo(() => {
    const _date = dayjs(accountInfo?.expired_at).add(
      Number(renewalPeriod),
      'year'
    )
    return formatDateTime(_date, TIME_FORMAT.fullDate)
  }, [renewalPeriod, accountInfo?.expired_at])

  const stripeServiceFee = useMemo(() => {
    if (!selectedToken?.decimals) return ''
    return new Decimal(renewalAmount || 0)
      .times(config?.premium_percentage || 0)
      .add(config?.premium_base || 0)
      .toFixed(selectedToken?.decimals, Decimal.ROUND_UP)
  }, [selectedToken, renewalAmount, config])

  const stripePaidAmount = useMemo(() => {
    if (!selectedToken?.decimals) return ''
    return new Decimal(renewalAmount || 0)
      .add(stripeServiceFee)
      .toFixed(selectedToken.decimals, Decimal.ROUND_UP)
  }, [renewalAmount, stripeServiceFee, selectedToken?.decimals])

  const paidTokenAmount = useMemo(() => {
    let tokenPrecision = TOKEN_DECIMAL_PLACES

    if (selectedToken?.token_id === USD.tokenId) {
      tokenPrecision = selectedToken?.decimals
    }

    if (selectedToken?.token_id === DASDidPointTokenId) {
      tokenPrecision = 2
    }

    return new Decimal(renewalAmount || 0)
      .div(selectedToken?.price || 1)
      .times(selectedToken?.premium || 1)
      .toFixed(tokenPrecision, Decimal.ROUND_UP)
  }, [renewalAmount, selectedToken])

  const amountVerify = (amount: number | string): string => {
    let number = amount
    // Compatible with TokenPocket
    if (selectedToken?.coin_type === CoinType.doge) {
      number = new Decimal(number || 0)
        .div(new Decimal(10).pow(TOKEN_DECIMAL_PLACES))
        .toFixed(TOKEN_DECIMAL_PLACES)
    }
    return number + ''
  }

  const returnUrl = useMemo(() => {
    try {
      return window?.location?.href
    } catch (e: any) {
      return ''
    }
  }, [])

  const handleConfirm = async () => {
    if (
      !selectedToken ||
      !connectedAccount?.chain?.coinType ||
      !connectedAccount?.address
    ) {
      return
    }

    setConfirmLoading(true)

    let signTxList: any
    let onClose: any

    if (selectedToken?.token_id !== USD.tokenId) {
      const signContext = await walletSdk?.initSignContext()
      signTxList = signContext.signTxList
      onClose = signContext.onClose
    }

    try {
      const orderInfo = await services.account.submitRenewOrder({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        account: accountInfo.account,
        pay_token_id: selectedToken?.token_id!,
        renew_years: Number(renewalPeriod)
      })

      if (!orderInfo) {
        setConfirmLoading(false)
        return
      }

      if (selectedToken?.token_id === USD.tokenId) {
        setClientSecret(orderInfo.client_secret!)
        setOrderId(orderInfo.order_id!)
        setSecondStepShowing(false)
        setFiatPayDialogShowing(true)
      } else if (selectedToken?.token_id === DASBalanceTokenId) {
        const txs = await services.account.dasBalancePay({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          evm_chain_id: connectedAccount?.computedChainId!,
          order_id: orderInfo?.order_id!
        })

        const signatureList = await signTxList(txs)

        const { hash } = await services.account.sendTrx(signatureList)
        if (hash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(hash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type].getExplorerTrx(
                isProd
              )}${hash}`
            )
          }
        }
      } else if (selectedToken?.token_id === DASDidPointTokenId) {
        const res = await services.didPoint.transfer({
          key_info: {
            coin_type: connectedAccount?.chain?.coinType!,
            key: connectedAccount?.address!
          },
          amount_dp: new Decimal(orderInfo?.amount || 0)
            .dividedBy(new Decimal(10).pow(selectedToken?.decimals || 6))
            .toString(),
          order_id: orderInfo?.order_id!,
          action_dp: DIDPOINT_ACTIONS.transfer_tldid
        })

        const signatureList = await signTxList(res)

        const { hash } = await services.didPoint.txSend(signatureList)
        if (hash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(hash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type].getExplorerTrx(
                isProd
              )}${hash}`
            )
          }
        }
      } else {
        const trxAmount = amountVerify(orderInfo?.amount || '0')
        const hash = await walletSdk.sendTransaction({
          to: orderInfo.receipt_address,
          value: trxAmount,
          data: orderInfo.order_id
        })

        if (hash) {
          setSecondStepShowing(false)
          setRenewalSubmittedShowing(true)
          if (CoinTypeToChainMap[selectedToken?.coin_type!]) {
            setTxHash(hash)
            setTxHashLink(
              `${CoinTypeToChainMap[selectedToken?.coin_type].getExplorerTrx(
                isProd
              )}${hash}`
            )
          }
        }
      }
    } catch (err: any) {
      onClose?.()

      if (err.code === errno.apiErrorCodeInsufficientBalance) {
        setDasBalanceInsufficientDialogShowing(true)
      } else if (err.code === errno.apiErrorCodeNotEnoughChange) {
        alert({
          title: tt('Tips'),
          message: tt(
            'DAS is a smart contract that runs on the Nervos. Due to the underlying logic of the contract, the remaining amount is too low (less than {minChangeCapacity} CKB) to send a transaction.',
            {
              minChangeCapacity: config.min_change_capacity
            }
          )
        })
      } else if (err.code === errno.rpcApiErrSignatureError) {
        setSignatureErrorDialogShowing(true)
      } else if (err.code === errno.apiErrorAccountRegistering) {
        alert({
          title: tt('Tips'),
          message: tt(
            'Someone else is registering {accountName}, it is currently unavailable, please try again later',
            { accountName: accountInfo.account }
          )
        })
      } else {
        handleError(err, tt, alert)
      }
    } finally {
      setConfirmLoading(false)
    }
  }

  const onNext = () => {
    onClose()
    if (isDobsMode) {
      setDobRenewalDialogShowing(true)
    } else {
      setSecondStepShowing(true)
    }
  }

  return (
    <>
      <Dialog
        showing={showing}
        title={tt('Extend')}
        closeButton
        onClose={onClose}
      >
        {!(isOwner || isManager) && (
          <div className={styles['renewal-bottom-sheet__warning']}>
            <span>*</span>
            <span>
              {tt(
                'Extending an account that does not belong to you does not give you ownership of it.'
              )}
            </span>
          </div>
        )}
        <div className="text-zinc-500 text-center text-sm mt-4">
          {tt('Extend time (years)')}
        </div>
        <div className="flex justify-center mb-3 h-[78px]">
          <input
            value={renewalPeriod}
            className={styles['renewal-bottom-sheet__renewal-period']}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            min={MIN_VALUE}
            max={MAX_VALUE}
            onChange={(e) => {
              checkRenewalPeriod(e)
            }}
          />
        </div>
        <div className="text-center text-zinc-500 text-sm">
          {tt('Cost: {renewalAmount}', {
            renewalAmount: `$${thousandSplit(
              renewalAmount,
              FIAT_DECIMAL_PLACES
            )}`
          })}
        </div>
        {Number(renewalPeriod) > MAX_VALUE && (
          <div
            className={styles['renewal-bottom-sheet__renewal-period__error']}
          >
            {tt('The maximum is 20.')}
          </div>
        )}
        <div
          className={cn(
            styles['renewal-bottom-sheet__renewal-label'],
            'text-gray-400 text-sm font-bold'
          )}
        >
          {tt('{accountName} is good through {renewalTo}', {
            accountName: (
              <span className="text-neutral-900">
                {accountInfo?.account || ''}
              </span>
            ),
            renewalTo: (
              <span className="text-teal-500">{renewalToDate || ''}</span>
            )
          })}
        </div>
        <div className="pb-8 pt-3">
          <DasButton
            disabled={
              Number(renewalPeriod) > MAX_VALUE ||
              Number(renewalPeriod) < MIN_VALUE
            }
            block
            black
            onClick={onNext}
          >
            {tt('Next')}
          </DasButton>
        </div>
      </Dialog>
      <DobRenewalDialog
        showing={dobRenewalDialogShowing}
        renewalPeriod={Number(renewalPeriod)}
        accountInfo={accountInfo}
        setTxHash={setTxHash}
        setTxHashLink={setTxHashLink}
        setRenewalSubmittedShowing={setRenewalSubmittedShowing}
        onClose={() => {
          setDobRenewalDialogShowing(false)
        }}
      />
      <Dialog
        showing={secondStepShowing}
        title={tt('Select payment')}
        closeButton
        onClose={() => {
          setSecondStepShowing(false)
        }}
      >
        <PaymentConfirm
          className="px-0"
          onTokenChange={setSelectedToken}
          selectedToken={selectedToken}
          stripePaidAmount={stripePaidAmount}
          stripeServiceFee={stripeServiceFee}
          paidTokenAmount={paidTokenAmount}
          fiatTermsLink="https://topdid.com/terms.html"
          tokenList={paymentTokens}
          handleConfirm={handleConfirm}
          confirmRegisterLoading={confirmLoading}
        />
      </Dialog>
      <FiatPayDialog
        showing={fiatPayDialogShowing}
        title={tt('Pay to Join')}
        onClose={() => {
          setFiatPayDialogShowing(false)
        }}
        returnUrl={returnUrl}
        clientSecret={clientSecret!}
        orderId={orderId}
        successCallback={() => {
          setTxHash('')
          setTxHashLink('')
          setFiatPayDialogShowing(false)
          setRenewalSubmittedShowing(true)
        }}
      />
      <DasBalanceInsufficientDialog
        selectToken={selectedToken}
        showing={dasBalanceInsufficientDialogShowing}
        onClose={() => {
          setDasBalanceInsufficientDialogShowing(false)
        }}
        registrationFees={paidTokenAmount}
      />
      <RenewalSubmitted
        showing={renewalSubmittedShowing}
        txHash={txHash}
        txHashLink={txHashLink}
        onClose={() => {
          setRenewalSubmittedShowing(false)
        }}
      />
      <SignatureErrorDialog
        showing={signatureErrorDialogShowing}
        onClose={() => {
          setSignatureErrorDialogShowing(false)
        }}
      />
    </>
  )
}
