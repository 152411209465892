import { DEBOUNCE_WAIT_TIME, FIAT_DECIMAL_PLACES } from '@did/constants'
import { InputCounter } from '@did/das-app-components'
import { useDasBalanceContext } from '@did/das-app-context'
import { RegisterInfoType } from '@did/das-app-types/module'
import { cn, debounce, smartOpen, thousandSplit } from '@did/tools'
import { IBaseProps } from '@did/types'
import { DasButton, Dialog, DidCard, Iconfont } from '@did/uikit'
import React, { useCallback, useState } from 'react'

interface IItemInfoProps extends IBaseProps {
  left: React.ReactNode | string
  right: React.ReactNode | string
}

const ItemInfo: React.FC<IItemInfoProps> = ({ left, right, className }) => {
  return (
    <div
      className={cn(
        'flex items-center justify-between py-1.5 text-xl font-medium text-neutral-400',
        className
      )}
    >
      <span>{left}</span>
      <div className="text-neutral-900 text-xl font-bold font-['Barlow-Medium']">
        {right}
      </div>
    </div>
  )
}

export const DashedLine = () => (
  <div className="w-full border-gray-400 border-dashed my-6 border-b-[0.7px]"></div>
)

export const RegisterInfo: RegisterInfoType = ({
  bitName,
  annualFee,
  storageDeposit,
  registrationPeriod = 1,
  onRegisterPeriodChange,
  paidAmount,
  totalCost,
  handleRegister,
  registerLoading,
  additionInfo,
  registrationPeriodInfo,
  couponCode,
  slots = {
    dashedLine: DashedLine
  }
}) => {
  const { tt, alert, router } = useDasBalanceContext()
  const [showStorageDepositDialog, setShowStorageDepositDialog] =
    useState(false)
  const handleStorageDepositClick = () => {
    setShowStorageDepositDialog(true)
  }

  const onClickRegistrationPeriod = useCallback(
    debounce(() => {
      if (!!couponCode) {
        alert({
          title: tt('Unable to Modify'),
          message: tt(
            'You are using a Builder Code to register, and the registration year cannot be modified. You can renew your .bit account after successful registration.'
          )
        })
      }
    }, DEBOUNCE_WAIT_TIME),
    [couponCode, alert, tt]
  )

  return (
    <>
      <DidCard width="130" account={bitName || ''} />
      <div className="w-full bg-white p-8 mt-8 rounded-t-[32px] md:rounded-[32px] shadow-panel overflow-hidden">
        {registrationPeriodInfo ? (
          <ItemInfo
            left={tt('Registration year')}
            right={registrationPeriodInfo}
          />
        ) : (
          <ItemInfo
            className={cn('max-sm:flex-col max-sm:gap-y-4 max-sm:items-start')}
            left={tt('Registration year')}
            right={
              <InputCounter
                value={registrationPeriod}
                onChange={onRegisterPeriodChange}
                disabled={!!couponCode}
                onClick={onClickRegistrationPeriod}
              />
            }
          />
        )}
        <slots.dashedLine index={0} />
        <ItemInfo
          left={
            <div className="inline-flex items-center">
              {tt('Annual fee')}
              <div className="text-sm font-normal text-zinc-500 bg-slate-600/10 px-1 ml-2 rounded border border-slate-300/40">
                {registrationPeriod}{' '}
                {registrationPeriod > 1 ? tt('years') : tt('year')}
              </div>
            </div>
          }
          right={`$${thousandSplit(annualFee || 0, FIAT_DECIMAL_PLACES)}`}
        />
        <ItemInfo
          left={
            <span
              onClick={handleStorageDepositClick}
              className="flex items-center cursor-pointer hover:opacity-70"
            >
              {tt('Storage deposit')}
              <Iconfont
                className="ml-1"
                name="info"
                color="#B0B8BF"
                size="14"
              />
            </span>
          }
          right={`$${thousandSplit(storageDeposit || 0, FIAT_DECIMAL_PLACES)}`}
        />
        {additionInfo}
        <slots.dashedLine index={1} />
        <ItemInfo
          className="items-start"
          left={tt('Total cost')}
          right={
            <div className="text-right text-[32px] h-[52px]">
              ${thousandSplit(paidAmount || 0, FIAT_DECIMAL_PLACES)}
              {totalCost > paidAmount && (
                <div className="text-gray-500 mt-1 font-normal text-sm">
                  {tt('Original price:')}
                  <span className="line-through">
                    ${thousandSplit(totalCost || 0, FIAT_DECIMAL_PLACES)}
                  </span>
                </div>
              )}
            </div>
          }
        />
        <div className="w-full mt-5">
          <DasButton
            large
            black
            block
            className="w-full"
            onClick={handleRegister}
            loading={registerLoading}
            isLoadingGradient={false}
          >
            {router.pathname === '/account/create/auction/[bitname]'
              ? tt('Bid now')
              : tt('Create')}
          </DasButton>
        </div>
      </div>
      <Dialog
        title={tt('Storage deposit')}
        showing={showStorageDepositDialog}
        enableCloseAction
        closeButton
        onClose={() => {
          setShowStorageDepositDialog(false)
        }}
        actionButtonText={tt('OK')}
      >
        {tt('Full refund when account expires for recycling.')}
        <div
          onClick={() => {
            smartOpen(
              'https://community.d.id/c/knowledge-base-bit/registration-process-faq'
            )
          }}
          className="mt-3 text-sky-700 hover:opacity-70 cursor-pointer font-medium"
        >
          {tt('Learn more')}
        </div>
      </Dialog>
    </>
  )
}
