import { Dialog, Iconfont } from '@did/uikit'
import { RenewalSubmittedType } from '@did/das-app-types/components'
import { useDasBalanceContext } from '@did/das-app-context'
import { useCallback } from 'react'
import styles from './styles.module.scss'
import { collapseString } from '@did/tools'

export const RenewalSubmitted: RenewalSubmittedType = ({
  showing,
  txHash,
  txHashLink,
  onClose
}) => {
  const { tt } = useDasBalanceContext()

  const onDialogClose = useCallback(() => {
    onClose(false)
  }, [onClose])

  return (
    <Dialog
      showing={showing}
      title={tt('Renewal Submitted')}
      closeButton
      onClose={onDialogClose}
    >
      <div className={styles['renewal-submitted__content']}>
        {tt(
          'Renewal takes effect in 5 minutes. Payment deducting transaction fees will be refunded in 24 hours if DID is not renewed.'
        )}
        {txHash && txHashLink && (
          <a
            className={styles['renewal-submitted__tx-hash']}
            href={txHashLink}
            target="_blank"
          >
            {collapseString(txHash, 5, 5)}
            <Iconfont
              className={styles['renewal-submitted__tx-hash__icon']}
              name="arrow-right"
              color="#B0B8BF"
              size="14"
            />
          </a>
        )}
        <div className="pb-8 pt-6">
          <div
            className={styles['renewal-submitted__action']}
            onClick={onDialogClose}
          >
            <Iconfont name="check-strong" color="#0DBA85" size="24" />
            {tt('Done')}
          </div>
        </div>
      </div>
    </Dialog>
  )
}
