import { IServices } from '@did/types'
import { AxiosError, AxiosResponse } from 'axios'
import Account from './account'
import Common from './common'
import DasReverse from './das-reverse'
import DidPoint from './did-point'
import SubAccount from './sub-account'

export class Services implements IServices {
  common: Common
  account: Account
  dasReverse: DasReverse
  didPoint: DidPoint
  subAccount: SubAccount

  constructor(param: {
    isProdData: boolean
    baseURL: string
    reverseApiUrl: string
    didPointApiUrl: string
    subAccountApiUrl: string
    onFulfilled?: (data: AxiosResponse) => any
    onRejected?: (data: AxiosError) => any
    headers?: any
  }) {
    this.common = new Common(param)
    this.account = new Account(param)
    this.dasReverse = new DasReverse({
      ...param,
      baseURL: param.reverseApiUrl
    })
    this.didPoint = new DidPoint({
      ...param,
      baseURL: param.didPointApiUrl
    })
    this.subAccount = new SubAccount({
      ...param,
      baseURL: param.subAccountApiUrl
    })
  }
}
