import { collapseString, ErrorInfo } from '@did/tools'
import { StatusTip } from './status-tip'
import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DasButton, DidAvatar, Iconfont } from '@did/uikit'
import { AccountSearch } from './account-search'
import { DEFAULT_PAGE_SIZE } from '@did/constants'
import { IRecyclableListRes } from '@did/types'
import { DOB_RECYCLE_STATUS } from '@did/das-app-constants'
import { CKB } from '@did/constants/chain'
import { RecycleDialog } from './recycle-dialog'

export const RecyclableListPage = () => {
  const { tt, services, walletSdk, isProd } = useDasBalanceContext()
  const { ccc, ckbAddress } = useCccContext()
  const cccConnector = ccc?.useCcc()
  const [fetchDataLoading, setFetchDataLoading] = useState(true)
  const [recyclableList, setRecyclableList] = useState<IRecyclableListRes[]>([])
  const [noMoreShowing, setNoMoreShowing] = useState(false)
  const [loadMoreShowing, setLoadMoreShowing] = useState(false)
  const [loadingShowing, setLoadingShowing] = useState(false)
  const [page, setPage] = useState(0)
  const [searchWord, setSearchWord] = useState('')
  const [accountInfo, setAccountInfo] = useState<IRecyclableListRes>(
    {} as IRecyclableListRes
  )
  const [recycleDialogShowing, setRecycleDialogShowing] = useState(false)

  const noRecords = useMemo(() => {
    return !fetchDataLoading && recyclableList.length === 0 && searchWord === ''
  }, [fetchDataLoading, recyclableList, searchWord])

  const getRecyclableList = useCallback(
    async (page: number, searchStr: string) => {
      if (!ckbAddress) {
        setRecyclableList([])
        setLoadMoreShowing(false)
        return
      }
      if (recyclableList.length === 0) {
        setFetchDataLoading(true)
      }
      setNoMoreShowing(false)
      if (page > 0) {
        setLoadMoreShowing(false)
        setLoadingShowing(true)
      }

      try {
        setPage(page + 1)
        const res = await services.account.recyclableList({
          key_info: {
            coin_type: CKB.coinType,
            key: ckbAddress
          },
          page: page + 1,
          keyword: searchStr
        })

        setLoadingShowing(false)
        if (!res || !res.list) {
          setFetchDataLoading(false)
          return
        }

        if (page + 1 === 1) {
          setRecyclableList(res.list)
        } else {
          setRecyclableList(recyclableList.concat(...res.list))
        }

        const length = res.list.length
        if (length < DEFAULT_PAGE_SIZE) {
          setLoadMoreShowing(false)
          if (length >= 0) {
            setNoMoreShowing(true)
          }
        } else {
          setLoadMoreShowing(true)
        }
      } catch (err: any) {
        ErrorInfo.error(err)
      } finally {
        setFetchDataLoading(false)
        setLoadingShowing(false)
      }
    },
    [recyclableList, ckbAddress]
  )

  const updateRecyclableList = (record: IRecyclableListRes) => {
    const list = recyclableList.map((item) => {
      if (item.account === record.account) {
        return { ...item, ...record }
      }
      return item
    })
    setRecyclableList(list)
  }

  const onSearch = useCallback(
    (value: string) => {
      setPage(0)
      setSearchWord(value)
      setFetchDataLoading(true)
      getRecyclableList(0, value)
    },
    [getRecyclableList]
  )

  const onConnect = useCallback(() => {
    cccConnector?.open?.()
  }, [cccConnector])

  const onRefund = async (info: IRecyclableListRes) => {
    if (!ckbAddress) {
      cccConnector?.open()
      return
    }

    setAccountInfo(info)
    setRecycleDialogShowing(true)
  }

  useEffect(() => {
    if (ckbAddress) {
      setPage(0)
      getRecyclableList(0, searchWord)
    } else {
      setRecyclableList([])
      setLoadingShowing(false)
      setLoadMoreShowing(false)
      setNoMoreShowing(false)
      setFetchDataLoading(false)
    }
  }, [ckbAddress])

  return (
    <div className="w-full md:w-[680px] mx-auto pb-[52px] px-4 md:px-0 pt-5 md:pt-16 flex flex-col gap-y-4">
      {!noRecords && <AccountSearch className="w-full" onSearch={onSearch} />}
      {fetchDataLoading && (
        <div className="mt-[80px]">
          <StatusTip
            className="font-medium my-4 mx-0 text-[#31333E]"
            icon="pending"
            iconSize="72"
            tip={tt('Loading')}
            tipFontSize="14"
          />
        </div>
      )}
      {noRecords &&
        (ckbAddress ? (
          <div className="w-full pt-10 pb-14 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
            <Iconfont name="no-records" size={156} />
            <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
              {tt(`You don't have a recyclable .bit.`)}
            </div>
          </div>
        ) : (
          <div className="w-full pt-10 pb-20 flex flex-col justify-center items-center bg-white rounded-3xl border border-slate-300/40">
            <Iconfont name="no-dobs-records" size={156} />
            <div className="font-semibold mb-5 mx-0 text-tip-font-color text-center px-6">
              {tt('Connect wallet to see your recyclable .bit.')}
            </div>
            <DasButton
              className="h-[38px] mx-auto font-semibold text-sm leading-[16px]"
              black
              onClick={onConnect}
              middle
            >
              {tt('Connect wallet')}
            </DasButton>
          </div>
        ))}
      <ul className="w-full">
        {!fetchDataLoading &&
          recyclableList.map((info, index) => (
            <li
              key={index}
              className="flex justify-between items-center py-1 px-5 mb-2 min-h-[84px] bg-white rounded-[12px] border border-status-border-color hover:bg-[#F7F9FA]"
            >
              <div className="flex justify-between items-center text-lg font-semibold text-tab-active break-words hyphens-auto leading-[21px]">
                <DidAvatar
                  account={info?.account || ''}
                  fontSize="20px"
                  lineClassName="w-[23px] h-[10px] mb-[-2px]"
                  textClassName="mb-[-2px]"
                  className="mr-3"
                  size={44}
                />
                <div className="flex flex-col text-lg font-semibold text-tab-active break-all hyphens-auto">
                  <span className="mr-2">{info.account}</span>
                  <div>
                    {info.recycle_status === DOB_RECYCLE_STATUS.recycling ? (
                      <span className="inline-flex flex-col md:flex-row">
                        <span className="inline-flex items-center mt-1 px-1 py-0.5 rounded text-xs text-success-font-color bg-list-status-bg-color leading-none">
                          <Iconfont
                            className="animate-spin mr-1"
                            name="loading"
                            size="16"
                            color="#2EB67D"
                          />
                          {tt('Refunding, about 5 minutes.')}
                        </span>
                        {info.recycle_hash && (
                          <a
                            className="inline-flex items-center mt-1 px-1 py-0.5 rounded text-xs text-gray-400 font-normal bg-gray-100 leading-none w-fit md:ml-1"
                            href={`${CKB.getExplorerTrx(isProd)}${
                              info.recycle_hash
                            }`}
                            target="_blank"
                          >
                            {collapseString(info.recycle_hash, 5, 5)}
                            <Iconfont
                              name="arrow-right"
                              color="#B0B8BF"
                              size="10"
                              className="ml-1"
                              style={{ verticalAlign: '0px' }}
                            />
                          </a>
                        )}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {![DOB_RECYCLE_STATUS.recycling].includes(
                info.recycle_status
              ) && (
                <DasButton
                  loading={info.recycle_status === DOB_RECYCLE_STATUS.loading}
                  black
                  small
                  isLoadingGradient={false}
                  onClick={() => {
                    onRefund(info)
                  }}
                >
                  <span className="font-normal text-sm">{tt('Refund')}</span>
                </DasButton>
              )}
            </li>
          ))}
        {loadMoreShowing && !fetchDataLoading && (
          <li
            className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm cursor-pointer text-link-font-color hover:text-link-hover-font-color"
            onClick={() => getRecyclableList(page, searchWord)}
          >
            {tt('Load more')}
          </li>
        )}
        {loadingShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {tt('Loading')}
          </li>
        )}
        {noMoreShowing && (
          <li className="m-[26px] text-center leading-[20px] text-assist-font-color text-sm">
            {searchWord && recyclableList?.length === 0
              ? tt('No matched .bit')
              : noRecords
                ? ''
                : tt('No more')}
          </li>
        )}
      </ul>
      <RecycleDialog
        showing={recycleDialogShowing}
        accountInfo={accountInfo}
        updateRecyclableList={updateRecyclableList}
        onClose={() => {
          setRecycleDialogShowing(false)
        }}
      />
    </div>
  )
}
