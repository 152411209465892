import { CYCLE_CALL_FUNCTION_TIME } from '@did/constants'
import errno from '@did/constants/errno'
import { useDasBalanceContext } from '@did/das-app-context'
import { UseTransStatusDialogType } from '@did/das-app-types/hooks'
import { ORDER_ACTION_TYPE, REVERSE_TX_STATUS, TRX_STATUS } from '@did/types'
import { useEffect, useState, useRef } from 'react'
export const useTransStatusDialogOld: UseTransStatusDialogType = ({
  account,
  coinType,
  address,
  showing,
  onClose = () => {},
  handleTxStatus,
  successCallback
}) => {
  const { tt, services, toast, connectedAccount } = useDasBalanceContext()
  const isInit = useRef<'init' | 'pending' | 'done'>('init')

  const [txStatus, setTxStatus] = useState<REVERSE_TX_STATUS>()

  useEffect(() => {
    handleTxStatus?.(txStatus)
  }, [txStatus])

  const checkStatus = async () => {
    if (isInit.current === 'done') return

    if (!coinType || !address || !connectedAccount) return

    // To avoid showing success status first in dialog when user clicks on delete
    try {
      const res = await services.account.checkTxStatus({
        key_info: {
          coin_type: connectedAccount.chain?.coinType,
          key: connectedAccount.address
        },
        actions: [ORDER_ACTION_TYPE.DELETE_DAS_REVERSE]
      })
      if (res && res.status === TRX_STATUS.pending) {
        setTxStatus(REVERSE_TX_STATUS.pending)
        isInit.current = 'pending'
      } else {
        setTxStatus(REVERSE_TX_STATUS.success)
        isInit.current = 'done'
      }
    } catch (err: any) {
      if (
        err.code === errno.apiErrorCodeNotExitsTrx &&
        isInit.current !== 'done'
      ) {
        setTxStatus(REVERSE_TX_STATUS.success)
        isInit.current = 'done'
      } else {
        setTxStatus(REVERSE_TX_STATUS.failed)
      }
    }
  }

  useEffect(() => {
    if (!coinType || !address) return
    let timerId: number = 0

    if (showing) {
      setTxStatus(REVERSE_TX_STATUS.pending)
      // @ts-ignore
      timerId = setInterval(() => {
        checkStatus()
      }, CYCLE_CALL_FUNCTION_TIME * 2)
      checkStatus()
    } else {
      clearInterval(timerId)
    }

    return () => {
      clearInterval(timerId)
      setTxStatus(REVERSE_TX_STATUS.pending)
    }
  }, [connectedAccount, showing, account, coinType, address, showing])

  const handleBtnClick = () => {
    successCallback?.({})
    isInit.current = 'init'
    setTxStatus(undefined)
  }

  return {
    handleBtnClick,
    onClose,
    showing: Boolean(showing),
    loading: txStatus === REVERSE_TX_STATUS.pending,
    done: txStatus === REVERSE_TX_STATUS.success,
    btnText: tt(txStatus === REVERSE_TX_STATUS.success ? 'Done' : 'Processing')
  }
}
