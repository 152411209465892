import { useCccContext, useDasBalanceContext } from '@did/das-app-context'
import { handleError } from '@did/das-app-utils'
import {
  cn,
  collapseString,
  convertUTXOSignature,
  findParsingRecordChain,
  isMobile,
  isUTXOChain,
  smartOpen,
  toLowerCase
} from '@did/tools'
import {
  ACCOUNT_STATUS,
  CKB_ADDRESS_TYPE,
  IAccountInfoRes,
  IAccountParsingRecord,
  ICallback,
  IReverseInfoRes,
  OPERATE_STATUS,
  ParsingRecordType,
  REVERSE_INFO_STATUS,
  REVERSE_TX_STATUS
} from '@did/types'
import {
  DasButton,
  Dropdown,
  IconButton,
  Iconfont,
  IconImage
} from '@did/uikit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TransStatusDialog } from './trans-status-dialog'
import dynamic from 'next/dynamic'
import LoadingSvg from '@did/das-assets/images/icon/loading.svg'
import MoreSvg from '@did/das-assets/images/icon/icon-more.svg'
import AddressListPng from '@did/das-assets/images/alias/address-list.png'
import { AccountAutocomplete, SwitchWalletTips } from '@did/das-app-components'
import { BTC, CKB, CoinType, ETH } from '@did/constants/chain'
import { ConfirmToRemoveDialog } from './confirm-to-remove-dialog'
import { useIsManager, useIsOwner } from '@did/das-app-hooks'
import errno from '@did/constants/errno'

const TransStatusDialogOld = dynamic(
  () =>
    import('./trans-status-dialog/trans-status-dialog-old').then((m) => ({
      default: m.TransStatusDialogOld
    })),
  {
    loading: () => (
      <LoadingSvg className=" animate-spin mx-auto" color="#B0B8BF" />
    )
  }
)

interface IRecords extends IAccountParsingRecord {
  id: number
  is_valid?: boolean
  loading?: boolean
  is_old?: boolean
  is_ccc_address?: boolean
}

export const AliasPage: React.FC<ICallback<any, any>> = ({
  successCallback
}) => {
  const { tt, connectedAccount, walletSdk, services, alert, router } =
    useDasBalanceContext()
  const { ccc, isDobsMode, setIsDobsMode, ckbAddress, coinType, address } =
    useCccContext()
  const cccConnector = ccc?.useCcc()
  const signer = ccc?.useSigner()
  const [isDob, setIsDob] = useState(false)
  const [bitName, setBitName] = useState('')
  const [aliasName, setAliasName] = useState('')
  const [accountInfo, setAccountInfo] = useState<IAccountInfoRes>(
    {} as IAccountInfoRes
  )
  const [actions, setActions] = useState<Array<'update' | 'remove'>>([])
  const [aliasInfo, setAliasInfo] = useState<IReverseInfoRes>({
    account: '',
    is_valid: false
  })
  const [txStatus, setTxStatus] = useState<REVERSE_TX_STATUS>()
  const [submittedShown, setSubmittedShown] = useState(false)
  const [submittedOldShowing, setSubmittedOldShowing] = useState(false)
  const [isOld, setIsOld] = useState(false)
  const [confirmToRemoveDialogShowing, setConfirmToRemoveDialogShowing] =
    useState(false)
  const [records, setRecords] = useState<IRecords[]>([])
  const [currentEditRecord, setCurrentEditRecord] = useState<IRecords>(
    {} as IRecords
  )
  const [recordsLoading, setRecordsLoading] = useState(false)
  const [permissionTipsShowing, setPermissionTipsShowing] = useState(false)
  const [permissionTipsAddress, setPermissionTipsAddress] = useState('')
  const [permissionTipsCoinType, setPermissionTipsCoinType] = useState('')
  const isManager = useIsManager(accountInfo)
  const isOwner = useIsOwner(accountInfo)

  const shouldBeUseCccFunc = useCallback(
    (record: IRecords | undefined) => {
      if (record?.is_ccc_address) {
        return true
      } else if (record?.key === BTC.coinType) {
        return true
      } else if (record?.key === ETH.coinType) {
        return (
          record?.value.toLowerCase() === address?.toLowerCase() ||
          record?.value.toLowerCase() !==
            connectedAccount?.address?.toLowerCase()
        )
      }
      return false
    },
    [address, connectedAccount?.address]
  )

  const findLogoImage = (record: IAccountParsingRecord) => {
    return `/bit/images/chain/chain-${
      findParsingRecordChain(record.key).value
    }.png`
  }

  const findKey = (record: IAccountParsingRecord) => {
    return findParsingRecordChain(record.key)
  }

  const fetchDasReverse = useCallback(async () => {
    if (isDobsMode && !ckbAddress) {
      return
    } else if (
      !isDobsMode &&
      (!connectedAccount?.address || !connectedAccount?.chain?.coinType)
    ) {
      return
    }

    try {
      const data = await services.dasReverse.reverseInfo({
        address: isDobsMode ? ckbAddress : connectedAccount?.address!,
        coinType: isDobsMode ? CKB.coinType : connectedAccount?.chain?.coinType!
      })
      setAliasInfo(data)
      if (!router?.query?.account) {
        setAliasName(data.account)
        if (data.account) {
          setRecordsLoading(true)
        }
      }
    } catch (err: any) {
      console.log(err)
      setAliasInfo({
        account: '',
        is_valid: false
      })
    }
  }, [isDobsMode, ckbAddress, connectedAccount, services, aliasName])

  const isOldAlias = useMemo(() => {
    if (!aliasInfo) return false
    return [
      REVERSE_INFO_STATUS.setBoth,
      REVERSE_INFO_STATUS.setOldOnly
    ].includes(aliasInfo.reverse_status!)
  }, [aliasInfo])

  const aliasNameFontSize = useMemo(() => {
    const _length = aliasName?.length

    if (!_length) {
      return ''
    }

    if (_length > 32) {
      return isMobile() ? 14 : 22
    } else if (_length > 28) {
      return isMobile() ? 20 : 28
    } else if (_length > 20) {
      return isMobile() ? 26 : 34
    } else if (_length > 12) {
      return isMobile() ? 32 : 40
    } else if (_length > 8) {
      return isMobile() ? 38 : 46
    } else {
      return isMobile() ? 44 : 52
    }
  }, [aliasName])

  const updateRecords = (record: IRecords) => {
    const updatedItems = records.map((item) => {
      if (item.id === record.id) {
        return { ...item, ...record }
      }
      return item
    })
    setRecords(updatedItems)
  }

  const onDeleteTips = async (record: IRecords) => {
    updateRecords({ ...record, loading: true })

    const shouldBeUseCcc = shouldBeUseCccFunc(record)

    if (shouldBeUseCcc) {
      setIsDobsMode(true)
      if (!ckbAddress) {
        cccConnector?.open()
        updateRecords({ ...record, loading: false })
        return
      } else if (
        [ETH.coinType, BTC.coinType].includes(record?.key as any) &&
        record?.value.toLowerCase() !== address?.toLowerCase()
      ) {
        setPermissionTipsAddress(record?.value)
        setPermissionTipsCoinType(record?.key)
        setPermissionTipsShowing(true)
        updateRecords({ ...record, loading: false })
        return
      } else if (
        ![ETH.coinType, BTC.coinType].includes(record?.key as any) &&
        record?.value.toLowerCase() !== ckbAddress.toLowerCase()
      ) {
        setPermissionTipsAddress(record?.value)
        setPermissionTipsCoinType(record?.key)
        setPermissionTipsShowing(true)
        updateRecords({ ...record, loading: false })
        return
      }
    } else {
      setIsDobsMode(false)
      const isInit = await walletSdk?.initWallet()
      if (!isInit) {
        updateRecords({ ...record, loading: false })
        return
      } else if (
        record.value?.toLowerCase() !== connectedAccount?.address?.toLowerCase()
      ) {
        setPermissionTipsAddress(record?.value)
        setPermissionTipsCoinType(record?.key)
        setPermissionTipsShowing(true)
        updateRecords({ ...record, loading: false })
        return
      }
    }

    setIsOld(record.is_old!)
    setConfirmToRemoveDialogShowing(true)
  }

  const onDelete = async () => {
    const shouldBeUseCcc = shouldBeUseCccFunc(currentEditRecord)
    const { signData, onFailed } =
      !shouldBeUseCcc && (await walletSdk?.initSignContext())
    try {
      const res = await services.dasReverse.updateReverse({
        coinType: shouldBeUseCcc
          ? CKB.coinType
          : connectedAccount?.chain?.coinType!,
        address: shouldBeUseCcc ? ckbAddress : connectedAccount?.address!,
        account: aliasName,
        action: 'remove'
      })
      const { sign_msg, sign_key } = res

      let signature

      if (shouldBeUseCcc) {
        const _res = await signer?.signMessage(sign_msg)
        signature = _res?.signature
      } else {
        signature = await signData(sign_msg)
      }

      if (!signature) {
        updateRecords({ ...currentEditRecord, loading: false })
        return
      }

      if (
        isUTXOChain(
          shouldBeUseCcc ? coinType : connectedAccount?.chain?.coinType!
        )
      ) {
        signature = convertUTXOSignature(signature)
        if (coinType === BTC.coinType) {
          signature = signature + '00'
        }
      }

      await services.dasReverse.sendSignature({
        sign_key,
        signature,
        sign_address: connectedAccount?.deviceData?.ckbAddr
      })
      setActions(['remove'])
      setSubmittedShown(true)
    } catch (err: any) {
      updateRecords({ ...currentEditRecord, loading: false })
      if (onFailed) {
        onFailed()
      }
      handleError(err, tt, alert)
    }
  }

  const handleDeleteOld = async () => {
    if (txStatus === REVERSE_TX_STATUS.pending) {
      setSubmittedOldShowing(true)
      return
    }

    setTxStatus(REVERSE_TX_STATUS.pending)

    if (
      !aliasInfo?.account ||
      !connectedAccount?.chain?.coinType ||
      !connectedAccount?.address
    )
      return

    const { onFailed, signTxList } = await walletSdk?.initSignContext()

    try {
      const res = await services.dasReverse.deleteDasReverse({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        evm_chain_id: connectedAccount?.computedChainId!
      })

      const signatureList = await signTxList(res)

      await services.dasReverse.sendTx(signatureList)
      setSubmittedOldShowing(true)
    } catch (err: any) {
      if (onFailed) {
        onFailed()
      }
      handleError(err, tt, alert)
    }
  }

  const onVerify = async (record: IRecords) => {
    updateRecords({ ...record, loading: true })

    const shouldBeUseCcc = shouldBeUseCccFunc(record)

    if (shouldBeUseCcc) {
      setIsDobsMode(true)
      if (!ckbAddress) {
        cccConnector?.open()
        updateRecords({ ...record, loading: false })
        return
      } else if (
        [ETH.coinType, BTC.coinType].includes(record?.key as any) &&
        record?.value.toLowerCase() !== address?.toLowerCase()
      ) {
        setPermissionTipsAddress(record?.value)
        setPermissionTipsCoinType(record?.key)
        setPermissionTipsShowing(true)
        updateRecords({ ...record, loading: false })
        return
      } else if (
        ![ETH.coinType, BTC.coinType].includes(record?.key as any) &&
        record?.value.toLowerCase() !== ckbAddress.toLowerCase()
      ) {
        setPermissionTipsAddress(record?.value)
        setPermissionTipsCoinType(record?.key)
        setPermissionTipsShowing(true)
        updateRecords({ ...record, loading: false })
        return
      }
    } else {
      setIsDobsMode(false)
      const isInit = await walletSdk?.initWallet()
      if (!isInit) {
        updateRecords({ ...record, loading: false })
        return
      } else if (
        record.value?.toLowerCase() !== connectedAccount?.address?.toLowerCase()
      ) {
        setPermissionTipsAddress(record?.value)
        setPermissionTipsCoinType(record?.key)
        setPermissionTipsShowing(true)
        updateRecords({ ...record, loading: false })
        return
      }
    }

    const { signData, onFailed } =
      !shouldBeUseCcc && (await walletSdk?.initSignContext())

    const data = await services.dasReverse.reverseInfo({
      coinType: record.key,
      address: record.value
    })
    if (data.is_valid && data.account && data.account === aliasName) {
      updateRecords({ ...record, loading: false, is_valid: true })
      return
    }

    if (data.is_valid && data.account) {
      const closeAlert = alert({
        title: tt('Tips'),
        message: tt(
          'This address is currently named as {old}. By continuing, this address will be renamed to {new}.',
          {
            old: (
              <span className="text-emerald-400 font-bold">{data.account}</span>
            ),
            new: <span className="text-emerald-400 font-bold">{aliasName}</span>
          }
        ),
        actionButtonText: tt('Continue'),
        closeButton: true,
        onAction: () => {
          closeAlert()
          handleSign(record, signData, onFailed)
        }
      })
      updateRecords({ ...record, loading: false })
      return
    }

    handleSign(record, signData, onFailed)
  }

  const handleSign = async (record: IRecords, signData: any, onFailed: any) => {
    const shouldBeUseCcc = shouldBeUseCccFunc(record)
    setTxStatus(REVERSE_TX_STATUS.pending)

    try {
      const res = await services.dasReverse.updateReverse({
        coinType: shouldBeUseCcc
          ? CKB.coinType
          : connectedAccount?.chain?.coinType!,
        address: shouldBeUseCcc ? ckbAddress : connectedAccount?.address!,
        account: aliasName
      })
      const { sign_msg, sign_key } = res

      let signature

      if (shouldBeUseCcc) {
        const _res = await signer?.signMessage(sign_msg)
        signature = _res?.signature
      } else {
        signature = await signData(sign_msg)
      }

      if (!signature) {
        updateRecords({ ...record, loading: false })
        return
      }

      if (
        isUTXOChain(
          shouldBeUseCcc ? coinType : connectedAccount?.chain?.coinType!
        )
      ) {
        signature = convertUTXOSignature(signature)
        if (coinType === BTC.coinType) {
          signature = signature + '00'
        }
      }

      await services.dasReverse.sendSignature({
        sign_key,
        signature,
        sign_address: connectedAccount?.deviceData?.ckbAddr
      })

      setActions(['update'])
      setSubmittedShown(true)
    } catch (err: any) {
      onFailed?.()
      updateRecords({ ...record, loading: false })
      setTxStatus(REVERSE_TX_STATUS.failed)
      handleError(err, tt, alert)
    }
  }

  const handleClickSnackbar = () => {
    if (txStatus === REVERSE_TX_STATUS.pending) {
      setSubmittedOldShowing(true)
      return
    }
    const closeAlert = alert({
      title: tt('Withdraw 201 CKB'),
      message: tt(
        'We have redesigned the Alias feature, so you can now use it without staking any CKB. Plus, you can retrieve the 201 CKB you staked when you set up your previous aliases.'
      ),
      actionButtonText: tt('Withdraw'),
      closeButton: true,
      onAction: () => {
        closeAlert()
        handleDeleteOld()
      }
    })
  }

  const showFaq = () => {
    const _list = [
      {
        title: tt('I don’t have a .bit yet.'),
        message: tt(
          'After connecting your wallet, you can search for the name you want, and create it if it’s available.'
        )
      },
      {
        title: tt('The .bit I want is not available.'),
        message: tt(
          'The .bit name is either held by others, or it is not released yet. You can search for another name.'
        )
      },
      {
        title: tt('Supported Chains for Alias.'),
        message: tt(
          'Our currently supported chain are: Ethereum, Nervos, Polygon, BNB, Tron, Dogecoin.'
        )
      },
      {
        title: tt('What does .bit Alias do?'),
        message: tt(
          '.bit alias let you display your .bit DID in supported apps, instead of the long and unreadable wallet address. You can also enjoy tailored service for your .bit DID when connected to supported apps.'
        )
      }
    ]
    alert({
      title: tt('FAQ'),
      message: (
        <>
          <ul>
            {_list.map((item, index) => {
              return (
                <li
                  key={index}
                  className="py-5 border-b border-gray-600/10 last:border-0"
                >
                  <h2 className="text-zinc-800 text-xl font-bold">
                    {item.title}
                  </h2>
                  <div className="text-zinc-500 text-base font-normal">
                    {item.message}
                  </div>
                </li>
              )
            })}
          </ul>
          <div className="text-zinc-500 text-base font-normal">
            {tt('Check out the {app} and {faq}.', {
              app: (
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    smartOpen('https://d.id/ecosystem')
                  }}
                >
                  {tt('supported apps')}
                </span>
              ),
              faq: (
                <span
                  className="underline cursor-pointer"
                  onClick={() => {
                    smartOpen(
                      'https://community.d.id/c/knowledge-base-bit/faqbitalias'
                    )
                  }}
                >
                  {tt('more FAQs')}
                </span>
              )
            })}
          </div>
        </>
      ),
      actionButtonText: tt('Got it'),
      closeButton: true
    })
  }

  const onSwitchWallet = async () => {
    setPermissionTipsShowing(false)
    if (JSON.stringify(currentEditRecord) !== '{}') {
      const shouldBeUseCcc = shouldBeUseCccFunc(currentEditRecord)
      if (shouldBeUseCcc && cccConnector) {
        setIsDobsMode(true)
        if (ckbAddress) {
          cccConnector?.disconnect()
          cccConnector?.open()
        } else {
          cccConnector?.open()
        }
      } else if (walletSdk) {
        setIsDobsMode(false)
        const isInit = await walletSdk?.initWallet()
        if (isInit) {
          await walletSdk?.walletSDK?.disconnect()
          walletSdk?.connectWallet()
        } else if (!isManager) {
          walletSdk?.connectWallet()
        }
      }
    } else {
      if (isDob && cccConnector) {
        setIsDobsMode(true)
        if (ckbAddress) {
          cccConnector?.disconnect()
          cccConnector?.open()
        } else {
          cccConnector?.open()
        }
      } else if (walletSdk) {
        setIsDobsMode(false)
        const isInit = await walletSdk?.initWallet()
        if (isInit) {
          await walletSdk?.walletSDK?.disconnect()
          walletSdk?.connectWallet()
        } else if (!isManager) {
          walletSdk?.connectWallet()
        }
      }
    }
  }

  const addAddress = async () => {
    setCurrentEditRecord({} as IRecords)
    const url = `/bit/data/${aliasName}?tab=data`
    const closeAddAddressAlert = alert({
      title: tt('Add Address'),
      message: tt(
        'Please add your desired Ethereum, Nervos, Polygon, BNB, Tron, Dogecoin address into your .bit data.'
      ),
      actionButtonText: tt('Add Address'),
      closeButton: true,
      onAction: () => {
        closeAddAddressAlert()
        smartOpen(url)
      }
    })
  }

  const getAccountRecords = async (account: string) => {
    if (!account) {
      return
    }
    try {
      setRecordsLoading(true)
      let _accountInfo: IAccountInfoRes = {} as IAccountInfoRes
      try {
        _accountInfo = await services.account.accountInfo(account)
        setIsDob(_accountInfo?.status === ACCOUNT_STATUS.upgradedAccount)
        setAccountInfo(_accountInfo)
      } catch (e) {
        setIsDob(false)
        setAccountInfo({} as IAccountInfoRes)
      }
      const accountRecords =
        await services.account.accountParsingRecords(account)
      const records = accountRecords.records.filter((record) => {
        if (
          record.type === ParsingRecordType.address &&
          [
            CoinType.doge,
            CoinType.eth,
            CoinType.trx,
            CoinType.ckb,
            CoinType.bsc,
            CoinType.pol,
            CoinType.btc
          ].includes(record.key as CoinType)
        ) {
          const ret = findParsingRecordChain(record.key)
          return {
            ...record,
            key: ret.coinType
          }
        }
      })

      // const _connectedAccountRecord = records.find(
      //   (record) =>
      //     record.key === connectedAccount?.chain?.coinType &&
      //     record.value?.toLowerCase() ===
      //       connectedAccount?.address?.toLowerCase()
      // )
      // if (!_connectedAccountRecord) {
      //   records.push({
      //     key: connectedAccount?.chain?.coinType!,
      //     type: ParsingRecordType.address,
      //     value: connectedAccount?.address!,
      //     label: '',
      //     ttl: ''
      //   })
      // }

      const _ownerRecord = records.find(
        (record) =>
          record.key === _accountInfo?.owner_coin_type &&
          record.value?.toLowerCase() === _accountInfo?.owner?.toLowerCase()
      )
      if (!_ownerRecord && _accountInfo?.owner) {
        records.push({
          key: _accountInfo?.owner_coin_type,
          type: ParsingRecordType.address,
          value: _accountInfo?.owner,
          label: '',
          ttl: ''
        })
      }

      const _managerRecord = records.find(
        (record) =>
          record.key === _accountInfo?.manager_coin_type &&
          record.value?.toLowerCase() === _accountInfo?.manager?.toLowerCase()
      )
      if (!_managerRecord && _accountInfo?.manager) {
        records.push({
          key: _accountInfo?.manager_coin_type,
          type: ParsingRecordType.address,
          value: _accountInfo?.manager,
          label: '',
          ttl: ''
        })
      }

      const reverseBatchRes = await services.dasReverse.reverseBatch({
        account,
        key_info_list: records.map((record) => {
          return {
            type: 'blockchain',
            key_info: {
              key: record.value,
              coin_type: record.key
            }
          }
        })
      })
      let _records: IRecords[] = []
      records.forEach((record, index) => {
        const reverseInfo = reverseBatchRes.list.find(
          (reverse) =>
            reverse.key?.toLowerCase() === record.value?.toLowerCase() &&
            reverse.coin_type === record.key
        )
        if (reverseInfo) {
          _records.push({
            ...record,
            id: index,
            is_valid: reverseInfo.is_valid,
            loading: reverseInfo.operate_status === OPERATE_STATUS.loading,
            is_ccc_address:
              reverseInfo.ckb_address_type === CKB_ADDRESS_TYPE.anyLock,
            is_old: [
              REVERSE_INFO_STATUS.setBoth,
              REVERSE_INFO_STATUS.setOldOnly
            ].includes(reverseInfo.reverse_status)
          })
        }
      })

      setRecords(_records)
      router?.replace({
        query: {
          account: account
        }
      })
    } catch (err: any) {
      if (err.code !== errno.rpcApiErrAccountNotExist) {
        handleError(err, tt, alert)
      }
    } finally {
      setRecordsLoading(false)
    }
  }

  const handleRemoveSuccess = () => {
    if (!bitName) {
      fetchDasReverse()
    }
    setSubmittedOldShowing(false)
    setSubmittedShown(false)
    getAccountRecords(aliasName)
    successCallback?.({})
  }

  useEffect(() => {
    if (aliasName) {
      const getData = async () => {
        await getAccountRecords(aliasName)
      }
      getData()
    }
  }, [aliasName])

  useEffect(() => {
    fetchDasReverse()
  }, [
    connectedAccount?.chain?.coinType,
    connectedAccount?.address,
    isDobsMode,
    ckbAddress
  ])

  useEffect(() => {
    const _account = toLowerCase(router?.query?.account as string)
    if (_account && _account !== aliasName) {
      setBitName(_account)
      setRecordsLoading(true)
      setAliasName(_account)
    }
  }, [router?.query?.account])

  return (
    <div className="w-full max-w-[1056px] mx-auto px-4 pt-5 pb-[52px] flex flex-col items-center">
      {aliasInfo.account && isOldAlias && (
        <div
          className="w-full bg-orange-100 p-3 rounded-lg flex justify-between items-center mb-5 cursor-pointer"
          onClick={handleClickSnackbar}
        >
          <div>
            <Iconfont name="processing" color="#E9862D" size="18" />
            <span className=" text-xs text-[#E9862D] font-semibold ml-2 ">
              {tt('Withdraw your staked 201 CKB')}
            </span>
          </div>
          <Iconfont name="arrow-right" color="#E9862D" size="16" />
        </div>
      )}
      <h1 className="text-center text-zinc-800 text-[44px] font-bold">
        {tt('.bit Alias')}
      </h1>
      <div className="mt-4 opacity-70 text-center text-neutral-700 text-base font-bold">
        {tt(
          'All verified addresses will appear as your .bit Alias in other apps, making your addresses human readable and easy to remember.'
        )}
      </div>
      <div className="w-full p-9 max-lg:p-4 bg-white rounded-[32px] border border-slate-600/10 flex-col justify-center items-center gap-12 inline-flex mt-9">
        <div className="mx-auto py-8 max-lg:pb-0 max-lg:pt-4 w-[400px] max-lg:w-full">
          <AccountAutocomplete
            className="rounded-full"
            placeholder={tt('Search and select a .bit account')}
            onChange={(val) => {
              if (val) {
                setBitName(val)
                setAliasName(val)
              } else {
                router?.replace({
                  query: {
                    account: ''
                  }
                })
              }
            }}
            bitName={bitName}
          />
        </div>
        <div className="w-full bg-white rounded-[32px] border border-slate-600/10 flex min-h-[374px] max-lg:flex-col max-lg:items-center max-lg:min-h-[600px]">
          <div className="w-full flex flex-1 flex-col p-6 max-lg:p-4 justify-center items-center gap-y-4">
            {recordsLoading ? (
              <LoadingSvg
                className="animate-spin mx-auto w-8 h-8"
                color="#B0B8BF"
              />
            ) : records && aliasName ? (
              <>
                {records.map((record, index) => {
                  const logoImage = findLogoImage(record)
                  const key = findKey(record)
                  return (
                    <div
                      key={index}
                      className={cn(
                        'flex items-center justify-between h-[50px] py-[9px] px-[10px] bg-[#EDF0F2] rounded-full w-full'
                      )}
                    >
                      <span className={cn('inline-flex items-center')}>
                        {logoImage && (
                          <IconImage
                            url={logoImage}
                            alt={key?.text}
                            size={32}
                            rounded
                          />
                        )}
                        <span className={cn('ml-2')}>
                          <div
                            className={cn(
                              'font-mono text-neutral-900 text-base font-bold leading-none'
                            )}
                          >
                            {isMobile()
                              ? collapseString(record.value, 6, 6)
                              : collapseString(record.value, 10, 10)}
                          </div>
                          {record.is_valid && (
                            <div className="mt-0.5">
                              <span className="px-0.5 py-[1px] rounded border border-emerald-400 text-emerald-400 text-xs leading-none font-bold">
                                {tt('Verified')}
                              </span>
                            </div>
                          )}
                        </span>
                      </span>
                      <span>
                        {record?.loading ? (
                          <DasButton
                            className="!h-8 !bg-white !px-4 !py-0"
                            loading={record?.loading}
                            small
                            status="normal"
                            isLoadingGradient={false}
                          ></DasButton>
                        ) : record.is_valid ? (
                          <Dropdown
                            options={[
                              {
                                label: (
                                  <span className="inline-flex gap-x-2 items-center text-neutral-900 text-sm">
                                    <Iconfont
                                      name="delete"
                                      color="#31333E"
                                      size={16}
                                    />
                                    {tt('Remove')}
                                  </span>
                                ),
                                value: 'Remove'
                              }
                            ]}
                            onMenuClick={(value: string) => {
                              onDeleteTips(record)
                              setCurrentEditRecord(record)
                            }}
                          >
                            <IconButton className="w-9 rounded-full bg-[#EDF0F2]">
                              <MoreSvg width={14} />
                            </IconButton>
                          </Dropdown>
                        ) : (
                          <DasButton
                            className="!h-8 !bg-white !px-4 !py-0"
                            loading={record.loading}
                            small
                            status="normal"
                            isLoadingGradient={false}
                            onClick={() => {
                              onVerify(record)
                              setCurrentEditRecord(record)
                            }}
                          >
                            {tt('Verify')}
                          </DasButton>
                        )}
                      </span>
                    </div>
                  )
                })}
                <div
                  className={cn(
                    'w-full h-[50px] px-2.5 py-4 bg-white border-[0.5px] border-dashed border-neutral-900 justify-center items-center gap-2 inline-flex rounded-full cursor-pointer hover:bg-neutral-100 text-gray-600 text-base max-lg:border-0'
                  )}
                  onClick={addAddress}
                >
                  <Iconfont color="#121314" name="add" size="16" />
                  {tt('Add address')}
                </div>
              </>
            ) : (
              <img width={223} src={AddressListPng.src} alt="logo" />
            )}
          </div>
          <div className="relative w-[66px] max-lg:w-full inline-flex justify-center max-lg:h-[36px] max-lg:items-center">
            <div className="border-l border-gray-100 w-[1px] max-lg:border-l-0 max-lg:border-b max-lg:w-full max-lg:h-[1px]"></div>
            <span className="flex-1 absolute top-0 bottom-0 m-auto inline-flex flex-col max-lg:flex-row items-center gap-y-1 h-[74px] bg-white py-2 max-lg:h-9 max-lg:px-3 max-lg:bg-slate-50 max-lg:rounded-full max-lg:border max-lg:border-gray-100">
              <Iconfont
                name="arrow-right-up"
                color="#20232D"
                className="rotate-45 !w-9 !h-9 max-lg:!w-5 max-lg:!h-5 max-lg:mr-1 max-lg:rotate-[135deg]"
              />
              <span className="text-neutral-400 max-lg:text-zinc-800 text-sm">
                {tt('Named to')}
              </span>
            </span>
          </div>
          <div className="inline-flex flex-1 p-6 max-lg:px-4 max-lg:py-8 justify-center items-center">
            <span
              className="text-zinc-800 font-bold leading-none break-all text-center text-[44px] md:text-[52px]"
              style={{
                fontSize: aliasNameFontSize
                  ? `${aliasNameFontSize}px`
                  : undefined
              }}
            >
              {aliasName || '.bit'}
            </span>
          </div>
        </div>
      </div>
      <ConfirmToRemoveDialog
        showing={confirmToRemoveDialogShowing}
        isOldAlias={isOld}
        deleteOldAlias={handleDeleteOld}
        deleteAlias={() => {
          setConfirmToRemoveDialogShowing(false)
          onDelete()
        }}
        aliasName={aliasName}
        onClose={() => {
          updateRecords({ ...currentEditRecord, loading: false })
          setConfirmToRemoveDialogShowing(false)
        }}
      />
      <TransStatusDialog
        showing={submittedShown}
        actions={actions}
        onClose={handleRemoveSuccess}
        handleTxStatus={setTxStatus}
        account={aliasName}
        address={
          shouldBeUseCccFunc(currentEditRecord)
            ? ckbAddress
            : connectedAccount?.address!
        }
        coinType={
          shouldBeUseCccFunc(currentEditRecord)
            ? CKB.coinType
            : connectedAccount?.chain?.coinType!
        }
        successCallback={handleRemoveSuccess}
      />
      <TransStatusDialogOld
        actions={['remove']}
        onClose={handleRemoveSuccess}
        handleTxStatus={setTxStatus}
        showing={submittedOldShowing}
        account={aliasName}
        address={connectedAccount?.address!}
        coinType={connectedAccount?.chain?.coinType!}
        successCallback={handleRemoveSuccess}
      />
      <SwitchWalletTips
        showing={permissionTipsShowing}
        title={tt('Tips')}
        message={tt(
          'To proceed, you need to first disconnect the current wallet and reconnect with the following address:'
        )}
        address={permissionTipsAddress}
        coinType={permissionTipsCoinType}
        onSwitchWallet={onSwitchWallet}
        onClose={() => {
          setPermissionTipsShowing(false)
        }}
      />
      <span
        className="fixed bottom-6 right-6 inline-flex p-3 bg-white rounded-full shadow border border-gray-100 cursor-pointer max-lg:hidden"
        onClick={showFaq}
      >
        <Iconfont name="help" color="#525866" size={20} />
      </span>
      <div className="flex justify-center mt-9 lg:hidden" onClick={showFaq}>
        <IconButton
          className="bg-gray-600/10 w-fit mt-4 text-sm text-zinc-500 border-none hover:bg-gray-600/10 hover:opacity-70"
          size="small"
        >
          <Iconfont name="guide" className="mr-1" size={20} />
          {tt('FAQ')}
        </IconButton>
      </div>
    </div>
  )
}
