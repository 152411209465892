import React from "react";
import styles from "./base-list-item.module.scss";
import { BaseListItemType } from "@did/das-balance-types";

export const BaseListItem: BaseListItemType = ({
  to,
  plain,
  children,
  head,
  tail,
  onClick,
  className,
}) => {
  const contentClass = plain
    ? `${styles.item_content} ${styles._plain}`
    : styles.item_content;

  const ListItem = to ? "a" : "div";
  const listItemProps = to ? { href: to } : {};

  return (
    <ListItem
      className={`${styles.base_list_item} ${className}`}
      {...listItemProps}
      onClick={onClick}
    >
      <div className={contentClass}>
        {head && <div className={styles.item_head}>{head}</div>}
        <div className={styles.item_main}>{children}</div>
        {tail && <div className={styles.item_tail}>{tail}</div>}
      </div>
    </ListItem>
  );
};
