import { ConnectWalletType } from "@did/das-balance-types";
import { collapseString } from "@did/tools";

export const ConnectWallet: ConnectWalletType = ({
  slots,
  chainIconName,
  address,
  empty,
  onClick,
  className,
  bitName,
}) => {
  return (
    <slots.button
      className={className}
      slots={{ icon: slots.icon }}
      onClick={onClick}
    >
      {!address && empty}
      {address && (
        <>
          <slots.icon name={chainIconName} size="20" className=" mr-2" />
          {!bitName && (
            <span className=" text-sm">{collapseString(address, 4, 4)}</span>
          )}
          {bitName && (
            <div className=" text-left" >
              <div className=" text-sm font-semibold leading-4 ">{bitName}</div>
              <div className=" text-xs text-gray-400 font-semibold leading-4 ">{collapseString(address, 4, 4)}</div>
            </div>
          )}
        </>
      )}
    </slots.button>
  );
};
