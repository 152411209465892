import { ALERT_KEY } from '@did/constants'
import { DASDidPointTokenId } from '@did/constants/chain'
import errno from '@did/constants/errno'
import { useDasBalanceContext } from '@did/das-app-context'
import {
  AUCTION_PARAM_TYPE,
  BidStatus,
  IAuctionInfoRes,
  IAuctionPriceRes,
  IToken
} from '@did/types'
import { useEffect, useMemo, useState } from 'react'
import { ErrorInfo } from '@did/monitoring'
import { handleError } from '@did/das-app-utils'

const DP: IToken = {
  name: 'DIDCredits',
  symbol: 'Credit',
  token_id: DASDidPointTokenId
} as any

export const useDutchAuction = (param: { account: string }) => {
  const { account } = param

  const [premiumPrice, setPremiumPrice] = useState<IAuctionPriceRes>()
  const [loading, setLoading] = useState(true)
  const [dPtoken, setDPtoken] = useState<IToken>(DP)

  const { walletSdk, services, connectedAccount, router, alert, tt } =
    useDasBalanceContext()
  const [auctionInfo, setAuctionInfo] = useState<IAuctionInfoRes>()
  const [confirmRegisterLoading, setConfirmRegisterLoading] = useState(false)
  const [confirmRegisterShowing, setConfirmRegisterShowing] = useState(false)
  const [onRegisterLoading, setOnRegisterLoading] = useState(false)
  const [
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing
  ] = useState(false)

  const getTokens = async () => {
    try {
      const res = await services.common.tokens()
      if (!(res && res.token_list && res.token_list.length > 0)) {
        return
      }
      const DPtoken = res.token_list.find((token) => {
        return token.token_id === DASDidPointTokenId
      })
      setDPtoken(DPtoken || DP)
    } catch (e: any) {
      ErrorInfo.error(e)
    }
  }

  const getPremiumPrice = async (bitName: string) => {
    try {
      setLoading(true)
      const res = await services.account.auctionPrice({
        account: bitName
      })

      if (res) {
        setPremiumPrice(res)
      }
      return res
    } catch (err: any) {
      handleError(err, tt, alert, ALERT_KEY.API_ERROR)
    } finally {
      setLoading(false)
    }
  }

  const getAuctionInfo = async (bitName: string) => {
    try {
      setLoading(true)
      const res = await services.account.auctionInfo({
        type: AUCTION_PARAM_TYPE.BLOCKCHAIN,
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        account: bitName
      })

      if (res.bid_status === BidStatus.BidStatusByMe) {
        router.push(`/account/create/status/auction/${account}`, {
          hash: res.hash
        })
      }

      if (res) {
        setAuctionInfo(res)
      }
      return res
    } catch (err: any) {
      handleError(err, tt, alert, ALERT_KEY.API_ERROR)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getTokens()
  }, [])

  useEffect(() => {
    if (!account) return
    getPremiumPrice(account)
    getAuctionInfo(account)
  }, [account, connectedAccount?.address])

  const { totalDays, gracePeriod } = useMemo(() => {
    if (!auctionInfo) return {}
    return {
      totalDays: Math.floor(
        (auctionInfo.end_auction_time - auctionInfo.start_auction_time) /
          (60 * 60 * 24)
      ),
      gracePeriod: Math.floor(
        (auctionInfo.start_auction_time - auctionInfo.expired_at) /
          (60 * 60 * 24)
      )
    }
  }, [auctionInfo])

  const handleRegister = async () => {
    try {
      if (onRegisterLoading) {
        return
      }
      setOnRegisterLoading(true)
      const isInit = await walletSdk?.initWallet()
      if (!isInit) {
        setOnRegisterLoading(false)
        return
      }

      setOnRegisterLoading(false)
      setConfirmRegisterShowing(true)
    } catch (e: any) {
      ErrorInfo.error(e)
    }
  }

  const handleConfirm = async () => {
    if (!connectedAccount?.address) return

    setConfirmRegisterLoading(true)
    const { signTxList, onClose } = await walletSdk?.initSignContext()

    try {
      const bidRes = await services.account.auctionBid({
        type: AUCTION_PARAM_TYPE.BLOCKCHAIN,
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        coin_type: connectedAccount.chain.coinType,
        account: account
      })

      const trxData = await signTxList(bidRes)
      const { hash } = await services.account.sendTrx(trxData)
      router.push(`/account/create/status/auction/${account}`, {
        hash
      })
    } catch (err: any) {
      console.log(err)
      onClose?.()
      if (err.code === errno.apiErrorCodeInsufficientBalance) {
        setDasBalanceInsufficientDialogShowing(true)
        return
      }

      // use reject
      if (
        [
          errno.metaMaskUserRejectedAccountAccess,
          errno.metaMaskUserDeniedMessageSignature
        ].includes(err.code) ||
        err === errno.tronLinkConfirmationDeclinedByUser ||
        err.message === errno.walletConnectUserRejectedTheTransaction ||
        err.shortMessage === errno.walletConnectUserRejectedTheRequest ||
        err.message === errno.passkeyUserReject
      ) {
        console.log('user reject')
        return
      }

      alert({
        title: tt('Error'),
        message: err.code ? `${err.code}: ${err.message}` : `${err}`
      })
    } finally {
      setConfirmRegisterLoading(false)
    }
  }

  return {
    premiumPrice,
    loading,
    handleRegister,
    onRegisterLoading,
    confirmRegisterShowing,
    setConfirmRegisterShowing,
    handleConfirm,
    confirmRegisterLoading,
    auctionInfo,
    totalDays,
    gracePeriod,
    dasBalanceInsufficientDialogShowing,
    setDasBalanceInsufficientDialogShowing,
    setConfirmRegisterLoading,
    dPtoken
  }
}
