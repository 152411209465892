import { useDasBalanceContext } from '@did/das-app-context'
import { AuctionAccountListType } from '@did/das-app-types/components'
import { cn } from '@did/tools'
import {
  AUCTION_PARAM_TYPE,
  IAuctionOrderStatusRes,
  IAuctionPendingOrderRes
} from '@did/types/das-services'
import { Iconfont, DidAvatar } from '@did/uikit'
import { useCallback, useEffect, useState } from 'react'
import { ErrorInfo } from '@did/monitoring'

export const AuctionAccountList: AuctionAccountListType = () => {
  const { tt, connectedAccount, services, router } = useDasBalanceContext()

  const [registeringAccounts, setRegisteringAccounts] =
    useState<IAuctionPendingOrderRes>([])

  const getRegisteringAccounts = useCallback(async () => {
    if (!connectedAccount?.address || !connectedAccount?.chain?.coinType) {
      return
    }
    try {
      const res = await services.account.auctionPendingOrder({
        key_info: {
          coin_type: connectedAccount?.chain?.coinType!,
          key: connectedAccount?.address!
        },
        type: AUCTION_PARAM_TYPE.BLOCKCHAIN
      })
      setRegisteringAccounts(res || [])
    } catch (err: any) {
      ErrorInfo.error(err)
      throw err
    }
  }, [connectedAccount?.chain?.coinType, connectedAccount?.address, services])

  useEffect(() => {
    getRegisteringAccounts()
  }, [connectedAccount?.address, getRegisteringAccounts])

  const seeDetails = useCallback(
    (account: IAuctionOrderStatusRes) => {
      router.push(`/account/create/status/auction/${account.account}`, {
        hash: account?.hash
      })
    },
    [router]
  )

  return (
    <div className={cn('w-full')}>
      <ul>
        {registeringAccounts.map((account) => (
          <li
            key={account.account}
            className="flex justify-between items-center py-0 px-5 mb-2 h-[90px] bg-white rounded-[12px] border-[1px] border-status-border-color cursor-pointer hover:bg-[#F7F9FA]"
            onClick={() => seeDetails(account)}
          >
            <div className="flex justify-between items-center text-lg font-semibold text-tab-active break-words hyphens-auto leading-[21px]">
              <DidAvatar
                account={account?.account || ''}
                fontSize="20px"
                lineClassName="w-[23px] h-[10px] mb-[-2px]"
                textClassName="mb-[-2px]"
                className="mr-3"
                size={44}
              />

              <div className="flex flex-col text-lg font-semibold text-tab-active break-all hyphens-auto">
                <span className="mr-2">{account.account}</span>
                <div>
                  <span className="inline-flex items-center mt-4px py-0 px-1 rounded text-xs text-success-font-color bg-list-status-bg-color">
                    <Iconfont
                      className="animate-rotate360DegAnimation"
                      name="loading"
                      size="16"
                      color="#2EB67D"
                    />
                    {tt('Auction successful, registering')}
                  </span>
                </div>
              </div>
            </div>
            <Iconfont name="arrow-right" color="#121314" size="18" />
          </li>
        ))}
      </ul>
    </div>
  )
}
